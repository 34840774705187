import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'primereact/button';
import {authenticationService} from "../../index";

export const AccessDeniedPage = (props: any) => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/');
    };

    return (
        <div className="exception-body accessdenied">
            <div className="exception-panel">
                <h1>ACCESS</h1>
                <h3>denied</h3>
                <p>Your account is inactive.</p>
                <p>Contact system administrator to activate your account.</p>
                <Button type="button" label="Go back to home" onClick={goDashboard}></Button>
                <Button type="button" label="Logout" onClick={authenticationService.logout}></Button>
            </div>
            <div className="exception-footer">
                <img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-logo" alt="exception-logo" />
                <img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-appname" alt="exception-appname" />
            </div>
        </div>
    );
};

import React, {FC} from "react";
import {Card} from "../../common/components/card/Card.component";
import {Toolbar} from "primereact/toolbar";
import {SplitButton} from "primereact/splitbutton";
import {DayRange} from "../../common/service/util/DayRange";
import {Day} from "../../common/service/util/Day";
import {PeriodChanger} from "../../common/components/periodChanger/PeriodChanger.component";
import {getChangePeriodByMonth} from "../../common/components/periodChanger/service/PeriodChangeStrategy";
import {getMonthYearChangerDisplay} from "../../common/components/periodChanger/service/PeriodChangerDisplay";
import {Button} from "primereact/button";
import {translate} from "../../common/lang/service/Translate.service";
import {CashReportStatus} from "../service/CashReportPage.type";

interface CashReportToolbarProps {
    onPeriodChange: (period: DayRange) => void;
    addPayments: () => void;
    addSalaries: () => void;
    acceptCashReport: () => void;
    downloadCashReport: () => void;
    recalculateReport: () => void;
    addBankDeposit: () => void;
    importPayments: () => void;
    importProofs: () => void;
    status: CashReportStatus;
    hasSalaries: boolean;
}

export const CashReportToolbar: FC<CashReportToolbarProps> = (props: CashReportToolbarProps) => {
    const items = [
        {
            label: translate("SALARIES"),
            command: () => {
                props.addSalaries();
            },
            visible: !props.hasSalaries
        },
        {
            label: translate("CASH_DEPOSIT"),
            command: () => {
                props.addBankDeposit();
            }
        },
        {
            label: translate("IMPORT_PAYMENTS"),
            command: () => {
                props.importPayments();
            }
        },
        {
            label: translate("IMPORT_PROOFS"),
            command: () => {
                props.importProofs();
            }
        },
    ];

    const canEditReport = () => {
        return props.status !== CashReportStatus.CLOSED;
    }

    const canAcceptReport = () => {
        return props.status === CashReportStatus.VALID;
    }

    const canDownloadReport = () => {
        return props.status === CashReportStatus.CLOSED;
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                {canEditReport() && <SplitButton label={translate("PAYMENTS")} onClick={props.addPayments} icon="pi pi-plus"
                              model={items} className={"mr-2"}/>}
                {canAcceptReport() && <Button label={translate("ACCEPT")} onClick={props.acceptCashReport} icon={"pi pi-check"} className={"p-button-success mr-2"}/>}
                {canDownloadReport() && <Button label={translate("REPORT")} onClick={props.downloadCashReport} icon={"pi pi-download"} className={"p-button-secondary mr-2"}/>}
                {canDownloadReport() && <Button label={translate("RECALCULATE")} onClick={props.recalculateReport} className={"p-button-secondary"}/>}
            </>
        );
    };

    const leftToolbarPanel = () => {
        const startPeriod = new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay());
        return (
            <PeriodChanger
                startPeriod={startPeriod}
                onNextPeriod={props.onPeriodChange}
                onPreviousPeriod={props.onPeriodChange}
                dayInLastPeriod={startPeriod.getMiddleDay()}
                dayInFirstPeriod={Day.getCompanyStartDate()}
                changePeriod={getChangePeriodByMonth()}
                display={getMonthYearChangerDisplay()}
            />
        );
    }

    return <div className={"col-12"}>
        <Card>
            <Toolbar
                start={leftToolbarPanel}
                end={rightToolbarTemplate}
            />
        </Card>
    </div>;
};

import styled from "styled-components";

export const StyledUserContractInformationTab = styled.div`
  .fields {
    @media screen and (min-width: 430px) {
      display: flex;
    }
  }

  .contract-field {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .info-label {
    padding-bottom: 9px;
    padding-left: 10px;
    color: #868C9B;
    font-weight: 500;
  }
`;

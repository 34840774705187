import styled from "styled-components";

export const StyledExpensesPage = styled.div`
  .buttons-row {
    display: flex;
    justify-content: space-between;
  }
  
  .tags {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .field {
    margin-bottom: unset;
  }
  
  .expense-card-content {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

import React, {useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import {classNames} from 'primereact/utils';
import {authenticationService} from "../../../../../../index";
import {UserBasicInfoDTO} from "../../../../security/userBasicInfo/service/UserBasicInfo.type";
import {isDefined} from "../../../../service/util/ObjectUtils";
import {Translate} from "../../../../lang/Translate.component";
import {useCurrentUser} from "../../../../security/userBasicInfo/service/CurrentUser.hook";
import {useAppViewConfig} from "../appViewConfig/service/redux/UseAppViewConfig.hook";
import {Link} from 'react-router-dom';
import {isDefinedAndNotEmpty} from "../../../../service/util/StringUtils";

export const InlineMenu = (props: any) => {
    const menuRef = useRef(null);
    const appViewConfig = useAppViewConfig();

    const userBasicInfo: UserBasicInfoDTO = useCurrentUser();
    const getUserProfileLink = (): string => `/user/${userBasicInfo.id}/profile`

    const getAvatarUrl = (): string => {
        const avatarUrl = userBasicInfo.avatarUrl;
        return isDefined(avatarUrl)
            ? avatarUrl
            : "/assets/layout/images/profile-image.png";
    }

    const getFullName = () => {
        if (isDefinedAndNotEmpty(userBasicInfo.displayName)) {
            return userBasicInfo.displayName;
        }

        return isDefinedAndNotEmpty(userBasicInfo.firstName)
            ? userBasicInfo.firstName + " " + userBasicInfo.lastName
            : userBasicInfo.email;
    }

    const isSlim = () => {
        return appViewConfig.menuMode === 'slim';
    };

    const isStatic = () => {
        return appViewConfig.menuMode === 'static';
    };

    const isSidebar = () => {
        return appViewConfig.menuMode === 'sidebar';
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    return (
        <>
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && (
                <div
                    className={classNames('layout-inline-menu', {'layout-inline-menu-active': props.activeInlineProfile})}>
                    <button className="layout-inline-menu-action p-link" onClick={props.onChangeActiveInlineMenu}>
                        <img src={getAvatarUrl()} alt="avatar" style={{width: '44px', height: '44px'}}/>
                        <span className="layout-inline-menu-text">{getFullName()}</span>
                        <i className="layout-inline-menu-icon pi pi-angle-down"></i>
                    </button>
                    <CSSTransition nodeRef={menuRef} classNames="p-toggleable-content"
                                   timeout={{enter: 1000, exit: 450}} in={props.activeInlineProfile} unmountOnExit>
                        <ul ref={menuRef} className="layout-inline-menu-action-panel">
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={authenticationService.logout}>
                                    <i className="pi pi-power-off pi-fw"></i>
                                    <span>
                                        {!isSlim() && <Translate id={"LOGOUT"}/>}
                                    </span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={props.onSettingsClick}>
                                    <i className="pi pi-cog pi-fw"></i>
                                    {!isSlim() && <Translate id={"SETTINGS"}/>}
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <Link className="p-link" to={getUserProfileLink()}>
                                    <i className="pi pi-user pi-fw"></i>
                                    {!isSlim() && <Translate id={"PROFILE"}/>}
                                </Link>
                            </li>
                        </ul>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

import React from 'react';
import {useAppViewConfig} from "../appViewConfig/service/redux/UseAppViewConfig.hook";

export const Footer = () => {

    const colorScheme: string = useAppViewConfig().colorScheme;

    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <img id="footer-logo" src={`/assets/layout/images/logo-${colorScheme === 'light' ? 'dark' : 'light'}.png`} alt="atlantis-layout" />
                <span className="app-name" style={{fontSize: "15px", fontWeight:"700", opacity: "0.9"}}>STApp</span>
            </div>
            <span className="copyright">&#169; Webrance - 2023</span>
        </div>
    );
};

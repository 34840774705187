import React, {FC} from "react";
import {isSuccess} from "../../common/service/result/Result.type";
import {ImportFileModal} from "../../common/modal/importFileModal/ImportFileModal.component";
import {translate} from "../../common/lang/service/Translate.service";
import {importBankTransfersRest} from "../service/BankTransfers.service";

interface ImportTransfersModalProps {
    hideModal: () => void;
    refreshReport: () => void;
    month: number;
    year: number;
}

export const ImportTransfersModal: FC<ImportTransfersModalProps> = (props: ImportTransfersModalProps) => {
    const onSave = async (files: File[]) => {
        const result = await importBankTransfersRest(props.month, props.year, files[0]);
        if (isSuccess(result)) {
            props.refreshReport();
            props.hideModal();
        }
    }

    return <ImportFileModal hideModal={props.hideModal} onSave={onSave} header={translate("IMPORT_BANK_TRANSFERS")}/>;
};

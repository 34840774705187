import React, {FC} from "react";
import {RepeatableExpenseDTO} from '../service/RepeatableExpensesPage.type';
import {Button} from 'primereact/button';
import {ColumnProps, FilterType} from '../../../common/components/table/service/Table.types';
import {translate} from '../../../common/lang/service/Translate.service';
import {Table} from '../../../common/components/table/Table.component';
import {formatAsCurrency} from '../../../common/service/util/CurrencyUtil';

interface RepeatableExpensesTableProps {
    repeatableExpenses: RepeatableExpenseDTO[];
    onEdit: (repeatableExpense: RepeatableExpenseDTO) => void;
    onDelete: (id: string) => void;
}

export const RepeatableExpensesTable: FC<RepeatableExpensesTableProps> = (props: RepeatableExpensesTableProps) => {
    const editBodyTemplate = (rowData: RepeatableExpenseDTO) => (
        <div className="actions">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => props.onEdit(rowData)}/>
            <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onDelete(rowData.id)}/>
        </div>
    );

    const columnProps: ColumnProps[] = [
        {header: translate('EXPENSE_TYPE'), fieldName: 'expenseType', sortable: true, filterType: FilterType.INPUT, transformValue: translate},
        {header: translate('DESCRIPTION'), fieldName: 'description', sortable: true, filterType: FilterType.INPUT},
        {header: translate('AMOUNT'), fieldName: 'amount', sortable: true, filterType: FilterType.INPUT, transformValue: formatAsCurrency},
        {header: "", fieldName: "ID", body: editBodyTemplate, width: '87px'},
    ];

    return <Table
        values={props.repeatableExpenses}
        dataKey={"id"}
        columnProps={columnProps}
        multiSortMeta={[{field: "expenseType", order: 1}]}
    />;
};

import {Day} from "../../../common/service/util/Day";

export interface CleaningDictionaryDTO {
    id?: string;
    name?: string;
    activationDate?: string;
    deactivationDate?: string;
    cleaningInterval?: number;
}

export const getInitialCleaningDictionaryDTO = () => ({
    id: undefined,
    name: '',
    activationDate: Day.today().toFormattedString(),
    deactivationDate: undefined,
    cleaningInterval: undefined,
});

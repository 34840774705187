import {FilterStrategy} from "../../../common/service/util/filters/FilterStrategy";
import {isNotDefined} from "../../../common/service/util/ObjectUtils";
import {ExpenseReadDTO} from "./ExpensesPage.type";
import {Day} from "../../../common/service/util/Day";

export class RequiresPaymentFilterStrategy implements FilterStrategy {
    constructor() {
    }

    filter(objectList: ExpenseReadDTO[], valueToFilter: boolean): ExpenseReadDTO[] {
        if (isNotDefined(valueToFilter) || !valueToFilter) {
            return objectList;
        }

        return objectList.filter(value => Day.fromString(value.invoiceDate).isBeforeOrEqualDay(Day.today()) && !value.isPaid)
    };
}

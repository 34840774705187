import React, {FC, useState} from "react";
import {StyledExpenseModal} from './ExpenseModal.style';
import {ActionModal} from '../../../common/modal/actionModal/ActionModal.component';
import {translate} from '../../../common/lang/service/Translate.service';
import {ExpenseDTO, ExpenseReadDTO, ExpenseType, PaymentType} from '../service/ExpensesPage.type';
import {SingleSelectInput} from '../../../common/components/input/singleSelectInput/SingleSelectInput.component';
import {
    getDictionarySelectOption,
    getEnumSelectOptions
} from '../../../common/components/input/multiselectChips/service/SelectOption.type';
import {CurrencyInput} from '../../../common/components/input/currencyInput/CurrencyInput.component';
import {Checkbox} from '../../../common/components/input/checkbox/Checkbox.component';
import {TextAreaInput} from '../../../common/components/input/textAreaInput/TextAreaInput.component';
import {LabelType} from '../../../common/components/input/inputLabel/InputLabel.component';
import {DictionaryDTO} from '../../../common/service/util/dictionary/Dictionary.type';
import {DateInput} from '../../../common/components/input/dateInput/DateInput.component';
import {Day} from "../../../common/service/util/Day";
import {isDefined} from '../../../common/service/util/ObjectUtils';
import {DayRange} from '../../../common/service/util/DayRange';
import {FileInput} from '../../../common/components/fileInput/FileInput.component';

interface ExpenseModalProps {
    expense?: ExpenseReadDTO;
    hideModal: () => void;
    addExpense: (expense: ExpenseDTO, files?: File[]) => void;
    updateExpense: (expenseId: string, expense: ExpenseDTO, files?: File[]) => void;
    supplierDictionary: DictionaryDTO[];
    period: DayRange;
}

export const ExpenseModal: FC<ExpenseModalProps> = (props: ExpenseModalProps) => {
    const [expense, setExpense] = useState<ExpenseDTO>({...props.expense});
    const [files, setFiles] = useState<File[]>()

    const saveExpense = () => {
        if (isValid()) {
            if (isDefined(props.expense.expenseId)) {
                props.updateExpense(props.expense.expenseId, expense, files);
            } else {
                props.addExpense(expense, files);
            }
        }
    }

    const isValid = () => {
        return isDefined(expense.paymentType)
            && isDefined(expense.expenseType)
            && isDefined(expense.invoiceDate);
    }

    const setValue = (fieldName: string, newValue: any) => {
        setExpense(prevState => ({
            ...prevState,
            [fieldName]: newValue,
            isPaid: fieldName === "paymentDate" ? isDefined(newValue) : prevState.isPaid
        }));
    }

    return      <ActionModal onConfirm={saveExpense} onReject={props.hideModal} isVisible={true} header={translate('EXPENSE')} isSaveDisabled={!isValid()}>
            <StyledExpenseModal> <div className="formgrid grid">
                <SingleSelectInput
                    options={getEnumSelectOptions(ExpenseType)}
                    selectedValue={expense.expenseType}
                    setSelectedValue={newValue => setValue('expenseType', newValue)}
                    label={translate("EXPENSE_TYPE")}
                    labelType={LabelType.VERTICAL}
                    className={"col"}
                    showClear={true}
                />

                <SingleSelectInput
                    options={getEnumSelectOptions(PaymentType)}
                    selectedValue={expense.paymentType}
                    setSelectedValue={newValue => setValue('paymentType', newValue)}
                    label={translate("PAYMENT_TYPE")}
                    labelType={LabelType.VERTICAL}
                    className={"col"}
                    showClear={true}
                />

                <SingleSelectInput
                    options={getDictionarySelectOption(props.supplierDictionary)}
                    selectedValue={expense.supplierId}
                    setSelectedValue={newValue => setValue('supplierId', newValue)}
                    label={translate("SUPPLIER")}
                    labelType={LabelType.VERTICAL}
                    className={"col"}
                    showClear={true}
                />
            </div>

            <div className="formgrid grid">
                <CurrencyInput
                    value={expense.amount}
                    setValue={newValue => setValue('amount', newValue)}
                    minValue={0}
                    maxValue={99999}
                    label={translate("AMOUNT")}
                    labelType={LabelType.VERTICAL}
                    className={"col"}

                />

                <Checkbox
                    value={expense.isPaid}
                    setValue={newValue => setValue('isPaid', newValue)}
                    triState={false}
                    label={translate("IS_PAID")}
                    className={"col checkbox-padding"}
                    verticalInputAlign={true}
                />
            </div>

            <div className="formgrid grid">
                <DateInput
                    setValue={newValue => setValue('invoiceDate', newValue?.dateToUrlString())}
                    value={Day.fromString(expense.invoiceDate)}
                    label={translate("INVOICE_DATE")}
                    labelType={LabelType.VERTICAL}
                    className={"col"}
                />

                <DateInput
                    setValue={newValue => setValue('paymentDate', newValue?.dateToUrlString())}
                    value={Day.fromString(expense.paymentDate)}
                    label={translate("PAYMENT_DATE")}
                    labelType={LabelType.VERTICAL}
                    className={"col"}
                />

                <DateInput
                    setValue={newValue => setValue('deadlineDate', newValue?.dateToUrlString())}
                    value={Day.fromString(expense.deadlineDate)}
                    label={translate("DEADLINE_DATE")}
                    labelType={LabelType.VERTICAL}
                    className={"col"}
                />
            </div>

            <div className="formgrid grid">
                <TextAreaInput
                    setValue={newValue => setValue('description', newValue)}
                    value={expense.description}
                    rows={5}
                    label={translate("DESCRIPTION")}
                    labelType={LabelType.VERTICAL}
                    className={"col"}
                />
            </div>

            <FileInput
                onSelect={files => setFiles(files)}
                files={files}
                maxFileSize={10000}
                multiple={true}
            />
            </StyledExpenseModal>
        </ActionModal>;
};

import {Day, DayDTO} from "./Day";
import {isDefined, isNotDefined} from "./ObjectUtils";

export interface DayRangeDTO {
    dayFrom: DayDTO;
    dayTo: DayDTO;
}

export class DayRange {
    constructor(private readonly dayFrom?: Day, private readonly dayTo?: Day) {
        // @ts-ignore
        this.validateRange(dayFrom, dayTo);
        this.dayFrom = dayFrom;
        this.dayTo = dayTo;
    }

    static getDateRangeFromDates = (dateFrom: Day, dateTo: Day): DayRange => {
        const dayFrom = isDefined(dateFrom) ? dateFrom : undefined;
        const dayTo = isDefined(dateTo) ? dateTo : undefined;
        return new DayRange(dayFrom, dayTo);
    };

    static fromDTO = (dayRangeDTO: DayRangeDTO): DayRange => {
        return new DayRange(Day.fromString(dayRangeDTO?.dayFrom?.date),
            Day.fromString(dayRangeDTO?.dayTo?.date));
    };

    static getCurrentQuarterPeriod = (): DayRange => {
        return new DayRange(Day.today().getQuarterFirstDay(), Day.today().getQuarterLastDay());
    };

    static getCurrentMonthPeriod = (): DayRange => {
        return new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay());
    };

    static getQuarterPeriod = (dayInQuarter: Day): DayRange => {
        return new DayRange(dayInQuarter.getQuarterFirstDay(), dayInQuarter.getQuarterLastDay());
    };

    static getNextQuarterPeriod = (quarter: DayRange): DayRange => {
        const lastDayOfQuarter = quarter.getDayTo();
        const firstDayOfNextQuarter = lastDayOfQuarter.getDayAfter();
        return DayRange.getQuarterPeriod(firstDayOfNextQuarter);
    };

    static getPreviousQuarterPeriod = (quarter: DayRange): DayRange => {
        const firstDayOfQuarter = quarter.getDayFrom();
        const lastDayOfPreviousQuarter = firstDayOfQuarter.getDayBefore();
        return DayRange.getQuarterPeriod(lastDayOfPreviousQuarter);
    };

    getDateFrom = (): Date => {
        return this.dayFrom?.getDate();
    };

    getDateTo = (): Date => {
        return this.dayTo?.getDate();
    };

    getDayFrom = (): Day => {
        return this.dayFrom;
    };

    isCurrent = (): boolean => {
        return this.containsDay(Day.today());
    };

    getDayTo = (): Day => {
        return this.dayTo;
    };

    hasLimit = (): boolean => {
        return isDefined(this.getDateFrom()) || isDefined(this.getDateTo());
    };

    containsDay = (day: Day): boolean => {
        if (isNotDefined(day)) {
            throw new Error("Could not check if undefined day is in range");
        }

        return this.isWithinStartRangeBoundary(day) && this.isWithinEndRangeBoundary(day);
    };

    private isWithinStartRangeBoundary = (day: Day): boolean => {
        const isBefore: boolean = this.dayFrom?.isBeforeOrEqualDay(day);
        return isNotDefined(isBefore) ? true : isBefore;
    };

    private isWithinEndRangeBoundary = (day: Day): boolean => {
        const isAfter: boolean = this.dayTo?.isAfterOrEqualDay(day);
        return isNotDefined(isAfter) ? true : isAfter;
    };

    private validateRange = (dayFrom: Day, dayTo: Day): void => {
        if (isDefined(dayTo) && isDefined(dayFrom) && dayFrom.isAfterDay(dayTo)) {
            throw new Error("Data range is invalid");
        }
    };

    getPeriodDurationInDays(): number {
        return this.dayTo.dayDiff(this.dayFrom);
    }

    getMiddleDay(): Day {
        const newDay: Day = Day.fromDate(this.dayFrom.getDate());
        return newDay.addDays(Math.round(this.getPeriodDurationInDays() / 2));
    }

    toString(): string {
        return this.dayFrom.toString() + " - " + this.dayTo.toString();
    }

    equals = (other: any) => {
        return other instanceof DayRange
            && this.dayFrom.equals(other.dayFrom)
            && this.dayTo.equals(other.dayTo);
    };
}

export interface UserBasicInfoDTO {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    displayName: string,
    avatarUrl: string
}

export const UserBasicInfoRetrieved = "user/current";

export interface RetrieveUserBasicInfoAction {
    type: typeof UserBasicInfoRetrieved;
    userBasicInfo: UserBasicInfoDTO;
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './app/App';
import {AuthenticationServiceImpl} from "./app/common/security/service/AuthenticationServiceImpl";
import {getBaseURL} from "./app/common/service/http/Http.service";
import {AuthenticationService} from "./app/common/security/service/AuthenticationService";
import '@fortawesome/fontawesome-free/css/all.min.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const authenticationService: AuthenticationService = new AuthenticationServiceImpl("/login",
    "",
    getBaseURL() + "auth/tokens/refresh",
    getBaseURL() + "auth/tokens/getIdToken",
    getBaseURL() + "auth/tokens/logout")

root.render(<App/>);

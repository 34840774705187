import styled from "styled-components";

export const StyledPaymentsModal = styled.div`
  .index-column {
    line-height: 30px;
    padding-right: 5px;
  }
  
  .grid {
    margin-right: 0;
    margin-left: 0;
  }
  
  .summary-row {
    justify-content: space-between;
    width: 100%;
    display: flex;
    line-height: 32px;
  }

  .file-input {
    padding-top: 1.714rem;
  }
`;

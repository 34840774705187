import showGrowl from "../redux/ShowGrowl.action";
import {AppDispatch} from "../../redux/Thunk.type";
import {useDispatch} from "react-redux";
import {ToastMessage} from "primereact/toast";
import {CommandResult, Result} from "../../service/result/Result.type";
import {translate} from "../../lang/service/Translate.service";
import {isDefined} from "../../service/util/ObjectUtils";

interface Growl {
    show: (severity: GrowlSeverity, details: string, summary?: string) => void;
    showMessage: (message: ToastMessage) => void;
    showSuccess: (details: string, summary?: string) => void;
    showError: (details: string, summary?: string) => void;
    showWarning: (details: string, summary?: string) => void;
    showInfo: (details: string, summary?: string) => void;
    showResult: (result: CommandResult, successMessage?: string, successSummary?: string, errorMessage?: string, errorSummary?: string) => void;
}

export enum GrowlSeverity {
    SUCCESS = 'success',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error'
}

export function useGrowl(): Growl {
    const dispatch: AppDispatch = useDispatch<AppDispatch>();

    const showMessage = (message: ToastMessage) => dispatch(showGrowl(message));

    const show = (severity: GrowlSeverity, details: string, summary?: string) => showMessage(
        {
            severity: severity,
            summary: isDefined(summary) ? summary : translate('SUCCESS'),
            detail: details
        });

    const showSuccess = (details: string, summary?: string) => show(
        GrowlSeverity.SUCCESS, details, isDefined(summary) ? summary : translate('SUCCESS'),
    );

    const showError = (details: string, summary?: string) => show(
        GrowlSeverity.ERROR, details, isDefined(summary) ? summary : translate('ERROR'),
    );

    const showWarning = (details: string, summary?: string) => show(
        GrowlSeverity.WARN, details, isDefined(summary) ? summary : translate('WARNING'),
    );

    const showInfo = (details: string, summary?: string) => show(
        GrowlSeverity.INFO, details, isDefined(summary) ? summary : translate('WARNING'),
    );

    const showResult = (result: CommandResult, successMessage?: string, successSummary?: string, errorMessage?: string, errorSummary?: string) => {
        if (isSuccess(result)) {
            const message: string = isDefined(successMessage)
                ? errorMessage
                : getSuccessMessage(result);
            showSuccess(message);
        } else {
            const message: string = isDefined(errorMessage)
                ? errorMessage
                : getErrorMessage(result);
            showError(message)
        }
    }
    const isSuccess = (result: CommandResult): boolean => {
        return result.result === Result.SUCCESS;
    }

    const getErrorMessage = (result: CommandResult): string => {
        const reduce = (previousValue, currentValue) => {
            if (previousValue === "") {
                return translate(currentValue);
            }

            return previousValue + "\n" + translate(currentValue);
        };

        return result.messages.isEmpty()
            ? translate("OPERATION_FAILED")
            : result.messages.reduce(reduce, "");
    }

    const getSuccessMessage = (result: CommandResult): string => {
        const reduce = (previousValue, currentValue) => {
            if (previousValue === "") {
                return translate(currentValue);
            }

            return previousValue + "\n" + translate(currentValue);
        };

        return result.messages.isEmpty()
            ? translate("OPERATION_SUCCEEDED")
            : result.messages.reduce(reduce, "");
    }

    return {
        show,
        showMessage,
        showSuccess,
        showError,
        showWarning,
        showInfo,
        showResult
    };
}

import React, {FC, useState} from "react";
import {isDefined} from '../../../common/service/util/ObjectUtils';
import {ActionModal} from '../../../common/modal/actionModal/ActionModal.component';
import {translate} from '../../../common/lang/service/Translate.service';
import {TextInput} from '../../../common/components/input/textInput/TextInput.component';
import {LabelType} from '../../../common/components/input/inputLabel/InputLabel.component';
import {SupplierDictionaryDTO, SupplierType} from '../service/SupplierDictionaryPage.type';
import {SingleSelectInput} from '../../../common/components/input/singleSelectInput/SingleSelectInput.component';
import {getEnumSelectOptions} from '../../../common/components/input/multiselectChips/service/SelectOption.type';
import {isNullOrWhiteSpace} from '../../../common/service/util/StringUtils';

interface SupplierDictionaryModalProps {
    initialEntry: SupplierDictionaryDTO;
    onSave: (entry: SupplierDictionaryDTO) => void;
    hideModal: () => void;
}

export const SupplierDictionaryModal: FC<SupplierDictionaryModalProps> = (props: SupplierDictionaryModalProps) => {
    const [entry, setEntry] = useState<SupplierDictionaryDTO>(props.initialEntry);

    const updateEntry = (field: string, value: any) => {
        setEntry(prevState => ({...prevState, [field]: value}));
    }

    const onConfirm = () => {
        props.onSave(entry);
    }

    const formInvalid = (): boolean => {
        return isNullOrWhiteSpace(entry.supplierType) || isNullOrWhiteSpace(entry.name);
    }

    return <ActionModal
        header={isDefined(props.initialEntry?.id) ? translate("ADD") : translate("EDIT")}
        onConfirm={onConfirm}
        isVisible={true}
        onReject={props.hideModal}
        isSaveDisabled={formInvalid()}
    >
        <div className="formgrid grid">
            <TextInput
                label={translate("NAME")}
                value={entry.name}
                setValue={(value: string) => updateEntry('name', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
            <TextInput
                label={translate("INVOICE_NAME")}
                value={entry.invoiceName}
                setValue={(value: string) => updateEntry('invoiceName', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
        </div>
        <div className="formgrid grid">
            <TextInput
                label={translate("SHOP_URL")}
                value={entry.shopUrl}
                setValue={(value: string) => updateEntry('shopUrl', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
            <SingleSelectInput
                options={getEnumSelectOptions(SupplierType)}
                selectedValue={entry.supplierType}
                labelType={LabelType.VERTICAL}
                label={translate("SUPPLIER_TYPE")}
                setSelectedValue={(value: string) => updateEntry('supplierType', value)}
                className={"col"}
            />
        </div>
    </ActionModal>;
};

import React, {FC, useId} from "react";
import {InputNumber} from "primereact/inputnumber";
import {getDefaultCurrency} from "../../../service/util/CurrencyUtil";
import {isDefined} from "../../../service/util/ObjectUtils";
import {InputLabel, LabelType} from "../inputLabel/InputLabel.component";

interface CurrencyInputProps {
    value: number;
    setValue: (newValue: number) => void;
    label?: string;
    required?: boolean;
    labelType?: LabelType;

    maxValue?: number;
    currency?: string;
    className?: string;
    onKeyDown?: (event) => void;
    minValue?: number;
}

export const CurrencyInput: FC<CurrencyInputProps> = (props: CurrencyInputProps) => {
    const id = useId();
    return <InputLabel id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <InputNumber
            onKeyDown={props.onKeyDown}
            id={id}
            required={props.required}
            value={props.value}
            onValueChange={(e) => props.setValue(e.value)}
            min={isDefined(props.minValue) ? props.minValue : 0}
            max={props.maxValue}
            minFractionDigits={2}
            mode="currency"
            className={"col"}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
            currency={isDefined(props.currency) ? props.currency : getDefaultCurrency()}
        />
    </InputLabel>;
};

import {AppViewConfig, AppViewConfigChange, getInitialAppViewConfig} from "../AppViewConfig.type";
import {AppViewConfigUpdated, UpdateAppViewConfigAction} from "./AppViewConfig.type";

const initialState: AppViewConfig = getInitialAppViewConfig();

export const appViewConfigReducer = (state = initialState,
                                     action: UpdateAppViewConfigAction): AppViewConfig => {
    switch (action.type) {
        case AppViewConfigUpdated:
            return updateAppViewConfig(state, action.appViewConfigChange);
        default:
            return state;
    }
};

function updateAppViewConfig(state: AppViewConfig, appViewConfigChange: AppViewConfigChange): AppViewConfig {
    const newAppViewConfig: AppViewConfig = {...state, ...{[appViewConfigChange.field]: appViewConfigChange.value}};

    saveAppViewConfig(newAppViewConfig);
    return newAppViewConfig;
}

function saveAppViewConfig(appViewConfig: AppViewConfig) {
    window.localStorage.setItem("app-view-config", JSON.stringify(appViewConfig))
}

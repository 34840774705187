import React, {FC, PropsWithChildren} from "react";
import {Modal} from "../Modal.component";
import {translate} from "../../lang/service/Translate.service";
import {Button} from "primereact/button";
import {Divider} from "../../Divider.style";

interface ActionModalProps {
    onConfirm: () => void;
    onReject: () => void;
    isVisible: boolean;
    header: string;
    isSaveDisabled?: boolean;
}

export const ActionModal: FC<PropsWithChildren<ActionModalProps>> = (props: PropsWithChildren<ActionModalProps>) => {
    const footer = <>
        <Divider margin={"10px"}/>

        <Button
            label={translate("SAVE")}
            className="p-button-outlined p-button-success"
            icon={"pi pi-check"}
            onClick={props.onConfirm}
            disabled={props.isSaveDisabled}
        />
        <Button
            label={translate("CANCEL")}
            className="p-button-danger p-button-text"
            icon={"pi pi-times"}
            onClick={props.onReject}
        />
    </>;

    return <Modal
        header={props.header}
        hideModal={props.onReject}
        isVisible={props.isVisible}
        footer={footer}
    >
        {props.children}
    </Modal>;
};

import React, {FC, useState} from "react";
import {CalendarEventDTO} from "../service/Calendar.types";
import {ClockTime} from "../../../../../service/util/ClockTime";

interface CalendarEventProps {
    event: CalendarEventDTO;
}

export const CalendarEvent: FC<CalendarEventProps> = (props: CalendarEventProps) => {
    const [detailsVisible, setDetailsVisible] = useState(false);

    return <li style={{borderLeftColor: props.event.color}} onClick={() => setDetailsVisible(prevState => !prevState)}
               className={"event"}>
        <span
            className="event-time">{ClockTime.fromString(props.event.startTime).toMinutesString()} - {ClockTime.fromString(props.event.endTime).toMinutesString()}</span>
        <span className="event-topic">{props.event.summary}</span>
        {detailsVisible &&
            <span dangerouslySetInnerHTML={{__html: props.event.details}} className="event-details"></span>}
    </li>;
};

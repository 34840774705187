import {ExpenseType, PaymentType} from '../../expensesPage/service/ExpensesPage.type';

export interface RepeatableExpenseDTO {
    id: string;
    description: string;
    supplierId: string;
    isPaid: boolean;
    expenseType: ExpenseType;
    paymentType: PaymentType;
    amount: number;
    paymentDayNumber: number;
    invoiceDayNumber: number;
    deadlineDayNumber: number;
}

export const getInitialRepeatableExpenseDTO = (): RepeatableExpenseDTO => {
    return {
        amount: undefined,
        deadlineDayNumber: undefined,
        description: undefined,
        expenseType: undefined,
        id: undefined,
        invoiceDayNumber: undefined,
        isPaid: undefined,
        paymentDayNumber: undefined,
        paymentType: undefined,
        supplierId: undefined
    }
}

export class UriBuilder {
    private uri: string = "";

    one(path: string, value: any): UriBuilder {
        this.uri += `/${path}/${value}`;
        return this;
    }

    all(path: string): UriBuilder {
        this.uri += `/${path}`;
        return this;
    }

    setPath(path: string): UriBuilder {
        this.uri = path;
        return this;
    }

    params(params: any) {
        this.uri += '?';
        this.uri += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        return this;
    }

    build(): string {
        return this.uri.substring(1, this.uri.length);
    }
}

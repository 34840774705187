import {CleaningDictionaryDTO} from "./CleaningDictionary.type";
import {get, post, put} from "../../../common/service/http/Http.service";
import {UriBuilder} from "../../../common/service/util/UriBuilder.class";
import {CommandResult} from "../../../common/service/result/Result.type";

export const getCleaningDictionaryRest = (): Promise<CleaningDictionaryDTO[]> => {
    return get<CleaningDictionaryDTO[]>(new UriBuilder()
        .all("cleaning")
        .all("dictionary")
        .build());
}

export const addNewCleaningDictionaryRest = (cleaningDictionaryDTO: CleaningDictionaryDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("cleaning")
        .all("dictionary")
        .all("new")
        .build(), cleaningDictionaryDTO);
}

export const updateCleaningDictionaryRest = (id: string, cleaningDictionaryDTO: CleaningDictionaryDTO): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .all("cleaning")
        .one("dictionary", id)
        .all("update")
        .build(), cleaningDictionaryDTO);
}


import React, {FC} from "react";

interface ValueCardProps {
    label: string;
    value: string;
}

export const ValueCard: FC<ValueCardProps> = (props: ValueCardProps) => {
    return <div className="card widget-overview-box widget-overview-box-1">
        <span className="overview-title">{props.label}</span>
        <div className="flex justify-content-between">
            <div className="overview-detail flex justify-content-between">
                <div className="overview-text">
                    {props.value}
                </div>
            </div>
        </div>
    </div>;
};

import {DisplayStrategy, PeriodChangerDisplay} from "./PeriodChanger.type";
import {dateLabel, monthYearLabel} from "./LabelFromDay";

export const getMonthYearChangerDisplay = (): PeriodChangerDisplay => ({
    displayStrategy: DisplayStrategy.MIDDLE_DAY,
    getLabelFromDay: monthYearLabel
});

export const getDailyChangerDisplay = (): PeriodChangerDisplay => ({
    displayStrategy: DisplayStrategy.MIDDLE_DAY,
    getLabelFromDay: dateLabel
});

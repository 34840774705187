import React, {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {useLocation} from 'react-router-dom';
import Topbar from './topbar/Topbar.component';
import {Tooltip} from 'primereact/tooltip';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {TitleBreadcrumb} from "./titleBreadcrumb/TitleBreadcrumb.component";
import {Footer} from './footer/Footer.component';
import {Menu} from "./menu/Menu.component";
import {AppViewConfigComponent} from "./appViewConfig/AppViewConfig.component";
import {RightPanel} from "./rightPanel/RightPanel.component.tsx";
import {useAppViewConfig} from "./appViewConfig/service/redux/UseAppViewConfig.hook";
import {AppViewConfig} from "./appViewConfig/service/AppViewConfig.type";

export interface AppLayoutProps {
    menuItems: any[];
}

export const AppLayout: FC<PropsWithChildren<AppLayoutProps>> = (props: PropsWithChildren<AppLayoutProps>) => {
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    const appViewConfig: AppViewConfig = useAppViewConfig();

    const mapMenuItemsToRoutes = (menuItems: any[], parentLabel: string = ''): any[] => {
        const routes: any[] = [];
        menuItems.forEach(item => {
            if (item.items) {
                const childRoutes = mapMenuItemsToRoutes(item.items, item.label);
                routes.push(...childRoutes);
            }
            if (item.to) {
                routes.push({parent: parentLabel, label: item.label, to: item.to});
            }
        });
        return routes;
    };

    const routes = mapMenuItemsToRoutes(props.menuItems);

    let rightMenuClick: any;
    let configClick: any;
    let menuClick: any;
    let topbarItemClick: any;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [appViewConfig.menuMode]);

    const onDocumentClick = () => {

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
    };

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true);
    };

    const onRightMenuClick = () => rightMenuClick = true;

    const onRightMenuActiveChange = (active: any) => setRightMenuActive(active);

    const onConfigClick = () => configClick = true;

    const onConfigButtonClick = (event: any) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (appViewConfig.menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (appViewConfig.menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
                setActiveInlineProfile(false);
            }, 250);
        }
    };

    const onMenuClick = () => menuClick = true;

    const onChangeActiveInlineMenu = (event: any) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => setMenuActive((prevState) => !prevState);

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => appViewConfig.menuMode === 'horizontal';

    const isSlim = () => appViewConfig.menuMode === 'slim';

    const isOverlay = () => appViewConfig.menuMode === 'overlay';

    const isDesktop = () => window.innerWidth > 991;


    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': appViewConfig.menuMode === 'static',
        'layout-overlay': appViewConfig.menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': appViewConfig.menuMode === 'slim',
        'layout-horizontal': appViewConfig.menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': appViewConfig.menuMode === 'sidebar',
        'layout-sidebar-static': appViewConfig.menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && appViewConfig.menuMode === 'static',
        'p-ripple-disabled': !appViewConfig.ripple
    });

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard"
                     event="focus"/>

            <div className="layout-main">
                <Topbar
                    items={props.menuItems}
                    menuActive={menuActive}
                    topbarMenuActive={topbarMenuActive}
                    activeInlineProfile={activeInlineProfile}
                    onTopbarItemClick={onTopbarItemClick}
                    onMenuButtonClick={onMenuButtonClick}
                    onSidebarMouseOver={onSidebarMouseOver}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onToggleMenu={onToggleMenu}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    onMenuClick={onMenuClick}
                    onMenuItemClick={onMenuItemClick}
                    onRootMenuItemClick={onRootMenuItemClick}
                    resetActiveIndex={resetActiveIndex}
                    onSettingsClick={onConfigButtonClick}
                />

                <Menu
                    model={props.menuItems}
                    onRootMenuItemClick={onRootMenuItemClick}
                    onMenuItemClick={onMenuItemClick}
                    onToggleMenu={onToggleMenu}
                    onMenuClick={onMenuClick}
                    menuActive={menuActive}
                    sidebarActive={sidebarActive}
                    sidebarStatic={sidebarStatic}
                    pinActive={pinActive}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onSidebarMouseOver={onSidebarMouseOver}
                    activeInlineProfile={activeInlineProfile}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    resetActiveIndex={resetActiveIndex}
                    onSettingsClick={onConfigButtonClick}
                />

                <TitleBreadcrumb
                    routes={routes}
                    onMenuButtonClick={onMenuButtonClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                />

                <div className="layout-main-content">
                    {props.children}
                </div>

                <Footer/>
            </div>
            <RightPanel
                rightMenuActive={rightMenuActive}
                onRightMenuClick={onRightMenuClick}
                onRightMenuActiveChange={onRightMenuActiveChange}
            />
            <AppViewConfigComponent
                configActive={configActive}
                onConfigClick={onConfigClick}
                hideOverlayMenu={() => setOverlayMenuActive(false)}
            />
        </div>
    );
};

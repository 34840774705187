import styled from "styled-components";

export const StyledSimpleTable = styled.div`
  .invoice {
    padding: 0;
  }
  
  .invoice-summary {
    margin-top: 8px;
  }
  
  .invoice-summary tr:last-child {
    border-bottom: unset !important;
  }
  
  .invoice-items {
    margin-top: 0;
  }
`;

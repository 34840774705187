import React, {FC} from "react";
import {AppointmentsSummaryDTO} from "../service/Appointments.type";
import {ValueCard} from "../../common/components/card/valueCard/ValueCard.component";
import {translate} from "../../common/lang/service/Translate.service";
import {formatAsCurrency} from "../../common/service/util/CurrencyUtil";
import {isDefined} from "../../common/service/util/ObjectUtils";

interface AppointmentsSummaryProps {
    summary: AppointmentsSummaryDTO;
}

export const AppointmentsSummary: FC<AppointmentsSummaryProps> = (props: AppointmentsSummaryProps) => {
    return <>
        {isDefined(props.summary) && <>
            <ValueCard
                label={translate('TOTAL_MONTH_INCOME')}
                value={formatAsCurrency(props.summary.totalMonthIncome) + " (" + props.summary.totalMonthAppointmentsNumber + ")"}
            />

            <ValueCard
                label={translate('ALREADY_EARNED')}
                value={formatAsCurrency(props.summary.alreadyEarned) + " (" + props.summary.alreadyFinishedAppointmentsNumber + ")"}
            />

            <ValueCard
                label={translate('PREDICTED_INCOME')}
                value={formatAsCurrency(props.summary.predictedIncome) + " (" + props.summary.predictedAppointmentsNumber + ")"}
            />

            <ValueCard
                label={translate('CASH_FOR_COMPANY')}
                value={formatAsCurrency(props.summary.cashForCompany)}
            />
        </>}
    </>;
};

import React, {FC} from "react";
import {Tag} from "primereact/tag";
import {translate} from "../../../../common/lang/service/Translate.service";
import {AppointmentStatus} from "../../../service/Appointments.type";
import {Day} from "../../../../common/service/util/Day";

interface AppointmentDetailsTagsProps {
    status: AppointmentStatus;
    appointmentDate: Day;
}

export const AppointmentDetailsTags: FC<AppointmentDetailsTagsProps> = (props: AppointmentDetailsTagsProps) => {
    const getIcon = (): string => {
        switch (props.status) {
            case AppointmentStatus.TO_CONFIRM:
                return Day.today().isAfterDay(props.appointmentDate) ? "pi-times" : "pi-question";
            case AppointmentStatus.CONFIRMED:
                return Day.today().isAfterDay(props.appointmentDate) ? "pi-times" : "pi-check";
            case AppointmentStatus.CANCELED:
                return "pi-times";
            case AppointmentStatus.FINISHED:
                return "pi-check";
        }
    };

    const getSeverity = (): any => {
        switch (props.status) {
            case AppointmentStatus.TO_CONFIRM:
                return Day.today().isAfterDay(props.appointmentDate) ? "danger" : "warning";
            case AppointmentStatus.CONFIRMED:
                return Day.today().isAfterDay(props.appointmentDate) ? "danger" : "success";
            case AppointmentStatus.CANCELED:
                return "success";
            case AppointmentStatus.FINISHED:
                return "success";
        }
    }

    const getValue = (): string => {
        switch (props.status) {
            case AppointmentStatus.TO_CONFIRM:
                return Day.today().isAfterDay(props.appointmentDate) ? translate("AFTER_DAY") : translate(props.status);
            case AppointmentStatus.CONFIRMED:
                return Day.today().isAfterDay(props.appointmentDate) ? translate("AFTER_DAY") : translate(props.status);
            case AppointmentStatus.CANCELED:
                return translate(props.status);
            case AppointmentStatus.FINISHED:
                return translate(props.status);
        }
    }

    return <div className={'tags'}>
        {<Tag
            icon={'pi ' + getIcon()}
            severity={getSeverity()}
            value={getValue()}
        />}
    </div>;
};

// @ts-ignore
interface Array<T> {
    isEmpty(): boolean;
    isNotEmpty(): boolean;
    singleOrDefault(defaultValue?: T): T;
    min(compare: (first: T, second: T) => number): T;
    max(compare: (first: T, second: T) => number): T;
    chunk(maxSize: number): T[][];
    getFirst(): T;
    getLast(): T;
    includesAny(elementsToCheck: T[]): boolean;
    none(check: (value) => boolean): boolean;
    getUniqueElements(): T[];
}

Array.prototype.isEmpty = function (): boolean {
    return this.length === 0;
};

Array.prototype.isNotEmpty = function (): boolean {
    return this.length !== 0;
};

Array.prototype.singleOrDefault = function <T>(defaultValue?: T): T {
    if (this.length > 1) {
        throw Error(`Sequence contains more than one element - ${this}`);
    }

    return (this[0] === undefined || this[0] === null) ? defaultValue : this[0];
};

Array.prototype.min = function <T>(compare: (first: T, second: T) => number): T {
    if (this.isEmpty()) {
        throw Error(`Sequence is empty`);
    }

    let minElement: T = this[0];
    this.forEach(element => {
        minElement = compare(minElement, element) < 0 ? minElement : element;
    });

    return minElement;
};

Array.prototype.max = function <T>(compare: (first: T, second: T) => number): T {
    if (this.isEmpty()) {
        throw Error(`Sequence is empty`);
    }

    let maxElement: T = this[0];
    this.forEach(element => {
        maxElement = compare(maxElement, element) > 0 ? maxElement : element;
    });

    return maxElement;
};

Array.prototype.chunk = function <T>(maxSize: number): T[][] {
    return Array.from({length: Math.ceil(this.length / maxSize)}, (v, i) =>
        this.slice(i * maxSize, i * maxSize + maxSize));
};

Array.prototype.getFirst = function <T>(): T {
    return this.isEmpty() ? undefined : this[0];
};

Array.prototype.getLast = function <T>(): T {
    return this.isEmpty() ? undefined : this[this.length - 1];
};

Array.prototype.includesAny = function <T>(elementsToCheck: T[]): boolean {
    if (elementsToCheck === null || elementsToCheck === undefined || elementsToCheck.isEmpty()) {
        return false
    }

    return this.some(value => elementsToCheck.includes(value));
};

Array.prototype.none = function <T>(check: (value: T) => boolean): boolean {
    return !this.some(check);
};

Array.prototype.getUniqueElements = function <T>():T[] {
    // @ts-ignore
    return [...new Set(this)];
};

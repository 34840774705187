import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {googleInitialize, loadScript} from "./GoogleAuth.service.js";

interface GoogleAuthProps {
    clientId: string;
    onUserAuthenticated?: (token: string) => void;
    cookieTheToken?: boolean;
    onScriptLoadFailure?: () => void;
}

export const GoogleAuth: FC<PropsWithChildren<GoogleAuthProps>> = (props: PropsWithChildren<GoogleAuthProps>) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const getScriptLoaded = () => setScriptLoaded(true);

    useEffect(() => {
        loadScript(() => getScriptLoaded(), () => {
        });
    }, []);

    useEffect(() => {
        if (scriptLoaded) {
            googleInitialize(props.clientId);
        }
    }, [scriptLoaded]);

    return <>
        {props.children}
    </>;
};

import React, {FC, useId} from "react";
import SelectOption from '../multiselectChips/service/SelectOption.type';
import {InputLabel, LabelType} from '../inputLabel/InputLabel.component';
import {Dropdown} from 'primereact/dropdown';

interface SingleSelectInputProps {
    options: SelectOption[];
    selectedValue: any;
    setSelectedValue: (selectedValues: any) => void;
    label?: string;
    required?: boolean;
    labelType?: LabelType;
    className?: string;
    disabled?: boolean;
    onBlur?: () => void;
    showClear?: boolean;
}

export const SingleSelectInput: FC<SingleSelectInputProps> = (props: SingleSelectInputProps) => {
    const id = useId();
    return <InputLabel withSpanPadding={true} id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <Dropdown
            id={id}
            options={props.options}
            required={props.required}
            value={props.selectedValue}
            onChange={(e) => props.setSelectedValue(e.value)}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
            disabled={props.disabled}
            filter={props.options?.length > 5}
            onBlur={props.onBlur}
            showClear={props.showClear}
        />
    </InputLabel>;
};

import {DayRange} from "../../../service/util/DayRange";

export const movePeriodByMonths = (period: DayRange, numberOfMonths: number): DayRange => {
    return new DayRange(period.getDayFrom().addMonths(numberOfMonths), period.getDayTo().addMonths(numberOfMonths));
};

export const movePeriodByYears = (period: DayRange, numberOfYears: number): DayRange => {
    return new DayRange(period.getDayFrom().addYears(numberOfYears), period.getDayTo().addYears(numberOfYears));
};

export const movePeriodByFullMonths = (period: DayRange, numberOfMonths: number): DayRange => {
    const dayFrom = period.getDayFrom().addMonths(numberOfMonths).getMonthFirstDay();
    const dayTo = period.getDayTo().addMonths(numberOfMonths).getMonthLastDay();
    return new DayRange(dayFrom, dayTo);
};

export const movePeriodByFullYears = (period: DayRange, numberOfYears: number): DayRange => {
    const dayFrom = period.getDayFrom().addYears(numberOfYears).getYearFirstDay();
    const dayTo = period.getDayTo().addYears(numberOfYears).getYearLastDay();
    return new DayRange(dayFrom, dayTo);
};

export const movePeriodByDays = (period: DayRange, numberOfDays: number): DayRange => {
    return new DayRange(period.getDayFrom().addDays(numberOfDays), period.getDayTo().addDays(numberOfDays));
};

import {_delete, get, handleZipDownload, post, put} from '../../../common/service/http/Http.service';
import {DayRange} from '../../../common/service/util/DayRange';
import {ExpenseDTO, ExpenseReadDTO, ExpenseType, PaymentType} from './ExpensesPage.type';
import {UriBuilder} from '../../../common/service/util/UriBuilder.class';
import {FileRequestBuilder} from '../../../common/service/util/FileRequestBuilder.class';
import {CommandResult} from '../../../common/service/result/Result.type';
import {isDefined, isNotDefined} from '../../../common/service/util/ObjectUtils';
import {translate} from '../../../common/lang/service/Translate.service';

export const getExpensesRest = (period: DayRange): Promise<ExpenseReadDTO[]> => {
    return get<ExpenseReadDTO[]>(new UriBuilder()
        .all('expense')
        .one('month', period.getMiddleDay().getMonthNumber())
        .one('year', period.getMiddleDay().getFullYear())
        .build());
}

export const addExpenseRest = (expenseDTO: ExpenseDTO, files: File[]): Promise<CommandResult> => {
    const formData: FormData = new FileRequestBuilder()
        .withObject(expenseDTO, 'expenseDTO')
        .withFiles(files, 'files')
        .build();

    return post<CommandResult>(new UriBuilder()
        .all('expense')
        .build(), formData);
}

export const updateExpenseRest = (expenseId: string, expenseDTO: ExpenseDTO, files: File[]): Promise<CommandResult> => {
    const formData: FormData = new FileRequestBuilder()
        .withObject(expenseDTO, 'expenseDTO')
        .withFiles(files, 'files')
        .build();

    return put<CommandResult>(new UriBuilder()
        .one('expense', expenseId)
        .all('update')
        .build(), formData);
}

export const removeExpenseRest = (expenseId: string): Promise<CommandResult> => {
    return _delete<CommandResult>(new UriBuilder()
        .one('expense', expenseId)
        .all('delete')
        .build());
}

export const downloadAllInvoices = (period: DayRange): void => {
    handleZipDownload(new UriBuilder()
        .all('expense')
        .one('month', period.getMiddleDay().getMonthNumber())
        .one('year', period.getMiddleDay().getFullYear())
        .all('files')
        .build());
}

export const getExpenseInvoicesUrl = (expenseId: string): string => {
    return new UriBuilder()
        .one('expense', expenseId)
        .all('files')
        .build();
}

export const validateExpense = (expense: ExpenseReadDTO): string[] => {
    const validationMessages: string[] = [];

    if (isNotDefined(expense.amount)) {
        validationMessages.push(translate("AMOUNT_MISSING"));
    }

    if (!expense.isPaid) {
        const expenseDeadline: string = isDefined(expense.deadlineDate) ? ' ' + translate('TO') +': ' + expense.deadlineDate : '';
        validationMessages.push(translate("PAYMENT_REQUIRED") + expenseDeadline);
    }

    if (expense.requireInvoice && !expense.hasFiles) {
        validationMessages.push(translate("INVOICE_MISSING"));
    }

    if (expense.isPaid && isNotDefined(expense.paymentDate)) {
        validationMessages.push(translate("PAYMENT_DATE_MISSING"));
    }

    return validationMessages;
}

export const filterExpenses = (expenses: ExpenseReadDTO[], filters: ExpenseFilters) => {
    return expenses.filter(expense => matchesFilters(expense, filters));
}

const matchesFilters = (expense: ExpenseReadDTO, filters: ExpenseFilters): boolean => {
    if (isDefined(filters.expenseType)) {

    }
    return true;
}


export interface ExpenseFilters {
    expenseType?: ExpenseType,
    paymentType?: PaymentType,
    requiresPayment?: boolean;
    hasMissingInvoice?: boolean;
}
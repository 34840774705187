import React, {FC, PropsWithChildren, ReactNode, useEffect, useState} from "react";
import {httpRequestError, httpRequestSuccess, RestResponse} from "../service/http/Events.service";
import {CommandResult} from "../service/result/Result.type";
import {useGrowl} from "../growl/service/Growl.hook";
import {translate} from "../lang/service/Translate.service";

interface RequestResultInterceptorProps {
}

export const RequestResultInterceptor: FC<PropsWithChildren<RequestResultInterceptorProps>> = (props: PropsWithChildren<RequestResultInterceptorProps>) => {
    const [childrenToRender, setChildrenToRender] = useState(<></> as ReactNode);

    const growl = useGrowl();

    useEffect(() => {
        window.addEventListener(httpRequestSuccess, handleCommandResultResponse);
        window.addEventListener(httpRequestError, handleErrorResponse);
        setChildrenToRender(props.children);
        return () => {
            window.removeEventListener(httpRequestSuccess, handleCommandResultResponse);
            window.removeEventListener(httpRequestError, handleErrorResponse);
        };
    }, []);

    const handleCommandResultResponse = ({detail}: CustomEvent<CommandResult>): void => {
        growl.showResult(detail);
    };

    const handleErrorResponse = ({detail}: CustomEvent<RestResponse>): void => {
        growl.showError(translate(detail.details));
    };

    return <>{childrenToRender}</>;
};

import React, {FC, useId} from "react";
import {InputLabel, LabelType} from '../inputLabel/InputLabel.component';
import {isDefined} from '../../../service/util/ObjectUtils';
import {InputTextarea} from 'primereact/inputtextarea';

interface TextAreaInputProps {
    value: string;
    setValue: (newValue: string) => void;
    label?: string;
    required?: boolean;
    labelType?: LabelType;

    className?: string;
    rows?: number;
}

export const TextAreaInput: FC<TextAreaInputProps> = (props: TextAreaInputProps) => {
    const id = useId();
    return <InputLabel id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <InputTextarea
            autoResize={true}
            rows={5}
            id={id}
            required={props.required}
            value={isDefined(props.value) ? props.value : ''}
            onChange={(e) => props.setValue(e.target.value)}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
        />
    </InputLabel>;
};

TextAreaInput.defaultProps = {
    rows: 5
}

import React, {useEffect} from 'react';
import {Button} from 'primereact/button';
import {isDefined, isNotDefined} from "../common/service/util/ObjectUtils";
import {getSearchParamsAsObject} from "../common/service/util/SearchParamsUtil";
import {authenticationService} from "../../index";
import {googleOauthSignIn} from "../common/security/googleAuth/GoogleAuth.service";
import {translate} from "../common/lang/service/Translate.service";

export const LoginPage = () => {

    useEffect(() => {
        if (isDefined(getSearchParamsAsObject()['code'])) {
            authenticationService.handleLoginResponse();
        }
    }, []);

    if (authenticationService.isUserLoggedIn()) {
        authenticationService.redirectToHomePage();
    }

    return <>
        {(!authenticationService.isUserLoggedIn() && isNotDefined(getSearchParamsAsObject()['code'])) &&
            <div className="login-body">
                <div className="login-image">
                    <img src={`/assets/layout/images/pages/login-onlight.png`} alt="atlantis"/>
                </div>
                <div className="login-panel p-fluid">
                    <div className="flex flex-column">
                        <div className="flex align-items-center mb-6 logo-container">
                            <img src={`/assets/layout/images/logo-light.png`} className="login-logo" alt="login-logo"/>
                            <img src={`/assets/layout/images/appname-light.png`} className="login-appname"
                                 alt="login-appname"/>
                        </div>
                        <div className="button-container">
                            <Button type="button" label={translate("LOGIN")} onClick={googleOauthSignIn}></Button>
                        </div>
                    </div>

                    <div className="login-footer flex align-items-center">
                        <div>© Webrance - 2023</div>
                    </div>
                </div>
            </div>}
    </>;
};

import {GrowlActionTypes, SHOW_GROWL} from "./GrowlActions.type";
import {ToastMessage} from "primereact/toast";

const initialState: ToastMessage = {};

export const growlReducer = (state = initialState, action: GrowlActionTypes): ToastMessage | ToastMessage[] => {
    switch(action.type) {
        case SHOW_GROWL:
            if (Array.isArray(action.message)) {
                return [...action.message];
            }
            return {...state, ...action.message};
        default:
            return state;
    }
};

import {FilterStrategy} from "../../../common/service/util/filters/FilterStrategy";
import {isNotDefined} from "../../../common/service/util/ObjectUtils";
import {ExpenseReadDTO} from "./ExpensesPage.type";

export class HasMissingInvoiceFilterStrategy implements FilterStrategy {
    constructor() {
    }

    filter(objectList: ExpenseReadDTO[], valueToFilter: boolean): ExpenseReadDTO[] {
        if (isNotDefined(valueToFilter) || !valueToFilter) {
            return objectList;
        }
        return objectList.filter(value => value.requireInvoice && !value.hasFiles)
    };
}

export const isDefined = (object: any): boolean => {
    return object !== null && object !== undefined;
};

export const isNotDefined = (object: any): boolean => {
    return !isDefined(object);
};

export const isArrayDefinedAndNotEmpty = (array: any[]): boolean => (
    isDefined(array) && array.length > 0
);

export const allDefined = (objects: any[]): boolean => {
    return objects.every(o => isDefined(o));
};

export const isObjectIncludingAllElementsFromArray = (object: any, array: any[]): boolean => {
    return array.every((otherObject: any) => object.includes(otherObject));
};

export const groupByKey = (list: any[], key: string): Map<any, any[]> => new Map(Object.entries(
    list.reduce((hash: any, obj: any) => ({...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj)}), {})));

export const toMap = (list: any[], key: string): Map<any, any> => {
    const map: Map<any, any> = new Map<any, any>();
    if (isDefined(list)) {
        list.forEach(value => map.set(value[key], value));
    }
    return map;
};

export const getObjectFromMap = (map: Map<any, any>) => {
    const obj: any = {};
    // @ts-ignore
    for (const key of map.keys()) {
        obj[key] = map.get(key);
    }
    return obj;
};

export const getFieldValuesFromObject = (object: any, fieldName: string,
                                         customFieldPath: string = fieldName): any[] => {
    let fieldValues: any[] = [object];
    customFieldPath.split(".").forEach(name => {
        fieldValues = flatArray(fieldValues.map(fieldValue => fieldValue[name]));
    });
    return flatArray(fieldValues);
};

export const flatArray = (array: any[]): any[] => (
    [].concat.apply([], array)
);

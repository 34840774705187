import {FC} from "react";
import {translate} from "../../../common/lang/service/Translate.service";
import {Card} from "../../../common/components/card/Card.component";
import {RequiresCleaningDTO} from "../service/CleaningPage.types";
import {Tag} from "primereact/tag";

interface CleaningRequiredProps {
    requiresCleaning: RequiresCleaningDTO[];
}

export const CleaningRequired: FC<CleaningRequiredProps> = (props: CleaningRequiredProps) => {
    const getTag = (value: RequiresCleaningDTO) => {
        if (value.markedAsRequiresCleaning) {
            return <Tag className="mr-2"  severity="danger" value={"!!!"}/>
        } else if (value.daysToWarning < 0) {
            return <Tag className="mr-2"  severity="success"
                        value={getSuccessLabel(value.daysToWarning)}/>
        } else if (value.daysToWarning === 0) {
            return <Tag className="mr-2"  severity="warning" value={"!!!"}/>
        } else if (value.daysToWarning > 0) {
            return <Tag className="mr-2"  severity="danger"
                        value={getDangerLabel(value.daysToWarning)}/>
        }
    }

    const getSuccessLabel = (daysToWarning: number): string => {
        return (-1 * daysToWarning).toString()
    }

    const getDangerLabel = (daysToWarning: number): string => {
        return daysToWarning.toString()
    }

    return <div className={"col-12 md:col-6"}>
        <Card label={translate("REQUIRES_CLEANING")}>
            {props.requiresCleaning.filter(value => !value.cleanedToday).map(value => (
                <div
                    key={value.itemId}
                    className={'requires-cleaning-row'}
                >
                    {value.itemName} {getTag(value)}
                </div>))}
        </Card>
    </div>;
}

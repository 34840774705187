import {isArrayDefinedAndNotEmpty, isDefined, isNotDefined} from "../../service/util/ObjectUtils";
import {isDefinedAndNotEmpty} from "../../service/util/StringUtils";
import {enMessages} from "./en-gb";

export const translate = (id: string): string => {
    if (isNotDefined(id)) {
        return "";
    }

    let translatedMessage = enMessages[id];
    return isDefined(translatedMessage) ? translatedMessage : id;
};

export const translateArray = (ids: string[]): string[] => {
    if (!isArrayDefinedAndNotEmpty(ids)) {
        return [] as string[];
    }
    return ids.map(id => translate(id));
};

export const translateWithParams = (id: string, parameters?: any[]): string => {
    const translatedMessage: string = enMessages[id];
    return isDefinedAndNotEmpty(translatedMessage) ? isDefined(parameters) ? format(translatedMessage,
        parameters) : translatedMessage : id;
};

export const translateError = (id: string, parameters?: any): string => {
    let translatedMessage: string = enMessages["VALIDATION"][id];
    return isDefinedAndNotEmpty(translatedMessage) ? isDefined(parameters) ? format(translatedMessage,
        parameters) : translatedMessage : id;
};

const format = (stringToFormat: string, parameters: any[]) => {
    return stringToFormat.replace(/{(\d+)}/g, function (match: any, number: number) {
        return typeof parameters[number] != 'undefined' ? parameters[number] : match;
    });
};

export const translateBoolean = (value: boolean): string => {
    if (isNotDefined(value)) {
        return undefined;
    }
    return translate(value ? "YES" : "NO");
};

export const translateStringAsBoolean = (value: string): string => {
    return translate(value.toLowerCase() === "true" ? "YES" : "NO");
};

import React, {FC} from "react";
import {Table} from "../../../../../common/components/table/Table.component";
import {ColumnProps} from "../../../../../common/components/table/service/Table.types";
import {translate} from "../../../../../common/lang/service/Translate.service";
import {formatAsCurrency} from "../../../../../common/service/util/CurrencyUtil";
import {Item} from "../../../service/OrderPage.type";
import {CurrencyInput} from "../../../../../common/components/input/currencyInput/CurrencyInput.component";
import {LabelType} from "../../../../../common/components/input/inputLabel/InputLabel.component";
import {Button} from "primereact/button";
import {NumberInput} from "../../../../../common/components/input/numberInput/NumberInput.component";
import {isNotDefined} from '../../../../../common/service/util/ObjectUtils';

interface SupplierOrderTableProps {
    inEditMode: boolean;
    items: Item[];
    updateValue: (itemId: string, value: number) => void;
    updateCount: (itemId: string, count: number) => void;
}

export const SupplierOrderTable: FC<SupplierOrderTableProps> = (props: SupplierOrderTableProps) => {
    const valueBody = (value: Item, index: number): React.ReactNode => {
        return <>
            <CurrencyInput
                labelType={LabelType.INLINE}
                maxValue={9999}
                value={props.items.find(value1 => value1.itemId === value.itemId)?.singleItemValue}
                setValue={(newValue) => props.updateValue(value.itemId, newValue)}
            />
        </>;
    }

    const countBody = (value: Item, index: number): React.ReactNode => {
        return <div className={'update-count'}>
            <div className={'update-count-button'}>
                {(value.optimalCount > value.currentCount) &&
                    <Button
                        icon={(value.minimalCount >= value.currentCount) ? "pi pi-angle-double-up" : "pi pi-angle-up"}
                        className="p-button-rounded p-button-danger p-button-text"
                        onClick={() => props.updateCount(value.itemId, (value.orderToCount - value.currentCount))}
                        tabIndex={-1}
                    />}
            </div>
            <NumberInput
                labelType={LabelType.INLINE}
                maxValue={999}
                minValue={0}
                value={props.items.find(value1 => value1.itemId === value.itemId)?.orderedCount}
                setValue={(newCount) => props.updateCount(value.itemId, newCount)}
            />
        </div>;
    }

    const linkBody = (value: Item, index: number): React.ReactNode => {
        if (isNotDefined(value.url)) {
            return <div>{value.itemName}</div>
        }

        return <div className={"shop-link"}>
            <a tabIndex={-1} href={value.url} target={"_blank"} rel="noopener noreferrer">{value.itemName}</a>
        </div>
    };

    const columnProps: ColumnProps[] = [];
    columnProps.push({header: translate('NAME'), fieldName: 'itemName', sortable: false, body: linkBody});
    if (props.inEditMode) {
        columnProps.push({header: translate('OPT_COUNT'), fieldName: 'optimalCount', sortable: false, width: '32px'});
    }
    if (props.inEditMode) {
        columnProps.push({
            header: translate('CURRENT_COUNT'),
            fieldName: 'currentCount',
            sortable: false,
            width: '32px'
        });
    }
    columnProps.push({
        header: translate('ORDERED_COUNT'),
        fieldName: 'orderedCount',
        sortable: false,
        body: props.inEditMode ? countBody : undefined,
        width: '139px'
    });
    columnProps.push({
        header: translate('SINGLE_ITEM_VALUE'),
        fieldName: 'singleItemValue',
        sortable: false,
        body: props.inEditMode ? valueBody : undefined,
        width: '107px',
        transformValue: props.inEditMode ? undefined : formatAsCurrency
    });
    columnProps.push({
        header: translate('TOTAL_ITEM_COST'),
        fieldName: 'totalItemCost',
        sortable: false,
        transformValue: formatAsCurrency,
        width: '80px',
        className: 'total-value-column'
    });

    return <>
        {props.items.isNotEmpty() &&
            <Table
                className={'supplier-order-table'}
                values={props.items}
                dataKey={'itemId'}
                columnProps={columnProps}
                multiSortMeta={[{field: "name", order: 1}]}
            />}
    </>;
};

import {FilterMatchMode, FilterOperator} from "primereact/api";
import {ColumnProps, FilterType} from "./Table.types";
import {isDefined, isNotDefined} from "../../../service/util/ObjectUtils";
import {compareStrings} from "../../../service/util/StringUtils";
import {DataTableFilterMeta} from "primereact/datatable";

export const getFilterOptions = (values: any[], columnProps: ColumnProps[]): any => {
    const selectableFilterColumns = columnProps
        .filter(value => value.filterType === FilterType.SELECT);

    return selectableFilterColumns
        .reduce((accumulator, columnProps) => ({
            ...accumulator,
            [columnProps.fieldName]: getOptions(values, columnProps.fieldName, columnProps.transformValue)
        }), {})
}

const getOptions = (values: any[], fieldName: string, transformValue?: (value: any) => string): any[] => {
    return values.map(value => value[fieldName]).getUniqueElements().map(value => ({
        label: isDefined(transformValue) ? transformValue(value) : value,
        value: value
    })).sort(getCompareFn);
}
const getCompareFn = (a, b) => {
    if (isNotDefined(a.label) || isNotDefined(b.label)) {
        return 0;
    }
    if (typeof a.value === 'boolean') {
        return a.value ? -1 : 1;
    }

    return compareStrings(a.label, b.label)
};

export const getFiltersMetadata = (columnProps: ColumnProps[]): DataTableFilterMeta => {
    const reduce = columnProps
        .filter(value => isDefined(value.filterType))
        .reduce((accumulator, props) => ({
            ...accumulator,
            [props.fieldName]: getFilter(props.filterType, props.initFilterValue)
        }), {});
    return reduce
};

const getFilter = (filterType: FilterType, initFilterValue: any | any[]) => {
    const value = isDefined(initFilterValue) && filterType === FilterType.SELECT && typeof initFilterValue !== "object" ? [initFilterValue] : initFilterValue;

    return filterType === FilterType.SELECT
        ? {operator: FilterOperator.OR, constraints: [{value: value, matchMode: FilterMatchMode.IN}]}
        : {operator: FilterOperator.OR, constraints: [{value: initFilterValue, matchMode: FilterMatchMode.CONTAINS}]}
}

import {CommandResult} from "../../common/service/result/Result.type";
import {FileRequestBuilder} from "../../common/service/util/FileRequestBuilder.class";
import {get, post} from "../../common/service/http/Http.service";
import {UriBuilder} from "../../common/service/util/UriBuilder.class";
import {BankTransferDetailsDTO, BankTransfersSummaryDTO, BankTransferType} from "./BankTransfers.type";
import {plainTextUTF8Headers} from "../../common/service/http/Headers";

export const importBankTransfersRest = (month: number, year: number, file: File): Promise<CommandResult> => {
    const formData = new FileRequestBuilder().withFile(file, "file").build();

    return post<CommandResult>(new UriBuilder()
        .all("bank-transfers")
        .one("month", month)
        .one("year", year)
        .all("import-transfers")
        .build(), formData);
}

export const updateTransferTypeRest = (month: number, year: number, id: string, bankTransferType: BankTransferType): Promise<void> => {
    return post<void>(new UriBuilder()
        .all("bank-transfers")
        .one("month", month)
        .one("year", year)
        .one("transfer", id)
        .all("update-transfer-type")
        .build(), bankTransferType.toString(), plainTextUTF8Headers);
}

export const transfersRegisteredRest = (month: number, year: number): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("bank-transfers")
        .one("month", month)
        .one("year", year)
        .all("transfers-registered")
        .build());
}

export const getAssignedTransfersRest = (month: number, year: number): Promise<BankTransferDetailsDTO[]> => {
    return get<BankTransferDetailsDTO[]>(new UriBuilder()
        .all("bank-transfers")
        .one("month", month)
        .one("year", year)
        .all("assigned-transfers")
        .build());
}

export const getTransfersToAssignRest = (month: number, year: number): Promise<BankTransferDetailsDTO[]> => {
    return get<BankTransferDetailsDTO[]>(new UriBuilder()
        .all("bank-transfers")
        .one("month", month)
        .one("year", year)
        .all("transfers-to-assign")
        .build());
}

export const getTransfersSummaryRest = (month: number, year: number): Promise<BankTransfersSummaryDTO[]> => {
    return get<BankTransfersSummaryDTO[]>(new UriBuilder()
        .all("bank-transfers")
        .one("month", month)
        .one("year", year)
        .all("transfers-summary")
        .build());
}

export const getAllKeywordsRest = (): Promise<any> => {
    return get<any>(new UriBuilder()
        .all("bank-transfers")
        .all("keywords")
        .build());
}

export const updateKeywordsRest = (keywords: any): Promise<void> => {
    return post<any>(new UriBuilder()
        .all("bank-transfers")
        .all("keywords")
        .build(), keywords);
}

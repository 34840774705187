import React, {FC} from "react";
import {Button} from "primereact/button";
import {translate} from "../../../common/lang/service/Translate.service";
import {DayRange} from "../../../common/service/util/DayRange";
import {Day} from "../../../common/service/util/Day";
import {PeriodChanger} from "../../../common/components/periodChanger/PeriodChanger.component";
import {getDailyChangerDisplay} from "../../../common/components/periodChanger/service/PeriodChangerDisplay";
import {Toolbar} from "primereact/toolbar";
import {isNotDefined} from "../../../common/service/util/ObjectUtils";
import {StyledInventoryToolbar} from "./InventoryToolbar.style";

interface InventoryToolbarProps {
    inventoryDate: Day;
    onPreviousInventory: () => void;
    onNextInventory: () => void;
    startInventory: () => void;
    closeInventory: () => void;
    canStartInventory: boolean;
    canCloseInventory: boolean;
    inventoryNumber: number;
    latestInventoryNumber: number;
}

export const InventoryToolbar: FC<InventoryToolbarProps> = (props: InventoryToolbarProps) => {
    const rightToolbarTemplate = () => {
        if (props.canCloseInventory) {
            return <Button label={translate("CLOSE")} onClick={props.closeInventory}/>;
        }

        if (props.canStartInventory) {
            return <Button label={translate("START")} onClick={props.startInventory}/>;
        }

        return <></>;
    };

    const leftToolbarPanel = () => {
        if (isNotDefined(props.inventoryNumber)) {
            return <></>;
        }

        return (
            <PeriodChanger
                startPeriod={DayRange.getDateRangeFromDates(props.inventoryDate, props.inventoryDate)}
                onNextPeriod={(range: DayRange) => props.onNextInventory()}
                onPreviousPeriod={(range: DayRange) => props.onPreviousInventory()}
                display={getDailyChangerDisplay()}
                isFirstPeriod={props.inventoryNumber === 1}
                isLastPeriod={props.inventoryNumber === props.latestInventoryNumber}
            />
        );
    }
    return <StyledInventoryToolbar className={"col-12"}>
        <Toolbar
            start={leftToolbarPanel}
            end={rightToolbarTemplate}
        />
    </StyledInventoryToolbar>;
};

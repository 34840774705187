import {Dispatch} from "redux";
import {AppActions} from "../../../redux/AppActions";
import {AppState} from "../../../redux/RootReducer";
import {UserBasicInfoDTO, UserBasicInfoRetrieved} from "./UserBasicInfo.type";
import {getCurrentUserBasicInfoRest} from "./UserBasicInfo.service";

const retrieveUserBasicInfoAction = (getUserBasicInfo: () => Promise<UserBasicInfoDTO> = getCurrentUserBasicInfoRest) => {
    return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
        let userBasicInfo;
        try {
            userBasicInfo = await getUserBasicInfo();
        } catch {
            window.top.location.href = window.top.location.href + "accessDenied"
            return getState();
        }

        dispatch(userBasicInfoRetrieved(userBasicInfo));
    };
};

const userBasicInfoRetrieved = (userBasicInfo: UserBasicInfoDTO): AppActions => {
    return {
        type: UserBasicInfoRetrieved,
        userBasicInfo: userBasicInfo
    };
};

export default retrieveUserBasicInfoAction;

import React, {FC, useEffect, useState} from 'react';
import {classNames} from 'primereact/utils';
import {RadioButton} from 'primereact/radiobutton';
import {InputSwitch} from 'primereact/inputswitch';
import PrimeReact from 'primereact/api';
import {useAppViewConfig} from "./service/redux/UseAppViewConfig.hook";
import {AppViewConfig, CalendarColor} from "./service/AppViewConfig.type";
import {AppDispatch} from "../../../../redux/Thunk.type";
import {useDispatch} from 'react-redux';
import updateAppViewConfigAction from "./service/redux/AppViewConfig.action";
import {getUserCalendarColorRest, updateUserCalendarColorRest} from "./service/Settings.service";
import {useCurrentUser} from "../../../../security/userBasicInfo/service/CurrentUser.hook";
import {isDefined} from "../../../../service/util/ObjectUtils";

export interface AppViewConfigProps {
    configActive: boolean;
    onConfigClick: () => void;
    hideOverlayMenu: () => void;
}

export const AppViewConfigComponent: FC<AppViewConfigProps> = (props: AppViewConfigProps) => {
    const appViewConfig: AppViewConfig = useAppViewConfig();
    const dispatch: AppDispatch = useDispatch<AppDispatch>();
    const currentUser = useCurrentUser();
    const [calendarColor, setCalendarColor] = useState<CalendarColor>();

    useEffect(() => {
        if (Object.keys(appViewConfig).length > 0) {
            changeComponentTheme(appViewConfig.componentTheme);
            changeColorScheme(appViewConfig.colorScheme);
            changeTheme(appViewConfig.theme);
            changeRipple(appViewConfig.ripple);
            changeMenuMode(appViewConfig.menuMode);
        }
    }, []);

    useEffect(() => {
        if (isDefined(currentUser)) {
            retrieveUserCalendarColor(currentUser.id);
        }
    }, [currentUser]);

    const changeComponentTheme = (componentTheme: string) => {
        const themeLink = document.getElementById('theme-css');
        const href = '/assets/theme/' + componentTheme + '/theme-' + appViewConfig.colorScheme + '.css';
        replaceLink(themeLink, href);
        dispatch(updateAppViewConfigAction({field: 'componentTheme', value: componentTheme}));
    };

    const replaceLink = (linkElement: any, href: string, callback?: any) => {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();
            const _linkElement = document.getElementById(id);
            _linkElement && _linkElement.remove();
            cloneLinkElement.setAttribute('id', id);

            if (callback) {
                callback();
            }
        });
    };

    const changeColorScheme = (colorScheme: string) => {
        changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1);
        dispatch(updateAppViewConfigAction({field: 'colorScheme', value: colorScheme}));
    };

    const changeTheme = (theme: string) => {
        const layoutLink = document.getElementById('layout-css');
        const href = '/assets/layout/css/' + theme + '/layout-' + appViewConfig.colorScheme + '.css';
        replaceLink(layoutLink, href);
        dispatch(updateAppViewConfigAction({field: 'theme', value: theme}));
    };

    const changeStyleSheetUrl = (id: any, value: any, from: any) => {
        const element = document.getElementById(id) as HTMLInputElement;
        const urlTokens = (element.getAttribute('href') as String).split('/');

        if (from === 1) {
            // which function invoked this function - change scheme
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function - change color
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');
        replaceLink(element, newURL);
    };

    const changeRipple = (newValue: boolean) => {
        PrimeReact.ripple = newValue;
        dispatch(updateAppViewConfigAction({field: 'ripple', value: newValue}));
    };

    const changeMenuMode = (menuMode: any) => {
        dispatch(updateAppViewConfigAction({field: 'menuMode', value: menuMode}));
        props.hideOverlayMenu();
    };

    const themes = [
        {name: 'blue', color: '#0F8BFD'},
        {name: 'green', color: '#0BD18A'},
        {name: 'magenta', color: '#EC4DBC'},
        {name: 'orange', color: '#FD9214'},
        {name: 'purple', color: '#873EFE'},
        {name: 'red', color: '#FC6161'},
        {name: 'teal', color: '#00D0DE'},
        {name: 'yellow', color: '#EEE500'}
    ];

    const componentThemes = [
        {name: 'blue', color: '#0F8BFD'},
        {name: 'green', color: '#0BD18A'},
        {name: 'magenta', color: '#EC4DBC'},
        {name: 'orange', color: '#FD9214'},
        {name: 'purple', color: '#873EFE'},
        {name: 'red', color: '#FC6161'},
        {name: 'teal', color: '#00D0DE'},
        {name: 'yellow', color: '#EEE500'}
    ];

    const calendarThemes: CalendarColor[] = [
        {name: "Lavender", colorId: "1", color: "#7986CB"},
        {name: "Sage", colorId: "2", color: "#33B679"},
        {name: "Grape", colorId: "3", color: "#8E24AA"},
        {name: "Flamingo", colorId: "4", color: "#E67C73"},
        {name: "Banana", colorId: "5", color: "#F6BF26"},
        {name: "Tangerine", colorId: "6", color: "#F4511E"},
        {name: "Peacock", colorId: "7", color: "#039BE5"},
        {name: "Blueberry", colorId: "9", color: "#3F51B5"},
        {name: "Basil", colorId: "10", color: "#0B8043"},
        {name: "Tomato", colorId: "11", color: "#D50000"},
    ];

    async function retrieveUserCalendarColor(userId: string) {
        if (userId) {
            const userCalendarColor: CalendarColor = await getUserCalendarColorRest(userId);
            setCalendarColor(calendarThemes.find(value => value.colorId === userCalendarColor.colorId));
        }
    }

    const changeCalendarColor = (calendarColor: CalendarColor) => {
        updateUserCalendarColorRest(currentUser.id, calendarColor.colorId, calendarColor.color);
        setCalendarColor(calendarColor);
    };

    return (
        <div id="layout-config">
            <div className={classNames('layout-config', {'layout-config-active': props.configActive})}
                 onClick={props.onConfigClick}>
                <h5>Menu Type</h5>
                <div className="field-radiobutton">
                    <RadioButton name="menuMode" value="static" id="mode1" checked={appViewConfig.menuMode === 'static'}
                                 onChange={() => changeMenuMode('static')}></RadioButton>
                    <label htmlFor="mode1">Static</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton name="menuMode" value="overlay" id="mode2"
                                 checked={appViewConfig.menuMode === 'overlay'}
                                 onChange={() => changeMenuMode('overlay')}></RadioButton>
                    <label htmlFor="mode2">Overlay</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton name="menuMode" value="slim" id="mode3" checked={appViewConfig.menuMode === 'slim'}
                                 onChange={() => changeMenuMode('slim')}></RadioButton>
                    <label htmlFor="mode3">Slim</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton name="menuMode" value="horizontal" id="mode4"
                                 checked={appViewConfig.menuMode === 'horizontal'}
                                 onChange={() => changeMenuMode('horizontal')}></RadioButton>
                    <label htmlFor="mode4">Horizontal</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton name="menuMode" value="sidebar" id="mode5"
                                 checked={appViewConfig.menuMode === 'sidebar'}
                                 onChange={() => changeMenuMode('sidebar')}></RadioButton>
                    <label htmlFor="mode5">Sidebar</label>
                </div>
                <hr/>

                <h5>Color Scheme</h5>
                <div className="field-radiobutton">
                    <RadioButton name="colorScheme" value="light" id="theme1"
                                 checked={appViewConfig.colorScheme === 'light'}
                                 onChange={() => changeColorScheme('light')}></RadioButton>
                    <label htmlFor="theme1">Light</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton name="colorScheme" value="dark" id="theme2"
                                 checked={appViewConfig.colorScheme === 'dark'}
                                 onChange={() => changeColorScheme('dark')}></RadioButton>
                    <label htmlFor="theme2">Dark</label>
                </div>
                <hr/>

                <h5>Ripple Effect</h5>
                <InputSwitch checked={appViewConfig.ripple} onChange={(e: any) => changeRipple(e.value)}/>
                <hr/>

                <h5>Layout Themes</h5>
                <div className="layout-themes">
                    {themes.map((t) => {
                        return (
                            <div key={t.name}>
                                <button className="p-link" style={{cursor: 'pointer', backgroundColor: t.color}}
                                        onClick={() => changeTheme(t.name)} title={t.name}>
                                    {appViewConfig.theme === t.name && <i className="pi pi-check"></i>}
                                </button>
                            </div>
                        );
                    })}
                </div>
                <hr/>

                <h5>Component Themes</h5>
                <div className="layout-themes">
                    {componentThemes.map((theme) => {
                        return (
                            <div key={theme.name}>
                                <button className="p-link" style={{cursor: 'pointer', backgroundColor: theme.color}}
                                        onClick={() => changeComponentTheme(theme.name)} title={theme.name}>
                                    {appViewConfig.componentTheme === theme.name && <i className="pi pi-check"></i>}
                                </button>
                            </div>
                        );
                    })}
                </div>

                <h5>Calendar color</h5>
                <div className="layout-themes">
                    {calendarThemes.map((theme) => {
                        return (
                            <div key={theme.name}>
                                <button className="p-link" style={{cursor: 'pointer', backgroundColor: theme.color}}
                                        onClick={() => changeCalendarColor(theme)} title={theme.name}>
                                    {theme.colorId === calendarColor?.colorId && <i className="pi pi-check"></i>}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

import React, {FC} from "react";
import {StyledHomePage} from "./HomePage.style";

interface HomePageProps {
}

export const HomePage: FC<HomePageProps> = (props: HomePageProps) => {
    return <StyledHomePage>
    </StyledHomePage>;
};

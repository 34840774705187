import {Day} from '../../../common/service/util/Day';

export interface SupplierDictionaryDTO {
    id: string;
    name: string;
    invoiceName: string;
    shopUrl: string;
    supplierType: SupplierType;
}

export enum SupplierType {
    ALLEGRO = "ALLEGRO",
    WEBSITE = "WEBSITE",
    STATIONARY_SHOP = "STATIONARY_SHOP"
}

export const getInitialSupplierDictionaryDTO = (): SupplierDictionaryDTO => ({
    id: undefined,
    name: '',
    invoiceName: '',
    shopUrl: '',
    supplierType: undefined,
});

import React, {FC, useState} from "react";
import {UserPersonalDataDTO} from "../../../service/UserProfilePage.type";
import {ActionModal} from "../../../../common/modal/actionModal/ActionModal.component";
import {TextInput} from "../../../../common/components/input/textInput/TextInput.component";
import {translate} from "../../../../common/lang/service/Translate.service";
import {LabelType} from "../../../../common/components/input/inputLabel/InputLabel.component";
import {isDefinedAndNotEmpty} from "../../../../common/service/util/StringUtils";

interface UserProfilePersonalDataModalProps {
    hideModal: () => void;
    savePersonalData: (personalData: UserPersonalDataDTO) => void;
    dataToEdit: UserPersonalDataDTO;
}

export const UserProfilePersonalDataModal: FC<UserProfilePersonalDataModalProps> = (props: UserProfilePersonalDataModalProps) => {
    const [editedData, setEditedData] = useState(props.dataToEdit);

    const isValid = (): boolean => {
        return isDefinedAndNotEmpty(editedData.firstName)
            && isDefinedAndNotEmpty(editedData.lastName)
            && editedData.firstName.length < 50
            && editedData.lastName.length < 50
            && (!isDefinedAndNotEmpty(editedData.displayName) || editedData.displayName.length < 100);
    }

    return <ActionModal
        header={translate("EDIT_PERSONAL_DATA")}
        isSaveDisabled={!isValid()}
        onConfirm={() => props.savePersonalData(editedData)}
        onReject={props.hideModal}
        isVisible={true}
    >
        <TextInput
            label={translate("FIRST_NAME")}
            value={editedData?.firstName}
            setValue={(value: string) => setEditedData(prevState => ({...prevState, firstName: value}))}
            labelType={LabelType.HORIZONTAL}
        />
        <TextInput
            label={translate("LAST_NAME")}
            value={editedData?.lastName}
            setValue={(value: string) => setEditedData(prevState => ({...prevState, lastName: value?.toString()}))}
            labelType={LabelType.HORIZONTAL}
        />
        <TextInput
            label={translate("DISPLAY_NAME")}
            value={editedData?.displayName}
            setValue={(value: string) => setEditedData(prevState => ({
                ...prevState,
                displayName: value?.toString()
            }))}
            labelType={LabelType.HORIZONTAL}
        />
    </ActionModal>;
};

import {Day} from "../../../../../service/util/Day";
import {CalendarEventDTO} from "./Calendar.types";
import {get} from "../../../../../service/http/Http.service";
import {UriBuilder} from "../../../../../service/util/UriBuilder.class";

export const getDayEvents = (day: Day): Promise<CalendarEventDTO[]> => {
    return get<CalendarEventDTO[]>(new UriBuilder()
        .all("calendar")
        .all("user")
        .one("day", day.toFormattedString())
        .build());
}

export const getMonthEvents = (day: Day): Promise<CalendarEventDTO[]> => {
    return get<CalendarEventDTO[]>(new UriBuilder()
        .all("calendar")
        .one("month", day.getMonthNumber())
        .one("year", day.getFullYear())
        .build());
}


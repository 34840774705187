import {UriBuilder} from '../../../common/service/util/UriBuilder.class';
import {CommandResult} from '../../../common/service/result/Result.type';
import {get, post} from '../../../common/service/http/Http.service';
import {
    AdditionalCostDTO,
    AddOrderItemCountDTO,
    AddOrderItemDTO,
    AddOrderItemPriceDTO,
    OrderDTO
} from './OrderPage.type';

export const startOrderRest = (): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("order")
        .all("start")
        .build());
}

export const closeOrderRest = (orderId: string): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("order", orderId)
        .all("close")
        .build());
}

export const addOrderItemPriceRest = (orderId: string, addOrderItemPriceDTO: AddOrderItemPriceDTO): Promise<void> => {
    return post<void>(new UriBuilder()
        .one("order", orderId)
        .all("addItemPrice")
        .build(), addOrderItemPriceDTO);
}

export const addItemCountRest = (orderId: string, addOrderItemCountDTO: AddOrderItemCountDTO): Promise<void> => {
    return post<void>(new UriBuilder()
        .one("order", orderId)
        .all("addItemCount")
        .build(), addOrderItemCountDTO);
}

export const addOrderItemRest = (orderId: string, addOrderItemDTO: AddOrderItemDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("order", orderId)
        .all("addItem")
        .build(), addOrderItemDTO);
}

export const addAdditionalCostRest = (orderId: string, additionalCostDTO: AdditionalCostDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("order", orderId)
        .all("addAdditionalCost")
        .build(), additionalCostDTO);
}

export const getLatestOrderRest = (): Promise<OrderDTO> => {
    return get<OrderDTO>(new UriBuilder()
        .all("order")
        .all("latest",)
        .build());
}

export const getOrderRest = (number: number): Promise<OrderDTO> => {
    return get<OrderDTO>(new UriBuilder()
        .one("order", number)
        .build());
}

import React, {FC} from "react";
import {SimpleTableColumnProps} from '../../../common/components/simpleTable/service/SimpleTable.type';
import {Card} from '../../../common/components/card/Card.component';
import {SimpleTable} from '../../../common/components/simpleTable/SimpleTable.component';

interface ReportCardProps {
    title: string;
    values: any;
    columnProps: SimpleTableColumnProps[];
}

export const ReportCard: FC<ReportCardProps> = (props: ReportCardProps) => {
    return <Card label={props.title}>
        <SimpleTable values={props.values} columnProps={props.columnProps}/>
    </Card>;
};

export enum Result {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE"
}

export interface CommandResult {
    result: Result;
    messages: string[];
}

export const isCommandResult = (object: any): boolean => {
    return (object.result === Result.SUCCESS || object.result === Result.FAILURE) && object.messages instanceof Array;
}

export const isSuccess = (commandResult: CommandResult): boolean => {
    return commandResult.result === Result.SUCCESS
}
import {isNotDefined} from './ObjectUtils';

export class FileRequestBuilder {
    private formData: FormData = new FormData();

    withFiles(files: File[], name: string): FileRequestBuilder {
        if(isNotDefined(files)) {
            return this;
        }

        for (const item of files) {
            this.formData.append(name, item);
        }

        return this;
    }

    withFile(file: File, name: string): FileRequestBuilder {
        this.formData.append(name, file);
        return this;
    }

    withObject(object: any, name: string): FileRequestBuilder {
        const paymentsDTO = new Blob([JSON.stringify(object)], {
            type: "application/json;charset=utf-8"
        });

        this.formData.append(name, paymentsDTO);
        return this;
    }

    build(): FormData {
        return this.formData;
    }
}

import React, {FC, useEffect, useState} from "react";
import {StyledCashReportPage} from "./CashReportPage.style";
import {DayRange} from "../common/service/util/DayRange";
import {Day} from "../common/service/util/Day";
import {
    BankDepositDTO,
    CashReportDataDTO,
    CashReportDataType,
    CashReportDTO,
    CashReportStatus,
    CashReportSummaryDTO,
    getInitialCashReportSummary,
    PaymentsDTO,
    SalariesDTO
} from "./service/CashReportPage.type";
import {
    acceptCashReportRest,
    deleteCashReportData,
    downloadCashReportRest,
    getCashReportRest,
    recalculateReportRest,
    saveBankDepositRest,
    savePaymentsRest,
    saveSalariesRest,
    updateBankDepositRest,
    updateSalariesRest
} from "./service/CashReportPage.service";
import {CashReportSummary} from "./cashReportSummary/CashReportSummary.component";
import {CashReportToolbar} from "./cashReportToolbar/CashReportToolbar.component";
import {CashReportData} from "./cashReportData/CashReportData.component";
import {BankDepositModal} from "./bankDepositModal/BankDepositModal.component";
import {ConfirmationModal} from "../common/modal/confirmationModal/ConfirmationModal.component";
import {SalariesModal} from "./salariesModal/SalariesModal.component";
import {PaymentsModal} from "./paymentsModal/PaymentsModal.component";
import {isDefined} from "../common/service/util/ObjectUtils";
import {CommandResult, isSuccess, Result} from "../common/service/result/Result.type";
import {ImportPaymentsModal} from "./importPaymentsModal/ImportPaymentsModal.component";
import {ImportProofsModal} from "./importProofsModal/ImportProofsModal.component";

export const CashReportPage: FC = () => {
    const [currentPeriod, setCurrentPeriod] = useState<DayRange>(new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay()));
    const [cashReportData, setCashReportData] = useState<CashReportDataDTO[]>([]);
    const [cashReportSummary, setCashReportSummary] = useState<CashReportSummaryDTO>(getInitialCashReportSummary());
    const [cashReportStatus, setCashReportStatus] = useState<CashReportStatus>(CashReportStatus.CLOSED);

    const [modifiedData, setModifiedData] = useState<CashReportDataDTO>();

    const [paymentsModalVisible, setPaymentsModalVisible] = useState(false);
    const [salariesModalVisible, setSalariesModalVisible] = useState(false);
    const [bankDepositModalVisible, setBankDepositModalVisible] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

    const [importProofsModalVisible, setImportProofsModalVisible] = useState(false);
    const [importPaymentsModalVisible, setImportPaymentsModalVisible] = useState(false);

    useEffect(() => {
        retrieveCashReport(currentPeriod);
    }, []);

    async function retrieveCashReport(period: DayRange) {
        const day = period.getMiddleDay();
        const cashReportDTO: CashReportDTO = await getCashReportRest(day.getMonthNumber(), day.getFullYear());
        setCashReportData(cashReportDTO.data);
        setCashReportSummary(cashReportDTO.summary);
        setCashReportStatus(cashReportDTO.status);
    }

    const onPeriodChange = (period: DayRange) => {
        retrieveCashReport(period);
        setCurrentPeriod(period);
    }

    const saveBankDeposit = async (bankDeposit: BankDepositDTO) => {
        const commandResult = isDefined(modifiedData)
            ? await updateBankDepositRest(getMonth(), getYear(), modifiedData?.id, bankDeposit)
            : await saveBankDepositRest(getMonth(), getYear(), bankDeposit);
        if (commandResult.result === Result.SUCCESS) {
            setSalariesModalVisible(false);
            setModifiedData(undefined);
            retrieveCashReport(currentPeriod);
        }
    }

    const saveSalaries = async (salaries: SalariesDTO) => {
        const commandResult = isDefined(modifiedData)
            ? await updateSalariesRest(getMonth(), getYear(), salaries)
            : await saveSalariesRest(getMonth(), getYear(), salaries);
        if (commandResult.result === Result.SUCCESS) {
            setSalariesModalVisible(false);
            setModifiedData(undefined);
            retrieveCashReport(currentPeriod);
        }
    }

    const savePayments = async (payments: PaymentsDTO, files: File[]) => {
        const commandResult: CommandResult = await savePaymentsRest(getMonth(), getYear(), modifiedData?.id, payments, files);
        if (commandResult.result === Result.SUCCESS) {
            setPaymentsModalVisible(false);
            setModifiedData(undefined);
            retrieveCashReport(currentPeriod);
        }
    }

    const acceptCashReport = async () => {
        const commandResult: CommandResult = await acceptCashReportRest(getMonth(), getYear());
        if (commandResult.result === Result.SUCCESS) {
            retrieveCashReport(currentPeriod);
        }
    }

    const getMonth = (): number => {
        return currentPeriod.getMiddleDay().getMonthNumber();
    }

    const getYear = (): number => {
        return currentPeriod.getMiddleDay().getFullYear();
    }

    const hideModal = () => {
        setModifiedData(undefined);
        setPaymentsModalVisible(false);
        setSalariesModalVisible(false);
        setBankDepositModalVisible(false);
        setConfirmationModalVisible(false);
        setImportProofsModalVisible(false);
        setImportPaymentsModalVisible(false);
    }

    const onDataEdit = (data: CashReportDataDTO) => {
        setModifiedData(data);
        switch (data.type) {
            case CashReportDataType.SALARIES:
                setSalariesModalVisible(true);
                break;
            case CashReportDataType.BANK_DEPOSIT:
                setBankDepositModalVisible(true);
                break;
            case CashReportDataType.PAYMENTS:
                setPaymentsModalVisible(true);
                break;
        }
    }

    const onDataDelete = (data: CashReportDataDTO) => {
        setModifiedData(data);
        setConfirmationModalVisible(true);
    }

    const onDeleteConfirm = async () => {
        setConfirmationModalVisible(false);
        const commandResult = await deleteCashReportData(getMonth(), getYear(), modifiedData);
        if (commandResult.result === Result.SUCCESS) {
            setModifiedData(undefined);
            retrieveCashReport(currentPeriod);
        }
    }

    const downloadCashReport = async () => {
        downloadCashReportRest(getMonth(), getYear());
    }

    const recalculateReport = async () => {
        const result = await recalculateReportRest(getMonth(), getYear());
        if (isSuccess(result)) {
            retrieveCashReport(currentPeriod);
        }
    }

    const hasSalaries = () => cashReportData.some(data => data.type === CashReportDataType.SALARIES);

    return <StyledCashReportPage className={"grid"}>
        <CashReportToolbar
            onPeriodChange={onPeriodChange}
            addPayments={() => setPaymentsModalVisible(true)}
            addSalaries={() => setSalariesModalVisible(true)}
            addBankDeposit={() => setBankDepositModalVisible(true)}
            importPayments={() => setImportPaymentsModalVisible(true)}
            importProofs={() => setImportProofsModalVisible(true)}
            recalculateReport={recalculateReport}
            status={cashReportStatus}
            hasSalaries={hasSalaries()}
            acceptCashReport={acceptCashReport}
            downloadCashReport={downloadCashReport}
        />
        <CashReportData
            cashReportData={cashReportData}
            editData={onDataEdit}
            deleteData={onDataDelete}
            status={cashReportStatus}
        />
        <CashReportSummary cashReportSummary={cashReportSummary}/>
        {bankDepositModalVisible &&
            <BankDepositModal
                data={modifiedData}
                hideModal={hideModal}
                saveBankDeposit={saveBankDeposit}
                currentPeriod={currentPeriod}
            />}
        {salariesModalVisible &&
            <SalariesModal
                data={modifiedData}
                hideModal={hideModal}
                saveSalaries={saveSalaries}
                currentPeriod={currentPeriod}
            />}
        {paymentsModalVisible &&
            <PaymentsModal
                data={modifiedData}
                hideModal={hideModal}
                savePayments={savePayments}
                currentPeriod={currentPeriod}
            />}
        {importPaymentsModalVisible &&
            <ImportPaymentsModal
                hideModal={hideModal}
                refreshReport={() => retrieveCashReport(currentPeriod)}
                month={getMonth()}
                year={getYear()}
            />}
        {importProofsModalVisible &&
            <ImportProofsModal
                hideModal={hideModal}
                refreshReport={() => retrieveCashReport(currentPeriod)}
                month={getMonth()}
                year={getYear()}
            />}
        {confirmationModalVisible &&
            <ConfirmationModal
                onConfirm={onDeleteConfirm}
                isVisible={confirmationModalVisible}
                onReject={hideModal}
            />}
    </StyledCashReportPage>;
};

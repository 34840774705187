import {UpdateUserDTO, UserDTO} from "./UserManagementPage.type";
import {get, post} from "../../../common/service/http/Http.service";
import {UriBuilder} from "../../../common/service/util/UriBuilder.class";
import {CommandResult} from "../../../common/service/result/Result.type";

export const getAllUserRest = (): Promise<UserDTO[]> => {
    return get<UserDTO[]>(new UriBuilder()
        .all("user")
        .all("all")
        .build());
}

export const saveEditedRolesRest = (id: string, roles: string[]): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("user", id)
        .all("roles")
        .all("update")
        .build(), roles);
}

export const updateUserRest = (id: string, updatedUser: UpdateUserDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("user", id)
        .all("details")
        .all("update")
        .build(), updatedUser);
}

export const activateUserRest = (id: string): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("user", id)
        .all("activate")
        .build());
}

export const deactivateUserRest = (id: string): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("user", id)
        .all("deactivate")
        .build());
}

export const getUserProfileLink = (id: string): string => `/user/${id}/profile`
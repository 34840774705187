import {Calendar} from "primereact/calendar";
import React, {FC, useId} from "react";
import {Day} from "../../../service/util/Day";
import {InputLabel, LabelType} from "../inputLabel/InputLabel.component";

interface DateInputProps {
    value?: Day;
    setValue: (newValue: Day) => void;
    label?: string;
    labelType?: LabelType;

    minValue?: Day;
    maxValue?: Day
    viewDate?: Day;
    className?: string;
}

export const DateInput: FC<DateInputProps> = (props: DateInputProps) => {
    const id = useId();

    return <InputLabel id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <Calendar
            value={props.value?.getDate()}
            dateFormat={"dd.mm.yy"}
            onSelect={(e) => props.setValue(Day.fromDate(e.value as Date))}
            showIcon
            showButtonBar
            onClearButtonClick={() => props.setValue(undefined)}
            className={"col"}
            locale="pl"
            minDate={props.minValue?.getDate()}
            maxDate={props.maxValue?.getDate()}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
            viewDate={props.viewDate?.getDate()}
        />
    </InputLabel>;
};

import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {AppLayout} from "./appLayout/AppLayout.component";
import {GlobalPermissions} from "../../security/globalPermissions/service/GlobalPermissions.type";
import {translate} from "../../lang/service/Translate.service";
import {usePermissions} from "../../security/globalPermissions/service/Permissions.hook";

export const AppContainer = (props: any) => {
    const permissions: GlobalPermissions = usePermissions();
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const getMenuItems = () => {
        const menuItems = [];
        if (permissions.canAccessHomePage) {
            menuItems.push({label: translate("HOME"), icon: 'fa-solid fa-house', to: '/'});
        }

        if (permissions.canManageCashReport) {
            menuItems.push({label: translate("CASH_REPORT"), icon: 'fa-solid fa-cash-register', to: '/cash-report'});
        }

        if (permissions.canAccessBankTransfersPage) {
            menuItems.push({label: translate("BANK_TRANSFERS"), icon: 'fa-solid fa-money-bill-transfer', to: '/bank-transfers'});
        }

        const cleaningPages = [];
        if (permissions.canAccessCleaningPage) {
            cleaningPages.push({label: translate("CLEANING"), icon: 'fa-solid fa-broom', to: '/cleaning'});
        }

        if (permissions.canAccessCleaningDictionaryPage) {
            cleaningPages.push({label: translate("DICTIONARY"), icon: 'fa-solid fa-book', to: '/cleaning/dictionary'});
        }

        if (permissions.canAccessCleaningDictionaryPage) {
            cleaningPages.push({label: translate("USER_CLEANING_REPORT"), icon: 'fa-solid fa-chart-line', to: '/cleaning/user-report'});
        }

        if (permissions.canAccessCleaningDictionaryPage) {
            cleaningPages.push({label: translate("ITEM_CLEANING_REPORT"), icon: 'fa-solid fa-chart-line', to: '/cleaning/item-report'});
        }

        if (cleaningPages.length === 1) {
            menuItems.push(cleaningPages[0])
        } else if (cleaningPages.length > 1) {
            menuItems.push({label: translate("CLEANING"), icon: 'fa-solid fa-broom', items: cleaningPages})
        }

        const warehousePages = [];
        if (permissions.canAccessInventoryPage) {
            warehousePages.push({label: translate("INVENTORY"), icon: 'fa-solid fa-box-open', to: '/warehouse/inventory'});
        }

        if (permissions.canAccessOrderPage) {
            warehousePages.push({label: translate("ORDER"), icon: 'fa-solid fa-basket-shopping', to: '/warehouse/order'});
        }

        if (permissions.canAccessItemDictionaryPage) {
            warehousePages.push({label: translate("ITEM_DICTIONARY"), icon: 'fa-solid fa-book', to: '/warehouse/item-dictionary'});
        }

        if (permissions.canAccessSupplierDictionaryPage) {
            warehousePages.push({label: translate("SUPPLIER_DICTIONARY"), icon: 'fa-solid fa-book', to: '/warehouse/supplier-dictionary'});
        }

        if (warehousePages.length === 1) {
            menuItems.push(warehousePages[0])
        } else if (warehousePages.length > 1) {
            menuItems.push({label: translate("WAREHOUSE"), icon: 'fa-solid fa-warehouse', items: warehousePages})
        }

        const expensesPages = [];
        if (permissions.canAccessExpensesPage) {
            expensesPages.push({label: translate("EXPENSES"), icon: 'fa-regular fa-credit-card', to: '/expenses'});
        }

        if (permissions.canAccessExpensesPage) {
            expensesPages.push({label: translate("REPEATABLE_EXPENSES"), icon: 'fa-solid fa-arrows-spin', to: '/expenses/repeatable'});
        }

        if (expensesPages.length === 1) {
            menuItems.push(expensesPages[0])
        } else if (expensesPages.length > 1) {
            menuItems.push({label: translate("EXPENSES"), icon: 'fa-regular fa-credit-card', items: expensesPages})
        }

        const appointmentsPages = [];
        if (permissions.canAddAppointments) {
            appointmentsPages.push({label: translate("MY_APPOINTMENTS"), icon: 'fa-regular fa-calendar-check', to: '/appointments'});
        }

        if (permissions.canAddAnyUserAppointments) {
            appointmentsPages.push({label: translate("OTHERS_APPOINTMENTS"), icon: 'fa-regular fa-calendar-check', to: '/others-appointments'});
        }

        if (appointmentsPages.length === 1) {
            menuItems.push(appointmentsPages[0])
        } else if (appointmentsPages.length > 1) {
            menuItems.push({label: translate("APPOINTMENTS"), icon: 'fa-regular fa-calendar-check', items: appointmentsPages})
        }

        const testPages = [];
        if (permissions.canAccessUserManagementPage) {
            testPages.push({label: translate("LOGIN"), icon: 'pi pi-home', to: '/login'});
        }

        if (permissions.canAccessTestPage) {
            testPages.push({label: translate("TEST_PAGE"), icon: 'pi pi-home', to: '/test'});
        }

        if (testPages.length > 0) {
            menuItems.push({label: translate("TEST"), icon: 'pi pi-compass', items: testPages})
        }

        const administrationPages = [];
        if (permissions.canAccessUserManagementPage) {
            administrationPages.push({label: translate("USER_MANAGEMENT"), icon: 'fa-solid fa-user', to: '/user-management'});
        }

        if (administrationPages.length > 0) {
            menuItems.push({label: translate("ADMINISTRATION"), icon: 'fa-solid fa-wrench', items: administrationPages})
        }

        return menuItems;
    };

    return (
        <AppLayout menuItems={getMenuItems()}>
            {props.children}
        </AppLayout>
    );
};

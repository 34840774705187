// @ts-ignore
interface String {
    toNumber(): number;
    isEmpty(): boolean;
}

String.prototype.toNumber = function (): number {
    return +this;
};

String.prototype.isEmpty = function (): boolean {
    return this === "";
};

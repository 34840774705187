import axios, {AxiosInstance, AxiosResponse} from "axios";
import saveAs from "file-saver";
import {
    getHttpCommandResultEvent,
    getHttpRequestEndedEvent,
    getHttpRequestErrorEvent,
    getHttpRequestStartedEvent
} from "./Events.service";
import {authenticationService} from "../../../../index";
import JSZip from 'jszip';
import {isCommandResult} from "../result/Result.type";

export interface ServerError {
    code: number;
    details: string;
}

export const getBaseURL = (): string => {
    return process.env.REACT_APP_STAPP_API_URL;
};

function addInterceptors(axiosInstance: AxiosInstance) {
    axiosInstance.interceptors.response.use(
        (success: AxiosResponse) => {
            if (isCommandResult(success.data)) {
                window.dispatchEvent(getHttpCommandResultEvent(success.data));
            }

            return success;
        },

        (error: any): Promise<AxiosResponse<ServerError | string>> => {
            window.dispatchEvent(getHttpRequestErrorEvent(error.response?.data));
            return Promise.reject(error);
        }
    );
}

const instance = axios.create({
    baseURL: getBaseURL()
});

addInterceptors(instance);

const downloadInstance = axios.create({
    baseURL: getBaseURL(),
    responseType: "blob",
});

const getAuthHeader = () => {
    return {"Authorization": authenticationService.getIdTokenString()};
}

export const get = async <T>(url: string, params?: any, headers?: any): Promise<T> => {
    return authenticationService.wrapRequest(async function () {
        window.dispatchEvent(getHttpRequestStartedEvent(url));
        const response: AxiosResponse<T> = await instance.get<T>(url, {
            params: params,
            headers: {...headers, ...getAuthHeader()}
        }).finally(() => {
            window.dispatchEvent(getHttpRequestEndedEvent(url));
        });
        return response.data;
    });
};

export const post = async <T>(url: string, data?: any, headers?: any, params?: any): Promise<T> => {
    return authenticationService.wrapRequest(async function () {
        window.dispatchEvent(getHttpRequestStartedEvent(url));
        const response: AxiosResponse<T> = await instance.post<T>(url, data, {
            withCredentials: true,
            params: params,
            headers: {...headers, ...getAuthHeader()}
        }).finally(() => {
            window.dispatchEvent(getHttpRequestEndedEvent(url));
        });
        return response.data;
    });
};

export const put = async <T>(url: string, data?: any, headers?: any, params?: any): Promise<T> => {
    return authenticationService.wrapRequest(async function () {
        window.dispatchEvent(getHttpRequestStartedEvent(url));
        const response: AxiosResponse<T> = await instance.put<T>(url, data, {
            withCredentials: true,
            params: params,
            headers: {...headers, ...getAuthHeader()}
        }).finally(() => {
            window.dispatchEvent(getHttpRequestEndedEvent(url));
        });
        return response.data;
    });
};

// underscore in name is needed as delete is reserved word
export const _delete = async <T>(url: string, data?: any, headers?: any, params?: any): Promise<T> => {
    return authenticationService.wrapRequest(async function () {
        window.dispatchEvent(getHttpRequestStartedEvent(url));
        const response: AxiosResponse<T> = await instance.delete<T>(url, {
            params: params,
            withCredentials: true,
            headers: {...headers, ...getAuthHeader()},
            data: data
        }).finally(() => {
            window.dispatchEvent(getHttpRequestEndedEvent(url));
        });
        return response.data;
    });
};

export const downloadFile = async (url: string, fileName: string) => {
    await authenticationService.wrapRequest(async function () {
        window.dispatchEvent(getHttpRequestStartedEvent(url));
        const response = await downloadInstance.get<Blob>(url)
            .finally(() => {
                window.dispatchEvent(getHttpRequestEndedEvent(url));
            });
        saveAs(response.data, fileName);
    });
};

export const handleDownload = (uri: string, type: string) => {
    axios({
        url: getBaseURL() + uri,
        method: 'GET',
        responseType: 'blob',
        headers: {...getAuthHeader()}
    })
        .then((response) => {
            saveAs(new Blob([response.data], {type: type}));
        })
        .catch((error) => {
            console.error('Error downloading file:', error);
        });
};

export const handleZipDownload = (uri: string) => {
    axios({
        url: getBaseURL() + uri,
        method: 'GET',
        responseType: 'blob',
        headers: {...getAuthHeader()}
    })
        .then((response) => {
            saveUnzipedFiles(new Blob([response.data], {type: 'application/zip'}));
        })
        .catch((error) => {
            console.error('Error downloading file:', error);
        });
};

const saveUnzipedFiles = (blob: Blob) => {
    const zip: JSZip = new JSZip();
    zip.loadAsync(blob).then((value: any) => {
        // browser can save only 10 files
        if (Object.keys(value.files).length > 9) {
            saveAs(blob, "download.zip");
        } else {
            Object.keys(value.files).map(async (relativePath: string) => {
                const file = value.files[relativePath];
                saveAs(await file.async('blob'), relativePath);
            });
        }
    });
}

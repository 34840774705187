import React, {FC} from "react";
import {Button} from 'primereact/button';
import {handleZipDownload} from '../../service/http/Http.service';

interface FileDownloadProps {
    isZip?: boolean;
    url: string;
}

export const FileDownload: FC<FileDownloadProps> = (props: FileDownloadProps) => {
    return <Button
        onClick={() => handleZipDownload(props.url)}
        icon={"pi pi-download"}
        className={"p-button-rounded p-button-text"}
    />;
};

import React, {FC} from "react";
import {StyledSimpleTable} from "./SimpleTable.style";
import {SimpleTableColumnProps, SimpleTablesSummaryProps} from "./service/SimpleTable.type";
import {isDefined} from "../../service/util/ObjectUtils";
import {Button} from "primereact/button";

interface SimpleTableProps {
    values: any[];
    columnProps: SimpleTableColumnProps[];
    summary?: SimpleTablesSummaryProps;
    transformValue?: (value: any) => string;
    onEdit?: (value: any) => void;
}

export const SimpleTable: FC<SimpleTableProps> = (props: SimpleTableProps) => {
    const getValue = (value: any, column: SimpleTableColumnProps) => {
        return isDefined(column.transformValue)
            ? column.transformValue(value)
            : value[column.field];
    }

    return <StyledSimpleTable>
        <div className="layout-invoice-page">
            <div id="invoice-content">
                <div className="invoice">
                    <div className="invoice-items">
                        <table>
                            <thead>
                            <tr>
                                {props.columnProps.map((column, index) =>
                                    <th key={index}>{isDefined(column.header) ? column.header : ""}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {props.values.map((value, index) =>
                                <tr key={index}>
                                    {props.columnProps.map((column, columnIndex) =>
                                        <td key={columnIndex}>{getValue(value, column)}</td>
                                    )}
                                    {props.onEdit && <td style={{width: "32px"}}>
                                        <Button onClick={() => props.onEdit(value)} icon={"pi pi-pencil"}
                                                className={"p-button-rounded p-button-text"}/></td>}
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>

                    {isDefined(props.summary) && <div className="invoice-summary">
                        <table>
                            <tbody>
                            <tr>
                                <td>{props.summary.header}</td>
                                <td>{props.summary.value}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
    </StyledSimpleTable>;
};

import {getCookie, removeCookie, setCookie} from "../../service/util/CookieUtils";
import {isDefined} from "../../service/util/ObjectUtils";
import {Token} from "./Token";
import {getSearchParamsAsObject} from "../../service/util/SearchParamsUtil";
import {AuthenticationService} from "./AuthenticationService";

export class AuthenticationServiceImpl implements AuthenticationService {
    private token: Token = undefined;
    private refreshingToken: Promise<string> = undefined;

    constructor(private loginPagePath: string,
                private homePagePath: string,
                private refreshTokenUrl: string,
                private getNewTokenUrl: string,
                private getLogoutUrl: string,
                ) {
    }


    private getNewToken = (accessCode: string): Promise<string> => {
        return this.doRequest(this.getNewTokenUrl, {"accessCode": accessCode}, "POST");
    }

    private revokeTokens = (): Promise<string> => {
        return this.doVoidRequest(this.getLogoutUrl, {"oldIdToken": this.getToken().getTokenString()}, "POST");
    }

    private getRefreshedToken = (): Promise<string> => {
        return this.doRequest(this.refreshTokenUrl, {"oldIdToken": this.getToken().getTokenString()}, "POST");
    }

    public redirectToHomePage = (): void => {
        window.top.location.href = window.origin + this.homePagePath;
    };

    private redirectToLoginPage = (): void => {
        window.top.location.href = window.origin + this.loginPagePath;
    };

    public handleUserAuth = async (): Promise<boolean> => {
        if (!this.tokenExists() || !this.isTokenValid()) {
            this.redirectToLoginPage();
            return false;
        }

        if ((this.isTokenExpired() || this.isTokenCloseToExpire())) {
            this.refreshingToken = isDefined(this.refreshingToken)
                ? this.refreshingToken
                : this.getRefreshedToken();
            try {
                let refreshedToken = await this.refreshingToken;
                this.cookieTheToken(refreshedToken);
                this.refreshingToken = undefined;
            } catch (e) {
                await this.logout();
                return false;
            }
        }

        return true;
    }

    public isUserLoggedIn = (): boolean => {
        return this.tokenExists() && this.isTokenValid() && !this.isTokenExpired();
    }

    private tokenExists = (): boolean => {
        return isDefined(this.getToken().getTokenString()) && this.getToken().getTokenString().length > 0;
    }

    private isTokenValid = (): boolean => {
        return this.getToken().isValid();
    }

    private isTokenExpired = (): boolean => {
        return this.getToken().isExpired();
    }

    private isTokenCloseToExpire = (): boolean => {
        return this.getToken().isCloseToExpire();
    }

    protected getToken = (): Token => {
        return isDefined(this.token) ? this.token : new Token(getCookie("Authorization"));
    }

    public async wrapRequest<T>(request: () => Promise<T>): Promise<T> {
        const isAuthenticated = await this.handleUserAuth();
        let result: Promise<T> = undefined;
        if (isAuthenticated) {
            result = request();
        } else {
            this.redirectToLoginPage();
        }

        return result;
    }

    handleLoginResponse = async () => {
        const newToken: any = await this.getNewToken(this.getAccessCode());
        this.cookieTheToken(newToken);
        this.redirectToHomePage();
    }

    private getAccessCode = (): string => {
        return getSearchParamsAsObject()['code'];
    }

    private cookieTheToken = (token: string) => {
        setCookie("Authorization", "Bearer%20" + token, 43200);
    };

    public logout = async () => {
        await this.revokeTokens();
        removeCookie("Authorization");
        this.redirectToLoginPage();
    }

    getIdTokenString(): string {
        return this.getToken().getTokenString();
    }

    private doRequest = (endpoint: string, params: any, method: string): Promise<any> => {
        const options = {
            method: method,
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return fetch(endpoint, options)
            .then(response => response.json())
            .then(data => {
                return data.idToken;
            });
    }

    private doVoidRequest = (endpoint: string, params: any, method: string): Promise<any> => {
        const options = {
            method: method,
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return fetch(endpoint, options);
    }
}

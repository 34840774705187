import React, {FC} from "react";
import {Button} from "primereact/button";
import {formatAsCurrency} from "../../../../../common/service/util/CurrencyUtil";

interface SupplierOrderSummaryProps {
    totalCost: number;
    inEditMode: boolean
    openAdditionalCostModal: () => void;
}

export const SupplierOrderSummary: FC<SupplierOrderSummaryProps> = (props: SupplierOrderSummaryProps) => {
    return <div className={"order-total"}>
        {props.inEditMode && <Button
            icon={"pi pi-plus"}
            className="p-button-rounded p-button-success p-button-text"
            tabIndex={-1}
            onClick={props.openAdditionalCostModal}
        />}
        {formatAsCurrency(props.totalCost)}
    </div>;
};

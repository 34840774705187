import {combineReducers, Reducer} from "redux";
import {UserBasicInfoDTO} from "../userBasicInfo/service/UserBasicInfo.type";
import {userBasicInfoReducer} from "../userBasicInfo/service/UserBasicInfo.reducer";
import {UserBasicInfoType} from "./UserBasicInfoActions.type";
import {globalPermissionsReducer} from "../globalPermissions/redux/GlobalPermissions.reducer";
import {GlobalPermissions} from "../globalPermissions/service/GlobalPermissions.type";

export const userBasicInfoRootReducer: Reducer<UserBasicInfoRootState, UserBasicInfoType> = combineReducers({
    userBasicInfo: userBasicInfoReducer,
    globalPermissions: globalPermissionsReducer,
});

export interface UserBasicInfoRootState {
    userBasicInfo: UserBasicInfoDTO;
    globalPermissions: GlobalPermissions
}

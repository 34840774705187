import React, {FC} from "react";
import {isDefined} from "../../../../../service/util/ObjectUtils";
import {isDefinedAndNotEmpty} from "../../../../../service/util/StringUtils";
import {UserBasicInfoDTO} from "../../../../../security/userBasicInfo/service/UserBasicInfo.type";
import {useCurrentUser} from "../../../../../security/userBasicInfo/service/CurrentUser.hook";

interface UserDetailsProps {
}

export const UserDetails: FC<UserDetailsProps> = (props: UserDetailsProps) => {
    const userBasicInfo: UserBasicInfoDTO = useCurrentUser();

    const getFullName = () => {
        if (isDefinedAndNotEmpty(userBasicInfo.displayName)) {
            return userBasicInfo.displayName;
        }

        return isDefinedAndNotEmpty(userBasicInfo.firstName)
            ? userBasicInfo.firstName + " " + userBasicInfo.lastName
            : userBasicInfo.email;
    }

    const getAvatarUrl = (): string => {
        const avatarUrl = userBasicInfo.avatarUrl;
        return isDefined(avatarUrl)
            ? avatarUrl
            : "/assets/layout/images/profile-image.png";
    }

    return <div className="user-detail-content">
        <img src={getAvatarUrl()} alt="atlantis" className="user-image"/>
        <span className="user-name">{getFullName()}</span>
    </div>;
};

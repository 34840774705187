import React, {FC} from "react";
import {InventoryEntryDTO} from "../../service/InventoryPage.type";
import {InputNumber} from "primereact/inputnumber";
import {StyledInventoryEntryInput} from "./InventoryEntryInput.style";

interface InventoryEntryInputProps {
    inventoryEntry: InventoryEntryDTO;
    setCount: (count: number) => void;
}

export const InventoryEntryInput: FC<InventoryEntryInputProps> = (props: InventoryEntryInputProps) => {
    return <StyledInventoryEntryInput>
        <div className={"row entry-container"}>
            <InputNumber
                value={props.inventoryEntry.count}
                onValueChange={(e) => props.setCount(e.value)}
                inputClassName={'input-input'}
                min={0}
                maxFractionDigits={0}
                inputId={"inventory-count-input"}
            />
        </div>
    </StyledInventoryEntryInput>;
};

import React, {FC, useId} from "react";
import {StyledInputLabel} from "../inputLabel/InputLabel.style";
import {isDefinedAndNotEmpty} from "../../../service/util/StringUtils";
import {LabelType} from '../inputLabel/InputLabel.component';
import {isDefined} from "../../../service/util/ObjectUtils";

interface ValueLabelProps {
    label: string;
    value: string;
    className?: string;
    labelType?: LabelType;
    link?: string;
}

export const ValueLabel: FC<ValueLabelProps> = (props: ValueLabelProps) => {
    const isHorizontal = props.labelType === LabelType.HORIZONTAL;
    const className = isHorizontal ? "field grid" : "field";
    const id = useId();

    const getValue = (): string => {
        return isDefinedAndNotEmpty(props.value) ? props.value : '---';
    }

    return <StyledInputLabel withSpanPadding={false} labelType={props.labelType} className={"p-fluid" + " " + props.className}>
        <div className={className}>
            {(props.labelType !== LabelType.INLINE) &&
                <label className={isHorizontal ? "col-6" : undefined} htmlFor={id}>{props.label}</label>}
            {isDefined(props.link) && isDefined(props.value)
                ? <div className={isHorizontal ? "col-6" : undefined}><a href={props.link} id={id} target={"_blank"}>{getValue()}</a></div>
                : <div id={id} className={isHorizontal ? "col-6" : undefined}>{getValue()}</div>}
        </div>
    </StyledInputLabel>;
};

ValueLabel.defaultProps = {
    labelType: LabelType.VERTICAL
}

import {UserBasicInfoDTO, UserBasicInfoRetrieved} from "./UserBasicInfo.type";
import {UserBasicInfoType} from "../../redux/UserBasicInfoActions.type";

const initialState: UserBasicInfoDTO = {
    avatarUrl: undefined,
    email: undefined,
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    displayName: undefined
};

export const userBasicInfoReducer = (state = initialState,
                                     action: UserBasicInfoType): UserBasicInfoDTO => {
    switch (action.type) {
        case UserBasicInfoRetrieved:
            return action.userBasicInfo;
        default:
            return state;
    }
};

import React, {FC} from "react";
import {importPaymentsRest} from "../service/CashReportPage.service";
import {isSuccess} from "../../common/service/result/Result.type";
import {ImportFileModal} from "../../common/modal/importFileModal/ImportFileModal.component";
import {translate} from "../../common/lang/service/Translate.service";

interface ImportPaymentsModalProps {
    hideModal: () => void;
    refreshReport: () => void;
    month: number;
    year: number;
}

export const ImportPaymentsModal: FC<ImportPaymentsModalProps> = (props: ImportPaymentsModalProps) => {
    const onSave = async (files: File[]) => {
        const result = await importPaymentsRest(props.month, props.year, files[0]);
        if (isSuccess(result)) {
            props.refreshReport();
            props.hideModal();
        }
    }

    return <ImportFileModal hideModal={props.hideModal} onSave={onSave} header={translate("IMPORT_PAYMENTS")}/>;
};

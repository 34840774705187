import React, {FC, PropsWithChildren} from "react";
import {StyledButtonRow} from "./ButtonRow.style";

export interface ButtonRowProps {
    className?: string;
    alignRight?: boolean;
}

export const ButtonRow: FC<PropsWithChildren<ButtonRowProps>> = (props: PropsWithChildren<ButtonRowProps>) => {
    return <StyledButtonRow alignRight={props.alignRight} className={props.className}>
        {props.children}
    </StyledButtonRow>;
};

ButtonRow.defaultProps = {
    alignRight: true
}
import React, {FC, useId} from "react";
import {MultiSelect} from "primereact/multiselect"
import {InputLabel, LabelType} from "../inputLabel/InputLabel.component";
import SelectOption from "../multiselectChips/service/SelectOption.type";

interface MultiselectProps {
    options: SelectOption[];
    selectedValues: any[];
    setSelectedValues: (selectedValues: any[]) => void;
    label?: string;
    required?: boolean;
    labelType?: LabelType;
    className?: string;
    display?: 'comma' | 'chip' | undefined;
    maxSelectedLabels?: number;
}

export const MultiselectInput: FC<MultiselectProps> = (props: MultiselectProps) => {
    const id = useId();
    return <InputLabel id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <MultiSelect
            id={id}
            display={props.display}
            options={props.options}
            required={props.required}
            value={props.selectedValues}
            onChange={(e) => props.setSelectedValues(e.value)}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
            filter={props.options?.length > 5}
            maxSelectedLabels={props.maxSelectedLabels}
        />
    </InputLabel>;
};

MultiselectInput.defaultProps = {
    display: 'chip'
}
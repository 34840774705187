import {get, put} from '../../../../common/service/http/Http.service';
import {ContractInformationDTO} from './UserContractInformation.type';
import {UriBuilder} from '../../../../common/service/util/UriBuilder.class';
import {CommandResult} from '../../../../common/service/result/Result.type';

export const getCurrentContractInformationRest = (userId: string): Promise<ContractInformationDTO> => {
    return get<ContractInformationDTO>(new UriBuilder()
        .one("user", userId)
        .all("contract")
        .build());
}

export const updateContractInformationRest = (userId: string, contractInformationDTO: ContractInformationDTO): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .one("user", userId)
        .all("contract")
        .all("update")
        .build(), contractInformationDTO);
}

export const terminateContractRest = (userId: string, terminationDate: string): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .one("user", userId)
        .all("contract")
        .all("terminate")
        .build(), terminationDate, {"content-type": "application/json"});
}

import React, {FC, useId} from "react";
import {InputLabel, LabelType} from "../inputLabel/InputLabel.component";
import {InputText} from 'primereact/inputtext';
import {isDefined} from '../../../service/util/ObjectUtils';

interface TextInputProps {
    value: string;
    setValue: (newValue: string) => void;
    label?: string;
    required?: boolean;
    labelType?: LabelType;

    className?: string;
    maxLength?: number
}

export const TextInput: FC<TextInputProps> = (props: TextInputProps) => {
    const id = useId();
    return <InputLabel id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <InputText
            maxLength={props.maxLength}
            type={"text"}
            id={id}
            required={props.required}
            value={isDefined(props.value) ? props.value : ''}
            onChange={(e) => props.setValue(e.target.value)}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
        />
    </InputLabel>;
};

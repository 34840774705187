import React, {FC} from "react";
import {Card} from "../../common/components/card/Card.component";
import {Title} from "../../common/components/title/Title.component";
import {SimpleTable} from "../../common/components/simpleTable/SimpleTable.component";
import {translate} from "../../common/lang/service/Translate.service";
import {SimpleTableColumnProps} from "../../common/components/simpleTable/service/SimpleTable.type";
import {CashReportDataDTO, CashReportItemDTO, CashReportStatus} from "../service/CashReportPage.type";
import {formatAsCurrency} from "../../common/service/util/CurrencyUtil";
import {Button} from "primereact/button";
import {ButtonRow} from "../../common/components/buttonRow/ButtonRow.component";
import {FileDownload} from '../../common/components/fileDownload/FileDownload.component';
import {getCashReportFilesUrl} from '../service/CashReportPage.service';

interface CashReportDataProps {
    cashReportData: CashReportDataDTO[];
    editData: (data: CashReportDataDTO) => void;
    deleteData: (data: CashReportDataDTO) => void;
    status: CashReportStatus;
}

export const CashReportData: FC<CashReportDataProps> = (props: CashReportDataProps) => {
    const columnProps: SimpleTableColumnProps[] = [
        {header: "", field: "name"},
        {
            header: "",
            field: "value",
            transformValue: ((value: CashReportItemDTO) => formatAsCurrency(value.value))
        }
    ];

    const getSummaryProps = (data: CashReportDataDTO) => ({
        header: translate("TOTAL"),
        value: formatAsCurrency(data?.amount)
    });

    const canEditReport = () => props.status !== CashReportStatus.CLOSED;

    return <div className={"col-12 md:col-8"}>
        {props.cashReportData.map(data =>
            <Card key={data.id}>
                <Title
                    title={translate(data.type)}
                    subtitle={data.proof}
                    upperTitle={data.date}
                />
                <SimpleTable
                    values={data.items}
                    columnProps={columnProps}
                    summary={getSummaryProps(data)}
                />
                {canEditReport() && <ButtonRow>
                    {data.hasFiles &&
                        <FileDownload
                            url={getCashReportFilesUrl(data.id)}
                            isZip={true}
                        />}
                    <Button onClick={() => props.editData(data)} icon={"pi pi-pencil"}
                            className={"p-button-rounded p-button-text"}/>
                    <Button onClick={() => props.deleteData(data)} icon={"pi pi-times"}
                            className={"p-button-rounded p-button-danger p-button-text"}/>
                </ButtonRow>}
                {!canEditReport() && data.hasFiles && <ButtonRow>
                    <FileDownload
                        url={getCashReportFilesUrl(data.id)}
                        isZip={true}
                    />
                </ButtonRow>}
            </Card>)}
    </div>;
};

import styled from "styled-components";

export const StyledRightPanel = styled.div`
  width: 356px;
  @media screen and (max-width: 400px) {
    width: 100%;
  }

  .p-calendar {
    width: 100%;
  }

  tr {
    height: 45px;
  }
  
  .event-details {
    white-space: pre-line;
    padding-top: 14px;
  }
  
  .event {
    border-left-width: 7px;
    border-left-style: solid;
  }
`;

import {Button} from "primereact/button";
import React, {FC} from "react";
import {Modal} from "../Modal.component";
import {isDefined, isNotDefined} from "../../service/util/ObjectUtils";
import {translate} from "../../lang/service/Translate.service";
import {Translate} from "../../lang/Translate.component";

interface ConfirmationModalProps {
    onConfirm: () => void;
    onReject: () => void;
    isVisible: boolean;
    header?: string;
    text?: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {
    const footer = <>
        <Button
            label={translate("YES")}
            className="p-button-outlined p-button-success"
            icon={"pi pi-check"}
            onClick={props.onConfirm}
        />
        <Button
            label={translate("NO")}
            className="p-button-danger p-button-text"
            icon={"pi pi-times"}
            onClick={props.onReject}
        />
    </>;

    return <Modal
        footer={footer}
        header={isDefined(props.header) ? props.header : translate("ARE_YOU_SURE")}
        hideModal={props.onReject}
        isVisible={props.isVisible}
    >
        <div className="flex align-items-center justify-content-center">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: "2rem"}}/>
            {isNotDefined(props.text)
                ? <Translate id={"ARE_YOU_SURE_YOU_WANT_TO_PROCEED"}></Translate>
                : <span>props.text</span>}
        </div>
    </Modal>;
};

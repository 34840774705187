import {DayRange} from "../../../service/util/DayRange";
import {Day} from "../../../service/util/Day";

export enum DisplayStrategy {
    FIRST_AND_LAST_DAY,
    MIDDLE_DAY
}

export interface PeriodChangerDisplay {
    displayStrategy: DisplayStrategy;
    getLabelFromDay: (day: Day) => string;
}

export interface PeriodChangeStrategy {
    periodMoveUnitAmount: number;
    movePeriod: (currentPeriod: DayRange, unitsToMove: number) => DayRange;
}

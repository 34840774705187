import React, {FC, PropsWithChildren, useEffect} from "react";
import {AppDispatch} from "../../redux/Thunk.type";
import {useDispatch} from "react-redux";
import {GlobalPermissions} from "./service/GlobalPermissions.type";
import retrieveGlobalPermissionsAction from "./redux/GlobalPermissions.action";
import {usePermissions} from "./service/Permissions.hook";

interface GlobalPermissionsProps {
}

export const GlobalPermissionsComponent: FC<PropsWithChildren<GlobalPermissionsProps>> = (props: PropsWithChildren<GlobalPermissionsProps>) => {
    const globalPermissions: GlobalPermissions = usePermissions();

    const dispatch: AppDispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!globalPermissions.canAccessStapp) {
            dispatch(retrieveGlobalPermissionsAction());
        }
    }, []);
    return <>
        {globalPermissions.canAccessStapp && props.children}
    </>;
};

import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AppRouter} from "./router/AppRouter";
import store from "./common/redux/ConfigureStore";
import {Provider} from 'react-redux';
import {IntlProvider} from 'react-intl';
import {enMessages} from "./common/lang/service/en-gb";
import {GlobalStyle} from "./GlobalStyle";
import {LoginPage} from "./loginPage/LoginPage.component";
import {Growl} from "./common/growl/Growl.component";
import "./common/service/util/prototypes/array";
import "./common/service/util/prototypes/string";
import {RequestResultInterceptor} from "./common/requestResultInterceptor/RequestResultInterceptor.component";
import {AccessDeniedPage} from "./accessDeniedPage/AccessDeniedPage";
import {addPolishLocale} from "./common/lang/Locale.service";

export const App = () => {
    addPolishLocale();

    return (
        <Provider store={store}>
            <RequestResultInterceptor>
                <IntlProvider locale="en" defaultLocale="en" messages={enMessages}>
                    <GlobalStyle/>
                    <Growl/>
                    <Router>
                        <Routes>
                            <Route path="/login" element={<LoginPage/>}/>
                            <Route path="/accessDenied" element={<AccessDeniedPage/>}/>
                            <Route path="*" element={<AppRouter/>}/>
                        </Routes>
                    </Router>
                </IntlProvider>
            </RequestResultInterceptor>
        </Provider>
    );
}

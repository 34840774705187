import React, {FC} from "react";
import {PageNotFound} from "../../../pageNotFound/PageNotFound.component";

interface ProtectedRouteProps {
    element: React.ReactNode;
    canAccessRoute: boolean;
}

export const Protected: FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
    return <>{props.canAccessRoute ? props.element : <PageNotFound/>}</>;
};

import {ItemCategoryDTO, ItemDictionaryDTO} from './ItemDictionaryPage.type';
import {get, post, put} from '../../../common/service/http/Http.service';
import {UriBuilder} from '../../../common/service/util/UriBuilder.class';
import {CommandResult} from '../../../common/service/result/Result.type';

export const getItemsRest = (): Promise<ItemDictionaryDTO[]> => {
    return get<ItemDictionaryDTO[]>(new UriBuilder()
        .all("item")
        .all("dictionary")
        .build());
}

export const addItemRest = (item: ItemDictionaryDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("item")
        .build(), item);
}

export const updateItemRest = (id: string, item: ItemDictionaryDTO): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .one("item", id)
        .all("update")
        .build(), item);
}

export const getItemCategoriesRest = (): Promise<ItemCategoryDTO[]> => {
    return get<ItemCategoryDTO[]>(new UriBuilder()
        .all("item-category")
        .all("dictionary")
        .build());
}

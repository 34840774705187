export interface ContractInformationDTO {
    startDate: string;
    endDate?: string;
    sharePercentage: number;
    contractType: ContractType;
    hasNext?: boolean;
}

export enum ContractType {
    INTERNSHIP = "INTERNSHIP",
    EMPLOYMENT = "EMPLOYMENT",
    MANDATE = "MANDATE"
}

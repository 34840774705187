import React, {FC, useId} from "react";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {isNotDefined} from "../../../service/util/ObjectUtils";
import {StyledCheckbox} from "./Checkbox.style";

interface CheckboxProps {
    value: boolean;
    setValue: (value: boolean) => void;
    triState?: boolean;

    label?: string;
    required?: boolean;
    className?: string;
    verticalInputAlign?: boolean;
    disabled?:boolean;
}

export const Checkbox: FC<CheckboxProps> = (props: CheckboxProps) => {
    const id = useId();

    const onChange = () => {
        if (props.triState) {
            if (isNotDefined(props.value)) {
                props.setValue(true);
            } else if (props.value) {
                props.setValue(false);
            } else {
                props.setValue(null);
            }
        } else {
            if (isNotDefined(props.value) || !props.value) {
                props.setValue(true);
            } else {
                props.setValue(false);
            }
        }

    }
    const getValueForCheckbox = () => {
        if (props.triState) {
            return props.value
        } else {
            return props.value ? true : null;
        }
    };

    return <StyledCheckbox className={props.className} verticalInputAlign={props.verticalInputAlign}>
        <div className={'checkbox'}>
            <TriStateCheckbox
                id={id}
                required={props.required}
                value={getValueForCheckbox()}
                onChange={onChange}
                disabled={props.disabled}
            />
        </div>
        <div className={"checkbox-label"} onClick={onChange}>
            <label htmlFor={id}>{props.label}</label>
        </div>
    </StyledCheckbox>
};

import {FC} from "react";
import {CleaningEntryDTO, CleaningEntryItemStatus} from "../../service/CleaningPage.types";
import {Card} from "../../../../common/components/card/Card.component";
import {ButtonRow} from "../../../../common/components/buttonRow/ButtonRow.component";
import {Button} from "primereact/button";
import {useCurrentUser} from "../../../../common/security/userBasicInfo/service/CurrentUser.hook";
import {isDefinedAndNotEmpty} from '../../../../common/service/util/StringUtils';
import {ValueLabel} from '../../../../common/components/input/valueLabel/ValueLabel.component';
import {translate} from '../../../../common/lang/service/Translate.service';

interface CleaningEntryProps {
    cleaningEntry: CleaningEntryDTO;
    editCleaning: (data: CleaningEntryDTO) => void;
    deleteCleaning: (cleaningId: string) => void;
}

export const CleaningEntry: FC<CleaningEntryProps> = (props: CleaningEntryProps) => {
    const currentUserId = useCurrentUser().id;
    const canEditCleaning = (): boolean => {
        return props.cleaningEntry.userId === currentUserId;
    }

    return <div className={"col-12 md:col-6"}>
        <Card label={props.cleaningEntry.userName}>
            {props.cleaningEntry.cleanedItemIds
                .map(value =>
                    <div key={value.itemId} className={"cleaning-entry-row"}>
                        <i className={'pi ' + (value.status === CleaningEntryItemStatus.CLEAN
                            ? 'text-green-500 pi-check-circle'
                            : 'text-yellow-500 pi-exclamation-circle')}/>
                        <div className={"cleaning-entry-label"}>
                            {value.itemName}
                        </div>
                    </div>)
            }

            {isDefinedAndNotEmpty(props.cleaningEntry.comment) &&
                <ValueLabel
                    label={translate("COMMENT")}
                    value={props.cleaningEntry.comment}
                    className={'comment-value-label'}
                />}

            {canEditCleaning() && <ButtonRow>
                <Button onClick={() => props.editCleaning(props.cleaningEntry)} icon={"pi pi-pencil"}
                        className={"p-button-rounded p-button-text"}/>
                <Button onClick={() => props.deleteCleaning(props.cleaningEntry.id)} icon={"pi pi-times"}
                        className={"p-button-rounded p-button-danger p-button-text"}/>
            </ButtonRow>}
        </Card>
    </div>;
}

import React, {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {Protected} from "../../common/components/protectedRoute/Protected";
import {UserPersonalDataDTO, UserProfilePagePermissionsDTO} from "../service/UserProfilePage.type";
import {UserProfilePersonalDataTab} from "../tabs/userProfilePersonalDataTab/UserProfilePersonalDataTab.component";
import {UserContractInformationTab} from '../tabs/userContractInformationTab/UserContractInformationTab.component';
import {StyledUserProfileRouter} from "./UserProfileRouter.style";

interface UserProfileRouterProps {
    id: string;
    permissions: UserProfilePagePermissionsDTO;
    personalData: UserPersonalDataDTO;
    refreshPersonalData: () => void;
}

export const UserProfileRouter: FC<UserProfileRouterProps> = (props: UserProfileRouterProps) => {
    return <StyledUserProfileRouter>
        <Routes>
            <Route
                path="/"
                element={<Protected
                    element={<UserProfilePersonalDataTab refreshPersonalData={props.refreshPersonalData} id={props.id}
                                                         userPersonalData={props.personalData}
                                                         permissions={props.permissions}/>}
                    canAccessRoute={props.permissions.canViewPersonalData}/>}
            />
            <Route
                path="/personalData"
                element={<Protected
                    element={<UserProfilePersonalDataTab refreshPersonalData={props.refreshPersonalData} id={props.id}
                                                         userPersonalData={props.personalData}
                                                         permissions={props.permissions}/>}
                    canAccessRoute={props.permissions.canViewPersonalData}/>}
            />
            <Route
                path="/contractInformation"
                element={<Protected
                    element={<UserContractInformationTab id={props.id} permissions={props.permissions}/>}
                    canAccessRoute={props.permissions.canViewContractInformation}/>}
            />
        </Routes>
    </StyledUserProfileRouter>;
};

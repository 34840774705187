import React, {FC} from "react";
import {FileUpload} from 'primereact/fileupload';
import {StyledFileInput} from './FileInput.style';
import {translate} from '../../lang/service/Translate.service';
import {isDefinedAndNotEmpty} from '../../service/util/StringUtils';

interface FileInputProps {
    label?: string;
    className?: string;
    onSelect: (files: File[]) => void;
    files: File[];
    multiple?: boolean;
    type?: boolean;
    maxFileSize?: number;
    accept?: string;
}

export const FileInput: FC<FileInputProps> = (props: FileInputProps) => {
    const label = isDefinedAndNotEmpty(props.files)
        ? (translate('CHOOSEN_FILES') + ' ' + props.files.length)
        : translate("CHOOSE_FILES");

    return <StyledFileInput className={props.className}>
        <FileUpload
            customUpload={true}
            uploadOptions={{style: {display: "none"}}}
            chooseOptions={{className: 'p-button p-component', style: {height: '33px'}, label: label}}
            cancelOptions={{
                iconOnly: true,
                className: 'p-button p-component p-button-rounded p-button-text delete-button p-button-icon-only p-button-danger'
            }}
            itemTemplate={<></>}
            onSelect={e => props.onSelect([...e.files])}
            progressBarTemplate={<></>}
            onClear={() => props.onSelect([])}
            multiple={props.multiple}
            accept={props.accept}
            maxFileSize={10000000}
        />
    </StyledFileInput>;
};

import {UserPersonalDataDTO, UserProfilePagePermissionsDTO} from "./UserProfilePage.type";
import {get, put} from "../../common/service/http/Http.service";
import {UriBuilder} from "../../common/service/util/UriBuilder.class";
import {CommandResult} from "../../common/service/result/Result.type";

export const getUserProfilePagePermissionsRest = (id: string): Promise<UserProfilePagePermissionsDTO> => {
    return get(new UriBuilder()
        .all("user")
        .one("profile", id)
        .all('permissions')
        .build());
}

export const getUserPersonalDataRest = (id: string): Promise<UserPersonalDataDTO> => {
    return get(new UriBuilder()
        .all("user")
        .one("profile", id)
        .all('personalData')
        .build());
}

export const saveUserPersonalDataRest = (id: string, personalData: UserPersonalDataDTO): Promise<CommandResult> => {
    return put(new UriBuilder()
        .all("user")
        .one("profile", id)
        .all('personalData')
        .all('update')
        .build(), personalData);
}


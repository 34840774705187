import React, {FC, PropsWithChildren} from "react";
import {StyledCard} from "./Card.style";
import {isDefined} from "../../service/util/ObjectUtils";

interface CardProps {
    label?: string;
    className?: string;
}

export const Card: FC<PropsWithChildren<CardProps>> = (props: PropsWithChildren<CardProps>) => {
    const className: string = isDefined(props.className) ? "card " + props.className : "card";

    return <StyledCard className={className}>
        {isDefined(props.label) && <h5>{props.label}</h5>}
        {props.children}
    </StyledCard>;
};

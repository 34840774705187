import React, {FC, useEffect, useState} from "react";
import {StyledRepeatableExpensesPage} from './RepeatableExpensesPage.style';
import {Card} from '../../common/components/card/Card.component';
import {translate} from '../../common/lang/service/Translate.service';
import {ButtonRow} from '../../common/components/buttonRow/ButtonRow.component';
import {Button} from 'primereact/button';
import {CommandResult, isSuccess} from '../../common/service/result/Result.type';
import {isDefined} from '../../common/service/util/ObjectUtils';
import {getInitialRepeatableExpenseDTO, RepeatableExpenseDTO} from './service/RepeatableExpensesPage.type';
import {
    addRepeatableExpensesRest,
    generateRepeatableExpensesRest,
    getRepeatableExpensesListRest,
    removeRepeatableExpensesRest,
    updateRepeatableExpensesRest
} from './service/RepeatableExpensesPage.service';
import {RepeatableExpensesTable} from './repeatableExpensesTable/RepeatableExpensesTable.component';
import {RepeatableExpensesModal} from './repeatableExpensesModal/RepeatableExpensesModal.component';
import {DictionaryDTO} from '../../common/service/util/dictionary/Dictionary.type';
import {
    getSuppliersSimpleDictionaryRest
} from '../../warehouse/supplierDictionaryPage/service/SupplierDictionaryPage.service';

export const RepeatableExpensesPage: FC = () => {
    const [repeatableExpenses, setRepeatableExpenses] = useState<RepeatableExpenseDTO[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [editedEntry, setEditedEntry] = useState<RepeatableExpenseDTO>(undefined);
    const [supplierDictionary, setSupplierDictionary] = useState<DictionaryDTO[]>([])

    useEffect(() => {
        retrieveRepeatableExpenses();
        retrieveSupplierDictionary();
    }, []);

    async function retrieveRepeatableExpenses() {
        const repeatableExpensesDTOs: RepeatableExpenseDTO[] = await getRepeatableExpensesListRest();
        setRepeatableExpenses(repeatableExpensesDTOs);
    }

    async function retrieveSupplierDictionary() {
        const supplierDictionaryDTOs: DictionaryDTO[] = await getSuppliersSimpleDictionaryRest();
        setSupplierDictionary(supplierDictionaryDTOs);
    }

    const onEdit = (repeatableExpense: RepeatableExpenseDTO) => {
        setEditedEntry(repeatableExpense);
        setModalVisible(true);
    }

    const onDelete = async (id: string) => {
        const result = await removeRepeatableExpensesRest(id);
        if (isSuccess(result)) {
            retrieveRepeatableExpenses();
        }
    }

    const onAdd = () => {
        setEditedEntry(getInitialRepeatableExpenseDTO());
        setModalVisible(true);
    }

    const onGenerate = () => {
        generateRepeatableExpensesRest();
    }

    const onSave = async (entry: RepeatableExpenseDTO) => {
        const result: CommandResult = isDefined(entry.id)
            ? await updateRepeatableExpensesRest(entry.id, entry)
            : await addRepeatableExpensesRest(entry);

        if (isSuccess(result)) {
            await retrieveRepeatableExpenses();
            hideModal();
        }
    }

    const hideModal = () => {
        setEditedEntry(getInitialRepeatableExpenseDTO());
        setModalVisible(false);
    }

    return <StyledRepeatableExpensesPage>
        <Card label={translate("REPEATABLE_EXPENSES")}>
            <RepeatableExpensesTable
                repeatableExpenses={repeatableExpenses}
                onEdit={onEdit}
                onDelete={onDelete}
            />

            <ButtonRow alignRight={true} className={'add-button'}>
                <Button icon="fa-solid fa-play" className="p-button-rounded p-button-success mr-2" onClick={onGenerate}></Button>
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" onClick={onAdd}></Button>
            </ButtonRow>
        </Card>

        {modalVisible && <RepeatableExpensesModal
            initialEntry={editedEntry}
            onSave={onSave}
            hideModal={hideModal}
            supplierDictionary={supplierDictionary}
        />}
    </StyledRepeatableExpensesPage>;
};

import React, {FC} from "react";
import {Order} from '../service/OrderPage.type';
import {SupplierOrder} from './supplierOrder/SupplierOrder.component';
import {compareStrings} from '../../../common/service/util/StringUtils';

interface OrderPageContainerProps {
    data: Order[]
    orderId: string;
    inEditMode: boolean;
}

export const OrderPageContainer: FC<OrderPageContainerProps> = (props: OrderPageContainerProps) => {
    return <div className={"col-12"}>
        {props.data?.isNotEmpty() && props.data
            .sort((a, b) => compareStrings(a.supplierName, b.supplierName))
            .map(value => <SupplierOrder key={value.supplierId} order={value} orderId={props.orderId} inEditMode={props.inEditMode}/>)}
    </div>;
};

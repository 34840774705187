import React, {FC, useEffect, useState} from "react";
import {StyledUserProfilePage} from "./UserProfilePage.style";
import {useNavigate, useParams} from "react-router-dom";
import {
    getInitialUserPersonalDataDTO,
    getInitialUserProfilePagePermissionsDTO,
    UserPersonalDataDTO,
    UserProfilePagePermissionsDTO
} from "./service/UserProfilePage.type";
import {getUserPersonalDataRest, getUserProfilePagePermissionsRest} from "./service/UserProfilePage.service";
import {UserProfileHeader} from "./userProfileHeader/UserProfileHeader.component";
import {UserProfileMenu} from "./userProfileMenu/UserProfileMenu.component";
import {UserProfileRouter} from "./userProfileRouter/UserProfileRouter.component";
import {isDefinedAndNotEmpty} from "../common/service/util/StringUtils";
import {Card} from "../common/components/card/Card.component";

interface UserProfilePageProps {
}

export const UserProfilePage: FC<UserProfilePageProps> = (props: UserProfilePageProps) => {
    const [permissions, setPermissions] = useState<UserProfilePagePermissionsDTO>(getInitialUserProfilePagePermissionsDTO());
    const [userPersonalData, setUserPersonalData] = useState<UserPersonalDataDTO>(getInitialUserPersonalDataDTO());

    const id = useParams().id;
    const navigate = useNavigate();
    useEffect(() => {
        retrievePermissions();
    }, [id]);

    async function retrievePermissions() {
        const permissions: UserProfilePagePermissionsDTO = await getUserProfilePagePermissionsRest(id);
        if (!permissions.canViewProfilePage) {
            navigate('/pageNotFound');
        } else {
            setPermissions(permissions);
            retrieveUserPersonalData();
        }
    }

    async function retrieveUserPersonalData() {
        const userPersonalDataDTO: UserPersonalDataDTO = await getUserPersonalDataRest(id);
        setUserPersonalData(userPersonalDataDTO);
    }

    const getName = (): string => {
        return isDefinedAndNotEmpty(userPersonalData.displayName)
            ? userPersonalData.displayName
            : userPersonalData.firstName + " " + userPersonalData.lastName;
    }

    return <>
        {permissions.canViewProfilePage &&
            <StyledUserProfilePage>
                <Card>
                    <UserProfileHeader name={getName()} pictureUrl={userPersonalData.profilePictureUrl}/>
                    <UserProfileMenu id={id} permissions={permissions}/>
                    <UserProfileRouter id={id} refreshPersonalData={retrieveUserPersonalData} permissions={permissions} personalData={userPersonalData}/>
                </Card>
            </StyledUserProfilePage>}
    </>;
};

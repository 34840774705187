import moment, {Moment} from "moment/moment";
import {isNotDefined} from "./ObjectUtils";

export class ClockTime {
    private static readonly format: string = "HH:mm:ss";
    private static readonly minutesFormat: string = "HH:mm";

    private constructor(private readonly time: Moment) {
    }

    static fromDate(date: Date): ClockTime {
        if (isNotDefined(date) || (date instanceof Date && isNaN(date.getDate()))) {
            // @ts-ignore
            return undefined;
        }

        return new ClockTime(moment(date, ClockTime.format));
    }

    static fromString(date: string): ClockTime {
        const result: Moment = moment(date, ClockTime.format, false);
        if (!result.isValid()) {
            // @ts-ignore
            return undefined;
        }

        return new ClockTime(result);
    }

    static from(hours: number = 0, minutes: number = 0, seconds: number = 0): ClockTime {
        return this.fromString(hours + ":" + minutes + ":" + seconds);
    }

    toString(): string {
        return moment(this.time).format(ClockTime.format);
    }

    toMinutesString(): string {
        return moment(this.time).format(ClockTime.minutesFormat);
    }

    toDate(): Date {
        return this.time.toDate();
    }

    toJSON(): string {
        return this.toString();
    }

    minutes(): number {
        return this.time.toDate().getMinutes();
    }

    hours(): number {
        return this.time.toDate().getHours();
    }

    seconds(): number {
        return this.time.toDate().getSeconds();
    }

    static formatTime(time: string): string {
        return ClockTime.fromString(time)?.toMinutesString();
    }

    minus(time: ClockTime): ClockTime {
        return new ClockTime(this.time.clone().add(-time.hours(), "hours").clone().add(-time.minutes(), "minutes"));
    }
}

import React, {FC} from "react";
import {ItemCategoryDTO, ItemDictionaryDTO} from '../service/ItemDictionaryPage.type';
import {Button} from 'primereact/button';
import {ColumnProps, FilterType} from '../../../common/components/table/service/Table.types';
import {translate} from '../../../common/lang/service/Translate.service';
import {Table} from '../../../common/components/table/Table.component';
import {DictionaryDTO} from '../../../common/service/util/dictionary/Dictionary.type';

interface ItemDictionaryTableProps {
    itemDictionary: ItemDictionaryDTO[];
    onEdit: (itemDictionary: ItemDictionaryDTO) => void;
    suppliers: DictionaryDTO[];
    itemCategories: ItemCategoryDTO[];
    itemSubCategories: DictionaryDTO[];
}

export const ItemDictionaryTable: FC<ItemDictionaryTableProps> = (props: ItemDictionaryTableProps) => {
    const editBodyTemplate = (rowData: ItemDictionaryDTO) => (
        <div className="actions">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2"
                    onClick={() => props.onEdit(rowData)}/>
        </div>
    );

    const getCategoryName = (categoryId: string) => {
        return props.itemCategories.filter(value => value.id === categoryId)?.getFirst()?.name;
    }

    const getSupplierName = (supplierId: string) => {
        return props.suppliers.filter(value => value.id === supplierId)?.getFirst()?.name;
    }

    const getSubCategoryName = (subCategoryId: string) => {
        return props.itemSubCategories.filter(value => value.id === subCategoryId)?.getFirst()?.name;
    }

    const columnProps: ColumnProps[] = [
        {header: translate('NAME'), fieldName: 'name', sortable: true, filterType: FilterType.INPUT},
        {header: translate('CATEGORY'), fieldName: 'category', sortable: true , transformValue: getCategoryName, filterType: FilterType.SELECT},
        {header: translate('SUB_CATEGORY'), fieldName: 'subCategory', sortable: true , transformValue: getSubCategoryName, filterType: FilterType.SELECT},
        {header: translate('DEFAULT_SUPPLIER'), fieldName: 'defaultSupplierId', sortable: true , transformValue: getSupplierName, filterType: FilterType.SELECT},
        {header: translate('OPTIMAL_COUNT'), fieldName: 'optimalCount', sortable: true},
        {header: "", fieldName: "ID", body: editBodyTemplate, width: '32px'},
    ];

    return <Table
        values={props.itemDictionary}
        dataKey={"id"}
        columnProps={columnProps}
        multiSortMeta={[{field: "name", order: 1}]}
    />;
};

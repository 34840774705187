import React, {FC, useState} from "react";
import {ItemCategoryDTO, ItemDictionaryDTO} from '../service/ItemDictionaryPage.type';
import {ActionModal} from '../../../common/modal/actionModal/ActionModal.component';
import {isDefined} from '../../../common/service/util/ObjectUtils';
import {translate} from '../../../common/lang/service/Translate.service';
import {TextInput} from '../../../common/components/input/textInput/TextInput.component';
import {LabelType} from '../../../common/components/input/inputLabel/InputLabel.component';
import {SingleSelectInput} from '../../../common/components/input/singleSelectInput/SingleSelectInput.component';
import {getDictionarySelectOption} from '../../../common/components/input/multiselectChips/service/SelectOption.type';
import {DictionaryDTO} from '../../../common/service/util/dictionary/Dictionary.type';
import {NumberInput} from '../../../common/components/input/numberInput/NumberInput.component';
import {isNullOrWhiteSpace} from '../../../common/service/util/StringUtils';

interface ItemDictionaryModalProps {
    initialEntry: ItemDictionaryDTO;
    onSave: (entry: ItemDictionaryDTO) => void;
    hideModal: () => void;
    itemCategories: ItemCategoryDTO[];
    suppliers: DictionaryDTO[];
}

export const ItemDictionaryModal: FC<ItemDictionaryModalProps> = (props: ItemDictionaryModalProps) => {
    const [entry, setEntry] = useState<ItemDictionaryDTO>(props.initialEntry);

    const updateEntry = (field: string, value: any) => {
        setEntry(prevState => ({...prevState, [field]: value}));
    }

    const onConfirm = () => {
        props.onSave(entry);
    }

    const formInvalid = (): boolean => {
        return false;
    }

    const changeCategory = (value: string) => {
        updateEntry('subCategory', undefined)
        updateEntry('category', value)
    };

    return <ActionModal
        header={isDefined(props.initialEntry?.id) ? translate("ADD") : translate("EDIT")}
        onConfirm={onConfirm}
        isVisible={true}
        onReject={props.hideModal}
        isSaveDisabled={formInvalid()}
    >
        <div className="formgrid grid">
            <TextInput
                label={translate("NAME")}
                value={entry.name}
                setValue={(value: string) => updateEntry('name', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
        </div>
        <div className="formgrid grid">
            <TextInput
                label={translate("URL")}
                value={entry.url}
                setValue={(value: string) => updateEntry('url', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
        </div>
        <div className="formgrid grid">
            <SingleSelectInput
                label={translate("CATEGORY")}
                selectedValue={entry.category}
                setSelectedValue={changeCategory}
                labelType={LabelType.VERTICAL}
                options={getDictionarySelectOption(props.itemCategories)}
                className={"col"}
            />
            <SingleSelectInput
                label={translate("SUB_CATEGORY")}
                selectedValue={entry.subCategory}
                setSelectedValue={(value: string) => updateEntry('subCategory', value)}
                labelType={LabelType.VERTICAL}
                options={getDictionarySelectOption(props.itemCategories.filter(value => entry.category === value.id)?.getFirst()?.subCategories)}
                className={"col"}
                disabled={isNullOrWhiteSpace(entry.category)}
            />
            <SingleSelectInput
                label={translate("DEFAULT_SUPPLIER")}
                selectedValue={entry.defaultSupplierId}
                setSelectedValue={(value: string) => updateEntry('defaultSupplierId', value)}
                labelType={LabelType.VERTICAL}
                options={getDictionarySelectOption(props.suppliers)}
                className={"col"}
            />
        </div>
        <div className="formgrid grid">
            <NumberInput
                label={translate("OPTIMAL_COUNT")}
                value={entry.optimalCount}
                setValue={(value: number) => updateEntry('optimalCount', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
            <NumberInput
                label={translate("MINIMAL_COUNT")}
                value={entry.minimalCount}
                setValue={(value: number) => updateEntry('minimalCount', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
            <NumberInput
                label={translate("ORDER_TO_COUNT")}
                value={entry.orderToCount}
                setValue={(value: number) => updateEntry('orderToCount', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
        </div>
    </ActionModal>;
};

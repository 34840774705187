import {Day} from "../../../service/util/Day";
import {translate} from "../../../lang/service/Translate.service";

export const monthYearLabel = (day: Day): string => {
    return getMonthName(day.getMonthNumber()) + " " + day.getFullYear();
};

export const shortMonthYearLabel = (day: Day): string => {
    return getShortMonthName(day.getMonthNumber()) + " " + day.getFullYear();
};

export const yearLabel = (day: Day): string => {
    return day.getDate().getFullYear().toString();
};

export const dateLabel = (day: Day): string => {
    return day.getDate().toLocaleDateString();
};

export const dayMonthLabel = (day: Day): string => {
    return addZeroIfLessThanTen(day.getDate().getDate()) + "."
        + addZeroIfLessThanTen(day.getDate().getMonth() + 1);
};

export const addZeroIfLessThanTen = (num: number): string => {
    return num > 9 ? num.toString() : "0" + num;
};

export const quarterLabel = (day: Day): string => {
    return day.getQuarterName() + " " + day.getFullYear();
};

export const dayShortMonthLabel = (day: Day): string => {
    return addZeroIfLessThanTen(day.getDate().getDate()) + " "
        + getShortMonthName(day.getMonthNumber());
};

const getMonthName = (monthNumber: number): string => {
    return translate("MONTH_NAME." + monthNumber);
}

const getShortMonthName = (monthNumber: number): string => {
    return translate("SHORT_MONTH_NAME." + monthNumber);
}

import React, {FC, useEffect, useState} from "react";
import {translate} from "../../common/lang/service/Translate.service";
import {ActionModal} from "../../common/modal/actionModal/ActionModal.component";
import {TextInput} from "../../common/components/input/textInput/TextInput.component";
import {LabelType} from "../../common/components/input/inputLabel/InputLabel.component";
import {BankTransferType} from "../service/BankTransfers.type";
import SelectOption, {
    getEnumSelectOptions
} from "../../common/components/input/multiselectChips/service/SelectOption.type";
import {getAllKeywordsRest, updateKeywordsRest} from "../service/BankTransfers.service";

interface BankTransferKeywordsModalProps {
    hideModal: () => void;
}

export const BankTransferKeywordsModal: FC<BankTransferKeywordsModalProps> = (props: BankTransferKeywordsModalProps) => {
    const [keywords, setKeywords] = useState<any>({})

    useEffect(() => {
        initKeywords();
    }, []);

    async function initKeywords() {
        const keywordsDTOs = await getAllKeywordsRest();
        setKeywords(keywordsDTOs)
    }

    const save = async () => {
        await updateKeywordsRest(keywords);
        props.hideModal();
    }

    function extracted(prevState: any, transferTypeOption: SelectOption, value: string) {
        return {...prevState, [transferTypeOption.value as string]: value};
    }

    return <>
        <ActionModal header={translate("KEYWORDS")} isVisible={true} onConfirm={save} onReject={props.hideModal}>
            <div className="formgrid grid">
                {getEnumSelectOptions(BankTransferType).map(transferTypeOption =>
                    <TextInput
                        key={transferTypeOption.label}
                        label={translate(transferTypeOption.label)}
                        value={keywords[transferTypeOption.value as string]}
                        setValue={value => setKeywords((prevState: any) => extracted(prevState, transferTypeOption, value))}
                        labelType={LabelType.VERTICAL}
                        className={"col-12"}
                    />)}
            </div>
        </ActionModal>
    </>;
};

import styled from "styled-components";

export const StyledCleaningPage = styled.div`
  .cleaning-entry-row {
    display: flex;
    padding-bottom: 10px;
  }

  i {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10px;
  }

  .cleaning-entry-label {
    line-height: 14px;
  }

  .requires-cleaning-row {
    padding-bottom: 10px;
  }

  .comment-value-label {
    padding-top: 16px;
  }
`;

import React from "react";
import {FormattedMessage} from "react-intl"
import {isDefined} from "../service/util/ObjectUtils";

interface TranslateProps {
    id: string;
    description?: string | object;
    defaultMessage?: string;
}

export const Translate = (props: TranslateProps) => {
    return <FormattedMessage
        {...props}
        defaultMessage={isDefined(props.defaultMessage) ? props.defaultMessage : props.id}
    />
};

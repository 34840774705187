import React, {FC, useState} from "react";
import {AppointmentDTO, AppointmentPaymentType, FinishAppointmentDTO} from "../../service/Appointments.type";
import {finishAppointmentRest} from "../../service/Appointments.service";
import {isSuccess} from "../../../common/service/result/Result.type";
import {ActionModal} from "../../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../../common/lang/service/Translate.service";
import {CurrencyInput} from "../../../common/components/input/currencyInput/CurrencyInput.component";
import {LabelType} from "../../../common/components/input/inputLabel/InputLabel.component";
import {SingleSelectInput} from "../../../common/components/input/singleSelectInput/SingleSelectInput.component";
import {getEnumSelectOptions} from "../../../common/components/input/multiselectChips/service/SelectOption.type";
import {Checkbox} from "../../../common/components/input/checkbox/Checkbox.component";
import {ClockTime} from "../../../common/service/util/ClockTime";
import {TimeInput} from "../../../common/components/input/timeInput/TimeInput.component";
import {StyledFinishAppointmentModal} from "./FinishAppointmentModal.style";
import {isDefined} from "../../../common/service/util/ObjectUtils";
import {useParams} from "react-router-dom";

interface FinishAppointmentModalProps {
    hideModal: () => void;
    refreshAppointments: () => void;
    appointment: AppointmentDTO;
}

export const FinishAppointmentModal: FC<FinishAppointmentModalProps> = (props: FinishAppointmentModalProps) => {
    const initialState: FinishAppointmentDTO = {
        duration: ClockTime.fromString(props.appointment.endTime).minus(ClockTime.fromString(props.appointment.startTime)),
        priceWithoutDeposit: props.appointment.totalAmount - props.appointment.deposit?.amount,
        paymentType: props.appointment.paymentType,
        withRecipe: props.appointment.withRecipe,
    };

    const [appointment, setAppointment] = useState<FinishAppointmentDTO>(initialState);

    const { userId } = useParams();
    const onSave = async () => {
        const result = await finishAppointmentRest(props.appointment.id, appointment, userId);
        if (isSuccess(result)) {
            props.hideModal();
            props.refreshAppointments();
        }
    }

    const setValue = (fieldName: string, newValue: any) => {
        setAppointment(prevState => ({...prevState, [fieldName]: newValue}));
    }

    const setPaymentType = (paymentType: AppointmentPaymentType) => {
        setAppointment(prevState => ({
            ...prevState,
            paymentType: paymentType,
            withRecipe: paymentType === AppointmentPaymentType.CARD ? true : prevState.withRecipe
        }));
    }

    const isValid = (): boolean => {
        return isDefined(appointment.paymentType) && isDefined(appointment.duration) && isDefined(appointment.priceWithoutDeposit)
            && isDefined(appointment.priceWithoutDeposit) && appointment.priceWithoutDeposit >= 0;
    }

    return <ActionModal
        header={translate("FINISH_APPOINTMENT")}
        onConfirm={onSave}
        onReject={props.hideModal}
        isVisible={true}
        isSaveDisabled={!isValid()}>

        <StyledFinishAppointmentModal className="formgrid grid">
            <TimeInput
                label={translate("WORK_TIME")}
                value={appointment.duration}
                setValue={duration => setValue("duration", duration)}
                labelType={LabelType.VERTICAL}
                className={"col-6 sm:col-6"}
                minuteInterval={30}
            />
            <CurrencyInput
                label={translate("PRICE_WITHOUT_DEPOSIT")}
                value={appointment.priceWithoutDeposit}
                setValue={priceWithoutDeposit => setValue("priceWithoutDeposit", priceWithoutDeposit)}
                labelType={LabelType.VERTICAL}
                className={"col-6 sm:col-6"}
            />
            <SingleSelectInput
                options={getEnumSelectOptions(AppointmentPaymentType)}
                selectedValue={appointment.paymentType}
                setSelectedValue={setPaymentType}
                label={translate("PAYMENT_TYPE")}
                className={"col-6 sm:col-6"}
            />
            <div className={"col-6 sm:col-6"}>
                <Checkbox
                    value={appointment.withRecipe}
                    setValue={withRecipe => setValue("withRecipe", withRecipe)}
                    label={translate("WITH_RECIPE")}
                    className={"checkbox-padding"}
                    disabled={appointment.paymentType === AppointmentPaymentType.CARD}
                />
            </div>
        </StyledFinishAppointmentModal>
    </ActionModal>;
};

import React, {FC, useState} from "react";
import {ItemDictionaryDTO} from "../../../itemDictionaryPage/service/ItemDictionaryPage.type";
import {InventoryDTO, WarehouseStateEntryDTO} from "../../service/InventoryPage.type";
import {SelectButton} from "primereact/selectbutton";
import {InventoryTableType} from "./service/InventoryList.type";
import {getEnumSelectOptions} from "../../../../common/components/input/multiselectChips/service/SelectOption.type";
import {Table} from "../../../../common/components/table/Table.component";
import {ColumnProps} from "../../../../common/components/table/service/Table.types";
import {DictionaryDTO} from "../../../../common/service/util/dictionary/Dictionary.type";
import {isDefined} from "../../../../common/service/util/ObjectUtils";
import {translate} from "../../../../common/lang/service/Translate.service";
import {Button} from 'primereact/button';

interface InventoryListProps {
    countedItems: ItemDictionaryDTO[];
    itemsToCount: ItemDictionaryDTO[];
    inventory: InventoryDTO;
    warehouseState: WarehouseStateEntryDTO[];
    setItem: (itemId: string) => void;
    itemSubCategories: DictionaryDTO[];
    inEditMode: boolean;
    removeInventoryEntry: (itemId: string) => void;
}

export const InventoryList: FC<InventoryListProps> = (props: InventoryListProps) => {
    const [tableType, setTableType] = useState<InventoryTableType>(InventoryTableType.TO_COUNT);
    const getSubCategoryName = (subCategoryId: string) => {
        return props.itemSubCategories.filter(value => value.id === subCategoryId)?.getFirst()?.name;
    }

    const getItemCount = (itemId: string): string => {
        const first = props.inventory?.inventoryEntries.filter(value => value.itemId === itemId)?.getFirst()?.count;
        if (isDefined(first)) {
            return first.toString();
        }

        return props.warehouseState.filter(value => value.itemId === itemId)?.getFirst()?.count?.toString() ?? '0';
    }

    const toCountColumnProps: ColumnProps[] = [
        {header: translate("NAME"), fieldName: 'name', sortable: true},
        {header: translate("CATEGORY"), fieldName: 'subCategory', sortable: true, transformValue: getSubCategoryName},
        {header: translate("COUNT"), fieldName: 'id', sortable: true, transformValue: getItemCount, width: '69px'},
    ];

    const removeElementBodyTemplate = (rowData: ItemDictionaryDTO) => {
        return <>
            {props.inEditMode && <div >
                <Button onClick={() => props.removeInventoryEntry(rowData.id)} icon={"pi pi-times"}
                        className={"p-button-rounded p-button-danger p-button-text"}/>
            </div>}
        </>
    };

    const countedColumnProps: ColumnProps[] = [
        {header: translate("NAME"), fieldName: 'name', sortable: true},
        {header: translate("CATEGORY"), fieldName: 'subCategory', sortable: true, transformValue: getSubCategoryName},
        {header: translate("COUNT"), fieldName: 'id', sortable: true, transformValue: getItemCount, width: '69px'},
        {header: "", fieldName: "ID", body: removeElementBodyTemplate, width: '32px'},
    ];

    return <>
        {props.inEditMode && <div className={'select-button-container'}>
            <SelectButton
                value={tableType}
                unselectable={false}
                onChange={(e) => setTableType(e.value)}
                options={getEnumSelectOptions(InventoryTableType)}
                optionLabel="label"
            />
        </div>}

        {props.inEditMode && tableType === InventoryTableType.TO_COUNT &&
            <Table
                dataKey={'id'}
                columnProps={toCountColumnProps}
                values={props.itemsToCount}
                onRowClick={(value: ItemDictionaryDTO) => props.setItem(value.id)}
                className={'inventory-list'}
            />}

        {(!props.inEditMode || tableType === InventoryTableType.COUNTED) &&
            <Table
                dataKey={'id'}
                columnProps={countedColumnProps}
                values={props.countedItems}
                onRowClick={props.inEditMode ? (value: ItemDictionaryDTO) => props.setItem(value.id) : undefined}
                className={'inventory-list'}
            />}
    </>;
};

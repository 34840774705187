export const getSearchParamsAsObject = (): any => {
    const paramsString = window.location.search;
    const searchParams: any = new URLSearchParams(paramsString);
    const paramsObj = {};

    for (let [key, value] of searchParams) {
        paramsObj[key] = value;
    }

    return paramsObj;
};

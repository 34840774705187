import React, {FC} from "react";
import {ExpenseFilters, ExpenseType, PaymentType} from "../../service/ExpensesPage.type";
import {OverlayPanel} from "primereact/overlaypanel";
import {MultiselectInput} from "../../../../common/components/input/multiselectInput/MultiselectInput.component";
import {getEnumSelectOptions} from "../../../../common/components/input/multiselectChips/service/SelectOption.type";
import {translate} from "../../../../common/lang/service/Translate.service";
import {ToggleButton} from "primereact/togglebutton";

interface ExpenseFiltersPanelProps {
    refcostam: any;
    expenseFilters: ExpenseFilters;
    setExpenseFilters: (name: string, value: any) => void;
}

export const ExpenseFiltersPanel: FC<ExpenseFiltersPanelProps> = (props: ExpenseFiltersPanelProps) => {

    return <OverlayPanel ref={props.refcostam}>
        <MultiselectInput
            selectedValues={props.expenseFilters.expenseType}
            options={getEnumSelectOptions(ExpenseType)}
            setSelectedValues={value => props.setExpenseFilters('expenseType', value)}
            label={translate("EXPENSE_TYPE")}
            display={'comma'}
            maxSelectedLabels={3}
        />
        <MultiselectInput
            selectedValues={props.expenseFilters.paymentType}
            options={getEnumSelectOptions(PaymentType)}
            setSelectedValues={value => props.setExpenseFilters('paymentType', value)}
            label={translate("PAYMENT_TYPE")}
            display={'comma'}
            maxSelectedLabels={3}
        />
        <ToggleButton
            checked={props.expenseFilters.requiresPayment}
            onChange={(e) => props.setExpenseFilters('requiresPayment', e.value ? true : undefined)}
            onLabel={"Z brakującą płatnością"}
            offLabel={"Z brakującą płatnością"}
        />     <ToggleButton
            checked={props.expenseFilters.hasMissingInvoice}
            onChange={(e) => props.setExpenseFilters('hasMissingInvoice', e.value ? true : undefined)}
            onLabel={"Z brakującą fakturą"}
            offLabel={"Z brakującą fakturą"}
        />

    </OverlayPanel>;
};

import React, {FC, useState} from "react";
import {AppointmentType, NewAppointmentDTO} from "../../service/Appointments.type";
import {ActionModal} from "../../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../../common/lang/service/Translate.service";
import {addNewAppointmentRest} from "../../service/Appointments.service";
import {isSuccess} from "../../../common/service/result/Result.type";
import {LabelType} from "../../../common/components/input/inputLabel/InputLabel.component";
import {DateInput} from "../../../common/components/input/dateInput/DateInput.component";
import {TimeInput} from "../../../common/components/input/timeInput/TimeInput.component";
import {TextAreaInput} from "../../../common/components/input/textAreaInput/TextAreaInput.component";
import {CurrencyInput} from "../../../common/components/input/currencyInput/CurrencyInput.component";
import {Day} from "../../../common/service/util/Day";
import {ClockTime} from "../../../common/service/util/ClockTime";
import {TextInput} from "../../../common/components/input/textInput/TextInput.component";
import {Divider} from "../../../common/Divider.style";
import {isDefined} from "../../../common/service/util/ObjectUtils";
import {isDefinedAndNotEmpty} from "../../../common/service/util/StringUtils";
import {useParams} from "react-router-dom";
import {DayRange} from "../../../common/service/util/DayRange";

interface AddNewAppointmentModalProps {
    hideModal: () => void;
    refreshAppointments: () => void;
    type: AppointmentType;
    currentPeriod: DayRange;
}

export const AddNewAppointmentModal: FC<AddNewAppointmentModalProps> = (props: AddNewAppointmentModalProps) => {
    const initialAppointment: NewAppointmentDTO = {
        type: props.type,
        client: {},
        day: props.currentPeriod.isCurrent() ? Day.today() : props.currentPeriod.getDayFrom(),
        startTime: ClockTime.from(12, 0),
        endTime: ClockTime.from(18, 0)
    };

    const [appointment, setAppointment] = useState<NewAppointmentDTO>(initialAppointment);
    const { userId } = useParams();

    const onSave = async () => {
        const result = await addNewAppointmentRest(appointment, userId);
        if (isSuccess(result)) {
            props.hideModal();
            props.refreshAppointments();
        }
    }

    const isValid = (): boolean => {
        return isDefined(appointment.day)
            && isDefined(appointment.startTime)
            && isDefined(appointment.endTime)
            && isDefined(appointment.totalAmount)
            && isDefinedAndNotEmpty(appointment.description)
            && isClientValid();
    }

    const isClientValid = (): boolean => {
        const isNameDefined = isDefinedAndNotEmpty(appointment.client.firstName) && isDefinedAndNotEmpty(appointment.client.lastName);
        return (isNameDefined || isDefinedAndNotEmpty(appointment.client.instagram))
            && (isDefinedAndNotEmpty(appointment.client.instagram)
                || isDefinedAndNotEmpty(appointment.client.phoneNumber)
                || isDefinedAndNotEmpty(appointment.client.email));
    }

    const setValue = (fieldName: string, newValue: any) => {
        setAppointment(prevState => ({...prevState, [fieldName]: newValue}));
    }

    const setClientValue = (fieldName: string, newValue: any) => {
        setAppointment(prevState => ({...prevState, client: {...prevState.client, [fieldName]: newValue} }));
    }

    return <ActionModal
        header={translate("ADD_" + props.type)}
        onConfirm={onSave}
        onReject={props.hideModal}
        isVisible={true}
        isSaveDisabled={!isValid()}

    >
        <div className="formgrid grid">
            <DateInput
                label={translate("DATE")}
                value={appointment.day}
                setValue={day => setValue("day", day)}
                labelType={LabelType.VERTICAL}
                className={"col-12 sm:col-4"}
            />
            <TimeInput
                label={translate("START_TIME")}
                value={appointment.startTime}
                setValue={startTime => setValue("startTime", startTime)}
                labelType={LabelType.VERTICAL}
                minuteInterval={15}
                className={"col-6 sm:col-4"}
            />
            <TimeInput
                label={translate("END_TIME")}
                value={appointment.endTime}
                setValue={endTime => setValue("endTime", endTime)}
                labelType={LabelType.VERTICAL}
                minuteInterval={15}
                className={"col-6 sm:col-4"}
            />
        </div>

        <Divider margin={"10px"}/>

        <div className="formgrid grid">
            <CurrencyInput
                label={translate("TOTAL_AMOUNT")}
                value={appointment.totalAmount}
                setValue={totalAmount => setValue("totalAmount", totalAmount)}
                labelType={LabelType.VERTICAL}
                className={"col-6 sm:col-6"}
            />

            <CurrencyInput
                label={translate("DEPOSIT_AMOUNT")}
                value={appointment.depositValue}
                setValue={depositValue => setValue("depositValue", depositValue)}
                labelType={LabelType.VERTICAL}
                className={"col-6 sm:col-6"}
            />
        </div>

        <Divider margin={"10px"}/>

        <div className="formgrid grid">
            <TextInput
                label={translate("FIRST_NAME")}
                value={appointment.client.firstName}
                setValue={firstName => setClientValue("firstName", firstName)}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-6 appointment-details-field"}
            />

            <TextInput
                label={translate("LAST_NAME")}
                value={appointment.client.lastName}
                setValue={lastName => setClientValue("lastName", lastName)}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-6 appointment-details-field"}
            />
        </div>

        <div className="formgrid grid">
            <TextInput
                label={translate("INSTAGRAM")}
                value={appointment.client.instagram}
                setValue={instagram => setClientValue("instagram", instagram)}
                labelType={LabelType.VERTICAL}
                className={"col-12 md:col-4 appointment-details-field"}
            />

            <TextInput
                setValue={email => setClientValue("email", email)}
                label={translate("EMAIL")}
                value={appointment.client.email}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />

            <TextInput
                label={translate("PHONE_NUMBER")}
                value={appointment.client.phoneNumber}
                setValue={phoneNumber => setClientValue("phoneNumber", phoneNumber)}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />
        </div>

        <Divider margin={"10px"}/>

        <div className="formgrid grid">
            <TextAreaInput
                setValue={description => setValue("description", description)}
                value={appointment.description}
                label={translate("DESCRIPTION")}
                labelType={LabelType.VERTICAL}
                rows={5}
                className={"col-12 sm:col-12"}
                required={true}
            />
        </div>
    </ActionModal>;
};

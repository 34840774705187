import {addLocale, locale} from "primereact/api";

export const addPolishLocale = () => {
    addLocale('pl', {
        firstDayOfWeek: 1,
        dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        dayNamesShort: ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb'],
        dayNamesMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb'],
        monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        monthNamesShort: [
            'st',
            'lut',
            'mrz',
            'kw',
            'maj',
            'cz',
            'lip',
            'sier',
            'wrz',
            'paź',
            'lis',
            'gr',
        ],
        today: 'Dziś',
        clear: 'Wyczyść',
    });

    locale('pl');
}

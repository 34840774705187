import {get} from "../../../service/http/Http.service";
import {UriBuilder} from "../../../service/util/UriBuilder.class";
import {GlobalPermissions} from "./GlobalPermissions.type";

export const getGlobalPermissionsRest = (): Promise<GlobalPermissions> => {
    return get<GlobalPermissions>(new UriBuilder()
        .all("user")
        .all("current")
        .all("permissions")
        .build());
}

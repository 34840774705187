import styled from "styled-components";

export const StyledInventoryPage = styled.div`
  .p-selectbutton.p-buttonset.p-component {
    div {
      width: 50%;
    }
  }

  .select-button-container {
    padding-bottom: 20px;
  }

  .save-button {
    justify-content: center;
    width: 100%;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px
  }

  .filter-container {
    display: flex;
  }

  .inventory-item-name {
    font-size: 30px;
    text-align: center;
    padding-bottom: 20px;
    height: 60px;
  }
  
  .inventory-list {
    .p-datatable-tbody {
      font-size: 17px;
    }
  }
`;

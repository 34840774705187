import React, {FC, PropsWithChildren} from "react";
import {GoogleAuth} from "./googleAuth/GoogleAuth.component";
import {RouteChangedInterceptor} from "./routeChangedInterceptor/RouteChangedInterceptor.component";
import {UserBasicInfo} from "./userBasicInfo/UserBasicInfo.component";
import {GlobalPermissionsComponent} from "./globalPermissions/GlobalPermissions.component";

interface SecurityProps {
}

export const Security: FC<PropsWithChildren<SecurityProps>> = (props: PropsWithChildren<SecurityProps>) => {
    return <>
        <GoogleAuth clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
            <RouteChangedInterceptor>
                <UserBasicInfo>
                    <GlobalPermissionsComponent>
                        {props.children}
                    </GlobalPermissionsComponent>
                </UserBasicInfo>
            </RouteChangedInterceptor>
        </GoogleAuth>
    </>;
};

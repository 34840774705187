export const enMessages: any = {
    "TEST_KEY": "Tłumaczenia działają",
    "SETTINGS": "Ustawienia",
    "LOGOUT": "Wyloguj",
    "PROFILE": "Profil",
    "COPYRIGHT": "Copyright 2023",
    "LOGIN": "Login",

    //menu
    "HOME": "Home",
    "ADMINISTRATION": "Administration",
    "USER_MANAGEMENT": "User Management",
    "TEST": "Test",
    "TEST_PAGE": "Test page",
    "CASH_REPORT": "Raport kasowy",
    "WAREHOUSE": "Magazyn",
    "INVENTORY": "Liczenie magazynu",
    "ORDER": "Zamówienie",
    "ITEM_DICTIONARY": "Słownik przedmiotów",
    "SUPPLIER_DICTIONARY": "Słownik dostawców",

    //months
    "MONTH_NAME.1": "Styczeń",
    "MONTH_NAME.2": "Luty",
    "MONTH_NAME.3": "Marzec",
    "MONTH_NAME.4": "Kwiecień",
    "MONTH_NAME.5": "Maj",
    "MONTH_NAME.6": "Czerwiec",
    "MONTH_NAME.7": "Lipiec",
    "MONTH_NAME.8": "Sierpień",
    "MONTH_NAME.9": "Wrzesień",
    "MONTH_NAME.10": "Październik",
    "MONTH_NAME.11": "Listopad",
    "MONTH_NAME.12": "Grudzień",

    //months
    "DAY_NAME.0": "Niedziela",
    "DAY_NAME.1": "Poniedziałek",
    "DAY_NAME.2": "Wtorek",
    "DAY_NAME.3": "Środa",
    "DAY_NAME.4": "Czwartek",
    "DAY_NAME.5": "Piątek",
    "DAY_NAME.6": "Sobota",

    //short month names
    "SHORT_MONTH_NAME.1": "st",
    "SHORT_MONTH_NAME.2": "lut",
    "SHORT_MONTH_NAME.3": "mrz",
    "SHORT_MONTH_NAME.4": "kw",
    "SHORT_MONTH_NAME.5": "maj",
    "SHORT_MONTH_NAME.6": "cz",
    "SHORT_MONTH_NAME.7": "lip",
    "SHORT_MONTH_NAME.8": "sier",
    "SHORT_MONTH_NAME.9": "wrz",
    "SHORT_MONTH_NAME.10": "paź",
    "SHORT_MONTH_NAME.11": "lis",
    "SHORT_MONTH_NAME.12": "gr",

    "SHORT_MONTH_NAME_UPPER.1": "St",
    "SHORT_MONTH_NAME_UPPER.2": "Lut",
    "SHORT_MONTH_NAME_UPPER.3": "Mrz",
    "SHORT_MONTH_NAME_UPPER.4": "Kw",
    "SHORT_MONTH_NAME_UPPER.5": "Maj",
    "SHORT_MONTH_NAME_UPPER.6": "Cz",
    "SHORT_MONTH_NAME_UPPER.7": "Lip",
    "SHORT_MONTH_NAME_UPPER.8": "Sier",
    "SHORT_MONTH_NAME_UPPER.9": "Wrz",
    "SHORT_MONTH_NAME_UPPER.10": "Paź",
    "SHORT_MONTH_NAME_UPPER.11": "Lis",
    "SHORT_MONTH_NAME_UPPER.12": "Gr",

    //common
    "DATE": "Data",
    "AMOUNT": "Wartość",
    "ACCEPT": "Potwierdź",
    "REPORT": "Raport",
    "YES": "Tak",
    "NO": "Nie",
    "ITEMS": "Towary",
    "OPERATION_SUCCEEDED": "Operation succeed",
    "OPERATION_FAILED": "Operation failed",
    "UNEXPECTED_SERVER_ERROR": "Unexpected server error",
    "ACCESS_DENIED": "Access denied",
    "EDIT": "Edytuj",
    "CHOOSEN_FILES": "Wybrane pliki: ",
    "CHOOSE_FILES": "Wybierz pliki",
    "DATE_TO": "Data do",
    "START_DATE": "Data do",
    "DATE_FROM": "Data od",
    "END_DATE": "Data do",

    //currencies
    "PLN": "zł",

    //MODAL
    "ARE_YOU_SURE_YOU_WANT_TO_PROCEED": "Are you sure you want to proceed?",
    "ARE_YOU_SURE": "Are you sure?",
    "SAVE": "Zapisz",
    "CANCEL": "Anuluj",

    //CASH REPORT
    "CASH_REGISTER_STATE": "Current cash state",
    "RECALCULATE": "Przelicz",
    "SALARIES": "Salaries",
    "CASH_DEPOSIT": "Deposit",
    "PAYMENTS": "Płatności",
    "TOTAL": "Suma:",
    "BANK_DEPOSIT": "Deposit",
    "SUCCESS": "Success",
    "INFO": "Info",
    "WARNING": "Warning",
    "ERROR": "Error",
    "PROOF": "Dowód",
    "MONTHLY_PAYMENTS": "Monthly payments",

    "CASH_REPORT_ALREADY_CLOSED": "Cash report already closed",
    "SALARIES_ARE_NOT_VALID": "Salaries are not valid",
    "PAYMENTS_ARE_NOT_VALID": "Payments are not valid",
    "CASH_REPORT_ACCEPTED": "Cash report accepted",
    "PREVIOUS_CASH_REPORT_NOT_ACCEPTED": "Previous cash report not accepted",
    "CASH_REPORT_DOES_NOT_EXIST": "Cash report does not exist",
    "DATE_OUTSIDE_REPORT_PERIOD": "Date outside report period",
    "INVALID_AMOUNT": "Invalid amount",
    "BANK_DEPOSIT_ALREADY_EXISTS_FOR_SELECTED_DATE": "Bank deposit already exists for selected date",
    "BANK_DEPOSIT_DELETED": "Bank deposit deleted",
    "BANK_DEPOSIT_DOES_NOT_EXIST": "Bank deposit does not exist",
    "BANK_DEPOSIT_UPDATED": "Bank deposit updated",
    "CASH_REPORT_IS_CLOSED": "Cash report is closed",
    "PAYMENTS_ALREADY_EXISTS_ON_SELECTED_DATE": "Payments already exists on selected date",
    "PAYMENTS_PROOF_IS_INVALID": "Payments proof is invalid",
    "INVALID_PAYMENTS_DATA": "Invalid payments data",
    "PAYMENTS_ADDED": "Payments added",
    "PAYMENTS_DOES_NOT_EXIST": "Payments does not exist",
    "PAYMENTS_UPDATED": "Payments updated",
    "PAYMENTS_DELETED": "Payments deleted",
    "SALARIES_ALREADY_EXISTS": "Salaries already exists",
    "INVALID_SALARY_DATA": "Invalid salary data",
    "SALARIES_ADDED": "Salaries added",
    "SALARIES_DOES_NOT_EXIST": "Salaries does not exist",
    "SALARIES_UPDATED": "Salaries updated",
    "SALARIES_DELETED": "Salaries deleted",

    // user management page
    "EDIT_USERS": "Edit users",
    "EMAIL": "Email",
    "STATUS": "Status",
    "NAME": "Nazwa",
    "ACTIVE": "Active",
    "INACTIVE": "Inactive",
    "EDIT_USER": "Edit user",
    "FIRST_NAME": "Imię",
    "LAST_NAME": "Nazwisko",
    "DISPLAY_NAME": "Preferowane imię",
    "ROLES": "Roles",
    "ACTIVATE": "Activate",
    "DEACTIVATE": "Dectivate",
    "USER_ACTIVATED": "User activated",
    "USER_DEACTIVATED": "User deactivated",
    "USER_NOT_FOUND": "User not found",
    "USER_ALREADY_ACTIVE": "User already active",
    "USER_ALREADY_DISABLED": "User already disabled",
    "INVALID_USER_DATA": "Invalid user data",
    "YOU_CANNOT_CHANGE_YOUR_OWN_STATUS": "You cannot change your own status",

    // CLEANING
    "CLEANING": "Sprzątanie",
    "MARKED_AS_DIRTY": "Wymaga sprzątnięcia",
    "REQUIRES_CLEANING": "Do sprzątnięcia",
    "ADD_CLEANING": "Dodaj sprzątanie",
    "ADD": "Dodaj",
    "DICTIONARY": "Słownik",
    "CLEANING_DICTIONARY": "Słownik sprzątania",
    "CLEANING_REMOVED": "Usunięto sprzątanie",
    "CLEANING_ADDED": "Dodano sprzątanie",
    "ACTIVATION_DATE": "Data aktywacji",
    "DEACTIVATION_DATE": "Data deaktywacji",
    "CLEANING_INTERVAL": "Częstotliwość sprzątania",
    "COMMENT": "Komentarz",
    "FILTER": "Filtruj",
    "USER_CLEANING_REPORT": "Raport użytkowników",
    "ITEM_CLEANING_REPORT": "Raport rzeczy do sprzątania",
    "GET_REPORT": "Pobierz raport",

    // USESR PROFILE
    "PERSONAL_DATA": "Dane osobowe",
    "EDIT_PERSONAL_DATA": "Edytuj dane osobowe",

    // WAREHOUSE
    "ALLEGRO": "Allegro",
    "WEBSITE": "Strona internetowa",
    "STATIONARY_SHOP": "Sklep stacjonarny",
    "INVOICE_NAME": "Nazwa do faktury",
    "SHOP_URL": "Adres sklepu",
    "SUPPLIER_TYPE": "Rodzaj sprzedawcy",
    "CATEGORY": "Kategoria",
    "SUB_CATEGORY": "Podkategoria",
    "OPTIMAL_COUNT": "Optymalna ilość",
    "DEFAULT_SUPPLIER": "Domyślny dostawca",
    "TO_COUNT": "Do policzenia",
    "COUNTED": "Policzone",
    "COUNT": "Ilość",
    "FILTER_ITEMS": "Filtruj przedmioty",
    "CLOSE": "Zakończ",
    "START": "Rozpocznij",
    "ADD_ITEM": "Dodaj przedmiot",
    "SUPPLIER": "Sprzedawca",
    "ITEM": "Przedmiot",
    "ADDITIONAL_COST": "Dodatkowy koszt",
    "COST": "Koszt",
    "ADDITIONAL_COST_DESCRIPTION": "Opis",

    //home page
    "CURRENT_MONTH_PAYMENTS": "Płatności gotówką",
    'OPT_COUNT': 'Optymalnie',
    'ORDERED_COUNT': 'Zamówione',
    'CURRENT_COUNT': 'Aktualnie',
    'SINGLE_ITEM_VALUE': 'Cena szt.',
    'TOTAL_ITEM_COST': 'Suma',

    // expenses
    'EXPENSES': 'Wydatki',
    'REPEATABLE_EXPENSES': 'Wydatki cykliczne',
    'DOWNLOAD_INVOICES': 'Pobierz faktury',
    'ADD_EXPENSE': 'Dodaj wydatek',

    'CASH': 'Gotówka',
    'PRIVATE_ACCOUNT': 'Prywatne konto',
    'COMPANY_ACCOUNT': 'Konto firmowe',

    'WAREHOUSE_ORDER': 'Magazyn',
    'MANUAL': 'Ręczne',
    'RECURRING': 'Cykliczne',

    'WAREHOUSE_SUPPLY': 'Zamówienie',
    'STUDIO_EQUIPMENT': 'Wyposażenie studia',
    'TAX': 'Podatek',
    'ADVERTISEMENT': 'Reklama',
    'BILLS': 'Rachunek',
    'RENT': 'Wynajem',
    'OTHER': 'Inne',

    'AMOUNT_MISSING': 'Brakująca kwota',
    'INVOICE_MISSING': 'Brakująca faktura',
    'PAYMENT_REQUIRED': 'Do Zapłacenia',
    'TO': 'do',
    'PAYMENT_DATE_MISSING': 'Brakująca data zapłaty',

    'EXPENSES_SUM': 'Suma wydatków',
    'PRIVATE_EXPENSES_SUM': 'Wydatki prywatne',
    'COMPANY_EXPENSES_SUM': 'Wydatki firmowe',
    'DESCRIPTION': 'Opis',
    'ERRORS': 'Błędy',
    'EXPENSE_TYPE': 'Rodzaj wydatku',
    'PAYMENT_TYPE': 'Rodzaj płatności',
    'IS_PAID': 'Czy zapłacono?',
    'PAYMENT_DATE': 'Data płatności',
    'INVOICE_DATE': 'Data wydatku',
    'DEADLINE_DATE': 'Termin płatności',
    'VALID': 'Poprawny',
    'EXPENSE': 'Wydatek',
    'INVOICE_DAY_NUMBER': 'Dzień wydatku',
    'PAYMENT_DAY_NUMBER': 'Dzień płatności',
    'DEADLINE_DAY_NUMBER': 'Termin płatności',
    'CONTRACT_INFORMATION': 'Umowa',
    'NO_CONTRACT_INFORMATION': 'Brak informacji o umowie',
    'UPDATE': 'Aktualizuj',
    'TERMINATE': 'Zakończ umowę',
    'CONTRACT_START_DATE': 'Data rozpoczęcia umowy',
    'SHARE_PERCENTAGE': 'Podział procentowy',
    'TERMINATE_CONTRACT': 'Zakończ umowę',
    'CONTRACT_TYPE': 'Rodzaj umowy',
    'TERMINATION_DATE': 'Data zakończenia umowy',
    "INTERNSHIP": "Praktyki",
    "EMPLOYMENT": "Umowa o pracę",
    "MANDATE": "Zlecenie",
    "NEW_CONTRACT_ON": "Aktualizacja umowy dnia: ",
    "CONTRACT_ENDED": "Umowa zakonczona",

    // appointments
    "OTHERS_APPOINTMENTS": "Zapisy pracowników",
    "APPOINTMENTS": "Zapisy",
    "MY_APPOINTMENTS": "Moje zapisy",
    "CASH_FOR_COMPANY": "Gotówka w kopercie",
    "PREDICTED_INCOME": "Do zarobienia",
    "ALREADY_EARNED": "Zarobione",
    "TOTAL_MONTH_INCOME": "Przewidywane zarobki",
    "TATTOO": "Tatuaż",
    "PIERCING": "Piercing",
    "TOOTH_GEMS": "Tooth gems",
    "DERMALS": "Dermale",

    "ADD_TATTOO": "Tatuaż",
    "ADD_PIERCING": "Piercing",
    "ADD_TOOTH_GEMS": "Tooth gems",
    "ADD_DERMALS": "Dermale",
    "TO_CONFIRM": "Niepotwierdzone",
    "CONFIRMED": "Potwierdzone",
    "CANCELED": "Anulowane",
    "COMPLETED": "Zakończone",
    "AFTER_DAY": "Po terminie",
    "CONFIRM_APPOINTMENT": "Potwierdź wizytę",
    "START_TIME": "Od",
    "END_TIME": "Do",
    "TOTAL_AMOUNT": "Cena",
    "DEPOSIT_AMOUNT": "Kwota zadatku",
    "WITH_RECIPE": "Paragon?",
    "INSTAGRAM": "Instagram",
    "PHONE_NUMBER": "Telefon",
    "HAS_DEPOSIT": "Zadatek?",
    "BANK_TRANSFER": "Przelew",
    "CARD": "Karta",
    "WORK_TIME": "Czas pracy",
    "PRICE_WITHOUT_DEPOSIT": "Cena bez zadatku",
    "CANCELLATION_REASON": "Powód anulowania",
    "RETURN_DEPOSIT": "Zwrócić zadatek?",

    "APPOINTMENT_MONTH_CHANGED": "Zmieniono miesiąc wizyty. Anuluj starą wizytę i dodaj nową",
    "MONTH_ALREADY_CONFIRMED": "Miesiąc jest już potwierdzony.",
    "MONTH_CONFIRMED": "Miesiąc potwierdzony",
    "NOT_ALL_APPOINTMENTS_ENDED": "Nie wszystkie wizyty są zakończone. Zakończ lub anuluj pozostałe wizyty.",
    "INVALID_APPOINTMENT_DATA": "Błędne dane wizyty. Uzupełnij poprawnie wizytę.",
    "INVALID_FINISH_DATA": "Błędne dane wizyty. Uzupełnij poprawnie wizytę.",
    "INVALID_CONFIRMATION_DATA": "Błędne dane wizyty. Uzupełnij poprawnie wizytę.",
    "COLLIDING_APPOINTMENTS": "Masz już wizytę w tym czasie. Zmień termin wizyty",
    "APPOINTMENT_ADDED": "Dodano wizytę",
    "APPOINTMENT_NOT_FOUND": "Nie znaleziono wizyty",
    "APPOINTMENT_CONFIRMED": "Wizyta potwierdzona",
    "APPOINTMENT_FINISHED": "Wizyta zakończona",
    "VOUCHER": "Voucher",
    "INVALID_CANCEL_DATA": "Niepoprawne dane",
    "INVALID_EDIT_DATA": "Niepoprawne dane. Poprawnie uzupełnij dane i spróbuj ponownie",
    "APPOINTMENT_EDITED": "Wizyta edytowana.",
    "CONTRACT_INFORMATION_MISSING": "Brakujące szczegóły umowy",
    "FINISHED": "Zakończone",
    "EDIT_APPOINTMENT": "Edytuj wizytę",
    "CANCEL_APPOINTMENT": "Anuluj wizytę",
    "APPOINTMENT_CANCELED": "Wizyta odwołana",
    "DEPOSIT_VALUE_BIGGER_THAN_TOTAL": "Wartość zadatku nie może być większa niż całkowita wartość",
    "FINISH_APPOINTMENT": "Zakończ wizytę",
    "IMPORT_PAYMENTS": "Importuj płatności",
    "IMPORT_PROOFS": "Importuj dowody",

    //bank transfers
    "IMPORT_BANK_TRANSFERS": "Importuj",
    "UPLOAD_TRANSFERS": "Importuj",
    "KEYWORDS": "Słowa kluczowe",
    "SUMMARY": "Podsumowanie",
    "TRANSFERS": "Przelewy",
    "TRANSFERS_TO_ASSIGN": "Do przypisania",
    "ASSIGNED_TRANSFERS": "Przypisane",
    "NEBA": "Neba",
    "TITLE": "Tytuł",
    "CLIENT_NAME": "Imię",
    "REGISTER_TRANSFERS": "Nabite",
    "BANK_TRANSFERS": "Przelewy",
}

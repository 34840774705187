import React, {FC, useId} from "react";
import {InputLabel, LabelType} from "../inputLabel/InputLabel.component";
import {Calendar} from "primereact/calendar";
import {ClockTime} from "../../../service/util/ClockTime";

interface TimeInputProps {
    value?: ClockTime;
    setValue: (newValue: ClockTime) => void;
    label?: string;
    labelType?: LabelType;

    minuteInterval?: number;
    className?: string;
}

export const TimeInput: FC<TimeInputProps> = (props: TimeInputProps) => {
    const id = useId();

    const updateTime = (e: any) => {
        const clockTime = ClockTime.fromDate(e.value);
        if (props.value?.hours() !== clockTime.hours()) {
            props.setValue(ClockTime.fromDate(e.value));
        } else {
            const oldMinutes = props.value.minutes();
            const selectedMinutes = clockTime.minutes();
            if ((oldMinutes + 1) === selectedMinutes || ((oldMinutes + 1) === 60) && selectedMinutes === 0) {
                const newMinutes = oldMinutes + props.minuteInterval;
                props.setValue(ClockTime.from(props.value.hours(), newMinutes >= 60 ? newMinutes - 60 : newMinutes))
            } else {
                const newMinutes = oldMinutes - props.minuteInterval;
                props.setValue(ClockTime.from(props.value.hours(), newMinutes < 0 ? 60 + newMinutes : newMinutes))
            }
        }
    }
    return <InputLabel id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <Calendar
            timeOnly={true}
            readOnlyInput={true}
            value={props.value?.toDate()}
            dateFormat={"dd.mm.yy"}
            onChange={(e) => updateTime(e)}
            showIcon
            icon={"pi pi-clock"}
            onClearButtonClick={() => props.setValue(undefined)}
            className={"col"}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
        />
    </InputLabel>;
};

TimeInput.defaultProps = {
    minuteInterval: 1
}
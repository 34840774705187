import React, {FC, useState} from "react";
import {ActionModal} from "../../../../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../../../../common/lang/service/Translate.service";
import {isNotDefinedOrEmpty} from "../../../../../common/service/util/StringUtils";
import {LabelType} from "../../../../../common/components/input/inputLabel/InputLabel.component";
import {CurrencyInput} from "../../../../../common/components/input/currencyInput/CurrencyInput.component";
import {TextAreaInput} from "../../../../../common/components/input/textAreaInput/TextAreaInput.component";
import {isNotDefined} from "../../../../../common/service/util/ObjectUtils";

interface AdditionalCostModalProps {
    updateAdditionalCost: (additionalCost: number, additionalCostDescription: string) => void;
    additionalCost: number;
    additionalCostDescription: string;
    hide: () => void;
}

export const AdditionalCostModal: FC<AdditionalCostModalProps> = (props: AdditionalCostModalProps) => {
    const [additionalCost, setAdditionalCost] = useState(props.additionalCost)
    const [additionalCostDescription, setAdditionalCostDescription] = useState(props.additionalCostDescription)

    const updateAdditionalCost = () => {
        props.updateAdditionalCost(additionalCost, additionalCostDescription);
    }

    const isInvalid = (): boolean => {
        return isNotDefined(additionalCost) || additionalCost === 0 || isNotDefinedOrEmpty(additionalCostDescription);
    }

    return <ActionModal header={translate("ADDITIONAL_COST")} onConfirm={updateAdditionalCost} isVisible={true} onReject={props.hide} isSaveDisabled={isInvalid()}>
        <CurrencyInput
            value={additionalCost}
            setValue={setAdditionalCost}
            label={translate("COST")}
            labelType={LabelType.VERTICAL}
            maxValue={9999}
            minValue={-9999}
        />
        <TextAreaInput
            setValue={setAdditionalCostDescription}
            value={additionalCostDescription}
            rows={5}
            labelType={LabelType.VERTICAL}
            label={translate('ADDITIONAL_COST_DESCRIPTION')}
        />
    </ActionModal>;
};

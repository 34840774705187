import React from "react";

export interface ColumnProps {
    header: string;
    fieldName: string;
    width?: string;
    minWidth?: string;
    body?: (value: any, index: number) => React.ReactNode;
    filterBody?: ({label, value}) => React.ReactNode;
    initFilterValue?: any | any[];
    sortable?: boolean;
    transformValue?: (value: any) => string;
    filterType?: FilterType
    className?: string
}

export enum FilterType {
    SELECT = 'SELECT',
    INPUT = 'INPUT'
}
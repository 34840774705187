import {createGlobalStyle} from "styled-components";

interface GlobalStyleProps {

}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  body {
    background-color: rgb(46, 49, 61);
  }

  .layout-wrapper .layout-main .layout-main-content {
    @media screen and (max-width: 769px) {
        padding: 12px;
    }
  }
  
  .layout-breadcrumb-container {
    @media screen and (max-width: 769px) {
      padding-top: 0;
    }
  }
`
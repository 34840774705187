import {DayRange} from '../../../common/service/util/DayRange';
import {get} from '../../../common/service/http/Http.service';
import {UriBuilder} from '../../../common/service/util/UriBuilder.class';
import {UserCleaningReportDTO} from './CleaningUserReportPage.type';

export const getCleaningUserReportRest = (dayRange: DayRange): Promise<UserCleaningReportDTO[]> => {
    return get<UserCleaningReportDTO[]>(new UriBuilder()
        .all('cleaning')
        .all('report')
        .all('users')
        .build(), {dateFrom: dayRange.getDayFrom().dateToUrlString(), dateTo: dayRange.getDayTo().dateToUrlString()});
}

import {
    AppointmentsDTO,
    CancelAppointmentDTO,
    ConfirmAppointmentDTO,
    EditAppointmentDTO,
    FinishAppointmentDTO,
    NewAppointmentDTO
} from "./Appointments.type";
import {CommandResult} from "../../common/service/result/Result.type";
import {get, post, put} from "../../common/service/http/Http.service";
import {UriBuilder} from "../../common/service/util/UriBuilder.class";
import {isDefined} from "../../common/service/util/ObjectUtils";

export const getAppointmentsRest = (month: number, year: number, userId: string): Promise<AppointmentsDTO> => {
    return get<AppointmentsDTO>(new UriBuilder()
        .all("appointments")
        .one("month", month)
        .one("year", year)
        .build(), getParams(userId));
}

export const acceptMonthRest = (month: number, year: number, userId: string): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("appointments")
        .one("month", month)
        .one("year", year)
        .all("accept")
        .build(), undefined, {}, getParams(userId));
}

export const addNewAppointmentRest = (newAppointment: NewAppointmentDTO, userId: string): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("appointments")
        .all("new")
        .build(), newAppointment, {}, getParams(userId));
}

export const cancelAppointmentRest = (id: string, cancelAppointment: CancelAppointmentDTO, userId: string): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .one("appointments", id)
        .all("cancel")
        .build(), cancelAppointment, {}, getParams(userId));
}

export const confirmAppointmentRest = (id: string, confirmAppointment: ConfirmAppointmentDTO, userId: string): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .one("appointments", id)
        .all("confirm")
        .build(), confirmAppointment, {}, getParams(userId));
}

export const editAppointmentRest = (id: string, editAppointment: EditAppointmentDTO, userId: string): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .one("appointments", id)
        .all("edit")
        .build(), editAppointment, {}, getParams(userId));
}

export const finishAppointmentRest = (id: string, finishAppointment: FinishAppointmentDTO, userId: string): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .one("appointments", id)
        .all("finish")
        .build(), finishAppointment, {}, getParams(userId));
}

const getParams = (userId: string) => isDefined(userId) ? {userId: userId} : undefined;

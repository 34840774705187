import React, {FC, useState} from "react";
import {AppointmentDTO, AppointmentStatus} from "../service/Appointments.type";
import {Card} from "../../common/components/card/Card.component";
import {Title} from "../../common/components/title/Title.component";
import {translate} from "../../common/lang/service/Translate.service";
import {Button} from "primereact/button";
import {ClockTime} from "../../common/service/util/ClockTime";
import {AppointmentDetails} from "./appointmentDetails/AppointmentDetails.component";
import {Day} from "../../common/service/util/Day";
import {AppointmentDetailsTags} from "./appointmentDetails/appointmentDetailsTags/AppointmentDetailsTags.component";
import {isDefinedAndNotEmpty} from "../../common/service/util/StringUtils";

interface AppointmentCardProps {
    appointment: AppointmentDTO
    openConfirmAppointmentModal: (appointment: AppointmentDTO) => void;
    openAcceptAppointmentModal: (appointment: AppointmentDTO) => void;
    openEditAppointmentModal: (appointment: AppointmentDTO) => void;
    openCancelAppointmentModal: (appointment: AppointmentDTO) => void;
    isForOtherUsers: boolean;
}

export const AppointmentCard: FC<AppointmentCardProps> = (props: AppointmentCardProps) => {
    const [detailsVisible, setDetailsVisible] = useState(false);

    const toggleVisibility = () => {
        setDetailsVisible(prevState => !prevState);
    }

    const getSubtitle = (): string => {
        const day = Day.fromString(props.appointment.day);
        const time = ClockTime.fromString(props.appointment.startTime);
        return day.getDayNumber() + " " + translate("SHORT_MONTH_NAME." + day.getMonthNumber()) + " " + time.toMinutesString()
    };

    const getUpperTitle = (): string => {
        if (isDefinedAndNotEmpty(props.appointment.client.instagram)) {
            return props.appointment.client.instagram;
        }

        if (isDefinedAndNotEmpty(props.appointment.client.firstName)) {
            return props.appointment.client.firstName + " " + props.appointment.client.lastName;
        }

        return props.appointment.client.email;
    };

    const canConfirmAppointment = (): boolean => {
        const status = props.appointment.status;
        return status === AppointmentStatus.TO_CONFIRM;
    };

    const canAcceptAppointment = (): boolean => {
        const status = props.appointment.status;
        return status === AppointmentStatus.CONFIRMED
            && Day.today().isAfterOrEqualDay(Day.fromString(props.appointment.day));
    };

    const canEditAppointment = (): boolean => {
        const status = props.appointment.status;
        return (status !== AppointmentStatus.FINISHED || props.isForOtherUsers) && status != AppointmentStatus.CANCELED;
    };

    const canCancelAppointment = (): boolean => {
        const status = props.appointment.status;
        return status !== AppointmentStatus.FINISHED && status != AppointmentStatus.CANCELED;
    }

    return <Card>
        <Title
            title={translate(props.appointment.type)}
            upperTitle={getUpperTitle()}
            subtitle={getSubtitle()}
            onClick={toggleVisibility}
        />

        {detailsVisible
            ? <AppointmentDetails appointment={props.appointment}/>
            : <div className={"appointment-card-content"}>{props.appointment.description}</div>
        }

        <div className={"buttons-row"}>
            <AppointmentDetailsTags
                appointmentDate={Day.fromString(props.appointment.day)}
                status={props.appointment.status}
            />
            <div>
                {canConfirmAppointment() && <Button
                    onClick={() => props.openConfirmAppointmentModal(props.appointment)}
                    icon={"pi pi-check"}
                    severity={"success"}
                    className={"p-button-rounded p-button-text"}
                />}
                {canAcceptAppointment() && <Button
                    onClick={() => props.openAcceptAppointmentModal(props.appointment)}
                    icon={"pi pi-check"}
                    severity={"success"}
                    className={"p-button-rounded p-button-text"}
                />}
                {canEditAppointment() && <Button
                    onClick={() => props.openEditAppointmentModal(props.appointment)}
                    icon={"pi pi-pencil"}
                    className={"p-button-rounded p-button-text"}
                />}
                {canCancelAppointment() && <Button
                    onClick={() => props.openCancelAppointmentModal(props.appointment)}
                    icon={"pi pi-times"}
                    className={"p-button-rounded p-button-danger p-button-text"}
                />}
            </div>
        </div>
    </Card>;
};

import React from 'react';
import {Button} from 'primereact/button';
import {authenticationService} from "../../index";

export const PageNotFound = (props: any) => {
    const redirectToHomePage = () => {
        authenticationService.redirectToHomePage();
    };

    return (
        <div className="exception-body notfound">
            <div className="exception-panel">
                <h1>404</h1>
                <h3>not found</h3>
                <p>The page that you are looking for does not exist</p>
                <Button type="button" label="Go back to home" onClick={redirectToHomePage}></Button>
            </div>
            <div className="exception-footer">
                <img src={`/assets/layout/images/logo-light.png`} className="exception-logo" alt="expection-logo"/>
                <img src={`/assets/layout/images/appname-light.png`} className="exception-appname"
                     alt="expection-appname"/>
            </div>
        </div>
    );
};

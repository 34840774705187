import styled from "styled-components";

export const StyledUserProfileHeader = styled.div`
  display: flex;

  .username {
    font-size: 24px;
    font-weight: 700;
    padding-top: 21px;
    padding-left: 12px;
  }
  
  img{
    width: 96px;  
    height: 96px;
    border-radius: 50px;
  }
`;

import {DayRange} from '../../../common/service/util/DayRange';
import {get} from '../../../common/service/http/Http.service';
import {UriBuilder} from '../../../common/service/util/UriBuilder.class';
import {CleaningEntryReportDTO} from './CleaningItemReportPage.type';

export const getCleaningEntryReportRest = (dayRange: DayRange): Promise<CleaningEntryReportDTO[]> => {
    return get<CleaningEntryReportDTO[]>(new UriBuilder()
        .all('cleaning')
        .all('report')
        .all('items')
        .build(), {dateFrom: dayRange.getDayFrom().dateToUrlString(), dateTo: dayRange.getDayTo().dateToUrlString()});
}

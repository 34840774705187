export interface AdditionalCostDTO {
    supplierId: string;
    additionalCost: number;
    description: string;
}

export interface AddOrderItemCountDTO {
    supplierId: string;
    itemId: string;
    count: number;
}

export interface AddOrderItemDTO {
    supplierId: string;
    itemId: string;
    itemPrice: number;
    count: number;
}

export interface AddOrderItemPriceDTO {
    itemId: string;
    supplierId: string;
    itemPrice: number;
}

export interface OrderDTO {
    id: string;
    orderStatus: OrderStatus;
    orderNumber: number;
    startDate: string;
    endDate: string;
    openingUserId: string;
    closingUserId: string;
    supplierOrders: SupplierOrderDTO[];
}

export enum OrderStatus {
    OPEN = "OPEN",
    CLOSED = "CLOSED"
}

export interface OrderItemDTO {
    itemId: string;
    count: number;
    itemPrice: number;
}

export interface SupplierOrderDTO {
    supplierId: string;
    additionalCost: number;
    additionalCostDescription: string;
    orderItems: OrderItemDTO[];
}

export interface Order {
    supplierName: string;
    supplierType: string;
    supplierId: string;
    orderedItems: Item[];
    additionalCost: number;
    additionalCostDescription: string;
    sum: number;
}

export interface Item {
    itemName: string;
    itemId: string
    defaultSupplierId: string;
    url: string;
    optimalCount: number;
    minimalCount: number;
    orderToCount: number;
    orderedCount: number;
    currentCount: number;
    singleItemValue: number;
    totalItemCost: number;
}

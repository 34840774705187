import React, {FC, useEffect, useState} from "react";
import {ExpensesToolbar} from './expensesToolbar/ExpensesToolbar.component';
import {DayRange} from '../../common/service/util/DayRange';
import {Day} from '../../common/service/util/Day';
import {ExpenseDTO, ExpenseFilters, ExpenseReadDTO, getInitialExpense} from './service/ExpensesPage.type';
import {
    addExpenseRest,
    downloadAllInvoices,
    getExpensesRest,
    removeExpenseRest,
    updateExpenseRest
} from './service/ExpensesPage.service';
import {CommandResult, isSuccess} from '../../common/service/result/Result.type';
import {isDefined} from '../../common/service/util/ObjectUtils';
import {ExpenseModal} from './expenseModal/ExpenseModal.component';
import {DictionaryDTO} from '../../common/service/util/dictionary/Dictionary.type';
import {
    getSuppliersSimpleDictionaryRest
} from '../../warehouse/supplierDictionaryPage/service/SupplierDictionaryPage.service';
import {ExpenseCard} from './expenseCard/ExpenseCard.component';
import {ExpensesSummary} from './expensesSummary/ExpensesSummary.component';
import {StyledExpensesPage} from './ExpensesPage.style';
import {ConfirmationModal} from "../../common/modal/confirmationModal/ConfirmationModal.component";
import {getFilteredList} from "../../common/service/util/filters/Filters.service";
import {ExpensesFilterFactory} from "./service/ExpensesFilterFactory.service";

export const ExpensesPage: FC = () => {
    const [currentPeriod, setCurrentPeriod] = useState<DayRange>(new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay()));
    const [expenses, setExpenses] = useState<ExpenseReadDTO[]>([])
    const [expenseModalVisible, setExpenseModalVisible] = useState(false);
    const [removeExpenseModalVisible, setRemoveExpenseModalVisible] = useState(false);
    const [expenseToRemove, setExpenseToRemove] = useState<string>(undefined);
    const [modifiedExpense, setModifiedExpense] = useState<ExpenseReadDTO>(getInitialExpense())
    const [supplierDictionary, setSupplierDictionary] = useState<DictionaryDTO[]>([])

    const [expenseFilters, setExpenseFilters] = useState<ExpenseFilters>({});

    useEffect(() => {
        retrieveExpenses(currentPeriod);
        retrieveSupplierDictionary();
    }, []);

    async function retrieveExpenses(period: DayRange) {
        const expenseReadDTOs: ExpenseReadDTO[] = await getExpensesRest(period);
        setExpenses(expenseReadDTOs);
    }

    async function retrieveSupplierDictionary() {
        const supplierDictionaryDTOs: DictionaryDTO[] = await getSuppliersSimpleDictionaryRest();
        setSupplierDictionary(supplierDictionaryDTOs);
    }

    async function addExpense(expense: ExpenseDTO, files: File[]) {
        const result: CommandResult = await addExpenseRest(expense, files);
        if (isSuccess(result)) {
            retrieveExpenses(currentPeriod);
            hideModal();
        }
    }

    async function updateExpense(expenseId: string, expense: ExpenseDTO, files: File[]) {
        const result: CommandResult = await updateExpenseRest(expenseId, expense, files);
        if (isSuccess(result)) {
            retrieveExpenses(currentPeriod);
            hideModal();
        }
    }

    async function getAllInvoices() {
        downloadAllInvoices(currentPeriod);
    }

    const openAddExpenseModal = (expense?: ExpenseReadDTO) => {
        setModifiedExpense(isDefined(expense) ? expense : getInitialExpense());
        setExpenseModalVisible(true);
    }

    const onPeriodChange = (period: DayRange): void => {
        retrieveExpenses(period);
        setCurrentPeriod(period);
    }

    const hasFiles = (): boolean => {
        return expenses.some(value => value.hasFiles);
    }

    const hideModal = () => {
        setModifiedExpense(getInitialExpense());
        setExpenseModalVisible(false);
    }

    async function removeExpense(expenseId: string) {
        const result: CommandResult = await removeExpenseRest(expenseId);
        if (isSuccess(result)) {
            retrieveExpenses(currentPeriod);
            hideRemoveExpenseModal();
        }
    }

    const showRemoveExpenseModal = (expenseId: string) => {
        setRemoveExpenseModalVisible(true);
        setExpenseToRemove(expenseId);
    }

    const hideRemoveExpenseModal = () => {
        setRemoveExpenseModalVisible(undefined);
        setExpenseToRemove(undefined);
    }

    const setObjectValue = (fieldName: string, fieldValue: any) => {
        setExpenseFilters(prevState => ({...prevState, [fieldName]: fieldValue}))
    };

    return <StyledExpensesPage className={"grid"}>
        <ExpensesToolbar
            addExpense={() => openAddExpenseModal()}
            downloadInvoices={getAllInvoices}
            hasFiles={hasFiles()}
            onPeriodChange={onPeriodChange}
            expenseFilters={expenseFilters}
            setExpenseFilters={setObjectValue}
        />

        <div className={"col-12 md:col-8"}>
            {getFilteredList(expenses, expenseFilters, new ExpensesFilterFactory()).map(value =>
                <ExpenseCard
                    key={value.expenseId}
                    expense={value}
                    removeExpense={showRemoveExpenseModal}
                    openAddExpenseModal={openAddExpenseModal}
                    supplierDictionary={supplierDictionary}
                />
            )}
        </div>

        <div className={"col-12 md:col-4"}>
            <ExpensesSummary
                expenses={expenses}
            />
        </div>

        {expenseModalVisible &&
            <ExpenseModal
                hideModal={hideModal}
                addExpense={addExpense}
                updateExpense={updateExpense}
                expense={modifiedExpense}
                supplierDictionary={supplierDictionary}
                period={currentPeriod}
            />}

        <ConfirmationModal
            onConfirm={() => removeExpense(expenseToRemove)}
            onReject={hideRemoveExpenseModal}
            isVisible={removeExpenseModalVisible}
        />
    </StyledExpensesPage>;
};

import {FC, useEffect, useState} from "react";
import {StyledCleaningPage} from "./CleaningPage.style";
import {CleaningToolbar} from "./cleaningToolbar/CleaningToolbar.component";
import {Day} from "../../common/service/util/Day";
import {AddCleaningModal} from "./addCleaningModal/AddCleaningModal.component";
import {CleaningRequired} from "./cleaningRequired/CleaningRequired.component";
import {CleaningEntries} from "./cleaningEntries/CleaningEntries.component";
import {CleaningEntryDTO, CleaningEntryItemDTO, RequiresCleaningDTO} from "./service/CleaningPage.types";
import {deleteCleaningEntryRest, getCleaningRest} from "./service/CleaningPage.service";
import {useCurrentUser} from "../../common/security/userBasicInfo/service/CurrentUser.hook";
import {isSuccess} from "../../common/service/result/Result.type";

export const CleaningPage: FC = () => {
    const [selectedDate, setSelectedDate] = useState<Day>(Day.today());
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [cleaningEntries, setCleaningEntries] = useState<CleaningEntryDTO[]>([]);
    const [requiresCleaning, setRequiresCleaning] = useState<RequiresCleaningDTO[]>([]);
    const [cleanedItems, setCleanedItems] = useState<CleaningEntryItemDTO[]>([]);
    const [comment, setComment] = useState<string>('');

    useEffect(() => {
        retrieveCleaning(selectedDate);
    }, []);

    async function retrieveCleaning(date: Day) {
        const cleaningDTO = await getCleaningRest(date);
        setCleaningEntries(cleaningDTO.cleaningEntries);
        setRequiresCleaning(cleaningDTO.requiresCleaning);
    }

    async function removeCleaning(cleaningId: string) {
        const commandResult = await deleteCleaningEntryRest(cleaningId);
        if (isSuccess(commandResult)) {
            retrieveCleaning(selectedDate);
        }
    }

    const onDateChange = (date: Day) => {
        setSelectedDate(date);
        retrieveCleaning(date);
    }

    const editCleaning = (data: CleaningEntryDTO) => {
        setCleanedItems(data.cleanedItemIds);
        setComment(data.comment);
        setModalVisible(true);
    }

    const openEditCleaningModal = () => {
        setCleanedItems([]);
        setComment('');
        setModalVisible(true);
    }

    const hideEditCleaningModal = () => {
        retrieveCleaning(selectedDate);
        setModalVisible(false);
    }

    const currentUserId = useCurrentUser().id;

    const isCleaningAdded = () => {
        return cleaningEntries.some(entry => entry.userId === currentUserId);
    }

    return <StyledCleaningPage className={"grid"}>
        <CleaningToolbar onDayChange={onDateChange} onEdit={openEditCleaningModal} cleaningAdded={isCleaningAdded()}/>
        <CleaningRequired requiresCleaning={requiresCleaning}/>
        <CleaningEntries
            editCleaning={editCleaning}
            deleteCleaning={removeCleaning}
            cleaningEntries={cleaningEntries}
        />
        {modalVisible &&
            <AddCleaningModal
                requiresCleaning={requiresCleaning}
                date={selectedDate}
                hideModal={hideEditCleaningModal}
                items={cleanedItems}
                comment={comment}
            />}
    </StyledCleaningPage>;
}

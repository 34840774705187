export enum BankTransferType {
    TATTOO = "TATTOO",
    PIERCING = "PIERCING",
    NEBA = "NEBA",
    OTHER = "OTHER"
}

export interface BankTransferDetailsDTO {
    id: string;
    date: string;
    amount: number;
    title: string;
    clientName: string;
    registered: boolean;
    bankTransferType: BankTransferType;
}

export interface BankTransfersSummaryDTO {
    amount: number;
    bankTransferType: BankTransferType;
    count: number;
}

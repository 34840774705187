import React, {FC, useEffect, useState} from "react";
import {Item, Order} from '../../service/OrderPage.type';
import {Card} from '../../../../common/components/card/Card.component';
import {Title} from '../../../../common/components/title/Title.component';
import {translate} from '../../../../common/lang/service/Translate.service';
import {isDefined, isNotDefined} from '../../../../common/service/util/ObjectUtils';
import {StyledSupplierOrder} from './SupplierOrder.style';
import {addAdditionalCostRest, addItemCountRest, addOrderItemPriceRest} from '../../service/OrderPage.service';
import {compareStrings} from '../../../../common/service/util/StringUtils';
import {SupplierOrderTable} from "./supplierOrderTable/SupplierOrderTable.component";
import {SupplierOrderSummary} from "./supplierOrderSummary/SupplierOrderSummary.component";
import {SupplierAdditionalCost} from "./supplierAdditionalCost/SupplierAdditionalCost.component";
import {CommandResult, isSuccess} from "../../../../common/service/result/Result.type";
import {AdditionalCostModal} from "./additionalCostModal/AdditionalCostModal.component";
import {formatAsCurrency} from "../../../../common/service/util/CurrencyUtil";

interface SupplierOrderProps {
    order: Order;
    orderId: string;
    inEditMode: boolean;
}

export const SupplierOrder: FC<SupplierOrderProps> = (props: SupplierOrderProps) => {
    const [items, setItems] = useState<Item[]>(
        props.order.orderedItems.sort((a, b) => compareStrings(a.itemName, b.itemName)))
    const [additionalCost, setAdditionalCost] = useState(props.order.additionalCost)
    const [additionalCostDescription, setAdditionalCostDescription] = useState(props.order.additionalCostDescription)
    const [additionalCostModalVisible, setAdditionalCostModalVisible] = useState(false);
    const [supplierOrderMinimized, setSupplierOrderMinimized] = useState(isMinimised());

    useEffect(() => {
        if(!props.inEditMode) {
            setSupplierOrderMinimized(false);
        }
    }, [props.inEditMode]);

    function isMinimised(): boolean {
        if(!props.inEditMode) {
            return false;
        }

        const item = window.localStorage.getItem("hidden-order-suppliers");
        if (isDefined(item)) {
            const parsed: string[] = JSON.parse(item);
            if (isDefined(parsed)) {
                return parsed.includes(props.order.supplierId);
            }
        }
        return false;
    }


    useEffect(() => {
        setItems(props.order.orderedItems.sort((a, b) => compareStrings(a.itemName, b.itemName)))
        setAdditionalCost(props.order.additionalCost);
        setAdditionalCostDescription(props.order.additionalCostDescription);
    }, [props.order]);

    function getTotalItemCost(count: number, itemValue: number) {
        return (isDefined(count) && isDefined(itemValue))
            ? count * itemValue
            : undefined;
    }

    const updateCount = (itemId: string, count: number) => {
        const orderedCount = items.find(value1 => value1.itemId === itemId).orderedCount;
        if ((isNotDefined(orderedCount) && isNotDefined(count)) || orderedCount === count) {
            return;
        }

        addItemCountRest(props.orderId, {supplierId: props.order.supplierId, itemId: itemId, count: count})
        setItems(prevState => {
            return prevState.map(value1 => value1.itemId === itemId
                ? {...value1, orderedCount: count, totalItemCost: getTotalItemCost(count, value1.singleItemValue)}
                : value1
            );
        })
    }

    const updateValue = (itemId: string, value: number) => {
        const singleItemValue = items.find(value1 => value1.itemId === itemId).singleItemValue;
        if ((isNotDefined(singleItemValue) && isNotDefined(value)) || singleItemValue === value) {
            return;
        }
        addOrderItemPriceRest(props.orderId, {supplierId: props.order.supplierId, itemId: itemId, itemPrice: value})
        setItems(prevState => prevState.map(value1 => value1.itemId === itemId
            ? {...value1, singleItemValue: value, totalItemCost: getTotalItemCost(value1.orderedCount, value)}
            : value1
        ))
    }

    const getTotalCost = (): number => {
        return (additionalCost ?? 0) + items.map(value => value.totalItemCost)
            .filter(value => isDefined(value))
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    };

    const openAdditionalCostModal = () => {
        setAdditionalCostModalVisible(true);
    }

    const hideAdditionalCostModal = () => {
        setAdditionalCostModalVisible(false);
    }

    const updateAdditionalCost = async (additionalCost: number, additionalCostDescription: string) => {
        const result: CommandResult = await addAdditionalCostRest(props.orderId, {
            supplierId: props.order.supplierId,
            additionalCost: additionalCost,
            description: additionalCostDescription
        });

        if (isSuccess(result)) {
            setAdditionalCost(additionalCost);
            setAdditionalCostDescription(additionalCostDescription);
            hideAdditionalCostModal();
        }
    }

    const toggleVisibility = () => {
        if(!props.inEditMode) {
            return;
        }
        setSupplierOrderMinimized(prevState => {
            const hiddenItems: string = window.localStorage.getItem("hidden-order-suppliers");
            const hiddenItemsList: string[] = isDefined(hiddenItems)
                ? [...JSON.parse(hiddenItems), props.order.supplierId]
                : [props.order.supplierId];
            window.localStorage.setItem("hidden-order-suppliers", JSON.stringify(hiddenItemsList));
            return !prevState;
        });
    }

    return <Card>
        <StyledSupplierOrder>
            <Title
                className={'supplier-order-title'}
                title={translate(props.order.supplierName)}
                upperTitle={translate(props.order.supplierType)}
                onClick={toggleVisibility}
                subtitle={supplierOrderMinimized ? formatAsCurrency(getTotalCost()) : undefined}
            />

            {!supplierOrderMinimized && <SupplierOrderTable
                inEditMode={props.inEditMode}
                items={items}
                updateValue={updateValue}
                updateCount={updateCount}
            />}

            {!supplierOrderMinimized && <SupplierAdditionalCost
                additionalCost={additionalCost}
                additionalCostDescription={additionalCostDescription}
            />}

            {additionalCostModalVisible &&
                <AdditionalCostModal
                    updateAdditionalCost={updateAdditionalCost}
                    additionalCost={additionalCost}
                    additionalCostDescription={additionalCostDescription}
                    hide={hideAdditionalCostModal}
                />}

            {!supplierOrderMinimized && <SupplierOrderSummary
                totalCost={getTotalCost()}
                inEditMode={props.inEditMode}
                openAdditionalCostModal={openAdditionalCostModal}
            />}
        </StyledSupplierOrder>
    </Card>;
};

import React, {FC, useState} from "react";
import {AppointmentDTO, CancelAppointmentDTO, DepositStatus} from "../../service/Appointments.type";
import {cancelAppointmentRest} from "../../service/Appointments.service";
import {isSuccess} from "../../../common/service/result/Result.type";
import {ActionModal} from "../../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../../common/lang/service/Translate.service";
import {TextAreaInput} from "../../../common/components/input/textAreaInput/TextAreaInput.component";
import {LabelType} from "../../../common/components/input/inputLabel/InputLabel.component";
import {Checkbox} from "../../../common/components/input/checkbox/Checkbox.component";
import {StyledCancelAppointmentModal} from "./CancelAppointmentModal.style";
import {isDefinedAndNotEmpty} from "../../../common/service/util/StringUtils";
import {useParams} from "react-router-dom";

interface CancelAppointmentModalProps {
    hideModal: () => void;
    refreshAppointments: () => void;
    appointment: AppointmentDTO;
}

export const CancelAppointmentModal: FC<CancelAppointmentModalProps> = (props: CancelAppointmentModalProps) => {
    const initialState: CancelAppointmentDTO = {
        cancellationReason: "",
        returnDeposit: false
    };

    const [appointment, setAppointment] = useState<CancelAppointmentDTO>(initialState);
    const { userId } = useParams();

    const onSave = async () => {
        const result = await cancelAppointmentRest(props.appointment.id, appointment, userId);
        if (isSuccess(result)) {
            props.hideModal();
            props.refreshAppointments();
        }
    }

    const setValue = (fieldName: string, newValue: any) => {
        setAppointment(prevState => ({...prevState, [fieldName]: newValue}));
    }

    const isValid = (): boolean => {
        return isDefinedAndNotEmpty(appointment.cancellationReason);
    }

    const showReturnDeposit = () => {
        return props.appointment.deposit?.status == DepositStatus.PAID;
    };

    return <ActionModal
        header={translate("CANCEL_APPOINTMENT")}
        onConfirm={onSave}
        onReject={props.hideModal}
        isVisible={true}
        isSaveDisabled={!isValid()}>

        <StyledCancelAppointmentModal className="formgrid grid">
            {showReturnDeposit() && <div className={"return-deposit-checkbox col-12 sm:col-12"}>
                <Checkbox
                value={appointment.returnDeposit}
                setValue={returnDeposit => setValue("returnDeposit", returnDeposit)}
                label={translate("RETURN_DEPOSIT")}
                />
            </div>}
            <TextAreaInput
                setValue={cancellationReason => setValue("cancellationReason", cancellationReason)}
                value={appointment.cancellationReason}
                label={translate("CANCELLATION_REASON")}
                labelType={LabelType.VERTICAL}
                rows={5}
                className={"col-12 sm:col-12"}
                required={true}
            />
        </StyledCancelAppointmentModal>
    </ActionModal>;
};

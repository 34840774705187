import styled from "styled-components";

export const StyledCleaningItemReportPage = styled.div`
  .field {
    margin-bottom: 0;
  }

  .dash {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

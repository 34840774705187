import React, {FC} from "react";
import {Title} from '../../../common/components/title/Title.component';
import {isDefined} from '../../../common/service/util/ObjectUtils';
import {formatAsCurrency} from '../../../common/service/util/CurrencyUtil';
import {translate} from '../../../common/lang/service/Translate.service';
import {FileDownload} from '../../../common/components/fileDownload/FileDownload.component';
import {Button} from 'primereact/button';
import {Card} from '../../../common/components/card/Card.component';
import {ExpenseReadDTO} from '../service/ExpensesPage.type';
import {DictionaryDTO} from '../../../common/service/util/dictionary/Dictionary.type';
import {getExpenseInvoicesUrl, validateExpense} from '../service/ExpensesPage.service';
import {Tag} from 'primereact/tag';
import {Day} from "../../../common/service/util/Day";

interface ExpenseCardProps {
    expense: ExpenseReadDTO;
    supplierDictionary: DictionaryDTO[];
    openAddExpenseModal: (expense: ExpenseReadDTO) => void;
    removeExpense: (expenseId: string) => void;
}

export const ExpenseCard: FC<ExpenseCardProps> = (props: ExpenseCardProps) => {
    const getTitle = (): string => {
        const supplier = isDefined(props.expense.supplierId)
            ? ' - ' + props.supplierDictionary.find(value => value.id === props.expense.supplierId)?.name
            : '';
        return translate(props.expense.expenseType) + supplier;
    }

    return <Card>
        <Title
            title={getTitle()}
            subtitle={isDefined(props.expense.amount) ? formatAsCurrency(props.expense.amount) : undefined}
            upperTitle={isDefined(props.expense.paymentDate) ? props.expense.paymentDate : props.expense.invoiceDate}
        />
        <div className={"expense-card-content"}>
            {props.expense.description}
        </div>

        <div className={"buttons-row"}>
            <div className={'tags'}>
                {!props.expense.isValid && Day.fromString(props.expense.invoiceDate).isBeforeOrEqualDay(Day.today())
                    && validateExpense(props.expense).map(value => <Tag icon={'pi pi-times'} className="mr-2" severity="danger" key={value} value={value}/>)}
                {!props.expense.isValid && Day.fromString(props.expense.invoiceDate).isAfterDay(Day.today())
                    && validateExpense(props.expense).map(value => <Tag icon={'pi pi-times'} className="mr-2" severity="warning" key={value} value={value}/>)}
                {props.expense.isValid
                    && <Tag icon={'pi pi-check'} className="mr-2" severity="success" key={'valid'} value={translate("VALID")}/>}
            </div>
            <div>
                {props.expense.hasFiles &&
                    <FileDownload
                        url={getExpenseInvoicesUrl(props.expense.expenseId)}
                        isZip={true}
                    />}
                <Button onClick={() => props.openAddExpenseModal(props.expense)} icon={"pi pi-pencil"}
                        className={"p-button-rounded p-button-text"}/>
                <Button onClick={() => props.removeExpense(props.expense.expenseId)} icon={"pi pi-times"}
                        className={"p-button-rounded p-button-danger p-button-text"}/>
            </div>
        </div>
    </Card>;
};

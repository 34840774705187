import {movePeriodByDays, movePeriodByFullMonths} from "./MovePeriod";
import {PeriodChangeStrategy} from "./PeriodChanger.type";
import {DayRange} from "../../../service/util/DayRange";

export const getChangePeriodByMonth = (): PeriodChangeStrategy => ({
    periodMoveUnitAmount: 1,
    movePeriod: movePeriodByFullMonths
})

export const getDailyPeriodChange = (): PeriodChangeStrategy => ({
    periodMoveUnitAmount: 1,
    movePeriod: movePeriodByDays
})

export const getStaticPeriodChange = (): PeriodChangeStrategy => ({
    periodMoveUnitAmount: 0,
    movePeriod: (period: DayRange, numberOfDays: number) => period
})

import {isDefined, isNotDefined} from "./ObjectUtils";

export const removeDiacritics = (value: string): string => {
    if (isNotDefined(value)) {
        return value;
    }

    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l").replace(/\u0141/g, "L");
};

export const compareStrings = (firstString: string, secondString: string): number => {
    return removeDiacritics(firstString.toLocaleLowerCase())
        .localeCompare(removeDiacritics(secondString.toLocaleLowerCase()), undefined, {numeric: true});
};

export const isDefinedAndNotEmpty = (obj: string | any[]): boolean => {
    return isDefined(obj) && obj.length > 0;
};

export const isNotDefinedOrEmpty = (obj: string | any[]): boolean => {
    return !isDefinedAndNotEmpty(obj);
};

export const isNullOrWhiteSpace = (value: string): boolean => {
    return isNotDefined(value) || value.trim() === "";
};

export const countOccurrences = (value: string, char: string): number => {
    let result = 0;
    if (!value) {
        return result;
    }

    for (let i = 0; i < value.length; i++) {
        if (value[i] === char) {
            result++;
        }
    }

    return result;
};

const getStringLength = (text: string): number => {
    return isDefined(text) ? text.length : 0;
};

export const isProperStringLength = (text: string, limit: number): boolean => {
    return isNotDefined(limit) || getStringLength(text) <= limit;
};

export const getRemainingStringLength = (text: string, limit: number): number => {
    return limit - getStringLength(text);
};

export const removeCamelCase = (text: string): string => {
    const textWithSpaces = text.replace(/([A-Z])/g,' $1');

    return textWithSpaces.charAt(0).toUpperCase()+textWithSpaces.slice(1);
};

export const fillRemaining = (text: string, character: string, targetLength: number): string => {
    let newText = text;
    while (newText.length < targetLength) {
        newText = character + newText;
    }

    return newText;
}

export const formatAsPercentage = (value: number): string => {
    return isDefined(value) ? value.toFixed(2) + "%" : undefined;
}

import styled from "styled-components";

export const StyledSalariesModal = styled.div`
  .summary-row {
    justify-content: space-between;
    width: 100%;
    display: flex;
    line-height: 32px;
  }
`;

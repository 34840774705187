import styled from "styled-components";

export const StyledSupplierOrder = styled.div`
  .field {
    margin-bottom: 0;
  }

  .update-count {
    display: flex;
  }
  
  .update-count-button {
    min-width: 32px;
  }

  .total-value-column {
    text-align: right;
  }
  
  .order-total {
    text-align: right;
    padding-right: 8px;
    font-weight: 600;
    padding-bottom: 5px;
    line-height: 32px;
  }

  .supplier-order-table {
    padding-bottom: 10px;
  }
  
  .additional-cost {
    text-align: right;
    padding-right: 8px;
    padding-top: 10px;
    opacity: 0.6;
  }
  
  .shop-link {
    a {
      color: unset;
    }
    
    a:hover {
      opacity: 0.7;
    }
  }  
`;

import React, {FC, useEffect, useState} from "react";
import {StyledUserContractInformationTab} from './UserContractInformationTab.style';
import {UserProfilePagePermissionsDTO} from '../../service/UserProfilePage.type';
import {ContractInformationDTO} from './service/UserContractInformation.type';
import {ValueLabel} from '../../../common/components/input/valueLabel/ValueLabel.component';
import {translate} from '../../../common/lang/service/Translate.service';
import {ButtonRow} from '../../../common/components/buttonRow/ButtonRow.component';
import {Button} from 'primereact/button';
import {formatAsPercentage} from '../../../common/service/util/StringUtils';
import {isDefined, isNotDefined} from '../../../common/service/util/ObjectUtils';
import {TerminateUserContractModal} from './terminateUserContractModal/TerminateUserContractModal.component';
import {UpdateUserContractModal} from './updateUserContractModal/UpdateUserContractModal.component';
import {getCurrentContractInformationRest} from './service/UserContractInformation.service';
import {Day} from '../../../common/service/util/Day';
import {Translate} from "../../../common/lang/Translate.component";

interface UserContractInformationTabProps {
    id: string;
    permissions: UserProfilePagePermissionsDTO;
}

export const UserContractInformationTab: FC<UserContractInformationTabProps> = (props: UserContractInformationTabProps) => {
    const [contractInformation, setContractInformation] = useState<ContractInformationDTO>(undefined)
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const [terminationModalVisible, setTerminationModalVisible] = useState(false);

    useEffect(() => {
        retrieveContractInformation();
    }, []);

    const retrieveContractInformation = async () => {
        const contractInformationDTO: ContractInformationDTO = await getCurrentContractInformationRest(props.id);
        setContractInformation(isDefined(contractInformationDTO?.startDate) ? contractInformationDTO : undefined);
    }

    const hideModal = () => {
        setUpdateModalVisible(false);
        setTerminationModalVisible(false);
        retrieveContractInformation();
    }

    const getNextContractInformation = (): string => {
        return translate("NEW_CONTRACT_ON") + Day.fromString(contractInformation.endDate)?.addDays(1);
    }

    return <StyledUserContractInformationTab>
        {isDefined(contractInformation) && <div className={'fields'}>
            <ValueLabel
                className={'contract-field'}
                value={contractInformation.startDate}
                label={translate("START_DATE")}
            />
            {(isDefined(contractInformation.endDate) && !contractInformation.hasNext)
                && <ValueLabel
                    className={'contract-field'}
                    value={contractInformation.endDate}
                    label={translate("END_DATE")}
                />}
            <ValueLabel
                className={'contract-field'}
                value={formatAsPercentage(contractInformation.sharePercentage)}
                label={translate("SHARE_PERCENTAGE")}
            />
            <ValueLabel
                className={'contract-field'}
                value={translate(contractInformation.contractType)}
                label={translate("DISPLAY_NAME")}
            />
        </div>}

        {(isDefined(contractInformation?.endDate) && contractInformation?.hasNext) &&
            <div className={"info-label"}>{getNextContractInformation()}</div>}

        {isNotDefined(contractInformation) &&
            <div className={"info-label"}><Translate id={"NO_CONTRACT_INFORMATION"}/></div>}

        {(isDefined(contractInformation?.endDate) && Day.fromString(contractInformation?.endDate).isBeforeDay(Day.today())
                && !contractInformation?.hasNext) &&
            <div className={"info-label"}><Translate id={"CONTRACT_ENDED"}/></div>}

        {!(isDefined(contractInformation?.endDate) && !contractInformation?.hasNext)
            && props.permissions.canEditContractInformation
            && <ButtonRow alignRight={false}>
            <Button onClick={() => setUpdateModalVisible(true)} icon={"pi pi-pencil"}
                    className={"p-button-rounded p-button-text"} label={translate('UPDATE')}/>
            {isDefined(contractInformation) &&
                <Button onClick={() => setTerminationModalVisible(true)} icon={"pi pi-times"}
                        className={"p-button-rounded p-button-text p-button-danger"} label={translate('TERMINATE')}/>}
        </ButtonRow>}
        {terminationModalVisible && <TerminateUserContractModal id={props.id} hideModal={hideModal}/>}
        {updateModalVisible && <UpdateUserContractModal id={props.id} hideModal={hideModal}/>}
    </StyledUserContractInformationTab>;
};

import {FilterFactory} from "../../../common/service/util/filters/FilterFactory";
import {FilterStrategy} from "../../../common/service/util/filters/FilterStrategy";
import {DefaultFilterStrategy} from "../../../common/service/util/filters/DefaultFilterStrategy";
import {RequiresPaymentFilterStrategy} from "./RequiresPaymentFilterStrategy";
import {HasMissingInvoiceFilterStrategy} from "./HasMissingInvoiceFilterStrategy";

export class ExpensesFilterFactory implements FilterFactory {
    getFilterStrategy(filterField: string): FilterStrategy {
        switch (filterField) {
            case "expenseType": {
                return new DefaultFilterStrategy(filterField);
            }
            case "paymentType": {
                return new DefaultFilterStrategy(filterField);
            }
            case "requiresPayment": {
                return new RequiresPaymentFilterStrategy();
            }
            case "hasMissingInvoice": {
                return new HasMissingInvoiceFilterStrategy();
            }

            default: {
                throw new Error("unsupported filter filed");
            }
        }
    }
}

import styled from "styled-components";

export const StyledUserManagementPage = styled.div`
  .avatar {
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
  
  .image-container {
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

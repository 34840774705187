import React, {FC, useEffect, useState} from "react";
import {Modal} from "../../../common/modal/Modal.component";
import {translate} from "../../../common/lang/service/Translate.service";
import {MultiselectInput} from '../../../common/components/input/multiselectInput/MultiselectInput.component';
import {ButtonRow} from '../../../common/components/buttonRow/ButtonRow.component';
import {Button} from 'primereact/button';
import {saveEditedRolesRest} from '../service/UserManagementPage.service';
import {isSuccess} from "../../../common/service/result/Result.type";

interface EditUserRolesModalProps {
    closeModal: () => void;
    userId: string;
    roleNames: string[];
}

export const EditUserRolesModal: FC<EditUserRolesModalProps> = (props: EditUserRolesModalProps) => {
    const [editedRoles, setEditedRoles] = useState<string[]>(props.roleNames)

    useEffect(() => {
        setEditedRoles(editedRoles);
    }, [props.userId]);

    const saveEditedRoles = async () => {
        const result = await saveEditedRolesRest(props.userId, editedRoles);
        if (isSuccess(result)) {
            props.closeModal();
        }
    }

    return <Modal header={translate("EDIT_USER")} hideModal={props.closeModal} isVisible={true} footer={<div></div>}>
        <MultiselectInput
            label={translate("ROLES")}
            selectedValues={editedRoles}
            setSelectedValues={(newRoles: string[]) => setEditedRoles(newRoles)}
            options={[
                {label: "OWNER", value: "OWNER"},
                {label: "EMPLOYEE", value: "EMPLOYEE"},
                {label: "ADMINISTRATOR", value: "ADMINISTRATOR"},
                {label: "MANAGER", value: "MANAGER"},
                {label: "TATTOOER", value: "TATTOOER"},
                {label: "TOOTH_GEMMER", value: "TOOTH_GEMMER"},
                {label: "PIERCER", value: "PIERCER"},
                {label: "DERMALER", value: "DERMALER"},
                {label: "TESTER", value: "TESTER"}
            ]}
        />
        <ButtonRow>
            <Button label={translate("SAVE")} onClick={saveEditedRoles}/>
        </ButtonRow>
    </Modal>;
};

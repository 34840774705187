import React, {FC} from "react";
import {ValueCard} from "../../common/components/card/valueCard/ValueCard.component";
import {translate} from "../../common/lang/service/Translate.service";
import {CashReportSummaryDTO} from "../service/CashReportPage.type";
import {formatAsCurrency} from "../../common/service/util/CurrencyUtil";
import {isDefined} from "../../common/service/util/ObjectUtils";

interface CashReportSummaryProps {
    cashReportSummary: CashReportSummaryDTO;
}

export const CashReportSummary: FC<CashReportSummaryProps> = (props: CashReportSummaryProps) => {
    return <>
        {isDefined(props.cashReportSummary) && <div className={"col-12 md:col-4"}>
            <ValueCard
                label={translate("CASH_REGISTER_STATE")}
                value={formatAsCurrency(props.cashReportSummary.cashAmount)}
            />
            <ValueCard
                label={translate("MONTHLY_PAYMENTS")}
                value={formatAsCurrency(props.cashReportSummary.incomeSum) + " (" + props.cashReportSummary.numberOfIncomes + ")"}
            />
            <ValueCard
                label={translate("SALARIES")}
                value={formatAsCurrency(props.cashReportSummary.salariesSum)}
            />
            {props.cashReportSummary.incomes.map(value =>
                <ValueCard
                    key={value.productName}
                    value={formatAsCurrency(value.incomeSum) + " (" + value.productsSold + ")"}
                    label={value.productName}
                />)}
        </div>}
    </>;
};

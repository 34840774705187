import React, {FC, useState} from "react";
import {Button} from 'primereact/button';
import {translate} from '../../../common/lang/service/Translate.service';
import {isNotDefined} from '../../../common/service/util/ObjectUtils';
import {PeriodChanger} from '../../../common/components/periodChanger/PeriodChanger.component';
import {DayRange} from '../../../common/service/util/DayRange';
import {getDailyChangerDisplay} from '../../../common/components/periodChanger/service/PeriodChangerDisplay';
import {Toolbar} from 'primereact/toolbar';
import {Day} from '../../../common/service/util/Day';
import {Card} from '../../../common/components/card/Card.component';
import {ConfirmationModal} from "../../../common/modal/confirmationModal/ConfirmationModal.component";

interface OrderPageToolbarProps {
    orderDate: Day;
    onPreviousOrder: () => void;
    onNextOrder: () => void;
    startOrder: () => void;
    closeOrder: () => void;
    addItem: () => void;
    canStartOrder: boolean;
    canCloseOrder: boolean;
    orderNumber: number;
    latestOrderNumber: number;
}

export const OrderPageToolbar: FC<OrderPageToolbarProps> = (props: OrderPageToolbarProps) => {
    const [closeOrderModalVisible, setCloseOrderModalVisible] = useState(false);

    const rightToolbarTemplate = () => {
        if (props.canCloseOrder) {
            return <>
                <Button label={translate("ADD")} onClick={props.addItem} icon={"pi pi-plus"} className={'mr-2'}/>
                <Button label={translate("CLOSE")} onClick={openCloseOrderModal} icon={"pi pi-check"}
                        className={"p-button-success"}/>
            </>;
        }

        if (props.canStartOrder) {
            return <Button label={translate("START")} onClick={props.startOrder}/>;
        }

        return <></>;
    };

    const leftToolbarPanel = () => {
        if (isNotDefined(props.orderNumber)) {
            return <></>;
        }

        return (
            <PeriodChanger
                startPeriod={DayRange.getDateRangeFromDates(props.orderDate, props.orderDate)}
                onNextPeriod={(range: DayRange) => props.onNextOrder()}
                onPreviousPeriod={(range: DayRange) => props.onPreviousOrder()}
                display={getDailyChangerDisplay()}
                isFirstPeriod={props.orderNumber === 1}
                isLastPeriod={props.orderNumber === props.latestOrderNumber}
            />
        );
    }

    const openCloseOrderModal = () => {
        setCloseOrderModalVisible(true);
    }

    const closeOrder = () => {
        props.closeOrder();
        setCloseOrderModalVisible(false);
        window.localStorage.removeItem("hidden-order-suppliers");
    }

    return <div className={"col-12"}>
        <Card>
            <Toolbar
                start={leftToolbarPanel}
                end={rightToolbarTemplate}
            />
        </Card>
        {closeOrderModalVisible &&
            <ConfirmationModal
                onConfirm={closeOrder}
                onReject={() => setCloseOrderModalVisible(false)}
                isVisible={closeOrderModalVisible}
            />}
    </div>;
};

import React, {FC, useEffect, useState} from "react";
import {StyledInventoryPage} from './InventoryPage.style';
import {InventoryDTO, InventoryStatus, WarehouseStateEntryDTO} from "./service/InventoryPage.type";
import {
    closeInventoryRest,
    getCurrentWarehouseStateRest,
    getInventoryRest,
    getLatestInventoryRest,
    startInventoryRest
} from "./service/InventoryPage.service";
import {ItemCategoryDTO, ItemDictionaryDTO} from "../itemDictionaryPage/service/ItemDictionaryPage.type";
import {getItemCategoriesRest, getItemsRest} from "../itemDictionaryPage/service/ItemDictionaryPage.service";
import {InventoryToolbar} from "./inventoryToolbar/InventoryToolbar.component";
import {Day} from "../../common/service/util/Day";
import {usePermissions} from "../../common/security/globalPermissions/service/Permissions.hook";
import {CommandResult, isSuccess} from "../../common/service/result/Result.type";
import {InventoryContainer} from "./inventoryContainer/InventoryContainer.component";
import {DictionaryDTO} from "../../common/service/util/dictionary/Dictionary.type";

interface InventoryPageProps {
}

export const InventoryPage: FC<InventoryPageProps> = (props: InventoryPageProps) => {
    const [inventory, setInventory] = useState<InventoryDTO>(undefined);
    const [warehouseState, setWarehouseState] = useState<WarehouseStateEntryDTO[]>([]);
    const [itemDictionary, setItemDictionary] = useState<ItemDictionaryDTO[]>([]);
    const [categoryDictionary, setCategoryDictionary] = useState<ItemCategoryDTO[]>([]);
    const [inventoryStarted, setInventoryStarted] = useState<boolean>(false);
    const [latestInventoryNumber, setLatestInventoryNumber] = useState<number>();

    const canEdit: boolean = usePermissions().canDoAnInventory;
    const inEditMode: boolean = canEdit && inventory?.inventoryStatus === InventoryStatus.OPEN;

    useEffect(() => {
        retrieveLatestInventory();
        retrieveWarehouseState();
        retrieveItemDictionary();
        retrieveItemCategories();
    }, []);

    async function retrieveLatestInventory() {
        const inventoryDTO: InventoryDTO = await getLatestInventoryRest();
        setInventory(inventoryDTO);
        setInventoryStarted(inventoryDTO.inventoryStatus === InventoryStatus.OPEN);
        setLatestInventoryNumber(inventoryDTO.inventoryNumber);
    }

    async function retrieveInventory(newInventoryNumber: number) {
        const inventoryDTO: InventoryDTO = await getInventoryRest(newInventoryNumber);
        setInventory(inventoryDTO);
    }

    async function retrieveWarehouseState() {
        const warehouseStateDTO: WarehouseStateEntryDTO[] = await getCurrentWarehouseStateRest();
        setWarehouseState(warehouseStateDTO);
    }

    async function retrieveItemDictionary() {
        const itemDTOs: ItemDictionaryDTO[] = await getItemsRest();
        setItemDictionary(itemDTOs);
    }

    async function retrieveItemCategories() {
        const itemCategoryDTOs: ItemCategoryDTO[] = await getItemCategoriesRest();
        setCategoryDictionary(itemCategoryDTOs);
    }

    const startInventory = async () => {
        const result: CommandResult = await startInventoryRest();

        if (isSuccess(result)) {
            retrieveLatestInventory();
        }
    }

    const closeInventory = async () => {
        const result: CommandResult = await closeInventoryRest(inventory.id);

        if (isSuccess(result)) {
            retrieveWarehouseState();
            retrieveLatestInventory();
        }
    }

    const getItemSubCategories = (): DictionaryDTO[] => {
        return categoryDictionary.map(value => value.subCategories).flat(1);
    }

    return <StyledInventoryPage>
        <InventoryToolbar
            inventoryDate={Day.fromString(inventory?.inventoryStartDate)}
            onPreviousInventory={() => retrieveInventory(--inventory.inventoryNumber)}
            onNextInventory={() => retrieveInventory(++inventory.inventoryNumber)}
            startInventory={startInventory}
            closeInventory={closeInventory}
            canStartInventory={canEdit && !inventoryStarted && latestInventoryNumber === inventory?.inventoryNumber}
            canCloseInventory={canEdit && inventoryStarted && latestInventoryNumber === inventory?.inventoryNumber}
            inventoryNumber={inventory?.inventoryNumber}
            latestInventoryNumber={latestInventoryNumber}
        />
        <InventoryContainer
            inventory={inventory}
            updateInventory={retrieveLatestInventory}
            warehouseState={warehouseState}
            itemDictionary={itemDictionary}
            categoryDictionary={categoryDictionary}
            inEditMode={inEditMode}
            itemSubCategories={getItemSubCategories()}
        />
    </StyledInventoryPage>;
};

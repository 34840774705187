import React, {FC, useState} from "react";
import {
    AppointmentDTO,
    AppointmentPaymentType,
    AppointmentStatus,
    EditAppointmentDTO
} from "../../service/Appointments.type";
import {editAppointmentRest} from "../../service/Appointments.service";
import {isSuccess} from "../../../common/service/result/Result.type";
import {ActionModal} from "../../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../../common/lang/service/Translate.service";
import {Day} from "../../../common/service/util/Day";
import {ClockTime} from "../../../common/service/util/ClockTime";
import {DateInput} from "../../../common/components/input/dateInput/DateInput.component";
import {LabelType} from "../../../common/components/input/inputLabel/InputLabel.component";
import {TimeInput} from "../../../common/components/input/timeInput/TimeInput.component";
import {Divider} from "../../../common/Divider.style";
import {CurrencyInput} from "../../../common/components/input/currencyInput/CurrencyInput.component";
import {TextInput} from "../../../common/components/input/textInput/TextInput.component";
import {TextAreaInput} from "../../../common/components/input/textAreaInput/TextAreaInput.component";
import {Checkbox} from "../../../common/components/input/checkbox/Checkbox.component";
import {SingleSelectInput} from "../../../common/components/input/singleSelectInput/SingleSelectInput.component";
import {getEnumSelectOptions} from "../../../common/components/input/multiselectChips/service/SelectOption.type";
import {StyledEditAppointmentModal} from "./EditAppointmentModal.style";
import {isDefined, isNotDefined} from "../../../common/service/util/ObjectUtils";
import {isDefinedAndNotEmpty} from "../../../common/service/util/StringUtils";
import {useParams} from "react-router-dom";

interface EditAppointmentModalProps {
    hideModal: () => void;
    refreshAppointments: () => void;
    appointment: AppointmentDTO;
}

export const EditAppointmentModal: FC<EditAppointmentModalProps> = (props: EditAppointmentModalProps) => {
    const initialAppointment: EditAppointmentDTO = {
        day: props.appointment.day,
        startTime: props.appointment.startTime,
        endTime: props.appointment.endTime,
        totalAmount: props.appointment.totalAmount,
        withRecipe: props.appointment.withRecipe,
        paymentType: props.appointment.paymentType,
        client: props.appointment.client,
        deposit: props.appointment.deposit ?? {},
        description: props.appointment.description,
    };

    const [appointment, setAppointment] = useState<EditAppointmentDTO>(initialAppointment);

    const {userId} = useParams();
    const onSave = async () => {
        const result = await editAppointmentRest(props.appointment.id, appointment, userId);
        if (isSuccess(result)) {
            props.hideModal();
            props.refreshAppointments();
        }
    }

    const isValid = (): boolean => {
        return isDefined(appointment.day)
            && isDefined(appointment.startTime)
            && isDefined(appointment.endTime)
            && isDefined(appointment.totalAmount)
            && isDefinedAndNotEmpty(appointment.description)
            && isClientValid()
            && isFinishedAppointmentValid()
            && isDepositValid();
    }

    const isClientValid = (): boolean => {
        const isNameDefined = isDefinedAndNotEmpty(appointment.client.firstName) && isDefinedAndNotEmpty(appointment.client.lastName);
        return (isNameDefined || isDefinedAndNotEmpty(appointment.client.instagram))
            && (isDefinedAndNotEmpty(appointment.client.instagram)
                || isDefinedAndNotEmpty(appointment.client.phoneNumber)
                || isDefinedAndNotEmpty(appointment.client.email));
    }

    const isDepositValid = (): boolean => {
        if (props.appointment.status === AppointmentStatus.TO_CONFIRM) {
            return true;
        }

        return isNotDefined(appointment.deposit?.amount) || appointment.deposit?.amount === 0
            || (isDefined(appointment.deposit?.amount)
                && appointment.deposit?.amount > 0
                && isDefined(appointment.deposit?.paymentDate)
                && isDefined(appointment.deposit?.paymentType));
    }

    const isFinishedAppointmentValid = (): boolean => {
        return props.appointment.status !== AppointmentStatus.FINISHED
            || isDefined(props.appointment.paymentType);
    }

    const setValue = (fieldName: string, newValue: any) => {
        setAppointment(prevState => ({...prevState, [fieldName]: newValue}));
    }

    const setClientValue = (fieldName: string, newValue: any) => {
        setAppointment(prevState => ({...prevState, client: {...prevState.client, [fieldName]: newValue}}));
    }

    const setDepositValue = (fieldName: string, newValue: any) => {
        setAppointment(prevState => ({...prevState, deposit: {...prevState.deposit, [fieldName]: newValue}}));
    }

    return <ActionModal
        header={translate("EDIT_APPOINTMENT")}
        onConfirm={onSave}
        onReject={props.hideModal}
        isVisible={true}
        isSaveDisabled={!isValid()}
    >
        <StyledEditAppointmentModal>
            <div className="formgrid grid">
                <DateInput
                    label={translate("DATE")}
                    value={Day.fromString(appointment.day)}
                    setValue={day => setValue("day", day)}
                    labelType={LabelType.VERTICAL}
                    className={"col-12 sm:col-4"}
                />
                <TimeInput
                    label={translate("START_TIME")}
                    value={ClockTime.fromString(appointment.startTime)}
                    setValue={startTime => setValue("startTime", startTime)}
                    labelType={LabelType.VERTICAL}
                    minuteInterval={15}
                    className={"col-6 sm:col-4"}
                />
                <TimeInput
                    label={translate("END_TIME")}
                    value={ClockTime.fromString(appointment.endTime)}
                    setValue={endTime => setValue("endTime", endTime)}
                    labelType={LabelType.VERTICAL}
                    minuteInterval={15}
                    className={"col-6 sm:col-4"}
                />
            </div>

            <Divider margin={"10px"}/>

            <div className="formgrid grid">
                <CurrencyInput
                    label={translate("TOTAL_AMOUNT")}
                    value={appointment.totalAmount}
                    setValue={totalAmount => setValue("totalAmount", totalAmount)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 sm:col-6"}
                />
                {props.appointment.status === AppointmentStatus.FINISHED && <SingleSelectInput
                    options={getEnumSelectOptions(AppointmentPaymentType)}
                    selectedValue={appointment.paymentType}
                    setSelectedValue={paymentType => setValue("paymentType", paymentType)}
                    label={translate("PAYMENT_TYPE")}
                    className={"col-6 sm:col-6"}
                />}
                {props.appointment.status === AppointmentStatus.FINISHED && <div className={"col-6 sm:col-6"}>
                    <Checkbox
                        value={appointment.withRecipe}
                        setValue={withRecipe => setValue("withRecipe", withRecipe)}
                        label={translate("WITH_RECIPE")}
                    />
                </div>}
            </div>

            <Divider margin={"10px"}/>

            <div className="formgrid grid">
                <CurrencyInput
                    label={translate("DEPOSIT_AMOUNT")}
                    value={appointment.deposit.amount}
                    setValue={amount => setDepositValue("amount", amount)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 sm:col-6"}
                />
                <DateInput
                    label={translate("DATE")}
                    value={Day.fromString(appointment.deposit?.paymentDate)}
                    setValue={paymentDate => setDepositValue("paymentDate", paymentDate)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 sm:col-6"}
                />
                <SingleSelectInput
                    options={getEnumSelectOptions(AppointmentPaymentType)}
                    selectedValue={appointment.deposit?.paymentType}
                    setSelectedValue={paymentType => setDepositValue("paymentType", paymentType)}
                    label={translate("PAYMENT_TYPE")}
                    className={"col-6 sm:col-6"}
                />
                <div className={"col-6 sm:col-6"}>
                    <Checkbox
                        value={appointment.deposit?.withRecipe}
                        setValue={withRecipe => setDepositValue("withRecipe", withRecipe)}
                        label={translate("WITH_RECIPE")}
                        className={"checkbox-padding"}
                    />
                </div>
                <Divider margin={"10px"}/>
            </div>

            <div className="formgrid grid">
                <TextInput
                    label={translate("FIRST_NAME")}
                    value={appointment.client.firstName}
                    setValue={firstName => setClientValue("firstName", firstName)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 md:col-6 appointment-details-field"}
                />

                <TextInput
                    label={translate("LAST_NAME")}
                    value={appointment.client.lastName}
                    setValue={lastName => setClientValue("lastName", lastName)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 md:col-6 appointment-details-field"}
                />
            </div>

            <div className="formgrid grid">
                <TextInput
                    setValue={email => setClientValue("email", email)}
                    label={translate("EMAIL")}
                    value={appointment.client.email}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 md:col-4 appointment-details-field"}
                />

                <TextInput
                    label={translate("INSTAGRAM")}
                    value={appointment.client.instagram}
                    setValue={instagram => setClientValue("instagram", instagram)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 md:col-4 appointment-details-field"}
                />

                <TextInput
                    label={translate("PHONE_NUMBER")}
                    value={appointment.client.phoneNumber}
                    setValue={phoneNumber => setClientValue("phoneNumber", phoneNumber)}
                    labelType={LabelType.VERTICAL}
                    className={"col-12 md:col-4 appointment-details-field"}
                />
            </div>

            <Divider margin={"10px"}/>

            <div className="formgrid grid">
                <TextAreaInput
                    setValue={description => setValue("description", description)}
                    value={appointment.description}
                    label={translate("DESCRIPTION")}
                    labelType={LabelType.VERTICAL}
                    rows={5}
                    className={"col-12 sm:col-12"}
                    required={true}
                />
            </div>
        </StyledEditAppointmentModal>
    </ActionModal>;
};

import React, {FC} from "react";
import {AppointmentDTO, AppointmentStatus, DepositStatus} from "../../service/Appointments.type";
import {Divider} from "../../../common/Divider.style";
import {ValueLabel} from "../../../common/components/input/valueLabel/ValueLabel.component";
import {translate, translateBoolean} from "../../../common/lang/service/Translate.service";
import {LabelType} from "../../../common/components/input/inputLabel/InputLabel.component";
import {ClockTime} from "../../../common/service/util/ClockTime";
import {formatAsCurrency} from "../../../common/service/util/CurrencyUtil";
import {StyledAppointmentDetails} from "./AppointmentDetails.style";
import {isDefinedAndNotEmpty} from "../../../common/service/util/StringUtils";

interface AppointmentDetailsProps {
    appointment: AppointmentDTO;
}

export const AppointmentDetails: FC<AppointmentDetailsProps> = (props: AppointmentDetailsProps) => {
    return <StyledAppointmentDetails className={"appointment-card-content"}>
        <Divider margin={"5px"}/>

        <div className="formgrid grid">
            <ValueLabel
                label={translate("DATE")}
                value={props.appointment.day}
                labelType={LabelType.VERTICAL}
                className={"col-12 md:col-4 appointment-details-field"}
            />
            <ValueLabel
                label={translate("START_TIME")}
                value={ClockTime.formatTime(props.appointment.startTime)}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />
            <ValueLabel
                label={translate("END_TIME")}
                value={ClockTime.formatTime(props.appointment.endTime)}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />
        </div>

        <div className="formgrid grid">
            <ValueLabel
                label={translate("TOTAL_AMOUNT")}
                value={formatAsCurrency(props.appointment.totalAmount)}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />

            <ValueLabel
                label={translate("PAYMENT_TYPE")}
                value={translate(props.appointment.paymentType)}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />

            <ValueLabel
                label={translate("WITH_RECIPE")}
                value={translateBoolean(props.appointment.withRecipe)}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />

            {!props.appointment.hasDeposit && <><ValueLabel
                label={translate("HAS_DEPOSIT")}
                value={translate("NO")}
                labelType={LabelType.VERTICAL}
                className={"col-6 appointment-details-field"}
            />
            </>}
        </div>

        <Divider margin={"5px"}/>

        {props.appointment.hasDeposit && <>
            <div className="formgrid grid">
                <ValueLabel
                    label={translate("DEPOSIT_AMOUNT")}
                    value={formatAsCurrency(props.appointment.deposit.amount)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 md:col-4 appointment-details-field"}
                />

                <ValueLabel
                    label={translate("PAYMENT_DATE")}
                    value={props.appointment.deposit.paymentDate}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 md:col-4 appointment-details-field"}
                />

                <ValueLabel
                    label={translate("WITH_RECIPE")}
                    value={translateBoolean(props.appointment.deposit.withRecipe)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 md:col-4 appointment-details-field"}
                />

                <ValueLabel
                    label={translate("PAYMENT_TYPE")}
                    value={translate(props.appointment?.deposit.paymentType)}
                    labelType={LabelType.VERTICAL}
                    className={"col-6 md:col-12 appointment-details-field"}
                />
            </div>

            {(props.appointment.deposit.status === DepositStatus.RETURNED
                    || props.appointment.deposit.status === DepositStatus.TO_RETURN)
                && <div className="formgrid grid">
                    <ValueLabel
                        label={translate("RETURN_DATE")}
                        value={props.appointment.deposit.returnDate}
                        labelType={LabelType.VERTICAL}
                        className={"col-12 md:col-12 appointment-details-field"}
                    />
                </div>}
            <Divider margin={"5px"}/>
        </>}

        <div className="formgrid grid">
            <ValueLabel
                label={translate("FIRST_NAME")}
                value={props.appointment.client.firstName}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />

            <ValueLabel
                label={translate("LAST_NAME")}
                value={props.appointment.client.lastName}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-8 appointment-details-field"}
            />
        </div>

        <div className="formgrid grid">
            <ValueLabel
                label={translate("EMAIL")}
                value={props.appointment.client.email}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />

            <ValueLabel
                label={translate("INSTAGRAM")}
                value={props.appointment.client.instagram}
                link={"https://instagram.com/_u/" + props.appointment.client.instagram}
                labelType={LabelType.VERTICAL}
                className={"col-6 md:col-4 appointment-details-field"}
            />

            <ValueLabel
                label={translate("PHONE_NUMBER")}
                value={props.appointment.client.phoneNumber}
                labelType={LabelType.VERTICAL}
                className={"col-12 md:col-4 appointment-details-field"}
            />
        </div>

        <Divider margin={"5px"}/>

        <ValueLabel
            label={translate("DESCRIPTION")}
            value={props.appointment.description}
            className={"appointment-details-field"}
        />
        <Divider margin={"5px"}/>

        {props.appointment.status === AppointmentStatus.CANCELED &&
            isDefinedAndNotEmpty(props.appointment.cancellationReason) &&
            <>
                <ValueLabel
                    label={translate("RETURN_REASON")}
                    value={props.appointment.cancellationReason}
                    className={"appointment-details-field"}
                />
                <Divider margin={"5px"}/>
            </>}
    </StyledAppointmentDetails>;
};

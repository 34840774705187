import React, {FC} from "react";
import {StyledUserProfileHeader} from "./UserProfileHeader.style";
import {isDefined} from "../../common/service/util/ObjectUtils";

interface UserProfileHeaderProps {
    name: string;
    pictureUrl: string;
}

export const UserProfileHeader: FC<UserProfileHeaderProps> = (props: UserProfileHeaderProps) => {
    const getAvatarUrl = (): string => {
        const avatarUrl = props.pictureUrl;
        return isDefined(avatarUrl)
            ? avatarUrl
            : "/assets/layout/images/profile-image.png";
    }

    return <StyledUserProfileHeader>
        <img src={getAvatarUrl()} alt={"profile picture"}/>
        <div className={'username'}>{props.name}</div>
    </StyledUserProfileHeader>;
};

import React, {FC, useState} from "react";
import {RepeatableExpenseDTO} from '../service/RepeatableExpensesPage.type';
import {ActionModal} from '../../../common/modal/actionModal/ActionModal.component';
import {isDefined, isNotDefined} from '../../../common/service/util/ObjectUtils';
import {translate} from '../../../common/lang/service/Translate.service';
import {LabelType} from '../../../common/components/input/inputLabel/InputLabel.component';
import {SingleSelectInput} from '../../../common/components/input/singleSelectInput/SingleSelectInput.component';
import {
    getDictionarySelectOption,
    getEnumSelectOptions
} from '../../../common/components/input/multiselectChips/service/SelectOption.type';
import {TextAreaInput} from '../../../common/components/input/textAreaInput/TextAreaInput.component';
import {NumberInput} from '../../../common/components/input/numberInput/NumberInput.component';
import {CurrencyInput} from '../../../common/components/input/currencyInput/CurrencyInput.component';
import {ExpenseType, PaymentType} from '../../expensesPage/service/ExpensesPage.type';
import {Checkbox} from '../../../common/components/input/checkbox/Checkbox.component';
import {DictionaryDTO} from '../../../common/service/util/dictionary/Dictionary.type';
import {isNullOrWhiteSpace} from '../../../common/service/util/StringUtils';

interface RepeatableExpensesModalProps {
    initialEntry: RepeatableExpenseDTO;
    onSave: (entry: RepeatableExpenseDTO) => void;
    hideModal: () => void;
    supplierDictionary: DictionaryDTO[];
}

export const RepeatableExpensesModal: FC<RepeatableExpensesModalProps> = (props: RepeatableExpensesModalProps) => {
    const [entry, setEntry] = useState<RepeatableExpenseDTO>(props.initialEntry);

    const updateEntry = (field: string, value: any) => {
        setEntry(prevState => ({...prevState, [field]: value}));
    }

    const onConfirm = () => {
        props.onSave(entry);
    }

    const formInvalid = (): boolean => {
        return isNotDefined(entry.expenseType) || isNotDefined(entry.invoiceDayNumber) || isNullOrWhiteSpace(entry.description);
    }

    return <ActionModal
        header={isDefined(props.initialEntry?.id) ? translate("ADD") : translate("EDIT")}
        onConfirm={onConfirm}
        isVisible={true}
        onReject={props.hideModal}
        isSaveDisabled={formInvalid()}
    >

        <div className="formgrid grid">
            <SingleSelectInput
                options={getEnumSelectOptions(ExpenseType)}
                selectedValue={entry.expenseType}
                labelType={LabelType.VERTICAL}
                label={translate("EXPENSE_TYPE")}
                setSelectedValue={value => updateEntry('expenseType', value)}
                className={"col"}
                showClear={true}
            />

            <SingleSelectInput
                options={getEnumSelectOptions(PaymentType)}
                selectedValue={entry.paymentType}
                labelType={LabelType.VERTICAL}
                label={translate("PAYMENT_TYPE")}
                setSelectedValue={value => updateEntry('paymentType', value)}
                className={"col"}
                showClear={true}
            />

            <SingleSelectInput
                options={getDictionarySelectOption(props.supplierDictionary)}
                selectedValue={entry.supplierId}
                labelType={LabelType.VERTICAL}
                label={translate("SUPPLIER")}
                setSelectedValue={value => updateEntry('supplierId', value)}
                className={"col"}
                showClear={true}
            />
        </div>

        <div className="formgrid grid">
            <CurrencyInput
                value={entry.amount}
                setValue={newValue => updateEntry('amount', newValue)}
                label={translate("AMOUNT")}
                labelType={LabelType.VERTICAL}
                minValue={0}
                maxValue={99999}
                className={"col"}
            />
            <Checkbox
                value={entry.isPaid}
                setValue={newValue => updateEntry('isPaid', newValue)}
                triState={false}
                label={translate("IS_PAID")}
                className={"col"}
                verticalInputAlign={true}
            />
        </div>

        <div className="formgrid grid">
            <NumberInput
                value={entry.invoiceDayNumber}
                setValue={newValue => updateEntry('invoiceDayNumber', newValue)}
                label={translate("INVOICE_DAY_NUMBER")}
                labelType={LabelType.VERTICAL}
                minValue={1}
                maxValue={31}
                className={"col"}
            />

            <NumberInput
                value={entry.paymentDayNumber}
                setValue={newValue => updateEntry('paymentDayNumber', newValue)}
                label={translate("PAYMENT_DAY_NUMBER")}
                labelType={LabelType.VERTICAL}
                minValue={1}
                maxValue={31}
                className={"col"}
            />

            <NumberInput
                value={entry.deadlineDayNumber}
                setValue={newValue => updateEntry('deadlineDayNumber', newValue)}
                label={translate("DEADLINE_DAY_NUMBER")}
                labelType={LabelType.VERTICAL}
                minValue={1}
                maxValue={31}
                className={"col"}
            />
        </div>

        <div className="formgrid grid">
            <TextAreaInput
                value={entry.description}
                setValue={newValue => updateEntry('description', newValue)}
                rows={5}
                label={translate("DESCRIPTION")}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
        </div>
    </ActionModal>;
};

export interface GlobalPermissions {
    canAccessStapp: boolean;
    canAccessHomePage: boolean;
    canAccessTestPage: boolean;
    canAccessUserManagementPage: boolean;
    canManageCashReport: boolean;
    canAccessCleaningPage: boolean;
    canAccessCleaningDictionaryPage: boolean;
    canAccessCleaningReportPage: boolean;
    canAccessItemDictionaryPage: boolean;
    canAccessSupplierDictionaryPage: boolean;
    canAccessInventoryPage: boolean;
    canAccessOrderPage: boolean;
    canDoAnInventory: boolean;
    canMakeOrders: boolean;
    canAccessExpensesPage: boolean;
    canAddAppointments: boolean;
    canAddToothGems: boolean;
    canAddTattoos: boolean;
    canAddDermals: boolean;
    canAddPiercing: boolean;
    canAccessBankTransfersPage: boolean;
    canAddAnyUserAppointments: boolean;
}

export const getInitialGlobalPermissions = (): GlobalPermissions => {
    return {
        canAccessHomePage: false,
        canAccessStapp: false,
        canAccessTestPage: false,
        canAccessUserManagementPage: false,
        canManageCashReport: false,
        canAccessCleaningPage: false,
        canAccessCleaningDictionaryPage: false,
        canAccessCleaningReportPage: false,
        canAccessItemDictionaryPage: false,
        canAccessSupplierDictionaryPage: false,
        canAccessInventoryPage: false,
        canAccessOrderPage: false,
        canDoAnInventory: false,
        canMakeOrders: false,
        canAccessExpensesPage: false,
        canAddAppointments: false,
        canAddToothGems: false,
        canAddTattoos: false,
        canAddDermals: false,
        canAddPiercing: false,
        canAccessBankTransfersPage: false,
        canAddAnyUserAppointments: false,
    };
}

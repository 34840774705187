import styled from "styled-components";

export const StyledInventoryToolbar = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 0;
  margin-bottom: 20px;
  border-radius: 8px;
  
  .p-toolbar.p-component {
    border: unset;
    padding: 8px;
  }
`;

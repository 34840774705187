import React, {FC, PropsWithChildren} from "react";
import {StyledModal} from "./Modal.style";

interface ModalProps {
    header: string;
    footer: React.ReactElement;
    hideModal: () => void;
    isVisible: boolean;

    icons?: React.ReactNode;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = (props: PropsWithChildren<ModalProps>) => {
    return props.isVisible ? <StyledModal
            footer={props.footer}
            visible={props.isVisible}
            header={props.header}
            modal
            onHide={props.hideModal}>
            {props.children}
    </StyledModal> : <></>;
};

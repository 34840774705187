import {Day} from "../../common/service/util/Day";

export interface CashReportDTO {
    id?: string;
    summary: CashReportSummaryDTO;
    data: CashReportDataDTO[];
    status: CashReportStatus;
}

export enum CashReportStatus {
    OPEN = "OPEN",
    VALID = "VALID",
    CLOSED = "CLOSED"
}

export interface CashReportDataDTO {
    id?: string;
    proof?: string;
    date: string;
    amount: number;
    type: CashReportDataType;
    items: CashReportItemDTO[];
    hasFiles: boolean;
}

export interface CashReportSummaryDTO {
    cashAmount: number;
    incomeSum: number;
    numberOfIncomes: number;
    salariesSum: number;
    incomes: ProductIncomeSummaryDTO[];
}

export interface ProductIncomeSummaryDTO {
    productName: string;
    incomeSum: number;
    productsSold: number;
}

export const getInitialCashReportSummary = (): CashReportSummaryDTO => {
    return {
        cashAmount: 0,
        incomeSum: 0,
        numberOfIncomes: 0,
        incomes: [],
        salariesSum: 0
    }
}

export enum CashReportDataType {
    SALARIES = "SALARIES",
    BANK_DEPOSIT = "BANK_DEPOSIT",
    PAYMENTS = "PAYMENTS"
}

export interface CashReportItemDTO {
    id?: string;
    name: string;
    value: number;
    offerItemIds?: string[];
}

export interface BankDepositDTO {
    id?: string;
    amount: number;
    date: string;
}

export interface PaymentsDTO {
    id?: string;
    payments: PaymentDTO[];
    date: Day;
    proof: string;
    filesChanged: boolean;
}

export interface PaymentDTO {
    index: number;
    amount: number;
    offerItemIds: string[];
}

export interface SalariesDTO {
    salaries: EmployeeSalaryDTO[];
    date: string;
}

export interface EmployeeSalaryDTO {
    userId: string;
    salary: number;
}

import styled from "styled-components";

interface StyledButtonRowProps {
    alignRight: boolean;
}

export const StyledButtonRow = styled.div<StyledButtonRowProps>`
  text-align: ${(props) => props.alignRight ? "right" : "left"};
  width: 100%;
  
  button {
    margin-left: 3px;
    margin-right: 4px;
  }
`;

import {combineReducers, Reducer} from "redux";
import {AppActions} from "./AppActions";
import {userBasicInfoRootReducer, UserBasicInfoRootState} from "../security/redux/UserBasicInfoRootReducer";
import {growlReducer} from "../growl/redux/Growl.reducer";
import {ToastMessage} from "primereact/toast";
import {AppViewConfig} from "../components/appContainer/appLayout/appViewConfig/service/AppViewConfig.type";
import {
    appViewConfigReducer
} from "../components/appContainer/appLayout/appViewConfig/service/redux/AppViewConfig.reducer";

export type AppState = ReturnType<typeof rootReducer>;

const rootReducer: Reducer<RootState, AppActions> = combineReducers({
    userBasicInfo: userBasicInfoRootReducer,
    growlMessage: growlReducer,
    appViewConfig: appViewConfigReducer
});

export interface RootState {
    userBasicInfo: UserBasicInfoRootState,
    growlMessage: ToastMessage | ToastMessage[],
    appViewConfig: AppViewConfig
}

export default rootReducer;

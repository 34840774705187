import React, {FC, useEffect, useState} from "react";
import {UserCleaningReportDTO} from './service/CleaningUserReportPage.type';
import {Day} from '../../common/service/util/Day';
import {getCleaningUserReportRest} from './service/CleaningUserReportPage.service';
import {DayRange} from '../../common/service/util/DayRange';
import {CleaningUserReportToolbar} from './cleaningUserReportToolbar/CleaningUserReportToolbar.component';
import {ReportCard} from './reportCard/ReportCard.component';
import {SimpleTableColumnProps} from '../../common/components/simpleTable/service/SimpleTable.type';
import {StyledCleaningUserReportPage} from './CleaningUserReportPage.style';

interface CleaningUserReportPageProps {
}

export const CleaningUserReportPage: FC<CleaningUserReportPageProps> = (props: CleaningUserReportPageProps) => {
    const [cleaningUserReport, setCleaningUserReport] = useState<UserCleaningReportDTO[]>([]);
    const [dateFrom, setDateFrom] = useState<Day>(Day.today().addDays(-30));
    const [dateTo, setDateTo] = useState<Day>(Day.today());

    useEffect(() => {
        retrieveCleaningUserReport();
    }, []);

    const retrieveCleaningUserReport = async () => {
        const userCleaningReportDTO: UserCleaningReportDTO[] = await getCleaningUserReportRest(new DayRange(dateFrom, dateTo));
        setCleaningUserReport(userCleaningReportDTO);
    };

    const columnProps: SimpleTableColumnProps[] = [
        {field: 'cleaningEntryName', header: ''},
        {field: 'count', header: ''},
    ];

    return <StyledCleaningUserReportPage>
        <CleaningUserReportToolbar
            dayFrom={dateFrom}
            dayTo={dateTo}
            setDayFrom={setDateFrom}
            setDayTo={setDateTo}
            getReport={retrieveCleaningUserReport}
        />
        {cleaningUserReport.map(value =>
            <ReportCard title={value.userName} values={value.cleaningCounts} columnProps={columnProps}/>)}
    </StyledCleaningUserReportPage>;
};

import React, {FC, useEffect, useState} from "react";
import {CleaningItemReportToolbar} from './cleaningItemReportToolbar/CleaningItemReportToolbar.component';
import {Day} from '../../common/service/util/Day';
import {DayRange} from '../../common/service/util/DayRange';
import {SimpleTableColumnProps} from '../../common/components/simpleTable/service/SimpleTable.type';
import {ReportCard} from '../cleaningUserReportPage/reportCard/ReportCard.component';
import {CleaningEntryReportDTO} from './service/CleaningItemReportPage.type';
import {getCleaningEntryReportRest} from './service/CleaningItemReportPage.service';
import {StyledCleaningItemReportPage} from './CleaningItemReportPage.style';

interface CleaningItemReportPageProps {
}

export const CleaningItemReportPage: FC<CleaningItemReportPageProps> = (props: CleaningItemReportPageProps) => {
    const [cleaningItemReport, setCleaningItemReport] = useState<CleaningEntryReportDTO[]>([]);
    const [dateFrom, setDateFrom] = useState<Day>(Day.today().addDays(-30));
    const [dateTo, setDateTo] = useState<Day>(Day.today());

    useEffect(() => {
        retrieveCleaningItemReport();
    }, []);

    const retrieveCleaningItemReport = async () => {
        const itemCleaningReportDTO: CleaningEntryReportDTO[] = await getCleaningEntryReportRest(new DayRange(dateFrom, dateTo));
        setCleaningItemReport(itemCleaningReportDTO);
    };

    const columnProps: SimpleTableColumnProps[] = [
        {field: 'userName', header: ''},
        {field: 'count', header: ''},
    ];

    return <StyledCleaningItemReportPage>
        <CleaningItemReportToolbar
            dayFrom={dateFrom}
            dayTo={dateTo}
            setDayFrom={setDateFrom}
            setDayTo={setDateTo}
            getReport={retrieveCleaningItemReport}
        />
        {cleaningItemReport.map(value =>
            <ReportCard title={value.cleaningEntryName} values={value.cleaningEntryCounts} columnProps={columnProps}/>)}
    </StyledCleaningItemReportPage>;
};

export class Token {
    private readonly parsedJwtToken: any;

    constructor(private readonly tokenString: string) {
        this.parsedJwtToken = this.parseJwt(tokenString);
    }

    private parseJwt = (token: string) => {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        } catch (e) {
            return undefined;
        }
    }

    public getTokenString(): string {
        return this.tokenString;
    }

    isValid() {
        return this.parsedJwtToken?.iss === "https://accounts.google.com" && this.parsedJwtToken?.email_verified;
    }

    isExpired() {
        return new Date() >= this.getExpirationDate();
    }

    isCloseToExpire() {
        const currentDate = new Date();
        const timeDiffMinutes = Math.floor((this.getExpirationDate().getTime() - currentDate.getTime()) / (1000 * 60));
        return timeDiffMinutes <= 5;
    }

    private getExpirationDate = (): Date => {
        const expDate = new Date(0);
        expDate.setUTCSeconds(this.parsedJwtToken.exp);
        return expDate;
    }
}

import React, {FC, PropsWithChildren} from "react";
import {StyledInputLabel} from "./InputLabel.style";

interface InputLabelProps {
    label: string;
    className?: string;
    id: string;
    labelType?: LabelType;
    withSpanPadding?: boolean;
}

export enum LabelType {
    VERTICAL = "VERTICAL",
    HORIZONTAL = "HORIZONTAL",
    INLINE = "INLINE",
}

export const InputLabel: FC<PropsWithChildren<InputLabelProps>> = (props: PropsWithChildren<InputLabelProps>) => {
    const isHorizontal = props.labelType === LabelType.HORIZONTAL;
    const className = isHorizontal ? "field grid" : "field";
    return <StyledInputLabel withSpanPadding={props.withSpanPadding} labelType={props.labelType} className={"p-fluid" + " " + props.className}>
        <div className={className}>
            {(props.labelType !== LabelType.INLINE) && <label className={isHorizontal ? "col-6" : undefined} htmlFor={props.id}>{props.label}</label>}
            <div className={isHorizontal ? "col-6" : undefined}>{props.children}</div>
        </div>
    </StyledInputLabel>;
};

InputLabel.defaultProps = {
    labelType: LabelType.VERTICAL
}

import React, {FC} from "react";
import {ImportFileModal} from "../../common/modal/importFileModal/ImportFileModal.component";
import {importProofsRest} from "../service/CashReportPage.service";
import {isSuccess} from "../../common/service/result/Result.type";
import {translate} from "../../common/lang/service/Translate.service";

interface ImportProofsModalProps {
    hideModal: () => void;
    refreshReport: () => void;
    month: number;
    year: number;
}

export const ImportProofsModal: FC<ImportProofsModalProps> = (props: ImportProofsModalProps) => {
    const onSave = async (files: File[]) => {
        const result = await importProofsRest(props.month, props.year, files[0]);
        if (isSuccess(result)) {
            props.refreshReport();
            props.hideModal();
        }
    }

    return <ImportFileModal hideModal={props.hideModal} onSave={onSave} header={translate("IMPORT_PROOFS")}/>;
};

import {groupByKey, isDefined, toMap} from '../../../common/service/util/ObjectUtils';
import {ItemDictionaryDTO} from '../../itemDictionaryPage/service/ItemDictionaryPage.type';
import {WarehouseStateEntryDTO} from '../../inventoryPage/service/InventoryPage.type';
import {Item, Order, OrderItemDTO, SupplierOrderDTO} from './OrderPage.type';
import {SupplierDictionaryDTO} from '../../supplierDictionaryPage/service/SupplierDictionaryPage.type';

export class OrderPageMapper {
    private readonly itemsBySupplier: Map<string, ItemDictionaryDTO[]>;
    private readonly warehouseStateByItemId: Map<string, WarehouseStateEntryDTO>;
    private readonly itemsById: Map<string, ItemDictionaryDTO>;
    private readonly supplierDictionary: Map<string, SupplierDictionaryDTO>;
    private readonly supplierOrdersBySupplier: Map<string, SupplierOrderDTO>;
    private readonly supplierOrders: SupplierOrderDTO[];
    private readonly inEditMode: boolean;

    constructor(itemDictionary: ItemDictionaryDTO[],
                warehouseState: WarehouseStateEntryDTO[],
                supplierDictionary: SupplierDictionaryDTO[],
                supplierOrders: SupplierOrderDTO[],
                inEditMode: boolean) {
        this.itemsBySupplier = groupByKey(itemDictionary, 'defaultSupplierId');
        this.warehouseStateByItemId = toMap(warehouseState, 'itemId')
        this.itemsById = toMap(itemDictionary, 'id');
        this.supplierDictionary = toMap(supplierDictionary, 'id');
        this.supplierOrdersBySupplier = toMap(supplierOrders, 'supplierId');
        this.supplierOrders = supplierOrders;
        this.inEditMode = inEditMode;
    }

    public static getOrders(itemDictionary: ItemDictionaryDTO[],
                            warehouseState: WarehouseStateEntryDTO[],
                            supplierDictionary: SupplierDictionaryDTO[],
                            supplierOrders: SupplierOrderDTO[],
                            inEditMode: boolean): Order[] {
        return new OrderPageMapper(itemDictionary, warehouseState, supplierDictionary, supplierOrders, inEditMode).getOrders();
    }

    public getOrders(): Order[] {
        return [
            ...this.getStandardItems(),
            ...this.getAdditionalSuppliers()
        ];
    }

    private getStandardItems(): Order[] {
        const orders: Order[] = [];
        this.itemsBySupplier?.forEach((itemDictionaryDTOs: ItemDictionaryDTO[], supplierId: string) => {
            const supplierOrderDTO = this.supplierOrdersBySupplier.get(supplierId);
            const orderItemsMap: Map<string, OrderItemDTO> = toMap(supplierOrderDTO?.orderItems, 'itemId');
            const orderItems: OrderItemDTO[] = supplierOrderDTO?.orderItems ?? [];
            const itemIds: string[] = [
                ...itemDictionaryDTOs.map(itemDictionaryDTO => itemDictionaryDTO.id),
                ...orderItems.map(value => value.itemId)].getUniqueElements();
            const orderedItems: Item[] = this.getOrderedItems(itemIds, orderItemsMap);

            if (this.inEditMode || orderedItems.isNotEmpty()) {
                orders.push({
                    orderedItems: orderedItems,
                    sum: this.sumValues(orderedItems),
                    supplierId: supplierId,
                    supplierName: this.supplierDictionary.get(supplierId)?.name,
                    supplierType: this.supplierDictionary.get(supplierId)?.supplierType,
                    additionalCost: supplierOrderDTO?.additionalCost,
                    additionalCostDescription: supplierOrderDTO?.additionalCostDescription
                });
            }
        });

        return orders;
    }

    private getOrderedItems(itemIds: string[], orderItems: Map<string, OrderItemDTO>): Item[] {
        return itemIds.map(itemId => {
            const orderedCount = orderItems.get(itemId)?.count ?? null;
            const singleItemValue = orderItems.get(itemId)?.itemPrice ?? null;
            const itemDictionaryDTO: ItemDictionaryDTO = this.itemsById.get(itemId);

            const totalItemCost = (isDefined(orderedCount) && isDefined(singleItemValue))
                ? orderedCount * singleItemValue
                : undefined;
            return ({
                itemName: itemDictionaryDTO.name,
                itemId: itemDictionaryDTO.id,
                defaultSupplierId: itemDictionaryDTO.defaultSupplierId,
                url: itemDictionaryDTO.url,
                optimalCount: itemDictionaryDTO.optimalCount,
                minimalCount: itemDictionaryDTO.minimalCount,
                orderToCount: itemDictionaryDTO.orderToCount,
                currentCount: this.warehouseStateByItemId.get(itemId)?.count ?? 0,
                orderedCount: orderedCount,
                singleItemValue: singleItemValue,
                totalItemCost: totalItemCost
            });
        }).filter(value => this.inEditMode || (isDefined(value.orderedCount) && value.orderedCount > 0));
    }

    private getAdditionalSuppliers(): Order[] {
        const existingSupplierIds: string[] = Array.from(this.itemsBySupplier.keys());
        const orders = this.supplierOrders?.filter(supplierOrder => this.isAdditional(supplierOrder, existingSupplierIds)).map(value => {
            const itemIds: string[] = value.orderItems?.map(orderItem => orderItem?.itemId);
            const orderedItems: Item[] = this.getOrderedItems(itemIds, toMap(value?.orderItems, 'itemId'));
            return ({
                orderedItems: orderedItems,
                sum: this.sumValues(orderedItems),
                supplierId: value.supplierId,
                supplierName: this.supplierDictionary.get(value.supplierId)?.name,
                supplierType: this.supplierDictionary.get(value.supplierId)?.supplierType,
                additionalCost: value.additionalCost,
                additionalCostDescription: value.additionalCostDescription
            });
        });
        return orders ?? [];
    }

    private isAdditional = (supplierOrder: SupplierOrderDTO, existingSupplierIds: string[]): boolean => {
        return !existingSupplierIds.includes(supplierOrder.supplierId);
    }

    private sumValues(orderedItems: Item[]): number {
        return orderedItems
            .filter(value => isDefined(value.totalItemCost) && value.totalItemCost > 0)
            .map(value => value.totalItemCost)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    }
}

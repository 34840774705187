import {DictionaryDTO} from '../../../common/service/util/dictionary/Dictionary.type';

export interface ItemDictionaryDTO {
    id: string;
    name: string;
    defaultSupplierId: string;
    url: string;
    category: string;
    subCategory: string;
    optimalCount: number;
    minimalCount: number;
    orderToCount: number;
    location: string;
}

export interface ItemCategoryDTO {
    id: string;
    name: string;
    subCategories: DictionaryDTO[];
}

export const getInitialItemDictionaryDTO = (): ItemDictionaryDTO => ({
    id: undefined,
    name: undefined,
    defaultSupplierId: undefined,
    url: undefined,
    category: undefined,
    subCategory: undefined,
    optimalCount: undefined,
    minimalCount: undefined,
    orderToCount: undefined,
    location: undefined,
})

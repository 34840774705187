import {CommandResult} from "../result/Result.type";

export const httpRequestStarted: string = "httpRequestStarted";
export const httpRequestEnded: string = "httpRequestEnded";

export const httpRequestError: string = "httpRequestError";
export const httpRequestSuccess: string = "httpRequestSuccess";

export interface HttpEvent {
    url: string;
}

export interface RestResponse {
    code: string;
    details: string;
}

export const getHttpRequestStartedEvent = (url: string): CustomEvent<HttpEvent> => {
    const httpEvent: HttpEvent = {
        url
    };
    return new CustomEvent(httpRequestStarted, {detail: httpEvent});
};

export const getHttpRequestEndedEvent = (url: string): CustomEvent<HttpEvent> => {
    const httpEvent: HttpEvent = {
        url
    };
    return new CustomEvent(httpRequestEnded, {detail: httpEvent});
};

export const getHttpCommandResultEvent = (result: CommandResult): CustomEvent<CommandResult> => {
    return new CustomEvent(httpRequestSuccess, {detail: result});
};

export const getHttpRequestErrorEvent = (restResponse: RestResponse): CustomEvent<RestResponse> => {
    return new CustomEvent(httpRequestError, {detail: restResponse});
};

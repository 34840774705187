import {isDefinedAndNotEmpty} from "../../../../../service/util/StringUtils";

export interface AppViewConfig {
    componentTheme: string;
    theme: string;
    colorScheme: string;
    menuMode: string;
    ripple: boolean;
}

export const getInitialAppViewConfig = (): AppViewConfig => {
    const savedViewConfig: string = window.localStorage.getItem("app-view-config");
    if (isDefinedAndNotEmpty(savedViewConfig)) {
        return JSON.parse(savedViewConfig);
    }

    return {
        colorScheme: 'dark',
        componentTheme: 'blue',
        menuMode: 'sidebar',
        theme: 'blue',
        ripple: true
    }
}

export interface AppViewConfigChange {
    field: string;
    value: any;
}

export interface CalendarColor {
    colorId: string;
    color: string;
    name: string;
}

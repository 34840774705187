import React, {FC} from "react";
import {Card} from "../../../common/components/card/Card.component";
import {Toolbar} from "primereact/toolbar";
import {DayRange} from "../../../common/service/util/DayRange";
import {Day} from "../../../common/service/util/Day";
import {PeriodChanger} from "../../../common/components/periodChanger/PeriodChanger.component";
import {getDailyPeriodChange} from "../../../common/components/periodChanger/service/PeriodChangeStrategy";
import {getDailyChangerDisplay} from "../../../common/components/periodChanger/service/PeriodChangerDisplay";
import {Button} from "primereact/button";
import {translate} from "../../../common/lang/service/Translate.service";
import {Month} from "../../../common/service/util/Month";

interface CashReportToolbarProps {
    onDayChange: (newDay: Day) => void;
    onEdit: () => void;
    cleaningAdded: boolean
}

export const CleaningToolbar: FC<CashReportToolbarProps> = (props: CashReportToolbarProps) => {
    const rightToolbarTemplate = () => {
        return props.cleaningAdded
            ? <></>
            : <Button label={translate("ADD")} onClick={props.onEdit} icon={"pi pi-plus"}/>;
    };

    const leftToolbarPanel = () => {
        const startPeriod = new DayRange(Day.today(), Day.today());
        return (
            <PeriodChanger
                startPeriod={startPeriod}
                onNextPeriod={(range: DayRange) => props.onDayChange(range.getMiddleDay())}
                onPreviousPeriod={(range: DayRange) => props.onDayChange(range.getMiddleDay())}
                dayInLastPeriod={Day.today()}
                dayInFirstPeriod={Day.from(2023, Month.AUGUST, 1)}
                changePeriod={getDailyPeriodChange()}
                display={getDailyChangerDisplay()}
            />
        );
    }

    return <div className={"col-12"}>
        <Card>
            <Toolbar
                start={leftToolbarPanel}
                end={rightToolbarTemplate}
            />
        </Card>
    </div>;
};

import styled from "styled-components";

export const StyledUserProfilePersonalDataTab = styled.div`
  .fields {
    @media screen and (min-width: 430px) {
      display: flex;
    }
  }

  .personal-data-field {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

import React, {FC, useEffect, useState} from "react";
import {StyledUserProfileMenu} from "./UserProfileMenu.style";
import {UserProfilePagePermissionsDTO} from "../service/UserProfilePage.type";
import {TabMenu} from 'primereact/tabmenu';
import {useNavigate, useParams} from "react-router-dom";
import {translate} from "../../common/lang/service/Translate.service";

interface UserProfileMenuProps {
    permissions: UserProfilePagePermissionsDTO;
    id: string;
}

export const UserProfileMenu: FC<UserProfileMenuProps> = (props: UserProfileMenuProps) => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(undefined);
    const urlParams = useParams();

    useEffect(() => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].urlName === urlParams['*']) {
                setActiveIndex(i);
            }
        }
    }, []);

    const items = [];
    if (props.permissions.canViewPersonalData) {
        items.push({
            label: translate('PERSONAL_DATA'),
            icon: 'pi pi-fw pi-user',
            urlName: 'personalData',
            command: () => navigate(`/user/${props.id}/profile/personalData`)
        });
    }

    if (props.permissions.canViewContractInformation) {
        items.push({
            label: translate('CONTRACT_INFORMATION'),
            icon: 'fa-solid fa-handshake',
            urlName: 'contractInformation',
            command: () => navigate(`/user/${props.id}/profile/contractInformation`)
        });
    }

    return <StyledUserProfileMenu>
        <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>
    </StyledUserProfileMenu>;
};

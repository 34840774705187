import React, {FC, useState} from "react";
import {AppointmentDTO, AppointmentPaymentType, ConfirmAppointmentDTO} from "../../service/Appointments.type";
import {confirmAppointmentRest} from "../../service/Appointments.service";
import {isSuccess} from "../../../common/service/result/Result.type";
import {ActionModal} from "../../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../../common/lang/service/Translate.service";
import {CurrencyInput} from "../../../common/components/input/currencyInput/CurrencyInput.component";
import {LabelType} from "../../../common/components/input/inputLabel/InputLabel.component";
import {DateInput} from "../../../common/components/input/dateInput/DateInput.component";
import {Day} from "../../../common/service/util/Day";
import {SingleSelectInput} from "../../../common/components/input/singleSelectInput/SingleSelectInput.component";
import {getEnumSelectOptions} from "../../../common/components/input/multiselectChips/service/SelectOption.type";
import {Checkbox} from "../../../common/components/input/checkbox/Checkbox.component";
import {StyledConfirmAppointmentModal} from "./ConfirmAppointmentModal.style";
import {isDefined, isNotDefined} from "../../../common/service/util/ObjectUtils";
import {useParams} from "react-router-dom";

interface ConfirmAppointmentModalProps {
    hideModal: () => void;
    refreshAppointments: () => void;
    appointment: AppointmentDTO;
}

export const ConfirmAppointmentModal: FC<ConfirmAppointmentModalProps> = (props: ConfirmAppointmentModalProps) => {
    const initialState: ConfirmAppointmentDTO = {
        depositPaymentDate: props.appointment.deposit?.paymentDate ?? Day.today().dateToUrlString(),
        depositAmount: props.appointment.deposit?.amount,
        depositPaymentType: props.appointment.deposit?.paymentType ?? AppointmentPaymentType.BANK_TRANSFER,
        withRecipe: props.appointment.deposit?.withRecipe
    };

    const [appointment, setAppointment] = useState<ConfirmAppointmentDTO>(initialState);

    const { userId } = useParams();
    const onSave = async () => {
        const result = await confirmAppointmentRest(props.appointment.id, appointment, userId);
        if (isSuccess(result)) {
            props.hideModal();
            props.refreshAppointments();
        }
    }

    const setValue = (fieldName: string, newValue: any) => {
        setAppointment(prevState => ({...prevState, [fieldName]: newValue}));
    }

    const isValid = (): boolean => {
        return isNotDefined(appointment.depositAmount) || appointment.depositAmount === 0
            || (isDefined(appointment.depositAmount)
                && appointment.depositAmount > 0
                && isDefined(appointment.depositPaymentDate)
                && isDefined(appointment.depositPaymentType));
    }

    return <ActionModal
        header={translate("CONFIRM_APPOINTMENT")}
        onConfirm={onSave}
        onReject={props.hideModal}
        isVisible={true}
        isSaveDisabled={!isValid()}>
        <StyledConfirmAppointmentModal className="formgrid grid">
            <CurrencyInput
                label={translate("DEPOSIT_AMOUNT")}
                value={appointment.depositAmount}
                setValue={depositAmount => setValue("depositAmount", depositAmount)}
                labelType={LabelType.VERTICAL}
                className={"col-6 sm:col-6"}
            />
            <DateInput
                label={translate("DATE")}
                value={Day.fromString(appointment.depositPaymentDate)}
                setValue={depositPaymentDate => setValue("depositPaymentDate", depositPaymentDate)}
                labelType={LabelType.VERTICAL}
                className={"col-6 sm:col-6"}
            />
            <SingleSelectInput
                options={getEnumSelectOptions(AppointmentPaymentType)}
                selectedValue={appointment.depositPaymentType}
                setSelectedValue={depositPaymentType => setValue("depositPaymentType", depositPaymentType)}
                label={translate("PAYMENT_TYPE")}
                className={"col-6 sm:col-6"}
                showClear={true}
            />
            <div className={"col-6 sm:col-6"}>
                <Checkbox
                    value={appointment.withRecipe}
                    setValue={withRecipe => setValue("withRecipe", withRecipe)}
                    label={translate("WITH_RECIPE")}
                    className={"checkbox-padding"}
                />
            </div>
        </StyledConfirmAppointmentModal>
    </ActionModal>;
};

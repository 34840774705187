import {CleaningDTO, UpdateCleaningEntryDTO} from "./CleaningPage.types";
import {_delete, get, post} from "../../../common/service/http/Http.service";
import {UriBuilder} from "../../../common/service/util/UriBuilder.class";
import {Day} from "../../../common/service/util/Day";
import {CommandResult} from "../../../common/service/result/Result.type";

export const getCleaningRest = (date: Day): Promise<CleaningDTO> => {
    return get<CleaningDTO>(new UriBuilder()
        .one("cleaning", date.toFormattedString())
        .build());
}

export const updateCleaningEntryRest = (updateCleaningEntryDTO: UpdateCleaningEntryDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
            .all("cleaning")
            .all("update")
            .build(),
        updateCleaningEntryDTO);
}

export const deleteCleaningEntryRest = (cleaningId: string): Promise<CommandResult> => {
    return _delete<CommandResult>(new UriBuilder()
        .one("cleaning", cleaningId)
        .all("delete")
        .build());
}

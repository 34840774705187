import styled from "styled-components";

export const StyledPeriodChanger = styled.div`
  font-size: 18px;
  display: flex;
  
  .arrow-container {
    height: 40px;
    width: 30px;
    text-align: center;
    line-height: 40px;
  }
  
  i {
    font-size: 24px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }
  
  .display {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
  }
`;

import {FilterStrategy} from "./FilterStrategy";
import {getFieldValuesFromObject, isDefined} from "../ObjectUtils";

export class DefaultFilterStrategy implements FilterStrategy {
    constructor(private filterField: string, private customFieldPathsObject: any = []) {
    }

    filter(objectList: any[], valueToFilter: any[]): any[] {
        return isDefined(valueToFilter) && valueToFilter.length > 0 ? objectList.filter(object =>
            getFieldValuesFromObject(object, this.filterField, this.customFieldPathsObject[this.filterField])
                .some((value: any) => valueToFilter.includes(value))) : objectList;
    };
}

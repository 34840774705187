import React, {FC, useState} from "react";
import {ActionModal} from "../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../common/lang/service/Translate.service";
import {BankDepositDTO, CashReportDataDTO} from "../service/CashReportPage.type";
import {CurrencyInput} from "../../common/components/input/currencyInput/CurrencyInput.component";
import {DateInput} from "../../common/components/input/dateInput/DateInput.component";
import {Day} from "../../common/service/util/Day";
import {DayRange} from "../../common/service/util/DayRange";
import {isDefined, isNotDefined} from "../../common/service/util/ObjectUtils";

interface BankDepositModalProps {
    data: CashReportDataDTO;
    saveBankDeposit: (cashDeposit: BankDepositDTO) => void;
    hideModal: () => void;
    currentPeriod: DayRange;
}

export const BankDepositModal: FC<BankDepositModalProps> = (props: BankDepositModalProps) => {
    const getInitialDate = () => {
        if (isDefined(props.data?.date)) {
            return Day.fromString(props.data?.date);
        }
        return props.currentPeriod.isCurrent() ? Day.today() : undefined
    }

    const [date, setDate] = useState<Day>(getInitialDate());
    const [amount, setAmount] = useState<number>(props.data?.amount);
    const saveBankDeposit = () => {
        props.saveBankDeposit({date: date.dateToUrlString(), amount: amount});
        props.hideModal();
    }

    const isInvalid = (): boolean => {
        return isNotDefined(amount) || isNotDefined(date);
    }

    return <ActionModal
        header={translate("BANK_DEPOSIT")}
        onConfirm={saveBankDeposit}
        onReject={props.hideModal}
        isVisible={true}
        isSaveDisabled={isInvalid()}
    >
        <div className="formgrid grid">
            <CurrencyInput required={true}
                           value={amount} setValue={setAmount} label={translate("AMOUNT")} className={"col"}/>
            <DateInput
                value={date}
                setValue={setDate}
                label={translate("DATE")}
                className={"col"}
                minValue={props.currentPeriod.getDayFrom()}
                maxValue={props.currentPeriod.getDayTo()}
                viewDate={props.currentPeriod.getMiddleDay()}
            />
        </div>
    </ActionModal>;
};

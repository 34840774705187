import {UserBasicInfoDTO} from "./UserBasicInfo.type";
import {get} from "../../../service/http/Http.service";
import {UriBuilder} from "../../../service/util/UriBuilder.class";

export const getCurrentUserBasicInfoRest = (): Promise<UserBasicInfoDTO> => {
    return get<UserBasicInfoDTO>(new UriBuilder()
        .all("user")
        .all("current")
        .build());
}

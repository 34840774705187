import React, {FC, useEffect, useState} from "react";
import {BankTransfersSummaryDTO} from "../service/BankTransfers.type";
import {translate} from "../../common/lang/service/Translate.service";
import {getTransfersSummaryRest} from "../service/BankTransfers.service";
import {formatAsCurrency} from "../../common/service/util/CurrencyUtil";
import {Simulate} from "react-dom/test-utils";
import {SimpleTable} from "../../common/components/simpleTable/SimpleTable.component";
import {SimpleTableColumnProps} from "../../common/components/simpleTable/service/SimpleTable.type";

interface TransfersSummaryProps {
    month: number;
    year: number;
}

export const TransfersSummary: FC<TransfersSummaryProps> = (props: TransfersSummaryProps) => {
    const [transfersSummary, setTransfersSummary] = useState<BankTransfersSummaryDTO[]>([]);

    useEffect(() => {
        fetch();
    }, [props.year, props.month]);

    async function fetch() {
        const transfersSummaryDTOs = await getTransfersSummaryRest(props.month, props.year);
        setTransfersSummary(transfersSummaryDTOs);
    }

    const total = transfersSummary.map(value => value.amount * value.count).reduce((prevValue, currentValue) => (prevValue + currentValue), 0);
    const columnProps: SimpleTableColumnProps[] = [
        {
            header: "",
            field: "bankTransferType",
            transformValue: ((bankTransfersSummary: BankTransfersSummaryDTO) => (translate(bankTransfersSummary.bankTransferType)))
        },
        {
            header: "",
            field: "amount",
            transformValue: ((bankTransfersSummary: BankTransfersSummaryDTO) => (formatAsCurrency(bankTransfersSummary.amount)))
        },
        {
            header: "",
            field: "count"
        }
    ];
    const getSummaryProps = () => ({
        header: translate("TOTAL"),
        value: formatAsCurrency(total)
    });
    return <>
        <SimpleTable
            values={transfersSummary}
            columnProps={columnProps}
            summary={getSummaryProps()}
        />
    </>;
};

import {ToastMessage} from "primereact/toast";

export const SHOW_GROWL = "SHOW_GROWL";

export interface ShowGrowlAction {
    type: typeof SHOW_GROWL;
    message: ToastMessage | ToastMessage[];
}

export type GrowlActionTypes = ShowGrowlAction;

import React, {FC, useEffect, useState} from "react";
import {StyledSupplierDictionaryPage} from './SupplierDictionaryPage.style';
import {Card} from '../../common/components/card/Card.component';
import {translate} from '../../common/lang/service/Translate.service';
import {ButtonRow} from '../../common/components/buttonRow/ButtonRow.component';
import {Button} from 'primereact/button';
import {SupplierDictionaryTable} from './supplierDictionaryTable/SupplierDictionaryTable.component';
import {CommandResult, isSuccess} from '../../common/service/result/Result.type';
import {isDefined} from '../../common/service/util/ObjectUtils';
import {getInitialSupplierDictionaryDTO, SupplierDictionaryDTO} from './service/SupplierDictionaryPage.type';
import {addSupplierRest, getSuppliersRest, updateSupplierRest} from './service/SupplierDictionaryPage.service';
import {SupplierDictionaryModal} from './supplierDictionaryModal/SupplierDictionaryModal.component';

interface SupplierDictionaryPageProps {
}

export const SupplierDictionaryPage: FC<SupplierDictionaryPageProps> = (props: SupplierDictionaryPageProps) => {
    const [supplierDictionary, setSupplierDictionary] = useState<SupplierDictionaryDTO[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [editedEntry, setEditedEntry] = useState<SupplierDictionaryDTO>(undefined);

    useEffect(() => {
        retrieveSupplierDictionary();
    }, []);

    async function retrieveSupplierDictionary() {
        const supplierDictionaryDTOs: SupplierDictionaryDTO[] = await getSuppliersRest();
        setSupplierDictionary(supplierDictionaryDTOs);
    }

    const onEdit = (supplierDictionaryEntry: SupplierDictionaryDTO) => {
        setEditedEntry(supplierDictionaryEntry);
        setModalVisible(true);
    }

    const onAdd = () => {
        setEditedEntry(getInitialSupplierDictionaryDTO());
        setModalVisible(true);
    }

    const onSave = async (entry: SupplierDictionaryDTO) => {
        const result: CommandResult = isDefined(entry.id)
            ? await updateSupplierRest(entry.id, entry)
            : await addSupplierRest(entry);

        if (isSuccess(result)) {
            await retrieveSupplierDictionary();
            hideModal();
        }
    }

    const hideModal = () => {
        setEditedEntry(getInitialSupplierDictionaryDTO());
        setModalVisible(false);
    }

    return <StyledSupplierDictionaryPage>
        <Card label={translate("SUPPLIER_DICTIONARY")}>
            <SupplierDictionaryTable
                supplierDictionary={supplierDictionary}
                onEdit={onEdit}
            />
            <ButtonRow alignRight={true} className={'add-button'}>
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" onClick={onAdd}></Button>
            </ButtonRow>
        </Card>

        {modalVisible && <SupplierDictionaryModal
            initialEntry={editedEntry}
            onSave={onSave}
            hideModal={hideModal}
        />}
    </StyledSupplierDictionaryPage>;
};

import {FilterFactory} from "./FilterFactory";

export function getFilteredList(objectList: any[], selectedFilters: any, filterFactory: FilterFactory): any[] {
    let filteredList = [...objectList];
    Object.keys(selectedFilters).forEach(filterKey => {
        const filterStrategy = filterFactory.getFilterStrategy(filterKey);
        filteredList = filterStrategy.filter(filteredList, selectedFilters[filterKey]);
    });
    return filteredList;
}

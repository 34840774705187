/**
 * @param labelWithoutDiacritics value for filtering without diacritics
 */
import {translate} from "../../../../lang/service/Translate.service";
import {compareStrings, removeDiacritics} from "../../../../service/util/StringUtils";
import {DictionaryDTO} from "../../../../service/util/dictionary/Dictionary.type";

export default interface SelectOption {
    value: string | number | boolean;
    label: string;
    labelWithoutDiacritics?: string;
    uniqueId?: string;
};

export function getSelectBoolean(): SelectOption[] {
    return [
        {
            value: true,
            label: "Yes"
        },
        {
            value: false,
            label: "No"
        }
    ];
}

export function getSelectNullableBoolean(): SelectOption[] {
    return [
        {
            value: undefined,
            label: translate("BLANK")
        },
        {
            value: true,
            label: translate("YES")
        },
        {
            value: false,
            label: translate("NO")
        }
    ];
}

export function getSelectOption(array: string[], sorted: boolean = true): SelectOption[] {
    const selectOptions = array.map(element => {
        return {
            value: element,
            label: element
        };
    });
    return sorted ? selectOptions.sort(compareSelectOptions) : selectOptions;
}

export function getNumbersSelectOptions(array: number[], sorted: boolean = true): SelectOption[] {
    const selectOptions = array.map(element => {
        return {
            value: element,
            label: element.toString()
        };
    });
    return sorted ? selectOptions.sort(compareNumberSelectOptions) : selectOptions;
}

export function getSelectPercentage(array: string[], sorted: boolean = true): SelectOption[] {
    const selectOptions = array.map(element => {
        return {
            value: element,
            label: element + "%"
        };
    });
    return sorted ? selectOptions.sort(compareNumberSelectOptions) : selectOptions;
}

const compareNumberSelectOptions = (a: SelectOption, b: SelectOption): number => {
    return (a.value as number) - (b.value as number);
};

export function getSelectRange(ranges: Range[]) {
    return ranges.map(range => {
        return {
            label: range.toString(),
            value: range
        };
    });
}

export function getSelectTranslate(array: string[], sorted: boolean = true): SelectOption[] {
    const selectOptions: SelectOption[] = array.map(element => {
        return {
            label: translate(element),
            value: element
        };
    });
    return sorted ? selectOptions.sort(compareSelectOptions) : selectOptions;
}

export function getSelectPrefixedTranslate(array: string[], prefix: string, sorted: boolean = true): SelectOption[] {
    const selectOptions: SelectOption[] = array.map(element => {
        return {
            label: translate(prefix + element),
            value: element
        };
    });
    return sorted ? selectOptions.sort(compareSelectOptions) : selectOptions;
}

export const compareSelectOptions = (a: SelectOption, b: SelectOption): number => {
    return compareStrings(a.label, b.label);
};

export function getEmptySelectOption(): SelectOption {
    return {label: ' ', value: null};
}

export function getDictionarySelectOption(dictionary: DictionaryDTO[], sorted: boolean = true, shouldTranslate: boolean = false): SelectOption[] {
    const selectOptions = dictionary?.map(element => {
        return {
            value: element.id,
            label: shouldTranslate ? translate(element.name) : element.name,
            labelWithoutDiacritics: shouldTranslate ? removeDiacritics(translate(element.name)) : removeDiacritics(element.name)
        };
    });
    return sorted ? selectOptions?.sort(compareSelectOptions) : selectOptions;
}

export function getEnumSelectOptions(enumClass: any) {
    const keys = Object.keys(enumClass);
    const selectOptions: SelectOption[] = keys.map(key => ({
        label: translate(key),
        value: enumClass[key as any]
    }));
    return selectOptions;
}

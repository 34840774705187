import React, {FC, useState} from "react";
import {Button} from "primereact/button";
import {translate} from "../../lang/service/Translate.service";
import {Modal} from "../Modal.component";
import {isDefined} from "../../service/util/ObjectUtils";
import {FileInput} from "../../components/fileInput/FileInput.component";

interface ImportFileModalProps {
    hideModal: () => void;
    onSave: (files: File[]) => void;
    header?: string;
}

export const ImportFileModal: FC<ImportFileModalProps> = (props: ImportFileModalProps) => {
    const [files, setFiles] = useState<File[]>([]);

    const footer = <>
        <Button
            label={translate("SAVE")}
            className="p-button-outlined p-button-success"
            icon={"pi pi-check"}
            onClick={() => props.onSave(files)}
            disabled={files.length <= 0}
        />
        <Button
            label={translate("CANCEL")}
            className="p-button-danger p-button-text"
            icon={"pi pi-times"}
            onClick={props.hideModal}
        />
    </>;


    const onDrop = (e: any) => {
        e.preventDefault();
        const files: File[] = [...e.dataTransfer?.items].map(value => value.getAsFile());
        setFiles(files);
    }

    return <div onDragOver={e => e.preventDefault()} onDrop={onDrop}>
        <Modal
            footer={footer}
            header={isDefined(props.header) ? props.header : translate("IMPORT")}
            hideModal={props.hideModal}
            isVisible={true}
        >
            <div className="flex align-items-center justify-content-center">
                <FileInput
                    className={"file-input"}
                    onSelect={files => setFiles(files)}
                    files={files}
                    maxFileSize={10000}
                    multiple={true}
                />
            </div>
        </Modal>
    </div>;
};

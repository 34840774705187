import styled from "styled-components";

export const StyledBankTransfersPage = styled.div`
  .select-button-container {
    padding-bottom: 20px;
  }

  .summary-row {
    justify-content: space-between;
    width: 100%;
    display: flex;
    line-height: 32px;
  }
  
  .select-button-container.p-selectbutton.p-buttonset.p-component {
    div {
      width: 50%;
    }
  }
`;

export const loadScript = (onScriptLoadSuccess, onScriptLoadError) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.onload = () => {
        onScriptLoadSuccess();
    };
    scriptTag.onerror = () => {
        onScriptLoadError();
    };
    document.body.appendChild(scriptTag);
}

export const googleInitialize = (clientId) => {
    /* global google */
    google.accounts.id.initialize({
        client_id: clientId
    })
}

export function googleOauthSignIn() {
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    const form = document.createElement('form');
    form.setAttribute('method', 'GET');
    form.setAttribute('action', oauth2Endpoint);

    const params = {
        'client_id': process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
        'redirect_uri': process.env.REACT_APP_STAPP_UI_URL + '/login',
        'response_type': 'code',
        'scope': 'openid email profile',
        'include_granted_scopes': 'true',
        'state': 'pass-through value',
        'access_type': 'offline'
    };

    for (let p in params) {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
}

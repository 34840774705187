import {Day} from "../../common/service/util/Day";
import {ClockTime} from "../../common/service/util/ClockTime";

export interface AppointmentsDTO {
    id: string;
    appointments: AppointmentDTO[];
    summary: AppointmentsSummaryDTO;
    status: UserMonthlyAppointmentsStatus;
}

export enum UserMonthlyAppointmentsStatus {
    TO_CONFIRM = "TO_CONFIRM",
    CONFIRMED = "CONFIRMED",
}

export interface AppointmentDTO {
    id: string;
    status: AppointmentStatus;
    type: AppointmentType;

    day: string;
    startTime: string;
    endTime?: string;

    totalAmount: number;
    withRecipe?: boolean;
    paymentType?: AppointmentPaymentType;
    client: ClientDTO;

    hasDeposit: boolean;
    deposit?: DepositDTO;
    cancellationReason?: string;

    description: string;
}

export enum AppointmentPaymentType {
    CASH = "CASH",
    BANK_TRANSFER = "BANK_TRANSFER",
    CARD = "CARD",
    VOUCHER = "VOUCHER",
}

export enum AppointmentType {
    TATTOO = "TATTOO",
    PIERCING = "PIERCING",
    TOOTH_GEMS = "TOOTH_GEMS",
    DERMALS = "DERMALS",
}

export enum AppointmentStatus {
    TO_CONFIRM = "TO_CONFIRM",
    CONFIRMED = "CONFIRMED",
    CANCELED = "CANCELED",
    FINISHED = "FINISHED"
}

export interface ClientDTO {
    firstName?: string;
    lastName?: string;
    email?: string;
    instagram?: string;
    phoneNumber?: string;
}

export interface DepositDTO {
    amount?: number;
    status?: DepositStatus;
    withRecipe?: boolean;
    paymentDate?: string;
    paymentType?: AppointmentPaymentType;
    returnDate?: string;
}

export enum DepositStatus {
    TO_BE_PAID = "TO_BE_PAID",
    PAID = "PAID",
    TO_RETURN = "TO_RETURN",
    RETURNED = "RETURNED",
    CANCELED = "CANCELED",
}

export interface AppointmentsSummaryDTO {
    totalMonthIncome: number;
    totalMonthAppointmentsNumber: number;

    alreadyEarned: number;
    alreadyFinishedAppointmentsNumber: number;

    predictedIncome: number;
    predictedAppointmentsNumber: number;

    cashForCompany: number;
}

export interface NewAppointmentDTO {
    type?: AppointmentType;
    day?: Day;
    startTime?: ClockTime;
    endTime?: ClockTime;
    totalAmount?: number;
    depositValue?: number;
    client?: ClientDTO;
    description?: string;
}

export interface CancelAppointmentDTO {
    cancellationReason?: string;
    returnDeposit?: boolean;
}

export interface ConfirmAppointmentDTO {
    depositPaymentDate?: string;
    depositAmount?: number;
    depositPaymentType?: AppointmentPaymentType;
    withRecipe?: boolean;
}

export interface EditAppointmentDTO {
    day?: string;
    startTime?: string;
    endTime?: string;
    totalAmount?: number;
    withRecipe?: boolean;
    paymentType?: AppointmentPaymentType;
    client?: ClientDTO;
    deposit?: DepositDTO;
    description?: string;
}

export interface FinishAppointmentDTO {
    duration?: ClockTime;
    priceWithoutDeposit?: number;
    paymentType?: AppointmentPaymentType;
    withRecipe?: boolean;
}

import React, {FC, useState} from "react";
import {Calendar} from "primereact/calendar";
import {Day} from "../../../../../service/util/Day";
import {CalendarEventDTO} from "../service/Calendar.types";

interface RightPanelCalendarProps {
    day: Day;
    setDay: (day: Day) => void;
    userMonthEvents: CalendarEventDTO[];
    onMonthChanged: (day: Day) => void;
}

export const RightPanelCalendar: FC<RightPanelCalendarProps> = (props: RightPanelCalendarProps) => {
    const [viewDate, setViewDate] = useState(props.day);

    const dateTemplate = (date) => {
        const day: Day = Day.from(date.year, date.month, date.day)

        if (userHasEvent(day)) {
            return (
                <strong style={{color: '#0BD18A'}}>{date.day}</strong>
            );
        }

        return date.day;
    }

    function userHasEvent(day: Day): boolean {
        return props.userMonthEvents.map(value => Day.fromString(value.date)).some(eventDay => eventDay.equals(day));
    }


    return <div>
        <Calendar
            onViewDateChange={(event) => {
                props.onMonthChanged(Day.fromDate(event.value as Date))
                setViewDate(Day.fromDate(event.value as Date))
            }}
            viewDate={viewDate?.getDate()}
            value={props.day?.getDate()}
            onChange={(e) => props.setDay(Day.fromDate(e.value as Date))}
            inline
            dateTemplate={dateTemplate}
        />
    </div>;
};

import React, {FC, useEffect, useState} from "react";
import {StyledBankTransfersPage} from "./BankTransfersPage.style";
import {BankTransfersToolbar} from "./bankTransfersToolbar/BankTransfersToolbar.component";
import {DayRange} from "../common/service/util/DayRange";
import {Day} from "../common/service/util/Day";
import {ImportTransfersModal} from "./importTransfersModal/ImportTransfersModal.component";
import {
    getAssignedTransfersRest,
    getTransfersToAssignRest,
    transfersRegisteredRest,
    updateTransferTypeRest
} from "./service/BankTransfers.service";
import {SelectButton} from "primereact/selectbutton";
import {translate} from "../common/lang/service/Translate.service";
import {AssignedTransfers} from "./assignedTransfers/AssignedTransfers.component";
import {TransfersToAssign} from "./transfersToAssign/TransfersToAssign.component";
import {TransfersSummary} from "./transfersSummary/TransfersSummary.component";
import {BankTransferDetailsDTO, BankTransferType} from "./service/BankTransfers.type";
import {isSuccess} from "../common/service/result/Result.type";
import {Card} from "../common/components/card/Card.component";
import {BankTransferKeywordsModal} from "./bankTransferKeywordsModal/BankTransferKeywordsModal.component";

interface BankTransfersPageProps {
}

export const BankTransfersPage: FC<BankTransfersPageProps> = (props: BankTransfersPageProps) => {
    const [importTransfersModalVisible, setImportTransfersModalVisible] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useState<DayRange>(new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay()));
    const [transfersToAssign, setTransfersToAssign] = useState<BankTransferDetailsDTO[]>([]);
    const [assignedTransfers, setAssignedTransfers] = useState<BankTransferDetailsDTO[]>([]);
    const [tableType, setTableType] = useState(transfersToAssign.isEmpty() ? "SUMMARY" : "TO_ASSIGN");
    const [keywordsModalVisible, setKeywordsModalVisible] = useState(false);

    const onPeriodChange = (period: DayRange) => {
        retrieveData(period);
        setCurrentPeriod(period);
    }

    useEffect(() => {
        retrieveData(currentPeriod);
    }, []);

    async function retrieveData(period: DayRange) {
        const transfersToAssignDTOs = await getTransfersToAssignRest(period.getMiddleDay().getMonthNumber(), period.getMiddleDay().getFullYear());
        setTransfersToAssign(transfersToAssignDTOs);
        const assignedTransfersDTOs = await getAssignedTransfersRest(period.getMiddleDay().getMonthNumber(), period.getMiddleDay().getFullYear());
        setAssignedTransfers(assignedTransfersDTOs);

        setTableType(transfersToAssignDTOs.isEmpty() ? "SUMMARY" : "TO_ASSIGN")
    }

    const getMonth = (): number => {
        return currentPeriod.getMiddleDay().getMonthNumber();
    }

    const getYear = (): number => {
        return currentPeriod.getMiddleDay().getFullYear();
    }

    const getTables = () => {
        if (transfersToAssign.isEmpty() && assignedTransfers.isEmpty()) {
            return [];
        }

        if (transfersToAssign.isEmpty()) {
            return [
                {value: "SUMMARY", label: translate("SUMMARY")},
                {value: "ASSIGNED", label: translate("TRANSFERS")}
            ];
        }

        return [
            {value: "TO_ASSIGN", label: translate("TRANSFERS_TO_ASSIGN")},
            {value: "ASSIGNED", label: translate("ASSIGNED_TRANSFERS")}
        ];
    }

    const updateTransferType = (id: string, transferType: BankTransferType) => {
        updateTransferTypeRest(getMonth(), getYear(), id, transferType);
        setAssignedTransfers(prevState => prevState.map(value => value.id === id
            ? {...value, bankTransferType: transferType}
            : value));
    }

    const setTransferType = async (id: string, transferType: BankTransferType) => {
        await updateTransferTypeRest(getMonth(), getYear(), id, transferType);
        const bankTransferDetailsDTO = transfersToAssign.find(value => value.id === id);
        setTransfersToAssign(prevState => {
            const toAssign = prevState.filter(value => value.id !== id);
            if (toAssign.isEmpty()) {
                setTableType("SUMMARY");
            }

            return toAssign;
        });
        setAssignedTransfers(prevState => [...prevState, {...bankTransferDetailsDTO}]);
    }

    const transfersRegistered = async () => {
        const result = await transfersRegisteredRest(getMonth(), getYear());
        if (isSuccess(result)) {
            retrieveData(currentPeriod);
        }
    }

    const openKeywordsModal = () => {
        setKeywordsModalVisible(true);
    }

    const anyUnregistered = assignedTransfers.some(value => !value.registered);
    return <StyledBankTransfersPage className={"grid"}>
        <BankTransfersToolbar
            onPeriodChange={onPeriodChange}
            transfersUploaded={transfersToAssign.isNotEmpty() || assignedTransfers.isNotEmpty()}
            allTransfersAssigned={transfersToAssign.isEmpty()}
            uploadTransfers={() => setImportTransfersModalVisible(true)}
            transfersRegistered={transfersRegistered}
            anyUnregistered={anyUnregistered}
            openKeywordsModal={openKeywordsModal}
        />

        {(transfersToAssign.isNotEmpty() || assignedTransfers.isNotEmpty()) && <div className={"col-12"}><Card>
            {<div className={'select-button-container'}>
                <SelectButton
                    value={tableType}
                    unselectable={false}
                    onChange={(e) => setTableType(e.value)}
                    options={getTables()}
                    optionLabel="label"
                    className={'select-button-container'}
                />
            </div>}
            {tableType === "ASSIGNED" &&
                <AssignedTransfers
                    assignedTransfers={assignedTransfers}
                    updateTransferType={updateTransferType}
                />
            }

            {tableType === "TO_ASSIGN" &&
                <TransfersToAssign
                    transfersToAssign={transfersToAssign}
                    setTransferType={setTransferType}
                />
            }

            {tableType === "SUMMARY" &&
                <TransfersSummary
                    month={getMonth()}
                    year={getYear()}
                />
            }
        </Card></div>}

        {importTransfersModalVisible &&
            <ImportTransfersModal
                hideModal={() => setImportTransfersModalVisible(false)}
                refreshReport={() => retrieveData(currentPeriod)}
                month={getMonth()}
                year={getYear()}
            />}
        {keywordsModalVisible &&
            <BankTransferKeywordsModal
                hideModal={() => setKeywordsModalVisible(false)}
            />}
    </StyledBankTransfersPage>;
};

import React, {FC} from "react";
import {DayRange} from "../../common/service/util/DayRange";
import {translate} from "../../common/lang/service/Translate.service";
import {Button} from "primereact/button";
import {Day} from "../../common/service/util/Day";
import {PeriodChanger} from "../../common/components/periodChanger/PeriodChanger.component";
import {getChangePeriodByMonth} from "../../common/components/periodChanger/service/PeriodChangeStrategy";
import {getMonthYearChangerDisplay} from "../../common/components/periodChanger/service/PeriodChangerDisplay";
import {Card} from "../../common/components/card/Card.component";
import {Toolbar} from "primereact/toolbar";

interface BankTransfersToolbarProps {
    onPeriodChange: (period: DayRange) => void;
    uploadTransfers: () => void;
    transfersUploaded: boolean;
    transfersRegistered: () => void;
    allTransfersAssigned: boolean;
    anyUnregistered: boolean;
    openKeywordsModal: () => void;
}

export const BankTransfersToolbar: FC<BankTransfersToolbarProps> = (props: BankTransfersToolbarProps) => {
    const rightToolbarTemplate = () => {
        return <>
            {(props.transfersUploaded && props.allTransfersAssigned && props.anyUnregistered) && <Button
                severity={"success"}
                label={translate("REGISTER_TRANSFERS")} onClick={props.transfersRegistered} icon={"fa-solid fa-check"}
            />}
            {<Button
                className={"ml-2"}
                    label={translate("UPLOAD_TRANSFERS")} onClick={props.uploadTransfers} icon={"fa-solid fa-upload"}
            />}
            {<Button
                severity={"secondary"}
                className={"ml-2"}
                label={translate("KEYWORDS")} onClick={props.openKeywordsModal} icon={"fa-solid fa-hashtag"}
            />}
        </>;
    };

    const leftToolbarPanel = () => {
        const startPeriod = new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay());
        return (
            <PeriodChanger
                startPeriod={startPeriod}
                onNextPeriod={props.onPeriodChange}
                onPreviousPeriod={props.onPeriodChange}
                dayInLastPeriod={startPeriod.getMiddleDay()}
                dayInFirstPeriod={Day.getCompanyStartDate()}
                changePeriod={getChangePeriodByMonth()}
                display={getMonthYearChangerDisplay()}
            />
        );
    }

    return <div className={"col-12"}>
        <Card>
            <Toolbar
                start={leftToolbarPanel}
                end={rightToolbarTemplate}
            />
        </Card>
    </div>;
};

import React, {FC, useEffect, useState} from "react";
import {StyledCleaningDictionaryPage} from "./CleaningDictionaryPage.style";
import {CleaningDictionaryDTO, getInitialCleaningDictionaryDTO} from "./service/CleaningDictionary.type";
import {
    addNewCleaningDictionaryRest,
    getCleaningDictionaryRest,
    updateCleaningDictionaryRest
} from "./service/CleaningDictionary.service";
import {CleaningDictionaryTable} from "./cleaningDictionaryTable/CleaningDictionaryTable.component";
import {ButtonRow} from "../../common/components/buttonRow/ButtonRow.component";
import {translate} from "../../common/lang/service/Translate.service";
import {Button} from "primereact/button";
import {Card} from "../../common/components/card/Card.component";
import {CleaningDictionaryModal} from "./cleaningDictionaryModal/CleaningDictionaryModal.component";
import {CommandResult, isSuccess} from "../../common/service/result/Result.type";
import {isDefined} from "../../common/service/util/ObjectUtils";

interface CleaningDictionaryPageProps {
}

export const CleaningDictionaryPage: FC<CleaningDictionaryPageProps> = (props: CleaningDictionaryPageProps) => {
    const [cleaningDictionary, setCleaningDictionary] = useState<CleaningDictionaryDTO[]>([]);
    const [editedEntry, setEditedEntry] = useState<CleaningDictionaryDTO>(undefined);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {
        retrieveCleaningDictionary();
    }, []);

    async function retrieveCleaningDictionary() {
        const cleaningDictionaryDTOs = await getCleaningDictionaryRest();
        setCleaningDictionary(cleaningDictionaryDTOs);
    }

    const onEdit = (cleaningDictionaryEntry: CleaningDictionaryDTO) => {
        setEditedEntry(cleaningDictionaryEntry);
        setModalVisible(true);
    }

    const onAdd = () => {
        setEditedEntry(getInitialCleaningDictionaryDTO());
        setModalVisible(true);
    }

    const onSave = async (entry: CleaningDictionaryDTO) => {
        const result: CommandResult = isDefined(entry.id)
            ? await updateCleaningDictionaryRest(entry.id, entry)
            : await addNewCleaningDictionaryRest(entry)

        if (isSuccess(result)) {
            await retrieveCleaningDictionary();
            hideModal();
        }
    }

    const hideModal = () => {
        setEditedEntry(getInitialCleaningDictionaryDTO());
        setModalVisible(false);
    }

    return <StyledCleaningDictionaryPage>
        <Card label={translate("CLEANING_DICTIONARY")}>
            <CleaningDictionaryTable
                cleaningDictionary={cleaningDictionary}
                onEdit={onEdit}
            />
            <ButtonRow alignRight={true} className={'add-button'}>
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" onClick={onAdd}></Button>
            </ButtonRow>
        </Card>
        {modalVisible && <CleaningDictionaryModal
            initialEntry={editedEntry}
            onSave={onSave}
            hideModal={hideModal}
        />}
    </StyledCleaningDictionaryPage>;
};

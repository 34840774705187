import React from 'react';
import {useLocation} from 'react-router-dom';
import {BreadCrumb} from 'primereact/breadcrumb';
import {useAppViewConfig} from "../appViewConfig/service/redux/UseAppViewConfig.hook";
import {AppViewConfig} from "../appViewConfig/service/AppViewConfig.type";
import {Button} from "primereact/button";

export const TitleBreadcrumb = (props: any) => {
    const appViewConfig: AppViewConfig = useAppViewConfig();

    const location = useLocation();
    const activeRoute = props.routes.filter((route: any) =>
        route.to === location.pathname);
    let items: any = [];

    if (!activeRoute || activeRoute.length === 0 || !activeRoute[0]) {
        items = [];
    } else if (activeRoute[0].parent === '') {
        items = [{label: activeRoute[0].label}];
    } else if (activeRoute[0]) {
        items = [{label: activeRoute[0].parent}, {label: activeRoute[0].label}];
    }

    const isStatic = () => {
        return appViewConfig.menuMode === 'static';
    };

    return (
        <div className="layout-breadcrumb-container">
            <div className="layout-breadcrumb-left-items">
                {isStatic() && (
                    <button className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>
                )}

                {items.length > 0 && <BreadCrumb model={items} className="layout-breadcrumb"/>}
            </div>
            <div className="layout-breadcrumb-right-items">

                <span className="layout-rightmenu-button-desktop">
                    <Button label="Kalendarz" icon="pi pi-calendar" className="layout-rightmenu-button"
                            onClick={props.onRightMenuButtonClick}></Button>
                </span>

                <span className="layout-rightmenu-button-mobile">
                    <Button icon="pi pi-calendar" className="layout-rightmenu-button"
                            onClick={props.onRightMenuButtonClick}></Button>
                </span>
            </div>
        </div>
    );
};

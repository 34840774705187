import React, {FC, useId} from "react";
import {InputLabel, LabelType} from "../inputLabel/InputLabel.component";
import {InputNumber} from "primereact/inputnumber";

interface NumberInputProps {
    value: number;
    setValue: (newValue: number) => void;
    label?: string;
    required?: boolean;
    labelType?: LabelType;

    minValue?: number;
    maxValue?: number;
    className?: string;
}

export const NumberInput: FC<NumberInputProps> = (props: NumberInputProps) => {
    const id = useId();
    return <InputLabel id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <InputNumber
            id={id}
            required={props.required}
            value={props.value}
            onValueChange={(e) => props.setValue(e.value)}
            min={props.minValue}
            max={props.maxValue}
            className={"col"}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
        />
    </InputLabel>;
};

import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {classNames} from 'primereact/utils';
import {Menu} from "../menu/Menu.component";
import {authenticationService} from "../../../../../../index";
import {UserBasicInfoDTO} from "../../../../security/userBasicInfo/service/UserBasicInfo.type";
import {isDefined} from "../../../../service/util/ObjectUtils";
import {Translate} from "../../../../lang/Translate.component";
import {useCurrentUser} from "../../../../security/userBasicInfo/service/CurrentUser.hook";
import {useAppViewConfig} from "../appViewConfig/service/redux/UseAppViewConfig.hook";
import {AppViewConfig} from "../appViewConfig/service/AppViewConfig.type";

const Topbar = (props: any) => {
    const userBasicInfo: UserBasicInfoDTO = useCurrentUser();
    const appViewConfig: AppViewConfig = useAppViewConfig();

    const onTopbarSubItemClick = (event: any) => {
        event.preventDefault();
    };

    const navigate = useNavigate();

    const getAvatarUrl = (): string => {
        const avatarUrl = userBasicInfo.avatarUrl;
        return isDefined(avatarUrl)
            ? avatarUrl
            : "/assets/layout/images/profile-image.png";
    }

    const getUserProfileLink = (): string => `/user/${userBasicInfo.id}/profile`

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    <button className="logo p-link" onClick={() => navigate('/')}>
                        <img
                            src={`/assets/layout/images/logo-${appViewConfig.colorScheme === 'light' ? 'dark' : 'light'}.png`}
                            alt="logo"/>
                    </button>

                    <button className="p-link" onClick={() => navigate('/')}>
                        <img
                            src={`/assets/layout/images/appname-${appViewConfig.colorScheme === 'light' ? 'dark' : 'light'}.png`}
                            className="app-name" alt="app-name"/>
                    </button>
                </div>

                <Menu
                    model={props.items}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
                />

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-right-items">
                        <li id="profile"
                            className={classNames('profile-item', {'active-topmenuitem': props.topbarMenuActive})}>
                            <button className="p-link" onClick={props.onTopbarItemClick}>
                                <img src={getAvatarUrl()} alt="profile" style={{borderRadius: "20px"}}/>
                            </button>

                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    <Link className="p-link" to={getUserProfileLink()}>
                                        <i className="pi pi-fw pi-user"></i>
                                        <Translate id={"PROFILE"}/>
                                    </Link>
                                </li>
                                <li role="menuitem">
                                    <button style={{margin:'0', padding: '10px'}} className="p-link" onClick={props.onSettingsClick}>
                                        <i className="pi pi-fw pi-cog"></i>
                                        <Translate id={"SETTINGS"}/>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button style={{margin:'0', padding: '10px'}} className="p-link" onClick={authenticationService.logout}>
                                        <i className="pi pi-fw pi-sign-out"></i>
                                        <Translate id={"LOGOUT"}/>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        {/*<li>*/}
                        {/*    <button className="p-link">*/}
                        {/*        <i className="topbar-icon pi pi-fw pi-bell"></i>*/}
                        {/*        <span className="topbar-badge">2</span>*/}
                        {/*        <span className="topbar-item-name">Notifications</span>*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <button className="p-link">*/}
                        {/*        <i className="topbar-icon pi pi-fw pi-comment"></i>*/}
                        {/*        <span className="topbar-badge">5</span>*/}
                        {/*        <span className="topbar-item-name">Messages</span>*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Topbar;

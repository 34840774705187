import React, {FC, useState} from "react";
import {translate} from '../../../../common/lang/service/Translate.service';
import {ActionModal} from "../../../../common/modal/actionModal/ActionModal.component";
import {Day} from '../../../../common/service/util/Day';
import {DateInput} from '../../../../common/components/input/dateInput/DateInput.component';
import {LabelType} from '../../../../common/components/input/inputLabel/InputLabel.component';
import {terminateContractRest} from '../service/UserContractInformation.service';
import {CommandResult, isSuccess} from '../../../../common/service/result/Result.type';
import {isDefined} from '../../../../common/service/util/ObjectUtils';

interface TerminateUserContractModalProps {
    id: string;
    hideModal: () => void;
}

export const TerminateUserContractModal: FC<TerminateUserContractModalProps> = (props: TerminateUserContractModalProps) => {
    const [terminationDate, setTerminationDate] = useState<Day>(Day.today);

    const isValid = (): boolean => {
        return isDefined(terminationDate);
    }

    const onSave = async () => {
        const result: CommandResult = await terminateContractRest(props.id, terminationDate.dateToUrlString());
        if (isSuccess(result)) {
            props.hideModal();
        }
    }

    return <ActionModal
        header={translate("TERMINATE_CONTRACT")}
        isSaveDisabled={!isValid()}
        onConfirm={onSave}
        onReject={props.hideModal}
        isVisible={true}
    >
        <DateInput
            setValue={setTerminationDate}
            value={terminationDate}
            labelType={LabelType.VERTICAL}
            label={translate("TERMINATION_DATE")}
        />
    </ActionModal>
};

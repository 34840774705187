import React, {FC, useEffect, useState} from "react";
import {StyledPeriodChanger} from "./PeriodChanger.style";
import {DayRange} from "../../service/util/DayRange";
import {Day} from "../../service/util/Day";
import {DisplayStrategy, PeriodChangerDisplay, PeriodChangeStrategy} from "./service/PeriodChanger.type";
import {isDefined, isNotDefined} from "../../service/util/ObjectUtils";
import {getStaticPeriodChange} from "./service/PeriodChangeStrategy";

interface PeriodChangerProps {
    startPeriod: DayRange;
    changePeriod?: PeriodChangeStrategy;
    display: PeriodChangerDisplay;

    onNextPeriod?: (nextPeriod: DayRange) => void;
    onPreviousPeriod?: (previousPeriod: DayRange) => void;
    dayInFirstPeriod?: Day;
    dayInLastPeriod?: Day;
    isLastPeriod?: boolean;
    isFirstPeriod?: boolean;
}

export const PeriodChanger: FC<PeriodChangerProps> = (props: PeriodChangerProps) => {
    const [currentPeriod, setCurrentPeriod] = useState(props.startPeriod);
    const [startPeriod, setStartPeriod] = useState(props.startPeriod);

    useEffect(() => {
        if (!startPeriod.equals(props.startPeriod)) {
            setCurrentPeriod(props.startPeriod);
        }
    }, [props.startPeriod]);

    const generateLabel = (): string => {
        switch (props.display.displayStrategy) {
            case DisplayStrategy.FIRST_AND_LAST_DAY:
                return props.display.getLabelFromDay(currentPeriod.getDayFrom()) + " - " + props.display.getLabelFromDay(
                    currentPeriod.getDayTo());
            case DisplayStrategy.MIDDLE_DAY:
                return props.display.getLabelFromDay(currentPeriod.getMiddleDay());
        }
    };

    const previousPeriod = () => {
        const newPeriod = props.changePeriod.movePeriod(currentPeriod, -props.changePeriod.periodMoveUnitAmount);
        setCurrentPeriod(newPeriod);
        if (isDefined(props.onPreviousPeriod)) {
            props.onPreviousPeriod(newPeriod);
        }
    };

    const nextPeriod = () => {
        const newPeriod = props.changePeriod.movePeriod(currentPeriod, props.changePeriod.periodMoveUnitAmount);
        setCurrentPeriod(newPeriod);
        if (isDefined(props.onNextPeriod)) {
            props.onNextPeriod(newPeriod);
        }
    };

    const hasPreviousPeriod = (): boolean => {
        if (props.isFirstPeriod) {
            return false;
        }

        const previousPeriod = props.changePeriod.movePeriod(currentPeriod, -props.changePeriod.periodMoveUnitAmount);
        return isNotDefined(props.dayInFirstPeriod)
            || previousPeriod.getDayTo().isAfterDay(props.dayInFirstPeriod);
    };

    const hasNextPeriod = (): boolean => {
        if (props.isLastPeriod) {
            return false;
        }

        const nextPeriod = props.changePeriod.movePeriod(currentPeriod, props.changePeriod.periodMoveUnitAmount);
        return isNotDefined(props.dayInLastPeriod)
            || nextPeriod.getDayFrom().isBeforeOrEqualDay(props.dayInLastPeriod);
    };

    return <StyledPeriodChanger>
        <div className={"arrow-container"}>
            {hasPreviousPeriod() && <i className={"pi pi-chevron-left"} onClick={previousPeriod}/>}
        </div>
        <div className={"arrow-container"}>
            {hasNextPeriod() && <i className={"pi pi-chevron-right"} onClick={nextPeriod}/>}
        </div>
        <div className={"p-button-label p-c display"}>{generateLabel()}</div>
    </StyledPeriodChanger>;
};

PeriodChanger.defaultProps = {
    isFirstPeriod: false,
    isLastPeriod: false,
    changePeriod: getStaticPeriodChange()
}

import {RepeatableExpenseDTO} from './RepeatableExpensesPage.type';
import {UriBuilder} from '../../../common/service/util/UriBuilder.class';
import {_delete, get, post, put} from '../../../common/service/http/Http.service';
import {CommandResult} from '../../../common/service/result/Result.type';
import {Day} from "../../../common/service/util/Day";

export const getRepeatableExpensesListRest = (): Promise<RepeatableExpenseDTO[]> => {
    return get<RepeatableExpenseDTO[]>(new UriBuilder()
        .all("expense")
        .all("repeatable")
        .all("all")
        .build());
}

export const addRepeatableExpensesRest = (repeatableExpense: RepeatableExpenseDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("expense")
        .all("repeatable")
        .build(), repeatableExpense);
}

export const updateRepeatableExpensesRest = (id: string, repeatableExpense: RepeatableExpenseDTO): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .all("expense")
        .one("repeatable", id)
        .all('update')
        .build(), repeatableExpense);
}

export const generateRepeatableExpensesRest = (): Promise<void> => {
    return get<void>(new UriBuilder()
        .all("expense")
        .all("repeatable")
        .all("generate")
        .one('month', Day.today().getMonthNumber())
        .one('year', Day.today().getFullYear())
        .build());
}

export const removeRepeatableExpensesRest = (id: string): Promise<CommandResult> => {
    return _delete<CommandResult>(new UriBuilder()
        .all("expense")
        .one("repeatable", id)
        .all('remove')
        .build());
}

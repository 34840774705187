import {Dispatch} from "redux";
import {AppActions} from "../../../redux/AppActions";
import {AppState} from "../../../redux/RootReducer";
import {GlobalPermissions} from "../service/GlobalPermissions.type";
import {getGlobalPermissionsRest} from "../service/GlobalPermissions.service";
import {GlobalPermissionsRetrieved} from "./GlobalPermissions.type";

const retrieveGlobalPermissionsAction = (getGlobalPermissions: () => Promise<GlobalPermissions> = getGlobalPermissionsRest) => {
    return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
        const globalPermissions: GlobalPermissions = await getGlobalPermissions();
        dispatch(globalPermissionsRetrieved(globalPermissions));
    };
};

const globalPermissionsRetrieved = (globalPermissions: GlobalPermissions): AppActions => {
    return {
        type: GlobalPermissionsRetrieved,
        globalPermissions: globalPermissions
    };
};

export default retrieveGlobalPermissionsAction;

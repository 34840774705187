import styled from "styled-components";
import {LabelType} from "./InputLabel.component";

interface StyledInputLabelProps {
    labelType: LabelType;
    withSpanPadding: boolean;
}

export const StyledInputLabel = styled.div<StyledInputLabelProps>`
  label {
    color: #868C9B;
    font-weight: 500;
  }
  .field > label {
    ${(props: StyledInputLabelProps) => (props.labelType === LabelType.HORIZONTAL && {
      marginBottom: 0,
    })}
  }
  .field {
    ${(props: StyledInputLabelProps) => (props.labelType === LabelType.HORIZONTAL && {
      justifyContent: "space-between"
    })}
  }
  .grid {
    margin-right: 0;
    margin-left: 0;
  }
  
  span {
    ${(props: StyledInputLabelProps) => (!props.withSpanPadding && {
        padding: "0"
    })}
  }
`;

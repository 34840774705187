export interface UserProfilePagePermissionsDTO {
    canViewProfilePage: boolean;
    canViewPersonalData: boolean;
    canEditPersonalData: boolean;
    canViewContractInformation: boolean;
    canEditContractInformation: boolean;
}

export const getInitialUserProfilePagePermissionsDTO = (): UserProfilePagePermissionsDTO => ({
    canViewProfilePage: false,
    canViewPersonalData: false,
    canEditPersonalData: false,
    canViewContractInformation: false,
    canEditContractInformation: false,
});

export interface UserPersonalDataDTO {
    firstName: string;
    lastName: string;
    displayName: string;
    profilePictureUrl: string;
}

export const getInitialUserPersonalDataDTO = (): UserPersonalDataDTO => ({
    firstName: '',
    lastName: '',
    displayName: '',
    profilePictureUrl: ''
});
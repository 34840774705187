import {CalendarColor} from "./AppViewConfig.type";
import {get, post} from "../../../../../service/http/Http.service";
import {UriBuilder} from "../../../../../service/util/UriBuilder.class";

export const getUserCalendarColorRest = (userId: string): Promise<CalendarColor> => {
    return get(new UriBuilder()
        .all("calendar")
        .one("user", userId)
        .all("color")
        .build())
}

export const updateUserCalendarColorRest = (userId: string, colorId: string, color: string): Promise<CalendarColor> => {
    return post(new UriBuilder()
        .all("calendar")
        .one("user", userId)
        .all("color")
        .build(), {colorId: colorId, color: color})
}

import React, {FC, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../redux/RootReducer";
import {isDefined} from "../service/util/ObjectUtils";
import {StyledGrowl} from "./Growl.style";

export const Growl: FC = () => {
    const toast = useRef(null);
    const growlMessage = useSelector((state: AppState) => state.growlMessage);

    useEffect(() => {
        if (isDefined(growlMessage) && Object.keys(growlMessage).length > 0) {
            toast.current.show(growlMessage);
        }
    });

    return <StyledGrowl ref={toast}/>;
};

import React, {FC} from "react";
import {BankTransferDetailsDTO, BankTransferType} from "../service/BankTransfers.type";
import {SelectButton} from "primereact/selectbutton";
import {getEnumSelectOptions} from "../../common/components/input/multiselectChips/service/SelectOption.type";
import {ColumnProps} from "../../common/components/table/service/Table.types";
import {translate} from "../../common/lang/service/Translate.service";
import {Table} from "../../common/components/table/Table.component";
import {formatAsCurrency} from "../../common/service/util/CurrencyUtil";

interface AssignedTransfersProps {
    assignedTransfers: BankTransferDetailsDTO[];
    updateTransferType: (id: string, type: BankTransferType) => void;
}

export const AssignedTransfers: FC<AssignedTransfersProps> = (props: AssignedTransfersProps) => {
    const setTransferTypeBodyTemplate = (rowData: BankTransferDetailsDTO) => (
        <div className="actions">
            {!rowData.registered && <SelectButton
                value={rowData.bankTransferType}
                onChange={(e) => props.updateTransferType(rowData.id, e.value)}
                options={getEnumSelectOptions(BankTransferType)}
                optionLabel="label"/>}
        </div>
    );

    const columnProps: ColumnProps[] = [
        {header: translate('AMOUNT'), fieldName: 'amount', sortable: true, transformValue: formatAsCurrency},
        {header: translate('TITLE'), fieldName: 'title', sortable: true},
        {header: translate('DATE'), fieldName: 'date', sortable: true},
        {header: translate('CLIENT_NAME'), fieldName: 'clientName', sortable: true},
        {header: "", fieldName: "ID", body: setTransferTypeBodyTemplate, width: '245px'},
    ];
    return <>
        <Table
            values={props.assignedTransfers}
            dataKey={"id"}
            columnProps={columnProps}
            multiSortMeta={[{field: "date", order: 1}]}
        />
    </>;
};

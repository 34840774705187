import {Day} from "../../../common/service/util/Day";

export interface ExpenseReadDTO {
    expenseId: string;
    description: string;
    supplierId: string;
    isPaid: boolean;
    expenseType: ExpenseType;
    expenseSource: ExpenseSource;
    paymentType: PaymentType;
    amount: number;
    paymentDate: string;
    invoiceDate: string;
    deadlineDate: string;
    isValid: boolean;
    requireInvoice: boolean;
    hasFiles: boolean;
}

export enum ExpenseType {
    WAREHOUSE_SUPPLY = "WAREHOUSE_SUPPLY",
    STUDIO_EQUIPMENT = "STUDIO_EQUIPMENT",
    TAX = "TAX",
    ADVERTISEMENT = "ADVERTISEMENT",
    BILLS = "BILLS",
    RENT = "RENT",
    OTHER = "OTHER"
}

export enum ExpenseSource {
    WAREHOUSE_ORDER = "WAREHOUSE_ORDER",
    MANUAL = "MANUAL",
    RECURRING = "RECURRING"
}

export enum PaymentType {
    CASH = "CASH",
    PRIVATE_ACCOUNT = "PRIVATE_ACCOUNT",
    COMPANY_ACCOUNT = "COMPANY_ACCOUNT"
}

export interface ExpenseDTO {
    expenseId: string;
    description: string;
    supplierId: string;
    isPaid: boolean;
    expenseType: ExpenseType;
    expenseSource: ExpenseSource;
    paymentType: PaymentType;
    amount: number;
    paymentDate: string;
    invoiceDate: string;
    deadlineDate: string;
}

export const getInitialExpense = (): ExpenseReadDTO => {
    return {
        amount: undefined,
        deadlineDate: Day.today().toFormattedString(),
        description: undefined,
        expenseId: undefined,
        expenseSource: undefined,
        expenseType: undefined,
        hasFiles: undefined,
        invoiceDate: Day.today().toFormattedString(),
        isPaid: true,
        isValid: undefined,
        paymentDate: Day.today().toFormattedString(),
        paymentType: undefined,
        requireInvoice: undefined,
        supplierId: undefined
    };
}

export interface ExpenseFilters {
    expenseType?: ExpenseType[],
    paymentType?: PaymentType[],
    requiresPayment?: boolean;
    hasMissingInvoice?: boolean;
}
import React, {FC} from "react";
import {Modal} from '../../../../../common/modal/Modal.component';
import {translate} from '../../../../../common/lang/service/Translate.service';
import {DictionaryDTO} from '../../../../../common/service/util/dictionary/Dictionary.type';
import {InventoryFilterFields} from '../../../service/InventoryPage.type';
import {MultiselectInput} from '../../../../../common/components/input/multiselectInput/MultiselectInput.component';
import {
    getDictionarySelectOption
} from '../../../../../common/components/input/multiselectChips/service/SelectOption.type';
import {Button} from 'primereact/button';
import {ButtonRow} from '../../../../../common/components/buttonRow/ButtonRow.component';

interface InventoryFiltersModalProps {
    hideModal: () => void;
    categories: DictionaryDTO[];
    subCategories: DictionaryDTO[];
    suppliers: DictionaryDTO[];
    filters: InventoryFilterFields;
    updateFilters: (fieldName: string, newValue: any) => void;
}

export const InventoryFiltersModal: FC<InventoryFiltersModalProps> = (props: InventoryFiltersModalProps) => {
    const footer = <ButtonRow>
            <Button label={translate("CLOSE")} onClick={props.hideModal}
                    className={"p-button-danger"}/>
        </ButtonRow>
    ;
    return <>
        <Modal header={translate("FILTER_ITEMS")} isVisible={true} hideModal={props.hideModal} footer={footer}>
            <MultiselectInput
                options={getDictionarySelectOption(props.suppliers)}
                selectedValues={props.filters.supplierId}
                setSelectedValues={newValue => props.updateFilters('supplierId', newValue)}
                label={translate("DEFAULT_SUPPLIER")}
                display={'comma'}
                maxSelectedLabels={3}
            />
            <MultiselectInput
                options={getDictionarySelectOption(props.categories)}
                selectedValues={props.filters.categoryId}
                setSelectedValues={newValue => props.updateFilters('categoryId', newValue)}
                label={translate("CATEGORY")}
                display={'comma'}
                maxSelectedLabels={3}
            />
            <MultiselectInput
                options={getDictionarySelectOption(props.subCategories)}
                selectedValues={props.filters.subCategoryId}
                setSelectedValues={newValue => props.updateFilters('subCategoryId', newValue)}
                label={translate("SUB_CATEGORY")}
                display={'comma'}
                maxSelectedLabels={3}
            />
        </Modal>
    </>;
};

import React, {FC, useEffect, useState} from "react";
import {InventoryFilterFields} from "../../service/InventoryPage.type";
import {TextInput} from '../../../../common/components/input/textInput/TextInput.component';
import {Button} from 'primereact/button';
import {translate} from '../../../../common/lang/service/Translate.service';
import {LabelType} from '../../../../common/components/input/inputLabel/InputLabel.component';
import {InventoryFiltersModal} from './inventoryFiltersModal/InventoryFiltersModal.component';
import {DictionaryDTO} from '../../../../common/service/util/dictionary/Dictionary.type';
import {getSuppliersSimpleDictionaryRest} from '../../../supplierDictionaryPage/service/SupplierDictionaryPage.service';
import {ItemCategoryDTO} from '../../../itemDictionaryPage/service/ItemDictionaryPage.type';
import {getItemCategoriesRest} from '../../../itemDictionaryPage/service/ItemDictionaryPage.service';

interface InventoryFiltersProps {
    filters: InventoryFilterFields;
    updateFilters: (fieldName: string, newValue: any) => void;
}

export const InventoryFilters: FC<InventoryFiltersProps> = (props: InventoryFiltersProps) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [categories, setCategories] = useState<DictionaryDTO[]>([]);
    const [subCategories, setSubCategories] = useState<DictionaryDTO[]>([]);
    const [suppliers, setSuppliers] = useState<DictionaryDTO[]>([]);

    useEffect(() => {
        retrieveDictionaries();
    }, []);

    const retrieveDictionaries = async () => {
        const supplierDTOs: DictionaryDTO[] = await getSuppliersSimpleDictionaryRest();
        setSuppliers(supplierDTOs);
        const itemCategoryDTOs: ItemCategoryDTO[] = await getItemCategoriesRest();
        setCategories(itemCategoryDTOs.map(value => ({id: value.id, name: value.name})));
        setSubCategories(itemCategoryDTOs.flatMap(value => value.subCategories));
    };

    const showFiltersModal = () => {
        setModalVisible(true);
    }

    return <>
        <div  className="grid">
            <div className="col">
                <TextInput
                    value={props.filters.name}
                    setValue={newValue => props.updateFilters('name', newValue)}
                    label={translate("FILTER")}
                    labelType={LabelType.INLINE}
                    className={'item-select'}
                />
            </div>
            <div className="col-fixed" style={{width: "39px"}}>
                <Button icon="pi pi-filter" className="p-button-rounded p-button-success" onClick={showFiltersModal}></Button>
            </div>
        </div>
        {modalVisible &&
            <InventoryFiltersModal
                hideModal={() => setModalVisible(false)}
                categories={categories}
                subCategories={subCategories}
                suppliers={suppliers}
                filters={props.filters}
                updateFilters={props.updateFilters}
            />}
    </>;
};

import React, {FC, useEffect, useState} from "react";
import {StyledUserManagementPage} from "./UserManagementPage.style";
import {
    activateUserRest,
    deactivateUserRest,
    getAllUserRest,
    getUserProfileLink
} from "./service/UserManagementPage.service";
import {UserDTO} from "./service/UserManagementPage.type";
import {Card} from "../../common/components/card/Card.component";
import {Table} from "../../common/components/table/Table.component";
import {ColumnProps, FilterType} from "../../common/components/table/service/Table.types";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import {translate} from "../../common/lang/service/Translate.service";
import {CommandResult, isSuccess} from '../../common/service/result/Result.type';
import {EditUserRolesModal} from './editUserRolesModal/EditUserRolesModal.component';
import {useNavigate} from 'react-router-dom';

interface UserManagementPageProps {
}

export const UserManagementPage: FC<UserManagementPageProps> = (props: UserManagementPageProps) => {
    const [users, setUsers] = useState<UserDTO[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [editedUser, setEditedUser] = useState<UserDTO>();
    const navigate = useNavigate();

    useEffect(() => {
        retrieveAllUserDetails();
    }, []);

    async function retrieveAllUserDetails() {
        const userDTOs: UserDTO[] = await getAllUserRest();
        setUsers([...userDTOs])
    }

    const showEditUserModal = (rowData: UserDTO) => {
        setEditedUser(rowData);
        setModalVisible(true);
    }

    const imageBodyTemplate = (rowData: UserDTO) => (
        <div className={'image-container'}>
            <img src={rowData.avatarUrl} className="avatar" alt={''}/>
        </div>
    );

    const activateUser = async (id: string) => {
        const commandResult: CommandResult = await activateUserRest(id);
        if (isSuccess(commandResult)) {
            retrieveAllUserDetails();
        }
    }

    const deactivateUser = async (id: string) => {
        const commandResult: CommandResult = await deactivateUserRest(id);
        if (isSuccess(commandResult)) {
            retrieveAllUserDetails();
        }
    }

    const editBodyTemplate = (rowData: UserDTO) => (
        <div className="actions">
            {!rowData.isActive && <Button onClick={() => activateUser(rowData.id)} icon={"pi pi-check"}
                                          className={"p-button-rounded p-button-success mr-2"}/>}
            {rowData.isActive && <Button onClick={() => deactivateUser(rowData.id)} icon={"pi pi-times"}
                                         className={"p-button-rounded p-button-danger mr-2"}/>}
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success"
                    onClick={() => showEditUserModal(rowData)}/>
        </div>
    );

    const statusBodyTemplate = (rowData: UserDTO) => {
        if (rowData.isActive) {
            return <Tag className="mr-2" severity="success" value={translate("ACTIVE")}/>
        } else {
            return <Tag className="mr-2" severity="danger" value={translate("INACTIVE")}/>
        }
    }

    const statusFilterBodyTemplate = ({label, value}) => {
        if (value) {
            return <Tag className="mr-2" severity="success" value={translate("ACTIVE")}/>
        } else {
            return <Tag className="mr-2" severity="danger" value={translate("INACTIVE")}/>
        }
    }

    const goToUserProfile = (id: string) => {
        navigate(getUserProfileLink(id));
    }

    const columnProps: ColumnProps[] = [
        {header: "", fieldName: "email", sortable: false, body: imageBodyTemplate, width: '50px'},
        {header: translate("EMAIL"), fieldName: "email", sortable: true, filterType: FilterType.INPUT},
        {header: translate("NAME"), fieldName: "fullName", sortable: true, filterType: FilterType.SELECT},
        {header: translate("STATUS"), fieldName: "isActive", sortable: true, body: statusBodyTemplate, initFilterValue: true,
            filterBody: statusFilterBodyTemplate, filterType: FilterType.SELECT},
        {header: "", fieldName: "isActive", body: editBodyTemplate, width: '87px'},
    ];

    const closeModal = () => {
        retrieveAllUserDetails();
        return setModalVisible(false);
    };

    return <StyledUserManagementPage>
        {modalVisible && <EditUserRolesModal
            userId={editedUser.id}
            roleNames={editedUser.roleNames}
            closeModal={closeModal}
        />}
        <Card label={translate("EDIT_USERS")}>
            <Table
                values={users}
                dataKey={"id"}
                columnProps={columnProps}
                onRowClick={(user: UserDTO) => goToUserProfile(user.id)}
                multiSortMeta={[{field: "fullName", order: 1}]}
            />
        </Card>
    </StyledUserManagementPage>;
};

import React, {useEffect, useState} from 'react';
import {classNames} from 'primereact/utils';
import {Day} from "../../../../service/util/Day";
import {translate} from "../../../../lang/service/Translate.service";
import {StyledRightPanel} from "./RightPanel.style";
import {UserDetails} from "./userDetails/UserDetails.component";
import {RightPanelCalendar} from "./rightPanelCalendar/RightPanelCalendar.component";
import {getDayEvents, getMonthEvents} from "./service/Calendar.service";
import {CalendarEventDTO} from "./service/Calendar.types";
import {CalendarEvent} from "./calendarEvent/CalendarEvent.component";

export const RightPanel = (props: any) => {
    const [day, setDay] = useState<Day>(Day.today());
    const [dayEvents, setDayEvents] = useState([]);
    const [userMonthEvents, setUserMonthEvents] = useState([]);


    useEffect(() => {
        retrieveUserMonthEvents(day);
        retrieveDayEvents(day);
    }, []);

    async function retrieveUserMonthEvents(day: Day) {
        const monthEventsDto: CalendarEventDTO[] = await getMonthEvents(day);
        setUserMonthEvents(monthEventsDto);
    }

    async function retrieveDayEvents(day: Day) {
        const calendarDTO: CalendarEventDTO[] = await getDayEvents(day);
        setDayEvents(calendarDTO);
    }

    const getSelectedDayDescription = (): string => {
        return day.getDayNumber().toString() + " "
            + translate("SHORT_MONTH_NAME_UPPER." + day.getMonthNumber().toString()) + " "
            + day.getFullYear().toString() + ", "
            + translate("DAY_NAME." + day.getWeekDayNumber());
    }

    function onDayChanged(day: Day) {
        retrieveDayEvents(day);
        setDay(day);
    }

    function onMonthChanged(day: Day) {
        retrieveUserMonthEvents(day);
    }

    return (
        <StyledRightPanel className={classNames('layout-rightmenu', {'layout-rightmenu-active': props.rightMenuActive})}
                          onClick={props.onRightMenuClick}>
            <button onClick={() => props.onRightMenuActiveChange(false)} className="layout-rightmenu-close p-link">
                <i className="pi pi-times"></i>
            </button>
            <div className="user-detail-wrapper">
                <UserDetails/>

                {/*TASKS*/}
                {/*    <div className="user-tasks">*/}
                {/*        <div className="user-tasks-item in-progress">*/}
                {/*            <button className="task-number p-link">23</button>*/}
                {/*            <span className="task-name">Progress</span>*/}
                {/*        </div>*/}
                {/*        <div className="user-tasks-item">*/}
                {/*            <button className="task-number p-link">6</button>*/}
                {/*            <span className="task-name">Overdue</span>*/}
                {/*        </div>*/}
                {/*        <div className="user-tasks-item">*/}
                {/*            <button className="task-number p-link">38</button>*/}
                {/*            <span className="task-name">All deals</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}

            </div>

            <RightPanelCalendar
                day={day}
                setDay={onDayChanged}
                userMonthEvents={userMonthEvents}
                onMonthChanged={onMonthChanged}
            />

            <div className="daily-plan-wrapper">
                <span className="today-date">{getSelectedDayDescription()}</span>
                <ul>
                    {dayEvents.map(value => <CalendarEvent key={value.id} event={value}/>)}
                </ul>
            </div>
        </StyledRightPanel>
    );
};

import {_delete, get, post} from "../../../common/service/http/Http.service";
import {InventoryDTO, InventoryEntryDTO, InventoryFilterFields, WarehouseStateEntryDTO} from "./InventoryPage.type";
import {UriBuilder} from "../../../common/service/util/UriBuilder.class";
import {CommandResult} from "../../../common/service/result/Result.type";
import {ItemDictionaryDTO} from '../../itemDictionaryPage/service/ItemDictionaryPage.type';
import {isNotDefined} from '../../../common/service/util/ObjectUtils';
import {isNotDefinedOrEmpty, removeDiacritics} from '../../../common/service/util/StringUtils';

export const getCurrentWarehouseStateRest = (): Promise<WarehouseStateEntryDTO[]> => {
    return get<WarehouseStateEntryDTO[]>(new UriBuilder()
        .all("warehouse-state")
        .all("current")
        .build());
}

export const getLatestInventoryRest = (): Promise<InventoryDTO> => {
    return get<InventoryDTO>(new UriBuilder()
        .all("inventory")
        .all("latest")
        .build());
}

export const getInventoryRest = (inventoryNumber: number): Promise<InventoryDTO> => {
    return get<InventoryDTO>(new UriBuilder()
        .one("inventory", inventoryNumber)
        .build());
}

export const startInventoryRest = (): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("inventory")
        .all("start")
        .build());
}

export const closeInventoryRest = (inventoryId: string): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("inventory", inventoryId)
        .all("close")
        .build());
}

export const addInventoryEntryRest = (inventoryId: string, inventoryEntryDTO: InventoryEntryDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("inventory", inventoryId)
        .all("add")
        .build(), inventoryEntryDTO);
}

export const updateInventoryEntryRest = (inventoryId: string, inventoryEntryDTO: InventoryEntryDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .one("inventory", inventoryId)
        .all("update")
        .build(), inventoryEntryDTO);
}

export const removeInventoryEntryRest = (inventoryId: string, itemId: string): Promise<CommandResult> => {
    return _delete<CommandResult>(new UriBuilder()
        .one("inventory", inventoryId)
        .one("item", itemId)
        .all("remove")
        .build());
}

export const focusOnInventoryNumberInput = () => {
    window.scrollTo(0, 0);
    const input: any = document.getElementById("inventory-count-input");
    input.select();
    input.focus();
}

export const filterInventoryItems = (items: ItemDictionaryDTO[], filters: InventoryFilterFields): ItemDictionaryDTO[] => {
    return items
        .filter(value =>
            filterByName(value, filters.name)
            && filterBySupplier(value, filters.supplierId)
            && filterByCategory(value, filters.categoryId)
            && filterBySubCategory(value, filters.subCategoryId));
};

const filterByName = (item: ItemDictionaryDTO, name: string): boolean => {
    if (isNotDefinedOrEmpty(name)) {
        return true;
    }

    return removeDiacritics(item.name.toLowerCase()).indexOf(removeDiacritics(name?.toLowerCase())) >= 0
}

const filterBySupplier = (item: ItemDictionaryDTO, suppliers: string[]): boolean => {
    return isNotDefined(suppliers) || suppliers.isEmpty() || suppliers.includes(item.defaultSupplierId);
}

const filterByCategory = (item: ItemDictionaryDTO, categories: string[]): boolean => {
    return isNotDefined(categories) || categories.isEmpty() || categories.includes(item.category);
}

const filterBySubCategory = (item: ItemDictionaryDTO, subCategories: string[]): boolean => {
    return isNotDefined(subCategories) || subCategories.isEmpty() || subCategories.includes(item.subCategory);
}

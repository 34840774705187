import React, {FC, useState} from "react";
import {ActionModal} from "../../../common/modal/actionModal/ActionModal.component";
import {DictionaryDTO} from "../../../common/service/util/dictionary/Dictionary.type";
import {translate} from "../../../common/lang/service/Translate.service";
import {SingleSelectInput} from "../../../common/components/input/singleSelectInput/SingleSelectInput.component";
import {getDictionarySelectOption} from "../../../common/components/input/multiselectChips/service/SelectOption.type";
import {isNotDefinedOrEmpty} from "../../../common/service/util/StringUtils";
import {isNotDefined} from "../../../common/service/util/ObjectUtils";
import {ItemDictionaryDTO} from "../../itemDictionaryPage/service/ItemDictionaryPage.type";
import {CurrencyInput} from "../../../common/components/input/currencyInput/CurrencyInput.component";
import {NumberInput} from "../../../common/components/input/numberInput/NumberInput.component";

interface AddItemModalProps {
    hideModal: () => void;
    addItem: (supplierId: string, itemId: string, itemCount: number, itemCost: number) => void;
    supplierDictionary: DictionaryDTO[];
    itemDictionary: ItemDictionaryDTO[];
}

export const AddItemModal: FC<AddItemModalProps> = (props: AddItemModalProps) => {
    const [supplierId, setSupplierId] = useState<string>();
    const [itemId, setItemId] = useState<string>();
    const [itemCount, setItemCount] = useState<number>();
    const [itemCost, setItemCost] = useState<number>()

    const addItem = () => {
        props.addItem(supplierId, itemId, itemCount, itemCost);
    }

    const isInvalid = (): boolean => {
        return isNotDefinedOrEmpty(supplierId) || isNotDefinedOrEmpty(itemId) || isNotDefined(itemCount) || itemCount <= 0 || isNotDefined(itemCost) || itemCost <= 0;
    }

    return <ActionModal onReject={props.hideModal} isVisible={true} header={translate("ADD_ITEM")} onConfirm={addItem}
                        isSaveDisabled={isInvalid()}>
        <div style={{display: "flex"}}>
            <SingleSelectInput
                className={'col-3'}
                options={getDictionarySelectOption(props.supplierDictionary)}
                selectedValue={supplierId}
                setSelectedValue={selectedValue => setSupplierId(selectedValue)}
                label={translate('SUPPLIER')}
            />

            <SingleSelectInput
                className={'col-3'}
                options={getDictionarySelectOption(props.itemDictionary)}
                selectedValue={itemId}
                setSelectedValue={selectedValue => setItemId(selectedValue)}
                label={translate('ITEM')}
            />

            <NumberInput
                className={'col-3'}
                maxValue={999}
                minValue={0}
                value={itemCount}
                setValue={(newCount) => setItemCount(newCount)}
                label={translate('COUNT')}
            />

            <CurrencyInput
                className={'col-3'}
                maxValue={9999}
                value={itemCost}
                setValue={(newValue) => setItemCost(newValue)}
                label={translate('SINGLE_ITEM_VALUE')}
            />
        </div>
    </ActionModal>;
};

import React, {FC, useEffect, useState} from "react";
import {StyledOrderPage} from './OrderPage.style';
import {OrderPageToolbar} from './orderPageToolbar/OrderPageToolbar.component';
import {WarehouseStateEntryDTO} from '../inventoryPage/service/InventoryPage.type';
import {ItemDictionaryDTO} from '../itemDictionaryPage/service/ItemDictionaryPage.type';
import {getCurrentWarehouseStateRest} from '../inventoryPage/service/InventoryPage.service';
import {getItemsRest} from '../itemDictionaryPage/service/ItemDictionaryPage.service';
import {Order, OrderDTO, OrderStatus} from './service/OrderPage.type';
import {
    addOrderItemRest,
    closeOrderRest,
    getLatestOrderRest,
    getOrderRest,
    startOrderRest
} from './service/OrderPage.service';
import {usePermissions} from '../../common/security/globalPermissions/service/Permissions.hook';
import {CommandResult, isSuccess} from '../../common/service/result/Result.type';
import {Day} from '../../common/service/util/Day';
import {getSuppliersRest} from '../supplierDictionaryPage/service/SupplierDictionaryPage.service';
import {isDefined} from '../../common/service/util/ObjectUtils';
import {OrderPageMapper} from './service/OrderPageMapper.service';
import {OrderPageContainer} from './orderPageContainer/OrderPageContainer.component';
import {SupplierDictionaryDTO} from '../supplierDictionaryPage/service/SupplierDictionaryPage.type';
import {isDefinedAndNotEmpty} from "../../common/service/util/StringUtils";
import {AddItemModal} from "./addItemModal/AddItemModal.component";

interface OrderPageProps {
}

export const OrderPage: FC<OrderPageProps> = (props: OrderPageProps) => {
    const [warehouseState, setWarehouseState] = useState<WarehouseStateEntryDTO[]>([]);
    const [itemDictionary, setItemDictionary] = useState<ItemDictionaryDTO[]>([]);
    const [supplierDictionary, setSupplierDictionary] = useState<SupplierDictionaryDTO[]>([]);
    const [order, setOrder] = useState<OrderDTO>(undefined);
    const [orderStarted, setOrderStarted] = useState<boolean>(false);
    const [latestOrderNumber, setLatestOrderNumber] = useState<number>();
    const [itemModalVisible, setItemModalVisible] = useState(false);

    const canEdit: boolean = usePermissions().canMakeOrders;
    const inEditMode: boolean = canEdit && order?.orderStatus === OrderStatus.OPEN;

    useEffect(() => {
        retrieveLatestOrder();
        retrieveWarehouseState();
        retrieveItemDictionary();
        retrieveSupplierDictionary();
    }, []);

    function getData(): Order[] {
        return (isDefined(order) && isDefinedAndNotEmpty(itemDictionary) && isDefinedAndNotEmpty(warehouseState) && isDefinedAndNotEmpty(supplierDictionary))
            ? OrderPageMapper.getOrders(itemDictionary, warehouseState, supplierDictionary, order.supplierOrders, inEditMode)
            : [];
    }

    async function retrieveLatestOrder() {
        const orderDTO: OrderDTO = await getLatestOrderRest();
        setOrder(orderDTO);
        setOrderStarted(orderDTO?.orderStatus === OrderStatus.OPEN);
        setLatestOrderNumber(orderDTO?.orderNumber);
    }

    async function retrieveOrder(newOrderNumber: number) {
        const orderDTO: OrderDTO = await getOrderRest(newOrderNumber);
        setOrder(orderDTO);
    }

    async function retrieveWarehouseState() {
        const warehouseStateDTO: WarehouseStateEntryDTO[] = await getCurrentWarehouseStateRest();
        setWarehouseState(warehouseStateDTO);
    }

    async function retrieveItemDictionary() {
        const itemDTOs: ItemDictionaryDTO[] = await getItemsRest();
        setItemDictionary(itemDTOs);
    }

    async function retrieveSupplierDictionary() {
        const suppliersDictionaryDTO: SupplierDictionaryDTO[] = await getSuppliersRest();
        setSupplierDictionary(suppliersDictionaryDTO);
    }

    const startOrder = async () => {
        const result: CommandResult = await startOrderRest();

        if (isSuccess(result)) {
            retrieveLatestOrder();
        }
    }

    const closeOrder = async () => {
        const result: CommandResult = await closeOrderRest(order.id);

        if (isSuccess(result)) {
            retrieveWarehouseState();
            retrieveLatestOrder();
        }
    }

    const addItem = async (supplierId: string, itemId: string, itemCount: number, itemCost: number) => {
        const result = await addOrderItemRest(order.id, {
            itemId: itemId,
            supplierId: supplierId,
            itemPrice: itemCost,
            count: itemCount
        });

        if (isSuccess(result)) {
            retrieveLatestOrder();
            setItemModalVisible(false);
        }
    }

    return <StyledOrderPage className={"grid"}>
        <OrderPageToolbar
            orderDate={Day.fromString(order?.startDate)}
            onPreviousOrder={() => retrieveOrder(--order.orderNumber)}
            onNextOrder={() => retrieveOrder(++order.orderNumber)}
            startOrder={startOrder}
            closeOrder={closeOrder}
            canStartOrder={canEdit && !orderStarted && latestOrderNumber === order?.orderNumber}
            canCloseOrder={canEdit && orderStarted && latestOrderNumber === order?.orderNumber}
            orderNumber={order?.orderNumber}
            latestOrderNumber={latestOrderNumber}
            addItem={() => setItemModalVisible(true)}
        />
        <OrderPageContainer
            data={getData()}
            orderId={order?.id}
            inEditMode={inEditMode}
        />

        {itemModalVisible &&
            <AddItemModal
                hideModal={() => setItemModalVisible(false)}
                addItem={addItem}
                supplierDictionary={supplierDictionary}
                itemDictionary={itemDictionary}
            />}
    </StyledOrderPage>;
};

import React, {FC, useEffect, useId, useState} from "react";
import {AutoComplete} from "primereact/autocomplete"
import SelectOption from "./service/SelectOption.type";
import shortid from "shortid";
import {InputLabel, LabelType} from "../inputLabel/InputLabel.component";
import {isNotDefined} from "../../../service/util/ObjectUtils";

interface MultiselectChipsProps {
    options: SelectOption[];
    selectedValues: any[];
    setSelectedValues: (selectedValues: any[]) => void;
    label?: string;
    required?: boolean;
    labelType?: LabelType;
    className?: string;
}

export const MultiselectChips: FC<MultiselectChipsProps> = (props: MultiselectChipsProps) => {
    const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(undefined);
    const [internalSelectedValues, setInternalSelectedValues] = useState<SelectOption[]>([]);

    useEffect(() => {
        const selectOptions: SelectOption[] = getSelectOptions(props.selectedValues);
        setInternalSelectedValues(selectOptions);
    }, [props.selectedValues]);

    const getSelectOptions = (values: any[]): SelectOption[] => {
        if (isNotDefined(values)) {
            return [];
        }

        return values.map(value => ({
            value: value,
            label: getLabel(value),
            uniqueId: shortid.generate()
        }));
    }

    const getLabel = (value: any): string => {
        return props.options.find(option => option.value === value)?.label;
    }

    const setValues = (values: SelectOption[]) => {
        props.setSelectedValues(values.map(value => value.value));
        setInternalSelectedValues(values);
    }

    const search = (event) => {
        let filteredOptions: SelectOption[];
        if (!event.query.trim().length) {
            filteredOptions = [...props.selectedValues];
        } else {
            filteredOptions = props.options.filter((option) => option.label.toLowerCase().startsWith(event.query.toLowerCase()))
                .map(value => ({...value, uniqueId: shortid.generate()}));
        }

        setFilteredOptions(filteredOptions);
    }
    const id = useId();

    return <InputLabel id={id} label={props.label} labelType={props.labelType} className={props.className}>
        <AutoComplete
            id={id}
            field="label"
            multiple
            delay={10}
            value={internalSelectedValues}
            suggestions={filteredOptions}
            completeMethod={search}
            placeholder={(props.labelType === LabelType.INLINE) ? props.label : undefined}
            onChange={(e) => setValues(e.value)}/>
    </InputLabel>;
};

import React, {FC, useEffect, useState} from "react";
import {
    getInitialInventoryFilters,
    InventoryDTO,
    InventoryEntryDTO,
    WarehouseStateEntryDTO
} from "../service/InventoryPage.type";
import {ItemCategoryDTO, ItemDictionaryDTO} from "../../itemDictionaryPage/service/ItemDictionaryPage.type";
import {InventoryEntryInput} from "./inventoryEntryInput/InventoryEntryInput.component";
import {InventoryFilters} from "./inventoryFilters/InventoryFilters.component";
import {InventoryList} from "./inventoryList/InventoryList.component";
import {
    addInventoryEntryRest,
    filterInventoryItems,
    focusOnInventoryNumberInput,
    removeInventoryEntryRest,
    updateInventoryEntryRest
} from "../service/InventoryPage.service";
import {CommandResult, isSuccess} from "../../../common/service/result/Result.type";
import {DictionaryDTO} from "../../../common/service/util/dictionary/Dictionary.type";
import {isDefined, isNotDefined} from "../../../common/service/util/ObjectUtils";
import {Button} from 'primereact/button';
import {translate} from '../../../common/lang/service/Translate.service';
import {isNotDefinedOrEmpty} from '../../../common/service/util/StringUtils';

interface InventoryContainerProps {
    inventory: InventoryDTO;
    updateInventory: () => void;
    warehouseState: WarehouseStateEntryDTO[];
    itemDictionary: ItemDictionaryDTO[];
    categoryDictionary: ItemCategoryDTO[];
    inEditMode: boolean;
    itemSubCategories: DictionaryDTO[];
}

export const InventoryContainer: FC<InventoryContainerProps> = (props: InventoryContainerProps) => {
    const [filters, setFilters] = useState(getInitialInventoryFilters());
    const [inventoryEntry, setInventoryEntry] = useState<InventoryEntryDTO>({itemId: undefined, count: 0});

    useEffect(() => {
        if (isDefined(inventoryEntry.itemId)) {
            focusOnInventoryNumberInput()
        }
    }, [inventoryEntry]);

    const getCountedItemIds = (): string[] => {
        return props.inventory?.inventoryEntries?.map(value => value.itemId);
    }

    const getItemsToCount = (): ItemDictionaryDTO[] => {
        const countedItemIds: string[] = getCountedItemIds() ?? [];
        return filterItems(props.itemDictionary.filter(value => !countedItemIds.includes(value.id)));
    }

    const getCountedItems = (): ItemDictionaryDTO[] => {
        const countedItemIds: string[] = getCountedItemIds() ?? [];
        return filterItems(props.itemDictionary.filter(value => countedItemIds.includes(value.id)));
    }

    const filterItems = (items: ItemDictionaryDTO[]): ItemDictionaryDTO[] => {
        return filterInventoryItems(items, filters);
    }

    const saveInventoryEntry = async () => {
        let result: CommandResult;
        if (props.inventory.inventoryEntries.some(value => value.itemId === inventoryEntry.itemId)) {
            result = await updateInventoryEntryRest(props.inventory.id, inventoryEntry)
        } else {
            result = await addInventoryEntryRest(props.inventory.id, inventoryEntry);
        }

        if (isSuccess(result)) {
            props.updateInventory();
            setInventoryEntry({itemId: undefined, count: 0});
        }
    }

    const removeInventoryEntry = async (itemId: string) => {
        const result: CommandResult = await removeInventoryEntryRest(props.inventory.id, itemId)

        if (isSuccess(result)) {
            setInventoryEntry({itemId: undefined, count: 0});
            props.updateInventory();
        }
    }

    const getItemCount = (itemId: string): number => {
        const first = props.inventory?.inventoryEntries.filter(value => value.itemId === itemId)?.getFirst()?.count;
        if (isDefined(first)) {
            return first;
        }

        return props.warehouseState.filter(value => value.itemId === itemId)?.getFirst()?.count ?? 0;
    }

    const updateFilters = (fieldName: string, newValue: any) => {
        setFilters(prevState => ({...prevState, [fieldName]: newValue}));
    }

    return <>
        {props.inEditMode &&
            <>
                <div className={"inventory-item-name"}>{props.itemDictionary.filter(value => value.id === inventoryEntry.itemId)?.getFirst()?.name}</div>
                <InventoryEntryInput
                    inventoryEntry={inventoryEntry}
                    setCount={(count: number) => setInventoryEntry(prevState => ({
                        itemId: prevState.itemId,
                        count: count
                    }))}
                />
                <div className={"save-button"}>
                    <Button
                        label={translate("SAVE")}
                        onClick={saveInventoryEntry}
                        style={{width: '120px'}}
                        disabled={isNotDefined(inventoryEntry) || isNotDefinedOrEmpty(inventoryEntry.itemId) || isNotDefined(inventoryEntry.count)}
                    />
                </div>
            </>}

        <InventoryFilters
            filters={filters}
            updateFilters={updateFilters}
        />

        <InventoryList
            itemsToCount={getItemsToCount()}
            countedItems={getCountedItems()}
            inventory={props.inventory}
            warehouseState={props.warehouseState}
            setItem={(itemId: string) => setInventoryEntry(() => ({itemId: itemId, count: getItemCount(itemId)}))}
            itemSubCategories={props.itemSubCategories}
            inEditMode={props.inEditMode}
            removeInventoryEntry={removeInventoryEntry}
        />
    </>;
};

export interface InventoryDTO {
    id: string;
    inventoryNumber: number;
    inventoryStatus: InventoryStatus;
    inventoryStartDate: string;
    inventoryEndDate: string;
    inventoryEntries: InventoryEntryDTO[];
}

export enum InventoryStatus {
    OPEN = "OPEN",
    CLOSED = "CLOSED"
}

export interface InventoryEntryDTO {
    itemId: string;
    count: number;
}

export interface WarehouseStateEntryDTO {
    itemId: string;
    count: number;
    lastUpdateDate: string;
}

export interface InventoryFilterFields {
    name: string;
    categoryId: string[];
    subCategoryId: string[];
    supplierId: string[];
}

export const getInitialInventoryFilters = (): InventoryFilterFields => {
    return {
        name: '',
        categoryId: [],
        subCategoryId: [],
        supplierId: []
    }
};

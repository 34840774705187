import React, {FC} from "react";
import {Day} from '../../../common/service/util/Day';
import {Button} from 'primereact/button';
import {translate} from '../../../common/lang/service/Translate.service';
import {Card} from '../../../common/components/card/Card.component';
import {Toolbar} from 'primereact/toolbar';
import {DateInput} from '../../../common/components/input/dateInput/DateInput.component';
import {LabelType} from '../../../common/components/input/inputLabel/InputLabel.component';

interface CleaningUserReportToolbarProps {
    dayFrom: Day;
    dayTo: Day;
    setDayFrom: (newDay: Day) => void;
    setDayTo: (newDay: Day) => void;
    getReport: () => void;
}

export const CleaningUserReportToolbar: FC<CleaningUserReportToolbarProps> = (props: CleaningUserReportToolbarProps) => {
    const rightToolbarTemplate = () => {
        return <Button label={translate("GET_REPORT")} onClick={props.getReport}/>;
    };

    const leftToolbarPanel = () => {
        return (<>
                <DateInput
                    setValue={props.setDayFrom}
                    value={props.dayFrom}
                    minValue={Day.getCompanyStartDate()}
                    maxValue={Day.today()}
                    label={translate("DATE_FROM")}
                    labelType={LabelType.INLINE}
                />
                <div className={'dash'}>&mdash;</div>
                <DateInput
                    setValue={props.setDayTo}
                    value={props.dayTo}
                    minValue={Day.getCompanyStartDate()}
                    maxValue={Day.today()}
                    label={translate("DATE_TO")}
                    labelType={LabelType.INLINE}
                />
            </>
        );
    }

    return <div>
        <Card>
            <Toolbar
                start={leftToolbarPanel}
                end={rightToolbarTemplate}
            />
        </Card>
    </div>;
};

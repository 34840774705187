import React, {FC} from "react";
import {ExpenseReadDTO, PaymentType} from '../service/ExpensesPage.type';
import {groupByKey} from '../../../common/service/util/ObjectUtils';
import {translate} from '../../../common/lang/service/Translate.service';
import {formatAsCurrency} from '../../../common/service/util/CurrencyUtil';
import {ValueCard} from '../../../common/components/card/valueCard/ValueCard.component';

interface ExpensesSummaryProps {
    expenses: ExpenseReadDTO[];
}

export const ExpensesSummary: FC<ExpensesSummaryProps> = (props: ExpensesSummaryProps) => {
    const expensesByType: Map<string, ExpenseReadDTO[]> = groupByKey(props.expenses, 'expenseType');

    if (props.expenses.isEmpty()) {
        return <></>
    }

    return <>
        <ValueCard
            label={translate('EXPENSES_SUM')}
            value={formatAsCurrency(props.expenses.map(value => value.amount).reduce((a, b) => a + b, 0)) + " (" + props.expenses.length + ")"}
        />

        <ValueCard
            label={translate('PRIVATE_EXPENSES_SUM')}
            value={formatAsCurrency(props.expenses.filter(value => value.paymentType !== PaymentType.COMPANY_ACCOUNT).map(value => value.amount).reduce((a, b) => a + b, 0)) + " (" + props.expenses.length + ")"}
        />

        <ValueCard
            label={translate('COMPANY_EXPENSES_SUM')}
            value={formatAsCurrency(props.expenses.filter(value => value.paymentType === PaymentType.COMPANY_ACCOUNT).map(value => value.amount).reduce((a, b) => a + b, 0)) + " (" + props.expenses.length + ")"}
        />

        {Array.from(expensesByType.values()).map((values: ExpenseReadDTO[]) =>
            <ValueCard
                key={translate(values[0].expenseType)}
                label={translate(values[0].expenseType)}
                value={formatAsCurrency(values.map(value => value.amount).reduce((a, b) => a + b, 0)) + " (" + values.length + ")"}
            />)
        }
    </>;
};

import styled from "styled-components";

interface DividerProps {
    margin?: string;
}

export const Divider = styled.div<DividerProps>`
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  height: 0;
  width: 100%;
  margin-top: ${(props: DividerProps) => props.margin};
  margin-bottom: ${(props: DividerProps) => props.margin};
`;

import styled from "styled-components";
import {DataTable} from 'primereact/datatable';

export const StyledTable = styled(DataTable)`&&& {
  th {
    border-radius: 0;
        background: unset;
  }
}
`;

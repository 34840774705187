import React, {FC} from "react";
import {formatAsCurrency} from "../../../../../common/service/util/CurrencyUtil";
import {isDefined} from "../../../../../common/service/util/ObjectUtils";

interface SupplierAdditionalCostProps {
    additionalCost: number;
    additionalCostDescription: string;
}

export const SupplierAdditionalCost: FC<SupplierAdditionalCostProps> = (props: SupplierAdditionalCostProps) => {
    return <>
        {isDefined(props.additionalCostDescription) && <div>
            <div className={'additional-cost'}>
                {props.additionalCostDescription + ': ' + formatAsCurrency(props.additionalCost)}
            </div>
        </div>}
    </>;
};

import styled from "styled-components";

export const StyledFileInput = styled.div`&&& {
  .p-button {
    margin-right: 0;
  }
  
  .p-fileupload-content {
    display: none;
  }
  
  .p-fileupload-buttonbar {
    border: unset;
    padding: 0;
  }
  
  .p-fileupload.p-fileupload-advanced.p-component {
  }
}`;

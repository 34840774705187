import React, {FC} from "react";
import {StyledTitle} from "./Title.style";
import {isDefined} from "../../service/util/ObjectUtils";

interface TitleProps {
    title: string;
    subtitle?: string;
    upperTitle?: string;
    className?: string;
    onClick?: () => void;
}

export const Title: FC<TitleProps> = (props: TitleProps) => {
    return <StyledTitle className={props.className} onClick={props.onClick}>
        <div className="layout-invoice-page">
            <div id="invoice-content">
                <div className="invoice">
                    <div className="invoice-to">
                        {isDefined(props.upperTitle) && <div className="invoice-date">{props.upperTitle}</div>}
                        <div className="invoice-id">
                            {props.title} {isDefined(props.subtitle) && <span>{props.subtitle}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </StyledTitle>;
};

import React, {FC} from "react";
import {Table} from "../../../common/components/table/Table.component";
import {CleaningDictionaryDTO} from "../service/CleaningDictionary.type";
import {ColumnProps} from "../../../common/components/table/service/Table.types";
import {translate} from "../../../common/lang/service/Translate.service";
import {Button} from "primereact/button";

interface CleaningDictionaryTableProps {
    cleaningDictionary: CleaningDictionaryDTO[];
    onEdit: (cleaningDictionary: CleaningDictionaryDTO) => void;
}

export const CleaningDictionaryTable: FC<CleaningDictionaryTableProps> = (props: CleaningDictionaryTableProps) => {
    const editBodyTemplate = (rowData: CleaningDictionaryDTO) => (
        <div className="actions">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2"
                    onClick={() => props.onEdit(rowData)}/>
        </div>
    );

    const columnProps: ColumnProps[] = [
        {header: translate('NAME'), fieldName: 'name'},
        {header: translate('ACTIVATION_DATE'), fieldName: 'activationDate'},
        {header: translate('DEACTIVATION_DATE'), fieldName: 'deactivationDate'},
        {header: translate('CLEANING_INTERVAL'), fieldName: 'cleaningInterval'},
        {header: "", fieldName: "ID", body: editBodyTemplate, width: '32px'},
    ];

    return <>
        <Table
            values={props.cleaningDictionary}
            dataKey={"id"}
            columnProps={columnProps}
        />
    </>;
};

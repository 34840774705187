import React, {FC, PropsWithChildren, useEffect} from "react";
import {AppDispatch} from "../../redux/Thunk.type";
import {useDispatch} from "react-redux";
import {UserBasicInfoDTO} from "./service/UserBasicInfo.type";
import {isDefined, isNotDefined} from "../../service/util/ObjectUtils";
import retrieveUserBasicInfoAction from "./service/UserBasicInfo.action";
import {authenticationService} from "../../../../index";
import {useCurrentUser} from "./service/CurrentUser.hook";

interface UserBasicInfoProps {
}

export const UserBasicInfo: FC<PropsWithChildren<UserBasicInfoProps>> = (props: PropsWithChildren<UserBasicInfoProps>) => {
    const userBasicInfo: UserBasicInfoDTO = useCurrentUser();
    const dispatch: AppDispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (isNotDefined(userBasicInfo.id) && authenticationService.isUserLoggedIn()) {
            dispatch(retrieveUserBasicInfoAction());
        }
    }, []);

    return <>
        {isDefined(userBasicInfo) && props.children}
    </>;
};

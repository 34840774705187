import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {authenticationService} from "../../../../index";

interface RouteChangedInterceptorProps {
}

const history = window.history;
const pushState = history.pushState;

export const RouteChangedInterceptor: FC<PropsWithChildren<RouteChangedInterceptorProps>> = (props: PropsWithChildren<RouteChangedInterceptorProps>) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        handleAuth();
    }, []);

    useEffect(() => {
        history.pushState = function (state) {
            handleAuth();
            pushState.apply(history, arguments);
        };

        window.onpopstate = function (event) {
            handleAuth();
        };
    }, []);

    const handleAuth = () => {
        authenticationService.handleUserAuth().then(value => setIsAuthenticated(value));
    }

    return <>
        {isAuthenticated && props.children}
    </>;
};

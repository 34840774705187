import styled from "styled-components";

export interface StyledCheckboxProps {
    verticalInputAlign: boolean;
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  
  ${props => props.verticalInputAlign ? {paddingTop: "32px"} : undefined }
  ${props => props.verticalInputAlign ? {paddingLeft: "7px"} : undefined }
  ${props => props.verticalInputAlign ? {paddingRight: "7px"} : undefined }
  
  label {
    line-height: 20px;
    cursor: pointer;
    color: #868C9B;
    font-weight: 500;
  }
  
  .checkbox {
    margin-right: 10px;
  }
  
  .checkbox-label {
    cursor: pointer;
  }
`;

import {FC} from "react";
import {CleaningEntryDTO} from "../service/CleaningPage.types";
import {CleaningEntry} from "./cleaningEntry/CleaningEntry.component";

interface CleaningEntriesProps {
    cleaningEntries: CleaningEntryDTO[];
    editCleaning: (data: CleaningEntryDTO) => void;
    deleteCleaning: (cleaningId: string) => void;
}

export const CleaningEntries: FC<CleaningEntriesProps> = (props: CleaningEntriesProps) => {
    return <>
        {props.cleaningEntries
            .map(value => <CleaningEntry
                key={value.userId}
                cleaningEntry={value}
                editCleaning={props.editCleaning}
                deleteCleaning={props.deleteCleaning}
            />)}
    </>;
}

export const setCookie = (name: string, value: string, minutesToExpire: number) => {
    let expiration = new Date();
    expiration.setTime(expiration.getTime() + (minutesToExpire * 60 * 1000));
    let expires = "expires=" + expiration.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const getCookie = (cookieName: string) => {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const removeCookie = (cookieName: string) => {
    const pastDate = new Date(2000, 0, 1);
    const cookies = document.cookie.split(";");
    cookies.filter(item => item.trim().indexOf(cookieName + "=") === 0)
        .forEach(item => document.cookie = item.trim() + "; expires=" + pastDate.toUTCString() + "; path=/");
}
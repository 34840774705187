import {Dispatch} from "redux";
import {SHOW_GROWL} from "./GrowlActions.type";
import {ToastMessage} from "primereact/toast";
import {AppActions} from "../../redux/AppActions";

const showGrowl = (message: ToastMessage | ToastMessage[]) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch(showGrowlAction(message));
    };
};

const showGrowlAction = (message: ToastMessage | ToastMessage[]): AppActions => {
    return {
        type: SHOW_GROWL,
        message: message
    };
};

export default showGrowl;

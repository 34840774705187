import React, {FC, useState} from "react";
import {StyledUserProfilePersonalDataTab} from "./UserProfilePersonalDataTab.style";
import {UserPersonalDataDTO, UserProfilePagePermissionsDTO} from "../../service/UserProfilePage.type";
import {ValueLabel} from "../../../common/components/input/valueLabel/ValueLabel.component";
import {ButtonRow} from "../../../common/components/buttonRow/ButtonRow.component";
import {Button} from "primereact/button";
import {UserProfilePersonalDataModal} from "./userProfilePersonalDataModal/UserProfilePersonalDataModal.component";
import {CommandResult, isSuccess} from "../../../common/service/result/Result.type";
import {saveUserPersonalDataRest} from "../../service/UserProfilePage.service";
import {translate} from "../../../common/lang/service/Translate.service";
import retrieveUserBasicInfoAction from "../../../common/security/userBasicInfo/service/UserBasicInfo.action";
import {AppDispatch} from "../../../common/redux/Thunk.type";
import {useDispatch} from "react-redux";

interface UserProfilePersonalDataTabProps {
    id: string;
    permissions: UserProfilePagePermissionsDTO;
    userPersonalData: UserPersonalDataDTO;
    refreshPersonalData: () => void;
}

export const UserProfilePersonalDataTab: FC<UserProfilePersonalDataTabProps> = (props: UserProfilePersonalDataTabProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const dispatch: AppDispatch = useDispatch<AppDispatch>();

    const savePersonalData = async (newPersonalData: UserPersonalDataDTO) => {
        const result: CommandResult = await saveUserPersonalDataRest(props.id, newPersonalData);
        if (isSuccess(result)) {
            setModalVisible(false);
            props.refreshPersonalData();
            dispatch(retrieveUserBasicInfoAction());
        }
    }

    return <>
        <StyledUserProfilePersonalDataTab>
            <div className={'fields'}>
                <ValueLabel
                    className={'personal-data-field'}
                    value={props.userPersonalData.firstName}
                    label={translate("FIRST_NAME")}
                />
                <ValueLabel
                    className={'personal-data-field'}
                    value={props.userPersonalData.lastName}
                    label={translate("LAST_NAME")}
                />
                <ValueLabel
                    className={'personal-data-field'}
                    value={props.userPersonalData.displayName}
                    label={translate("DISPLAY_NAME")}
                />
            </div>
            <ButtonRow alignRight={false}>
                <Button onClick={() => setModalVisible(true)} icon={"pi pi-pencil"}
                        className={"p-button-rounded p-button-text"} label={translate('EDIT')}/>
            </ButtonRow>
        </StyledUserProfilePersonalDataTab>
        {modalVisible && <UserProfilePersonalDataModal
            hideModal={() => setModalVisible(false)}
            savePersonalData={savePersonalData}
            dataToEdit={props.userPersonalData}
        />}
    </>;
};

import {FC, useState} from "react";
import {CleaningEntryItemDTO, CleaningEntryItemStatus, RequiresCleaningDTO} from "../service/CleaningPage.types";
import {ActionModal} from "../../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../../common/lang/service/Translate.service";
import {Day} from "../../../common/service/util/Day";
import {CommandResult, isSuccess} from "../../../common/service/result/Result.type";
import {updateCleaningEntryRest} from "../service/CleaningPage.service";
import {Checkbox} from "../../../common/components/input/checkbox/Checkbox.component";
import {isDefined, isNotDefined} from "../../../common/service/util/ObjectUtils";
import {TextInput} from '../../../common/components/input/textInput/TextInput.component';
import {LabelType} from '../../../common/components/input/inputLabel/InputLabel.component';
import {isNotDefinedOrEmpty, removeDiacritics} from '../../../common/service/util/StringUtils';
import {TextAreaInput} from '../../../common/components/input/textAreaInput/TextAreaInput.component';
import {StyledAddCleaningModal} from './AddCleaningModal.style';
import {Divider} from "../../../common/Divider.style";

interface AddCleaningModalProps {
    requiresCleaning: RequiresCleaningDTO[];
    hideModal: () => void;
    date: Day;
    items?: CleaningEntryItemDTO[];
    comment: string;
}

export const AddCleaningModal: FC<AddCleaningModalProps> = (props: AddCleaningModalProps) => {
    const [cleanedItems, setCleanedItems] = useState<CleaningEntryItemDTO[]>(props.items);
    const [comment, setComment] = useState<string>(props.comment);
    const [nameFilter, setNameFilter] = useState<string>('');

    const save = async () => {
        const result: CommandResult = await updateCleaningEntryRest({
            date: props.date.toFormattedString(),
            items: cleanedItems,
            comment: comment
        });
        if (isSuccess(result)) {
            props.hideModal();
        }
    }

    const setValue = (value: boolean, itemId: string) => {
        if (!isDefined(value)) {
            setCleanedItems(prevState => prevState.filter(v => v.itemId !== itemId));
        } else if (value) {
            setCleanedItems(prevState => {
                const newState = prevState.filter(v => v.itemId !== itemId);
                newState.push({itemId: itemId, status: CleaningEntryItemStatus.CLEAN})
                return newState;
            })
        } else {
            setCleanedItems(prevState => {
                const newState = prevState.filter(v => v.itemId !== itemId);
                newState.push({itemId: itemId, status: CleaningEntryItemStatus.DIRTY})
                return newState;
            })
        }
    }

    function getCheckboxValue(itemId: string) {
        const foundItem = cleanedItems.filter(value => value.itemId === itemId).getFirst();
        return isNotDefined(foundItem) ? null : mapStatus(foundItem);
    }

    const mapStatus = (cleaningEntryItem: CleaningEntryItemDTO): boolean => {
        return cleaningEntryItem.status === CleaningEntryItemStatus.CLEAN;
    }

    const getFilteredCleaning = (): RequiresCleaningDTO[] => {
        if (isNotDefinedOrEmpty(nameFilter)) {
            return props.requiresCleaning;
        }

        return props.requiresCleaning
            .filter(value => removeDiacritics(value.itemName.toLowerCase())
                .indexOf(removeDiacritics(nameFilter?.toLowerCase())) >= 0)
    };

    return <ActionModal
        header={translate("ADD_CLEANING")}
        onConfirm={save}
        onReject={props.hideModal}
        isVisible={true}
        isSaveDisabled={cleanedItems.isEmpty() || (isDefined(comment) && comment.length > 2000)}>
        <StyledAddCleaningModal>

            <TextInput
                value={nameFilter}
                setValue={newValue => setNameFilter(newValue)}
                label={translate("FILTER")}
                labelType={LabelType.INLINE}
            />
            <Divider margin={'12px'}/>

            {getFilteredCleaning().map(value =>
                <Checkbox
                    key={value.itemId}
                    value={getCheckboxValue(value.itemId)}
                    label={value.itemName}
                    setValue={(checkboxValue: boolean) => setValue(checkboxValue, value.itemId)}
                    triState={true}
                />
            )}
            <TextAreaInput
                value={comment}
                setValue={newValue => setComment(newValue)}
                label={translate("COMMENT")}
                labelType={LabelType.INLINE}
                rows={3}
                className={'comment'}
            />
        </StyledAddCleaningModal>
    </ActionModal>;
}

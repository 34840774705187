import React, {FC, useEffect, useState} from "react";
import {CashReportDataDTO, EmployeeSalaryDTO, SalariesDTO} from "../service/CashReportPage.type";
import {DayRange} from "../../common/service/util/DayRange";
import {ActionModal} from "../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../common/lang/service/Translate.service";
import {CurrencyInput} from "../../common/components/input/currencyInput/CurrencyInput.component";
import {getMandateEmployeesRest} from "../service/CashReportPage.service";
import {LabelType} from "../../common/components/input/inputLabel/InputLabel.component";
import {StyledSalariesModal} from "./SalariesModal.style";
import {DateInput} from "../../common/components/input/dateInput/DateInput.component";
import {Divider} from "../../common/Divider.style";
import {Day} from "../../common/service/util/Day";
import {isDefined, isNotDefined} from "../../common/service/util/ObjectUtils";
import {DictionaryDTO} from "../../common/service/util/dictionary/Dictionary.type";
import {Translate} from "../../common/lang/Translate.component";
import {formatAsCurrency} from "../../common/service/util/CurrencyUtil";
import {compareStrings} from "../../common/service/util/StringUtils";

interface SalariesModalProps {
    data: CashReportDataDTO;
    saveSalaries: (salaries: SalariesDTO) => void;
    hideModal: () => void;
    currentPeriod: DayRange;
}

export const SalariesModal: FC<SalariesModalProps> = (props: SalariesModalProps) => {
    const getInitialDate = () => {
        if (isDefined(props.data?.date)) {
            return Day.fromString(props.data?.date);
        }
        return props.currentPeriod.isCurrent() ? Day.today() : undefined
    }

    const [date, setDate] = useState<Day>(getInitialDate());
    const [salaries, setSalaries] = useState<EmployeeSalaryDTO[]>([]);
    const [employees, setEmployees] = useState<DictionaryDTO[]>([]);

    useEffect(() => {
        retrieveEmployees();
    }, []);

    async function retrieveEmployees() {
        const month = props.currentPeriod.getMiddleDay().getMonthNumber();
        const year = props.currentPeriod.getMiddleDay().getFullYear();
        const employeeDictionaryDTO: DictionaryDTO[] = await getMandateEmployeesRest(month, year);
        setEmployees(employeeDictionaryDTO.sort((a, b) => compareStrings(a.name.split(" ")[1], b.name.split(" ")[1])));
        setSalaries(getSalaries(employeeDictionaryDTO));
    }

    const getSalaries = (employees: DictionaryDTO[]): EmployeeSalaryDTO[] => {
        return employees.map(employee => ({userId: employee.id, salary: getSalary(employee.name)}));
    }

    const getSalary = (employeeName: string): number => {
        return props.data?.items.find(value => value.name === employeeName)?.value;
    }

    const confirm = () => {
        props.saveSalaries({salaries: salaries, date: date.dateToUrlString()});
    }

    const isDataValid = (): boolean => {
        return isDefined(date) && salaries.none(salary => isNotDefined(salary.salary));
    }

    const setSalary = (newSalary: number, userId: string) => {
        return setSalaries(prevState => prevState.map(salary => salary.userId === userId
            ? {salary: newSalary, userId: userId}
            : salary));
    }

    const getSalaryValue = (userId: string): number => {
        return salaries.find(salary => salary.userId === userId)?.salary;
    }

    const total = salaries.map(value => value.salary).reduce((prevValue, currentValue) => (prevValue + currentValue), 0);

    return <ActionModal
        isVisible={true}
        onConfirm={confirm}
        isSaveDisabled={!isDataValid()}
        onReject={props.hideModal}
        header={translate("SALARIES")}>
        <StyledSalariesModal>
            <DateInput
                label={translate("DATE")}
                labelType={LabelType.HORIZONTAL}
                value={date}
                setValue={setDate}
                minValue={props.currentPeriod.getDayFrom()}
                maxValue={props.currentPeriod.getDayTo()}
                viewDate={props.currentPeriod.getMiddleDay()}
            />

            <Divider margin={"15px"}/>

            {employees.map((user, index) =>
                <CurrencyInput
                    key={user.id}
                    label={(index + 1) + ". " + user.name}
                    value={getSalaryValue(user.id)}
                    setValue={(value) => setSalary(value, user.id)}
                    labelType={LabelType.HORIZONTAL}
                />
            )}

            <div className={"summary-row"}>
                {<div><Translate id={"TOTAL"}/> {formatAsCurrency(total)}</div>}
            </div>
        </StyledSalariesModal>
    </ActionModal>;
};

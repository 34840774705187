export interface CleaningDTO {
    cleaningEntries: CleaningEntryDTO[];
    requiresCleaning: RequiresCleaningDTO[];
}

export interface CleaningEntryDTO {
    id: string;
    userId: string;
    userName: string;
    comment: string;
    cleanedItemIds: CleaningEntryItemDTO[];
}

export interface RequiresCleaningDTO {
    itemId: string;
    itemName: string;
    daysToWarning: number;
    cleanedToday: boolean;
    markedAsRequiresCleaning: boolean;
    cleaningInterval: number;
}

export interface CleaningEntryItemDTO {
    itemId: string;
    itemName?: string;
    status: CleaningEntryItemStatus;
}

export enum CleaningEntryItemStatus {
    CLEAN = "CLEAN",
    DIRTY = "DIRTY"
}

export interface UpdateCleaningEntryDTO {
    items: CleaningEntryItemDTO[];
    date: string;
    comment: string;
}

import React, {FC, useState} from "react";
import {Day} from '../../../../common/service/util/Day';
import {isDefined} from '../../../../common/service/util/ObjectUtils';
import {CommandResult, isSuccess} from '../../../../common/service/result/Result.type';
import {updateContractInformationRest} from '../service/UserContractInformation.service';
import {ActionModal} from '../../../../common/modal/actionModal/ActionModal.component';
import {translate} from '../../../../common/lang/service/Translate.service';
import {LabelType} from '../../../../common/components/input/inputLabel/InputLabel.component';
import {ContractType} from '../service/UserContractInformation.type';
import {SingleSelectInput} from '../../../../common/components/input/singleSelectInput/SingleSelectInput.component';
import {getEnumSelectOptions} from '../../../../common/components/input/multiselectChips/service/SelectOption.type';
import {DateInput} from '../../../../common/components/input/dateInput/DateInput.component';
import {NumberInput} from '../../../../common/components/input/numberInput/NumberInput.component';

interface UpdateUserContractModalProps {
    id: string;
    hideModal: () => void;
}

export const UpdateUserContractModal: FC<UpdateUserContractModalProps> = (props: UpdateUserContractModalProps) => {
    const [startDate, setStartDate] = useState<Day>();
    const [sharePercentage, setSharePercentage] = useState<number>();
    const [contractType, setContractType] = useState<ContractType>();

    const isValid = (): boolean => {
        return isDefined(startDate) && isDefined(sharePercentage) && isDefined(contractType);
    }

    const onSave = async () => {
        const result: CommandResult = await updateContractInformationRest(props.id,
            {contractType: contractType, startDate: startDate.dateToUrlString(), sharePercentage: sharePercentage});
        if (isSuccess(result)) {
            props.hideModal();
        }
    }

    return <ActionModal
        header={translate("TERMINATE_CONTRACT")}
        isSaveDisabled={!isValid()}
        onConfirm={onSave}
        onReject={props.hideModal}
        isVisible={true}
    >
        <DateInput
            setValue={setStartDate}
            value={startDate}
            labelType={LabelType.VERTICAL}
            label={translate("CONTRACT_START_DATE")}
        />

        <NumberInput
            setValue={setSharePercentage}
            value={sharePercentage}
            labelType={LabelType.VERTICAL}
            label={translate("SHARE_PERCENTAGE")}
            minValue={0}
            maxValue={100}
        />

        <SingleSelectInput
            options={getEnumSelectOptions(ContractType)}
            selectedValue={contractType}
            setSelectedValue={setContractType}
            labelType={LabelType.VERTICAL}
            label={translate("CONTRACT_TYPE")}
        />
    </ActionModal>
};

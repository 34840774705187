import React, {FC, useEffect, useState} from "react";
import {CashReportDataDTO, PaymentDTO, PaymentsDTO} from "../service/CashReportPage.type";
import {DayRange} from "../../common/service/util/DayRange";
import {ActionModal} from "../../common/modal/actionModal/ActionModal.component";
import {translate} from "../../common/lang/service/Translate.service";
import {DateInput} from "../../common/components/input/dateInput/DateInput.component";
import {Day} from "../../common/service/util/Day";
import {LabelType} from "../../common/components/input/inputLabel/InputLabel.component";
import {Divider} from "../../common/Divider.style";
import {MultiselectChips} from "../../common/components/input/multiselectChips/MultiselectChips.component";
import SelectOption, {
    getDictionarySelectOption
} from "../../common/components/input/multiselectChips/service/SelectOption.type";
import {getCurrentOfferRest} from "../service/CashReportPage.service";
import {DictionaryDTO} from "../../common/service/util/dictionary/Dictionary.type";
import {CurrencyInput} from "../../common/components/input/currencyInput/CurrencyInput.component";
import {isDefined, isNotDefined} from "../../common/service/util/ObjectUtils";
import {Button} from "primereact/button";
import {StyledPaymentsModal} from "./PaymentsModal.style";
import {Translate} from "../../common/lang/Translate.component";
import {formatAsCurrency} from "../../common/service/util/CurrencyUtil";
import {FileInput} from '../../common/components/fileInput/FileInput.component';
import {TextInput} from "../../common/components/input/textInput/TextInput.component";

interface PaymentsModalProps {
    data: CashReportDataDTO;
    savePayments: (payments: PaymentsDTO, files: File[]) => void;
    hideModal: () => void;
    currentPeriod: DayRange;
}

export const PaymentsModal: FC<PaymentsModalProps> = (props: PaymentsModalProps) => {
    const getInitialDate = () => {
        if (isDefined(props.data?.date)) {
            return Day.fromString(props.data?.date);
        }
        return props.currentPeriod.isCurrent() ? Day.today() : undefined
    }

    const [date, setDate] = useState<Day>(getInitialDate());
    const [proof, setProof] = useState<string>(props.data?.proof);
    const [currentOffer, setCurrentOffer] = useState<SelectOption[]>([]);

    const [payments, setPayments] = useState<PaymentDTO[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [filesChanged, setFilesChanged] = useState(false);

    useEffect(() => {
        retrieveCurrentOffer();
    }, []);

    useEffect(() => {
        if (isDefined(props.data)) {
            setPayments(props.data?.items.map((item, index) => ({
                index: index,
                amount: item.value,
                offerItemIds: item.offerItemIds
            })));
        } else {
            setPayments([{index: 0, offerItemIds: [], amount: undefined}]);
        }
    }, [props.data]);

    async function retrieveCurrentOffer() {
        const middleDay = props.currentPeriod.getMiddleDay();
        const currentOffer: DictionaryDTO[] = await getCurrentOfferRest(middleDay.getMonthNumber(), middleDay.getFullYear());
        setCurrentOffer(getDictionarySelectOption(currentOffer));
    }

    const confirm = () => {
        props.savePayments({payments: payments, date: date, proof: proof, filesChanged: filesChanged}, files);
    }

    const isDataValid = (): boolean => {
        return isDefined(date) && arePaymentsValid();
    }

    const arePaymentsValid = (): boolean =>
        payments.isNotEmpty() && payments.none(payment =>
            isNotDefined(payment.index)
            || isNotDefined(payment.amount)
            || isNotDefined(payment.offerItemIds)
            || payment.amount <= 0
            || payment.offerItemIds.isEmpty())

    const setSelectedOffer = (value: PaymentDTO, offerItemIds: string[]) => {
        setPayments(prevState => prevState.map(payment => payment.index !== value.index ? payment : {
            index: value.index,
            amount: value.amount,
            offerItemIds: offerItemIds
        }))
    }

    const setPaymentValue = (value: PaymentDTO, amount: number) => {
        setPayments(prevState => prevState.map(payment => payment.index !== value.index ? payment : {
            index: value.index,
            amount: amount,
            offerItemIds: value.offerItemIds
        }))
    }

    function addNewRow() {
        setPayments(prevState => [...prevState, {index: prevState.length, amount: undefined, offerItemIds: []}])
    }

    const deleteRow = (index: number) => {
        setPayments(prevState => prevState
            .filter(value => value.index !== index)
            .map((value, index) => ({...value, index: index})))
    }

    const total = payments.map(value => value.amount).reduce((prevValue, currentValue) => (prevValue + currentValue), 0);

    const enterListener = (keyCode: number, index: number) => {
        if (keyCode === 9 && index === payments.length - 1) {
            setPayments(prevState => [...prevState, {index: prevState.length, amount: undefined, offerItemIds: []}])
        }
    }

    return <ActionModal
        isVisible={true}
        onConfirm={confirm}
        isSaveDisabled={!isDataValid()}
        onReject={props.hideModal}

        header={translate("PAYMENTS")}>
        <StyledPaymentsModal>
            <div className="formgrid grid" onDrop={e => {
                e.preventDefault();
            }} onDragOver={e => {
                e.preventDefault()
            }}>
                <DateInput
                    label={translate("DATE")}
                    labelType={LabelType.VERTICAL}
                    value={date}
                    setValue={setDate}
                    className={"col"}
                    minValue={props.currentPeriod.getDayFrom()}
                    maxValue={props.currentPeriod.getDayTo()}
                    viewDate={props.currentPeriod.getMiddleDay()}
                />
                <TextInput
                    label={translate("PROOF")}
                    labelType={LabelType.VERTICAL}
                    value={proof}
                    setValue={setProof}
                    className={"col"}
                />
            </div>
            <Divider margin={"15px"}/>
            {payments.map((payment) =>
                <div className="formgrid grid" key={payment.index}>
                    <div className={"index-column"}>{payment.index + 1}.</div>
                    {currentOffer.isNotEmpty() && <MultiselectChips
                        selectedValues={payment.offerItemIds}
                        setSelectedValues={(values) => setSelectedOffer(payment, values)}
                        options={currentOffer}
                        className={"col"}
                        labelType={LabelType.INLINE}
                        label={payment.offerItemIds.isEmpty() ? translate("ITEMS") : ""}
                    />}
                    <CurrencyInput
                        value={payment.amount}
                        setValue={(amount) => setPaymentValue(payment, amount)}
                        className={"col-3"}
                        labelType={LabelType.INLINE}
                        label={translate("AMOUNT")}
                        onKeyDown={(event) => enterListener(event.keyCode, payment.index)}
                    />
                    <Button
                        tabIndex={-1}
                        icon={"pi pi-times"}
                        severity={"danger"}
                        className={"p-button-rounded p-button-text delete-button"}
                        onClick={() => deleteRow(payment.index)}
                    />
                </div>
            )}

            <div className={"summary-row"}>
                {<div><Translate id={"TOTAL"}/> {formatAsCurrency(total)}</div>}
                <Button
                    icon={"pi pi-plus"}
                    severity={"success"}
                    className={"p-button-rounded p-button-text add-button"}
                    onClick={addNewRow}
                />
            </div>
            <FileInput
                className={"file-input"}
                onSelect={files => {
                    setFiles(files);
                    setFilesChanged(true)
                }}
                files={files}
                maxFileSize={10000}
                accept={".png, .jpg, .jpeg"}
                multiple={true}
            />
        </StyledPaymentsModal>
    </ActionModal>;
};

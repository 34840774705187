import React, {FC, useState} from "react";
import {CleaningDictionaryDTO} from "../service/CleaningDictionary.type";
import {ActionModal} from "../../../common/modal/actionModal/ActionModal.component";
import {isDefined, isNotDefined} from "../../../common/service/util/ObjectUtils";
import {TextInput} from "../../../common/components/input/textInput/TextInput.component";
import {translate} from "../../../common/lang/service/Translate.service";
import {LabelType} from "../../../common/components/input/inputLabel/InputLabel.component";
import {DateInput} from "../../../common/components/input/dateInput/DateInput.component";
import {Day} from "../../../common/service/util/Day";
import {NumberInput} from "../../../common/components/input/numberInput/NumberInput.component";
import {isDefinedAndNotEmpty} from "../../../common/service/util/StringUtils";

interface CleaningDictionaryModalProps {
    initialEntry: CleaningDictionaryDTO;
    onSave: (entry: CleaningDictionaryDTO) => void;
    hideModal: () => void;
}

export const CleaningDictionaryModal: FC<CleaningDictionaryModalProps> = (props: CleaningDictionaryModalProps) => {
    const [entry, setEntry] = useState<CleaningDictionaryDTO>(props.initialEntry);

    const updateEntry = (field: string, value: any) => {
        setEntry(prevState => ({...prevState, [field]: value}));
    }

    const onConfirm = () => {
        props.onSave(entry);
    }

    const formInvalid = (): boolean => {
        return !isDefinedAndNotEmpty(entry.name)
            || isNotDefined(entry.cleaningInterval)
            || entry.cleaningInterval <= 0
            || isNotDefined(entry.activationDate)
            || (isDefined(entry.activationDate) && isDefined(entry.deactivationDate)
                && Day.nullableFromURLString(entry.deactivationDate).isBeforeOrEqualDay(Day.fromURLString(entry.activationDate)))
    }

    return <ActionModal
        header={isDefined(props.initialEntry?.id) ? translate("ADD") : translate("EDIT")}
        onConfirm={onConfirm}
        isVisible={true}
        onReject={props.hideModal}
        isSaveDisabled={formInvalid()}
    >
        <div className="formgrid grid">
            <TextInput
                label={translate("NAME")}
                value={entry.name}
                setValue={(value: string) => updateEntry('name', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
            <NumberInput
                label={translate("CLEANING_INTERVAL")}
                value={entry.cleaningInterval}
                setValue={(value: number) => updateEntry('cleaningInterval', value)}
                labelType={LabelType.VERTICAL}
                className={"col"}
                minValue={1}
                maxValue={999}
            />
        </div>
        <div className="formgrid grid">
            <DateInput
                label={translate("ACTIVATION_DATE")}
                value={Day.nullableFromURLString(entry?.activationDate)}
                setValue={(value: Day) => updateEntry('activationDate', value?.toFormattedString())}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
            <DateInput
                label={translate("DEACTIVATION_DATE")}
                value={Day.nullableFromURLString(entry.deactivationDate)}
                setValue={(value: Day) => updateEntry('deactivationDate', value?.toFormattedString())}
                labelType={LabelType.VERTICAL}
                className={"col"}
            />
        </div>
    </ActionModal>;
};

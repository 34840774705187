import styled from "styled-components";

export const StyledInventoryEntryInput = styled.div`
  .entry-container {
    text-align: center;
  }

  .save-button {
    margin-top: auto;
    margin-bottom: auto;
    height: 43px;
  }

  .select-save-container {
    //display: flex;
  }

  .input-button {
    width: 120px;
    height: 60px;
    line-height: 60px;
    font-size: 28px;
    font-weight: bold;
  }

  .input-input {
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    width: 120px;
  }

  .item-select {
    span {
      font-size: 20px;
    }
  }
`;

import React, {FC} from "react";
import {SupplierDictionaryDTO} from '../service/SupplierDictionaryPage.type';
import {Button} from 'primereact/button';
import {ColumnProps, FilterType} from '../../../common/components/table/service/Table.types';
import {translate} from '../../../common/lang/service/Translate.service';
import {Table} from '../../../common/components/table/Table.component';

interface SupplierDictionaryTableProps {
    supplierDictionary: SupplierDictionaryDTO[];
    onEdit: (supplierDictionary: SupplierDictionaryDTO) => void;
}

export const SupplierDictionaryTable: FC<SupplierDictionaryTableProps> = (props: SupplierDictionaryTableProps) => {
    const editBodyTemplate = (rowData: SupplierDictionaryDTO) => (
        <div className="actions">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2"
                    onClick={() => props.onEdit(rowData)}/>
        </div>
    );

    const columnProps: ColumnProps[] = [
        {header: translate('NAME'), fieldName: 'name', sortable: true, filterType: FilterType.INPUT},
        {header: translate('INVOICE_NAME'), fieldName: 'invoiceName', sortable: true, filterType: FilterType.INPUT},
        {header: translate('SHOP_URL'), fieldName: 'shopUrl', sortable: true},
        {header: translate('SUPPLIER_TYPE'), fieldName: 'supplierType', transformValue: translate, sortable: true, filterType: FilterType.SELECT},
        {header: "", fieldName: "ID", body: editBodyTemplate, width: '32px'},
    ];

    return <Table
        values={props.supplierDictionary}
        dataKey={"id"}
        columnProps={columnProps}
        multiSortMeta={[{field: "name", order: 1}]}
    />;
};

import React, {FC, useEffect, useState} from "react";
import {StyledItemDictionaryPage} from "./ItemDictionaryPage.style";
import {getInitialItemDictionaryDTO, ItemCategoryDTO, ItemDictionaryDTO} from './service/ItemDictionaryPage.type';
import {addItemRest, getItemCategoriesRest, getItemsRest, updateItemRest} from './service/ItemDictionaryPage.service';
import {CommandResult, isSuccess} from '../../common/service/result/Result.type';
import {isDefined} from '../../common/service/util/ObjectUtils';
import {Card} from '../../common/components/card/Card.component';
import {translate} from '../../common/lang/service/Translate.service';
import {ItemDictionaryTable} from './itemDictionaryTable/ItemDictionaryTable.component';
import {ButtonRow} from '../../common/components/buttonRow/ButtonRow.component';
import {Button} from 'primereact/button';
import {ItemDictionaryModal} from './itemDictionaryModal/ItemDictionaryModal.component';
import {DictionaryDTO} from '../../common/service/util/dictionary/Dictionary.type';
import {getSuppliersSimpleDictionaryRest} from '../supplierDictionaryPage/service/SupplierDictionaryPage.service';

interface ItemDictionaryPageProps {
}

export const ItemDictionaryPage: FC<ItemDictionaryPageProps> = (props: ItemDictionaryPageProps) => {
    const [itemDictionary, setItemDictionary] = useState<ItemDictionaryDTO[]>([]);
    const [itemCategories, setItemCategories] = useState<ItemCategoryDTO[]>([]);
    const [suppliers, setSuppliers] = useState<DictionaryDTO[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [editedEntry, setEditedEntry] = useState<ItemDictionaryDTO>(undefined);

    useEffect(() => {
        retrieveItemDictionary();
    }, []);

    async function retrieveItemDictionary() {
        const itemDictionaryDTOs = await getItemsRest();
        setItemDictionary(itemDictionaryDTOs);
        const itemCategoryDTOs = await getItemCategoriesRest();
        setItemCategories(itemCategoryDTOs);
        const supplierDTOs = await getSuppliersSimpleDictionaryRest();
        setSuppliers(supplierDTOs);
    }

    const onEdit = (itemDictionaryEntry: ItemDictionaryDTO) => {
        setEditedEntry(itemDictionaryEntry);
        setModalVisible(true);
    }

    const onAdd = () => {
        setEditedEntry(getInitialItemDictionaryDTO());
        setModalVisible(true);
    }

    const onSave = async (entry: ItemDictionaryDTO) => {
        const result: CommandResult = isDefined(entry.id)
            ? await updateItemRest(entry.id, entry)
            : await addItemRest(entry)

        if (isSuccess(result)) {
            await retrieveItemDictionary();
            hideModal();
        }
    }

    const hideModal = () => {
        setEditedEntry(getInitialItemDictionaryDTO());
        setModalVisible(false);
    }

    const getItemSubCategories = (): DictionaryDTO[] => {
        return itemCategories.map(value => value.subCategories).flat(1);
    }

    return <StyledItemDictionaryPage>
        <Card label={translate("SUPPLIER_DICTIONARY")}>
            <ItemDictionaryTable
                itemDictionary={itemDictionary}
                onEdit={onEdit}
                suppliers={suppliers}
                itemCategories={itemCategories}
                itemSubCategories={getItemSubCategories()}
            />
            <ButtonRow alignRight={true} className={'add-button'}>
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" onClick={onAdd}></Button>
            </ButtonRow>
        </Card>
        {modalVisible && <ItemDictionaryModal
            initialEntry={editedEntry}
            onSave={onSave}
            hideModal={hideModal}
            itemCategories={itemCategories}
            suppliers={suppliers}
        />}
    </StyledItemDictionaryPage>;
};

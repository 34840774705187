import React, {FC, useRef} from "react";
import {translate} from '../../../common/lang/service/Translate.service';
import {Button} from 'primereact/button';
import {DayRange} from '../../../common/service/util/DayRange';
import {Day} from '../../../common/service/util/Day';
import {PeriodChanger} from '../../../common/components/periodChanger/PeriodChanger.component';
import {getChangePeriodByMonth} from '../../../common/components/periodChanger/service/PeriodChangeStrategy';
import {getMonthYearChangerDisplay} from '../../../common/components/periodChanger/service/PeriodChangerDisplay';
import {Card} from '../../../common/components/card/Card.component';
import {Toolbar} from 'primereact/toolbar';
import {ExpenseFiltersPanel} from "./expenseFilters/ExpenseFilters.component";
import {ExpenseFilters} from "../service/ExpensesPage.type";

interface ExpensesToolbarProps {
    onPeriodChange: (period: DayRange) => void;
    addExpense: () => void;
    downloadInvoices: () => void;
    hasFiles: boolean;
    expenseFilters: ExpenseFilters;
    setExpenseFilters: (name: string, value: any) => void;
}

export const ExpensesToolbar: FC<ExpensesToolbarProps> = (props: ExpensesToolbarProps) => {
    const op = useRef(null);
    const rightToolbarTemplate = () => {
        return (
            <>
                <Button onClick={(e) => op.current.toggle(e)} icon={"pi pi-filter"}
                        className={"p-button-secondary"}/>
                <Button disabled={!props.hasFiles} label={translate("DOWNLOAD_INVOICES")}
                        onClick={props.downloadInvoices} icon={"pi pi-download"}
                        className={"p-button-secondary ml-2"}/>
                <Button label={translate("ADD_EXPENSE")} onClick={props.addExpense} icon={"pi pi-plus"}
                        className={"p-button-success ml-2"}/>
            </>
        );
    };

    const leftToolbarPanel = () => {
        const startPeriod = new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay());
        return (
            <PeriodChanger
                startPeriod={startPeriod}
                onNextPeriod={props.onPeriodChange}
                onPreviousPeriod={props.onPeriodChange}
                dayInLastPeriod={startPeriod.getMiddleDay()}
                dayInFirstPeriod={Day.getCompanyStartDate()}
                changePeriod={getChangePeriodByMonth()}
                display={getMonthYearChangerDisplay()}
            />
        );
    }

    return <div className={"col-12"}>
        <Card>
            <Toolbar
                start={leftToolbarPanel}
                end={rightToolbarTemplate}
            />
        </Card>
        <ExpenseFiltersPanel
            refcostam={op}
            expenseFilters={props.expenseFilters}
            setExpenseFilters={props.setExpenseFilters}
        />
    </div>;
};

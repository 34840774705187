import React, {FC, useEffect, useState} from "react";
import {StyledTable} from "./Table.style";
import {ColumnProps, FilterType} from "./service/Table.types";
import {Column} from "primereact/column";
import {isDefined} from "../../service/util/ObjectUtils";
import {DataTableSortMeta} from "primereact/datatable";
import {MultiSelect} from 'primereact/multiselect';
import {getFilterOptions, getFiltersMetadata} from "./service/TableFilter.service";

interface TableProps {
    values: any[];
    dataKey: string;
    columnProps: ColumnProps[];
    multiSortMeta?: DataTableSortMeta[];
    onValueChange?: (updatedValues: any[]) => void;
    onRowClick?: (value: any) => void;
    className?: string;
}

export const Table: FC<TableProps> = (props: TableProps) => {
    const [filterOptions, setFilterOptions] = useState(getFilterOptions(props.values, props.columnProps));
    const [filters, setFilters] = useState(getFiltersMetadata(props.columnProps));

    useEffect(() => {
        setFilterOptions(getFilterOptions(props.values, props.columnProps))
    }, [props.values, props.columnProps]);

    const getHeaderStyle = (width: string, minWidth: string) => {
        return isDefined(width) || isDefined(minWidth) ? {width: width, minWidth: minWidth} : undefined;
    };

    const getBody = (columnProps: ColumnProps, rowData: any, column: any): React.ReactNode => {
        if (isDefined(columnProps.body)) {
            return columnProps.body(rowData, column.rowIndex)
        }

        if (isDefined(columnProps.transformValue)) {
            return <div className={columnProps.className}>{columnProps.transformValue(rowData[column.field])}</div>;
        }

        return <div className={columnProps.className}>{rowData[column.field]}</div>
    }
    const filterTemplate = (body: any) => {
        return (options: any) => {
            const filterOption = filterOptions[options.field];
            const onChange = (e: any) => {
                options.filterApplyCallback(e.value, options.index)
            };

            return <MultiSelect
                filter={filterOption.length > 5}
                maxSelectedLabels={2}
                value={options.value}
                options={filterOption}
                onChange={onChange}
                placeholder="Select"
                className="p-column-filter"
                showClear
                itemTemplate={body}
            />;
        };
    }

    return <StyledTable
        className={"datatable-responsive" + (isDefined(props.className) ? ' ' + props.className : '')}
        value={props.values}
        dataKey={props.dataKey}
        filters={filters}
        filterDisplay={'menu'}
        removableSort
        multiSortMeta={props.multiSortMeta}
        sortMode={'multiple'}
        rowHover={true}
        onValueChange={props.onValueChange}
        onRowClick={props.onRowClick ? (e) => props.onRowClick(e.data) : undefined}
    >
        {props.columnProps.map(columnProps => {
                return <Column
                    key={columnProps.header}
                    field={columnProps.fieldName}
                    header={columnProps.header}
                    sortable={columnProps.sortable}
                    filter={isDefined(columnProps.filterType)}
                    showApplyButton={false}
                    showFilterMenuOptions={false}
                    filterElement={columnProps.filterType === FilterType.SELECT ? filterTemplate(columnProps.filterBody) : undefined}
                    body={(rowData, column) => getBody(columnProps, rowData, column)}
                    headerStyle={getHeaderStyle(columnProps.width, columnProps.minWidth)}
                />;
            }
        )}
    </StyledTable>;
};

import React, {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {HomePage} from "../homePage/HomePage.component";
import {Test} from "../test/Test.component";
import {AppContainer} from "../common/components/appContainer/AppContainer.component";
import {PageNotFound} from "../pageNotFound/PageNotFound.component";
import {Security} from "../common/security/Security.component";
import {Protected} from "../common/components/protectedRoute/Protected";
import {GlobalPermissions} from "../common/security/globalPermissions/service/GlobalPermissions.type";
import {UserManagementPage} from "../administration/userManagementPage/UserManagementPage.component";
import {CashReportPage} from "../cashReportPage/CashReportPage.component";
import {usePermissions} from "../common/security/globalPermissions/service/Permissions.hook";
import {UserProfilePage} from "../userProfilePage/UserProfilePage.component";
import {CleaningPage} from "../cleaning/cleaningPage/CleaningPage.component";
import {CleaningDictionaryPage} from "../cleaning/cleaningDictionaryPage/CleaningDictionaryPage.component";
import {InventoryPage} from '../warehouse/inventoryPage/InventoryPage.component';
import {ItemDictionaryPage} from '../warehouse/itemDictionaryPage/ItemDictionaryPage.component';
import {SupplierDictionaryPage} from '../warehouse/supplierDictionaryPage/SupplierDictionaryPage.component';
import {OrderPage} from '../warehouse/orderPage/OrderPage.component';
import {CleaningUserReportPage} from '../cleaning/cleaningUserReportPage/CleaningUserReportPage.component';
import {CleaningItemReportPage} from '../cleaning/cleaningItemReportPage/CleaningItemReportPage.component';
import {ExpensesPage} from '../expenses/expensesPage/ExpensesPage.component';
import {RepeatableExpensesPage} from '../expenses/repeatableExpensesPage/RepeatableExpensesPage.component';
import {AppointmentsPage} from "../appointmentsPage/AppointmentsPage.component";
import {BankTransfersPage} from "../bankTransfersPage/BankTransfersPage.component";

interface AppRouterProps {
}

export const AppRouter: FC<AppRouterProps> = (props: AppRouterProps) => {
    const permissions: GlobalPermissions = usePermissions();

    return <Security>
        <AppContainer>
            <Routes>
                <Route
                    path="/"
                    element={<Protected element={<HomePage/>} canAccessRoute={permissions.canAccessHomePage}/>}
                />
                <Route
                    path="/test"
                    element={<Protected element={<Test/>} canAccessRoute={permissions.canAccessTestPage}/>}
                />
                <Route
                    path="/cash-report"
                    element={<Protected element={<CashReportPage/>}
                                        canAccessRoute={permissions.canManageCashReport}/>}
                />
                <Route
                    path="/bank-transfers"
                    element={<Protected element={<BankTransfersPage/>}
                                        canAccessRoute={permissions.canAccessBankTransfersPage}/>}
                />
                <Route
                    path="/cleaning"
                    element={<Protected element={<CleaningPage/>}
                                        canAccessRoute={permissions.canAccessCleaningPage}/>}
                />
                <Route
                    path="/cleaning/dictionary"
                    element={<Protected element={<CleaningDictionaryPage/>}
                                        canAccessRoute={permissions.canAccessCleaningDictionaryPage}/>}
                />
                <Route
                    path="/cleaning/user-report"
                    element={<Protected element={<CleaningUserReportPage/>}
                                        canAccessRoute={permissions.canAccessCleaningReportPage}/>}
                />
                <Route
                    path="/cleaning/item-report"
                    element={<Protected element={<CleaningItemReportPage/>}
                                        canAccessRoute={permissions.canAccessCleaningReportPage}/>}
                />
                <Route
                    path="/warehouse/inventory"
                    element={<Protected element={<InventoryPage/>}
                                        canAccessRoute={permissions.canAccessInventoryPage}/>}
                />
                <Route
                    path="/warehouse/order"
                    element={<Protected element={<OrderPage/>}
                                        canAccessRoute={permissions.canAccessOrderPage}/>}
                />
                <Route
                    path="/warehouse/item-dictionary"
                    element={<Protected element={<ItemDictionaryPage/>}
                                        canAccessRoute={permissions.canAccessItemDictionaryPage}/>}
                />
                <Route
                    path="/warehouse/supplier-dictionary"
                    element={<Protected element={<SupplierDictionaryPage/>}
                                        canAccessRoute={permissions.canAccessSupplierDictionaryPage}/>}
                />
                <Route
                    path="/expenses"
                    element={<Protected element={<ExpensesPage/>}
                                        canAccessRoute={permissions.canAccessExpensesPage}/>}
                />
                <Route
                    path="/appointments"
                    element={<Protected element={<AppointmentsPage/>}
                                        canAccessRoute={permissions.canAddAppointments}/>}
                />
                <Route
                    path="/others-appointments/:userId?"
                    element={<Protected element={<AppointmentsPage isForOtherUsers={true}/>}
                                        canAccessRoute={permissions.canAddAnyUserAppointments}/>}
                />
                <Route
                    path="/appointments"
                    element={<Protected element={<AppointmentsPage/>}
                                        canAccessRoute={permissions.canAddAppointments}/>}
                />
                <Route
                    path="/expenses/repeatable"
                    element={<Protected element={<RepeatableExpensesPage/>}
                                        canAccessRoute={permissions.canAccessExpensesPage}/>}
                />

                <Route
                    path="/user/:id/profile/*"
                    element={<UserProfilePage/>}
                />

                <Route
                    path="/user-management"
                    element={<Protected element={<UserManagementPage/>}
                                        canAccessRoute={permissions.canAccessUserManagementPage}/>}
                />
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </AppContainer>
    </Security>;
};

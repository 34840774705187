import styled from "styled-components";
import {isDefined} from "../../service/util/ObjectUtils";

export const StyledTitle = styled.div`
  ${props => isDefined(props.onClick) ? {cursor: "pointer"} : undefined }
  
  .invoice {
    padding: 0;
  }

  .invoice .invoice-to {
    margin-top: 0;
    padding-top: 0;
    border-top: unset;
  }
`;

import moment, {Moment} from "moment";
import {isNotDefined} from "./ObjectUtils";
import {Month} from "./Month";

export interface DayDTO {
    date: string;
}

export class Day {
    private static readonly dateFormat: string = "YYYY-MM-DD";
    private static readonly URLDateFormat: string = "YYYY-MM-DD";

    private constructor(private readonly date: Moment) {
    }

    static fromAny(date: any): Day {
        return (date instanceof Date) ? Day.fromDate(date) : Day.fromString(date);
    }

    static fromDay(day: Day, format: string = Day.dateFormat): Day {
        if (isNotDefined(day) || day instanceof Date) {
            throw new Error(`Invalid Day ${day}`);
        }
        return new Day(moment(day.getDate(), format).startOf('day'));
    }

    static fromDate(date: Date): Day {
        if (isNotDefined(date) || (date instanceof Date && isNaN(date.getDate()))) {
            // @ts-ignore
            return undefined;
        }

        return new Day(moment(date, Day.dateFormat).startOf('day'));
    }

    static fromURLString(date: string): Day {
        return Day.fromString(date, Day.URLDateFormat);
    }

    static from(year: number, month: Month, day: number): Day {
        if (isNotDefined(year) || isNotDefined(month) || isNotDefined(day)) {
            throw new Error('Invalid parameters');
        }
        return Day.fromDate(new Date(year, month.valueOf(), day));
    }

    static fromString(date: string, format: string = Day.dateFormat): Day {
        const result: Moment = moment(date, format, true).startOf('day');
        if (!result.isValid()) {
            // @ts-ignore
            return undefined;
        }

        return new Day(result.startOf('day'));
    }

    static nullableFromURLString(date: string): Day {
        return Day.fromString(date, Day.URLDateFormat);
    }

    static today(): Day {
        return new Day(moment().startOf('day'));
    }

    static max(d1: Day, d2: Day): Day {
        return d1.isAfterOrEqualDay(d2) ? d1 : d2;
    }

    static getCompanyStartDate = (): Day => {
        return Day.from(2022, Month.SEPTEMBER, 27);
    };

    static isCurrentMonth = (monthNumber: number, year: number): boolean => {
        const now: Day = Day.today();
        return now.getFullYear() === year && now.getMonthNumber() === monthNumber;
    };

    getDate(): Date {
        return this.date.toDate();
    }

    isAfterDate(date: Date): boolean {
        return this.isAfterDay(Day.fromDate(date));
    }

    isAfterDay(day: Day): boolean {
        return this.date.isAfter(day.getMoment());
    }

    isAfterOrEqualDate(date: Date): boolean {
        return this.isAfterOrEqualDay(Day.fromDate(date));
    }

    isAfterOrEqualDay(day: Day): boolean {
        return this.date.isSameOrAfter(day.getMoment(), "day");
    }

    isBeforeDate(date: Date): boolean {
        return this.isBeforeDay(Day.fromDate(date));
    }

    isBeforeDay(day: Day): boolean {
        return this.date.isBefore(day.getMoment());
    }

    isBeforeOrEqualDate(date: Date): boolean {
        return this.isBeforeOrEqualDay(Day.fromDate(date));
    }

    isBeforeOrEqualDay(day: Day): boolean {
        return this.date.isSameOrBefore(day.getMoment(), "day");
    }

    isBetweenDays(dayStart: Day, dayEnd: Day): boolean {
        return this.isAfterOrEqualDay(dayStart) && this.isBeforeOrEqualDay(dayEnd);
    }

    addMonths(numberOfMonths: number): Day {
        return new Day(this.date.clone().add(numberOfMonths, 'M'));
    }

    addDays(numberOfDays: number): Day {
        return new Day(this.date.clone().add(numberOfDays, 'd'));
    }

    addYears(numberOfYears: number): Day {
        return new Day(this.date.clone().add(numberOfYears, 'y'));
    }

    dayDiff(day: Day): number {
        return this.date.clone().diff(day.date, 'days');
    }

    getMonthFirstDay(): Day {
        return new Day(this.date.clone().startOf('month'));
    }

    getMonthLastDay(): Day {
        return new Day(this.date.clone().endOf('month'));
    }

    getWeekFirstDay(): Day {
        return new Day(this.date.clone().startOf('isoWeek'));
    }

    getWeekLastDay(): Day {
        return new Day(this.date.clone().endOf('isoWeek'));
    }

    getQuarterFirstDay(): Day {
        return new Day(this.date.clone().startOf('quarter'));
    }

    getQuarterLastDay(): Day {
        return new Day(this.date.clone().endOf('quarter'));
    }

    getYearFirstDay(): Day {
        return new Day(this.date.clone().startOf('year'));
    }

    getYearLastDay(): Day {
        return new Day(this.date.clone().endOf('year'));
    }

    getQuarter(): number {
        return Math.ceil((this.date.month() + 1) / 3);
    }

    getQuarterName(): string {
        return "Q" + this.getQuarter();
    }

    getFullYear(): number {
        return this.getDate().getFullYear();
    }

    getMonthNumber(): number {
        return this.getDate().getMonth() + 1;
    }

    getMonth(): Month {
        const month = Object.keys(Month)[this.getMonthNumber() - 1];
        return Month[month];
    }

    getDayNumber(): number {
        return this.getDate().getDate();
    }

    getDayBefore(): Day {
        return this.addDays(-1);
    }

    getDayAfter(): Day {
        return this.addDays(1);
    }

    toString(): string {
        return this.getDate().toLocaleDateString();
    }

    toFormattedString(format: string = Day.dateFormat): string {
        return moment(this.getDate()).format(format);
    }

    dateToUrlString(): string {
        return moment(this.getDate()).format(Day.URLDateFormat);
    }

    private getMoment(): Moment {
        return this.date.startOf('day');
    }

    isNotEmpty = (): boolean => {
        return this.getDate().toString() !== "NaN";
    };

    equals = (other: any): boolean => {
        return other instanceof Day && other.toFormattedString() === this.toFormattedString();
    };

    toJSON(): string {
        return this.dateToUrlString();
    }

    getWeekDayNumber(): number {
        return this.date.weekday();
    }
}

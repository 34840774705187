import React, {FC, useEffect, useState} from "react";
import {get, post} from "../common/service/http/Http.service";
import {UriBuilder} from "../common/service/util/UriBuilder.class";
import {UserBasicInfoDTO} from "../common/security/userBasicInfo/service/UserBasicInfo.type";
import {Translate} from "../common/lang/Translate.component";
import {useCurrentUser} from "../common/security/userBasicInfo/service/CurrentUser.hook";
import {Link} from "react-router-dom";
import {isDefined} from "../common/service/util/ObjectUtils";

interface TestProps {
}

export const Test: FC<TestProps> = (props: TestProps) => {
    const [testGet, setTestGet] = useState("start");
    const [testPost, setTestPost] = useState("start");
    const userBasicInfo: UserBasicInfoDTO = useCurrentUser();

    useEffect(() => {
        getcostam();
        postcostam();
    }, []);

    async function getcostam() {
        let newVar: string = await get(new UriBuilder().all("/test/get").build());
        setTestGet("get: " + newVar);
    }

    async function getError() {
        await get(new UriBuilder().all("/error/error").build());
    }

    async function getStappError() {
        await get(new UriBuilder().all("/error/stapp-error").build());
    }

    async function getMissingPrivilegeError() {
        await get(new UriBuilder().all("/error/missing-privilege").build());
    }

    async function getNotFound() {
        await get(new UriBuilder().all("/error/notfound").build());
    }

    async function getCommandResultSuccess() {
        await get(new UriBuilder().all("/error/successResult").build());
    }

    async function getCommandResultFailure() {
        await get(new UriBuilder().all("/error/failureResult").build());
    }

    async function getWarehouse() {
        await get(new UriBuilder().all("/warehouse-state/current").build());
    }

    async function postcostam() {
        let newVar: string = await post(new UriBuilder().all("/test/post").build());
        setTestPost("post " + newVar);
    }

    const fullName = isDefined(userBasicInfo.firstName)
        ? userBasicInfo.firstName + " " + userBasicInfo.lastName
        : userBasicInfo.email;

    return <>
        <div>{testGet}</div>
        <div>{testPost}</div>
        <div>{fullName}</div>
        <Translate id={"TEST_KEY"}/>
        <div>TEST REBUILD</div>
        <div><img src={userBasicInfo.avatarUrl} alt={""}/></div>
        <button onClick={getError}>Unexpected error</button>
        <button onClick={getStappError}>Stapp Error</button>
        <button onClick={getMissingPrivilegeError}>Missing privilege error</button>
        <button onClick={getNotFound}>Not found error</button>
        <button onClick={getCommandResultSuccess}>Command result success</button>
        <button onClick={getCommandResultFailure}>Command result failure</button>
        <button onClick={getWarehouse}>Warehouse</button>
        <Link to={"/user/dupa/profile"}>User profile page</Link>
    </>;
};

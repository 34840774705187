import {GlobalPermissionsRetrieved} from "./GlobalPermissions.type";
import {getInitialGlobalPermissions, GlobalPermissions} from "../service/GlobalPermissions.type";
import {UserBasicInfoType} from "../../redux/UserBasicInfoActions.type";

const initialState: GlobalPermissions = getInitialGlobalPermissions();

export const globalPermissionsReducer = (state = initialState,
                                         action: UserBasicInfoType): GlobalPermissions => {
    switch (action.type) {
        case GlobalPermissionsRetrieved:
            return action.globalPermissions;
        default:
            return state;
    }
};

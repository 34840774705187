import {SupplierDictionaryDTO} from './SupplierDictionaryPage.type';
import {get, post, put} from '../../../common/service/http/Http.service';
import {UriBuilder} from '../../../common/service/util/UriBuilder.class';
import {DictionaryDTO} from '../../../common/service/util/dictionary/Dictionary.type';
import {CommandResult} from '../../../common/service/result/Result.type';

export const getSuppliersRest = (): Promise<SupplierDictionaryDTO[]> => {
    return get<SupplierDictionaryDTO[]>(new UriBuilder()
        .all("suppliers")
        .all("dictionary")
        .build());
}

export const addSupplierRest = (supplierDto: SupplierDictionaryDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("suppliers")
        .build(), supplierDto);
}

export const updateSupplierRest = (id: string, supplierDto: SupplierDictionaryDTO): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .one("suppliers", id)
        .all("update")
        .build(), supplierDto);
}

export const getSuppliersSimpleDictionaryRest = (): Promise<DictionaryDTO[]> => {
    return get<SupplierDictionaryDTO[]>(new UriBuilder()
        .all("suppliers")
        .all("dictionary")
        .all("simple")
        .build());
}

import {Dispatch} from "redux";
import {AppViewConfigChange} from "../AppViewConfig.type";
import {AppActions} from "../../../../../../redux/AppActions";
import {AppState} from "../../../../../../redux/RootReducer";
import {AppViewConfigUpdated} from "./AppViewConfig.type";

const updateAppViewConfigAction = (change: AppViewConfigChange) => {
    return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
        dispatch(appViewConfigUpdated(change));
    };
};

const appViewConfigUpdated = (change: AppViewConfigChange): AppActions => {
    return {
        type: AppViewConfigUpdated,
        appViewConfigChange: change
    };
};

export default updateAppViewConfigAction;
